import { TableComponent } from '@energyweb/origin-ui-core';
import { Skeleton, Button, Box } from '@mui/material';
import React, { FC } from 'react';
import { useCompaniesPageEffects } from './VRCompaniesPage.effects';
import { useTranslation } from 'react-i18next';

export const VRCompaniesPage: FC = () => {
  const { t } = useTranslation();
  const { tableData, pageLoading, onCreateClick } = useCompaniesPageEffects();

  if (pageLoading) {
    return <Skeleton height={200} width="100%" />;
  }
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" flexDirection="row" justifyContent="start">
        <Button onClick={onCreateClick} variant="contained">
          {t('user.vrCompanies.company.create')}
        </Button>
      </Box>
      <TableComponent {...tableData} />
    </Box>
  );
};

export default VRCompaniesPage;
