import {
  CreateReservesSubmissionDTO,
  ProjectDTO,
  getProjectControllerGetQueryKey,
  reservesSubmissionControllerCreate,
  useProjectWellControllerPropagateWellData,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

export const useAutomatedWellRegistrationHandler = (project?: ProjectDTO) => {
  const { t } = useTranslation();
  const { mutate } = useProjectWellControllerPropagateWellData();

  const queryClient = useQueryClient();
  const projectKey = getProjectControllerGetQueryKey(project?.id);

  const submitHandler = async (files: File[]) => {
    let reservesSubmissionId = project?.reservesSubmission?.id;
    if (!reservesSubmissionId) {
      const reservesSubmissionData: CreateReservesSubmissionDTO = {
        uploads: [],
        projectId: project?.id,
      };
      const reservesSubmission = await reservesSubmissionControllerCreate(
        reservesSubmissionData
      );
      if (reservesSubmission?.id) {
        reservesSubmissionId = reservesSubmission.id;
      }
    }
    mutate(
      {
        reservesSubmissionId,
        data: { files, dataCy: 'automatedRegistration' },
      },
      {
        onSuccess: () => {
          showNotification(
            t('project.register.notifications.templateFileUploadSuccessfully'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(projectKey);
        },
        onError: (error: any) => {
          showNotification(
            t('project.register.notifications.templateFileUploadFailure'),
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return { submitHandler };
};
