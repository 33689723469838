import { useState, useEffect } from 'react';
import { NotificationStatus } from '@energyweb/origin-backend-core';

import {
  useNotificationGetAll,
  useUpdateNotificationsStatus,
} from '@energyweb/origin-ui-user-data';

export const useNotificationsPageEffects = () => {
  const { notifications: notificationsList, update } = useNotificationGetAll();
  const { submitHandler } = useUpdateNotificationsStatus();
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);

  const updateStatuses = () => {
    const ids = paginatedNotifications
      ?.filter(
        (notification) => notification.status === NotificationStatus.NotViewed
      )
      ?.map((notification: any) => notification.id);
    if (ids?.length) {
      submitHandler(ids);
      update().then((value: any) => {
        setNotifications(value.data);
      });
    }
  };

  useEffect(() => setNotifications(notificationsList), [notificationsList]);
  useEffect(updateStatuses, [notifications, page]);

  const paginate = <ContainerId, ItemId>(
    allItems: any[],
    itemsPerPage: number,
    currentPage: number
  ) => {
    return allItems
      ? allItems.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        )
      : [];
  };

  const paginatedNotifications = notifications?.length
    ? paginate(notifications, 10, page)
    : notifications;

  return {
    notifications: paginatedNotifications,
    pageCount: notifications?.length || 0,
    setPage,
  };
};
