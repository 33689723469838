import { useUserControllerUpdatePhoneNumber } from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';

export interface RequestChangePhoneNumberFormValues {
  verificationCode: string;
  phoneNumber: string;
}

export const useRequestChangePhoneNumberHandler = () => {
  const { mutate, isLoading: isMutating } =
    useUserControllerUpdatePhoneNumber();
  const { t } = useTranslation();

  const requestHandler = (values: RequestChangePhoneNumberFormValues) => {
    mutate(
      { data: values },
      {
        onSuccess: () => {
          showNotification(
            t('user.requestChangePhone.notifications.success'),
            NotificationTypeEnum.Success
          );
        },
        onError: (error: any) => {
          showNotification(
            `${t('user.requestChangePhone.notifications.error')}:
            ${error?.response?.data?.message || ''}
            `,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };
  return { requestHandler, isMutating };
};
