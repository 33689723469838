import {
  CarbonOffsetProjectType,
  QualifiedCredit,
} from '@energyweb/origin-backend-core';
import { FormSelectOption } from '@energyweb/origin-ui-core';

export const PROJECT_CARBON_TYPES_OPTIONS = [
  ...Object.values(CarbonOffsetProjectType).map((item) => ({
    value: item,
    label: item,
  })),
];

export const QUALIFIED_CREDIT_OPTIONS = [
  ...Object.values(QualifiedCredit).map((item) => ({
    value: item,
    label: item,
  })),
];

export const prepareConnectivityRiskOptions = (
  projectType: any
): FormSelectOption[] => {
  if (!projectType) {
    return [];
  }
  const CONNECTIVITY_RISK_OPTIONS: FormSelectOption[] =
    projectType?.options.map((option: any) => ({
      value: option.id,
      label: option.name,
      percent: option.percent,
    }));

  return CONNECTIVITY_RISK_OPTIONS;
};
