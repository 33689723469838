import { TableComponent } from '@energyweb/origin-ui-core';
import { Skeleton } from '@mui/material';
import React from 'react';
import { FC } from 'react';
import { useProjectOwnersPageEffects } from './ProjectOwnersPage.effects';

export const ProjectOwnersPage: FC = () => {
  const { tableData, pageLoading } = useProjectOwnersPageEffects();

  if (pageLoading) {
    return <Skeleton height={200} width="100%" />;
  }

  return <TableComponent {...tableData} />;
};

export default ProjectOwnersPage;
