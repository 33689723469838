import { useState } from 'react';
import { useExchangeAddress, useUser } from '@energyweb/origin-ui-user-data';
import { getNotViewedNotificationsCount } from '@energyweb/origin-ui-organization-data';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

export const useUserMenuEffects = () => {
  const [isUpdateRunning, setIsUpdateRunning] = useState(false);
  const { exchangeAddress } = useExchangeAddress();
  const { user } = useUser();
  let { count: notificationsCount, update } = getNotViewedNotificationsCount();

  const updateCount = () => {
    if (isUpdateRunning) return;
    setIsUpdateRunning(true);
    update().then((resp: any) => {
      notificationsCount = resp.data;
    });
    setTimeout(() => setIsUpdateRunning(false), 10000);
  };

  const copyValue = async (value: string) => {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(value);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = value;
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
    showNotification('Copied', NotificationTypeEnum.Info);
  };

  return {
    exchangeAddress,
    blockchainAddress: user?.blockchainAccountAddress,
    notificationsCount,
    updateCount,
    copyValue,
  };
};
