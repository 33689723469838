import React, { FC, memo } from 'react';
import { Box, Button, Toolbar } from '@mui/material';
import clsx from 'clsx';
import { TopBarButtonData } from '../TopBar';
import { useUser } from '@energyweb/origin-ui-user-data';
import { useStyles } from './DesktopTopBar.styles';
import { UserMenu } from '../../navigation';
import { NotificationMenu } from '../../notification';
import { ZeroSixLogoBlack } from '@energyweb/origin-ui-assets';
import { useNavigate } from 'react-router-dom';

type DesktopTopbarButton = Omit<TopBarButtonData, 'Icon'>;

export interface DesktopTopBarProps {
  buttons: DesktopTopbarButton[];
  toolbarClassName?: string;
}

export const DesktopTopBar: FC<DesktopTopBarProps> = memo(
  ({ buttons, toolbarClassName }) => {
    const classes = useStyles();
    const { user } = useUser();
    const navigate = useNavigate();
    return (
      <Toolbar className={clsx(toolbarClassName, classes.toolbar)}>
        <Box
          sx={{ display: { lg: 'block', xs: 'none' } }}
          className={classes.logobar}
        >
          <ZeroSixLogoBlack
            className={classes.logo}
            onClick={(_) => navigate('/account/')}
          />
        </Box>
        <Box flexGrow={1} />
        {user ? (
          <>
            <NotificationMenu />
            <UserMenu />
          </>
        ) : (
          buttons
            ?.filter((v) => v.show !== false)
            .map(({ label, onClick, dataCy }) => (
              <Button
                className={classes.button}
                key={label}
                onClick={onClick}
                data-cy={dataCy}
              >
                {label}
              </Button>
            ))
        )}
      </Toolbar>
    );
  }
);

DesktopTopBar.displayName = 'DesktopTopBar';
