import React, { FC, ReactNode } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { ZeroSixLogoWhite } from '@energyweb/origin-ui-assets';
import { GenericForm } from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { LoginPageLayout } from '../../containers';
import { useStyles } from './LoginPage.styles';
import { useLogInPageEffects } from './LoginPage.effects';
import { styles } from '../../common';

export interface LoginPageProps {
  bgImage?: string;
  formIcon?: ReactNode;
}

export const LoginPage: FC<LoginPageProps> = ({ bgImage }) => {
  const classes = useStyles();
  const formClasses = styles.formStyles.useStyles();
  const { t } = useTranslation();
  const { formProps, navigateToRegister, navigateToResetPassword } =
    useLogInPageEffects();

  return (
    <LoginPageLayout bgImage={bgImage}>
      <Box className={classes.paper}>
        <Box className={classes.logoContainer}>
          <ZeroSixLogoWhite />
        </Box>
        <Typography
          className={formClasses.formSubTitle + ' ' + classes.primaryText}
          variant="h4"
        >
          {t('user.login.formTitle')}
        </Typography>
        <GenericForm
          formClass={`${classes.form} ${formClasses.container}`}
          {...formProps}
        />
        <Box>
          <Box className={formClasses.bottomActions}>
            <Button variant="text" onClick={navigateToResetPassword}>
              {t('user.login.forgotPassword')}
            </Button>
          </Box>
          <Box className={formClasses.bottomActions}>
            <Typography className={classes.secondaryText}>
              {t('user.login.dontHaveAcc')}
            </Typography>
            <Button data-cy="register-now-button" onClick={navigateToRegister}>
              {t('user.login.registerNow')}
            </Button>
          </Box>
        </Box>
      </Box>
    </LoginPageLayout>
  );
};
