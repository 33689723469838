import React, { ReactNode } from 'react';
import { useStyles } from './PageWrapper.styles';
import { useLocation } from 'react-router-dom';

export interface PageWrapperProps {
  children: ReactNode;
}

export const PageWrapper = ({ children }: PageWrapperProps) => {
  const classes = useStyles();
  const location = useLocation();

  const exceptions =
    /\/project\/register|\/project\/\d+\/edit|\/project\/project-list|\/account\/settings\/profile|\/account\/settings\/wallet|\/verifier\/project-list|\/project\/all-project-list|\/project\/following-project-list/;
  const getWrapperClass =
    classes.wrapper + (exceptions.test(location.pathname) ? ' pd-small' : '');
  return <div className={getWrapperClass}>{children}</div>;
};
