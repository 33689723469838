import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    width: 75,
    height: 'fit-content',

    '& > .MuiDrawer-paper': {
      width: 355,
      transition: 'width 0.3s ease-in',
      boxShadow: '0px 10px 8px rgba(0, 0, 0, 0.1)',
      borderRight: 'none',
      marginTop: 81,
    },

    '& .accountMenuButton': {
      position: 'fixed',
      bottom: 15,
      width: 321,
    },

    '&.closed': {
      '& .MuiBackdrop-root': {
        display: 'none',
      },
      '& > .MuiDrawer-paper': {
        boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.1)',
        width: 75,
      },
      '& .accountMenuButton': {
        width: '75px !important',
        transition: 'width 0.3s ease-in',
      },
    },
  },
  userAndOrg: {
    margin: '0 10px 20px 20px',
  },
  list: {
    padding: 17,
    height: '-webkit-fill-available',
    '& .MuiListItem-root': {
      overflow: 'hidden',
      '& .MuiButton-root': {
        transition: 'font-size 0.4s ease-in-out',
      },
    },
    '&.closed .MuiListItem-root .MuiButton-root': {
      // transition: 'font-size 0.3s ease-in',
      fontSize: 0,
    },
  },
});
