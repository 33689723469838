import * as yup from 'yup';
import { TSupplementalDataForm } from './types';
import { SupplementalDataForm } from '@energyweb/origin-ui-organization-view';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import React from 'react';
import { prepareConnectivityRiskOptions } from '../select-options/projectTypes';

const SupplementalDataFormWrapper = (project: ProjectDTO) => (props: any) =>
  <SupplementalDataForm {...props} project={project} />;

export const supplementalDataForm: TSupplementalDataForm = (
  t,
  project?: ProjectDTO
) => {
  const supplementalData = project?.supplementalData;
  const projectType = project?.carbonContent?.type;
  const CONNECTIVITY_RISK_OPTIONS = prepareConnectivityRiskOptions(projectType);

  return {
    formTitle: t('project.register.supplementalDataTitle'),
    inputsVariant: 'outlined',
    initialValues: {
      oilReservesEstimation: supplementalData?.oilReservesEstimation || null,
      gasReservesEstimation: supplementalData?.gasReservesEstimation || null,
      riskOptionId: supplementalData?.connectivityRiskOption?.id || null,
      uploads: supplementalData?.uploads || [],
      projectId: project?.id,
    },
    validationSchema: yup.object().shape({
      oilReservesEstimation: yup
        .number()
        .moreThan(0)
        .required()
        .label(t('project.register.supplementalData.oilReservesEstimation')),
      gasReservesEstimation: yup
        .number()
        .moreThan(0)
        .required()
        .label(t('project.register.supplementalData.gasReservesEstimation')),
      riskOptionId: yup
        .number()
        .required()
        .label(t('project.register.supplementalData.riskOptionId')),
    }),
    fields: [
      {
        name: 'riskOptionId',
        label: t('project.register.supplementalData.riskOptionId'),
        required: true,
        select: true,
        options: CONNECTIVITY_RISK_OPTIONS,
        inputProps: { ['data-cy']: 'riskOptionId' },
        helperText: 'Select connetivity risk option',
      },
      {
        name: 'oilReservesEstimation',
        label: t('project.register.supplementalData.oilReservesEstimation'),
        required: true,
        inputProps: { ['data-cy']: 'oilReservesEstimation' },
        placeholder: '0',
        helperText: '(bbl)',
        tooltipLabel: t('project.register.tooltips.oilReservesEstimation'),
        endAdornment: { isValidCheck: false, element: 'Mscf' },
      },
      {
        name: 'gasReservesEstimation',
        label: t('project.register.supplementalData.gasReservesEstimation'),
        required: true,
        inputProps: { ['data-cy']: 'gasReservesEstimation' },
        placeholder: '0',
        helperText: '(bbl)',
        tooltipLabel: t('project.register.tooltips.gasReservesEstimation'),
        endAdornment: { isValidCheck: false, element: 'Mscf' },
      },
    ],
    buttonText: t('general.buttons.save'),
    component: SupplementalDataFormWrapper(project),
    customStep: true,
  };
};
