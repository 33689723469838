import { fileControllerUpload } from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

export const fileUploadHandler = async (file: Blob[]): Promise<any> => {
    return await fileControllerUpload({ files: file }).catch((error) => {
      showNotification(
        error?.response?.data?.message || '',
        NotificationTypeEnum.Error
      );
    });
};
