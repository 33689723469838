import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  form: {
    width: 250,
    margin: '10px 5px 0',
  },
  select: {
    padding: 0,
    width: 250,
    minHeight: 36,
    '& .MuiSelect-select': {
      minHeight: 'unset',
      '& .MuiInputLabel-root': {
        padding: '5px 0',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '5px 5px',
    },
  },
  label: {
    fontSize: 14,
    color: '#2B201F',
  },
  chip: {
    height: 25,
    backgroundColor: '#78CC8A',
    fontFamily: theme.typography.fontFamily,
  },
}));
