import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { useValidationProjectListingEffects } from './ValidationProjectsListingPage.effects';
import { ProjectListingFormsLayout } from '../../containers/layout/ProjectListingFormWrapper';

export const ValidationProjectsListingPage: FC = () => {
  const { t } = useTranslation();
  const {
    projects,
    header,
    actions,
    isLoading,
    dataToFilter,
    filterFields,
    applyFilters,
  } = useValidationProjectListingEffects();

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <ProjectListingFormsLayout
      projects={projects}
      title={t('project.listing.validationProjects')}
      header={header}
      actions={actions}
      dataToFilter={dataToFilter}
      filterFields={filterFields}
      applyFilters={applyFilters}
    />
  );
};

export default ValidationProjectsListingPage;
