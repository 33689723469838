import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  action: {
    padding: '10px 23px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    '& .Mui-disabled': {
      backgroundColor: '#78CC8A4012',
    },
  },
  ownersTable: {
    borderCollapse: 'collapse',
    borderBottom: 'none',
  },
  defaultHead: {
    '&& tr': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: '#F8FDFE30',
    },
  },
  ownersTableBody: {
    '&& .MuiTableRow-root': {
      borderTop: `1px solid #E4E7EB`,
      backgroundColor: '#F8FDFE30',
    },
    '&& .MuiTableCell-root': {
      color: theme.palette.text.primary,
    },
  },
  warningText: {
    fontFamily: 'Gotham',
    color: '#A1BFA8',
    margin: '10px 0',
  },
}));
