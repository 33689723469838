import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataField: {
    '&& label': {
      color: '#676767',
      fontSize: '14px',
    },
    '&& input': {
      color: '#3F4246',
      textFillColor: '#3F4246',
    },
    '&& .Mui-disabled:before': {
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.primary.light,
    },
  },
  dataFieldOffset: {
    paddingRight: '45px',
  },
}));
