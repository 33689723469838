import { useTranslation } from 'react-i18next';
import { TProjectVerifiedSuccessfulLogic } from './types';

export const useProjectVerifiedSuccessfulLogic: TProjectVerifiedSuccessfulLogic =
  (closeModal) => {
    const { t } = useTranslation();
    return {
      buttons: [
        {
          label: t('general.buttons.ok'),
          onClick: closeModal,
        },
      ],
    };
  };
