export const paginate = <ContainerId, ItemId>(
  allItems: any[],
  itemsPerPage: number,
  currentPage: number
) => {
  return allItems
    ? allItems.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    : [];
};
