import { PropsWithChildren, useEffect } from 'react';
import {
  ProjectDTO,
  UserDTO,
} from '@energyweb/origin-backend-react-query-client';
import L from 'leaflet';
import { isRole, Role } from '@energyweb/origin-backend-core';

export interface ProjectsMapProps {
  pendingProjects: ProjectDTO[];
  verifiedProjects: ProjectDTO[];
  rejectedProjects: ProjectDTO[];
  changeRequestedProjects: ProjectDTO[];
  user?: UserDTO;
  title?: string;
}

export type TProjectMapEffects = (
  props: PropsWithChildren<ProjectsMapProps>
) => {
  pendingProjectMarkers: any[];
  changeRequestedProjectMarkers: any[];
  pendingProjectsCount: number;
  changeRequestedProjectsCount: number;
  verifiedProjectsCount: number;
  userIsProjectOwner: boolean;
  noCarbonCredit: boolean;
  title?: string;
};

export const useProjectMapEffects: TProjectMapEffects = ({
  pendingProjects,
  verifiedProjects,
  changeRequestedProjects,
  user,
}) => {
  const userIsProjectOwner = isRole(user, Role.ProjectOwner);
  const markers: any = [];

  const groupMarkers = (wellMarkers: any[], icon: any) => {
    return wellMarkers?.map(
      (marker) => markers.push(L.marker(marker, { icon, title: marker })) || []
    );
  };

  const setMarkers = (projects: ProjectDTO[]) => {
    return projects
      ?.flatMap((project: ProjectDTO) => project?.reservesSubmission?.wells)
      .reduce((markers, well) => {
        if (well?.holeLatitude && well?.holeLongitude) {
          markers.push([well.holeLatitude, well.holeLongitude]);
        }
        return markers;
      }, []);
  };

  const pendingProjectMarkers = setMarkers(pendingProjects);
  const changeRequestedProjectMarkers = setMarkers(changeRequestedProjects);
  const verifiedProjectMarkers = setMarkers(verifiedProjects);

  const orangeIcon = new L.Icon({
    iconSize: [25, 41],
    iconUrl:
      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
  });

  const greenIcon = new L.Icon({
    iconSize: [25, 41],
    iconUrl:
      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  });

  const greyIcon = new L.Icon({
    iconSize: [25, 41],
    iconUrl:
      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png',
  });

  useEffect(() => {
    if (pendingProjectMarkers?.length) {
      groupMarkers(pendingProjectMarkers, greyIcon);
    }
  }, [pendingProjectMarkers]);

  useEffect(() => {
    if (changeRequestedProjectMarkers?.length) {
      groupMarkers(changeRequestedProjectMarkers, orangeIcon);
    }
  }, [changeRequestedProjectMarkers]);

  useEffect(() => {
    if (userIsProjectOwner && verifiedProjectMarkers?.length) {
      groupMarkers(verifiedProjectMarkers, greenIcon);
    }
  }, [user, verifiedProjectMarkers]);

  useEffect(() => {
    const container: any = L.DomUtil.get('map');

    if (container != null) {
      container._leaflet_id = null;
    }
    const map = L.map('map', { zoom: 4 }).setView([38.5, -98.35]); // The latitude and longitude of Texas
    map.dragging.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();

    const layer = L.tileLayer(
      'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    ).addTo(map);

    if (markers.length) {
      const group = L.featureGroup(markers).addTo(map);
      map.fitBounds(group.getBounds(), { maxZoom: 5 });
    } else {
      layer.setOpacity(0.1);
      map.zoomControl.remove();
    }
  }, [markers]);

  return {
    pendingProjectMarkers,
    changeRequestedProjectMarkers,
    pendingProjectsCount: pendingProjects?.length || 0,
    changeRequestedProjectsCount: changeRequestedProjects?.length || 0,
    userIsProjectOwner,
    verifiedProjectsCount: verifiedProjects?.length || 0,
    noCarbonCredit:
      !pendingProjects?.length &&
      !changeRequestedProjects?.length &&
      !verifiedProjects?.length,
  };
};
