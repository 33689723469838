import { GenericModalProps } from '@energyweb/origin-ui-core';
import { useStyles } from './ConfirmTokenOrder.styles';

import {
  OrganizationModalsActionsEnum,
  useOrgModalsDispatch,
  useOrgModalsStore,
} from '../../../context';
import { useOrderCreditHandler } from '@energyweb/origin-ui-organization-data';

export const useConfirmTokenOrderEffects = () => {
  const classes = useStyles();

  const { confirmTokenPurchase } = useOrgModalsStore();
  const { open, tokenToBuy } = confirmTokenPurchase;

  const dispatchModals = useOrgModalsDispatch();
  const { submitHandler } = useOrderCreditHandler();

  const closeModal = () => {
    dispatchModals({
      type: OrganizationModalsActionsEnum.SHOW_CONFIRM_TOKEN_PURCHASE,
      payload: {
        open: false,
        tokenToBuy: null,
      },
    });
  };

  const onSubmit = () => {
    submitHandler(tokenToBuy);
    closeModal();
  };

  const dialogProps: GenericModalProps['dialogProps'] = {
    maxWidth: 'sm',
    classes,
  };

  return { tokenToBuy, open, dialogProps, closeModal, onSubmit };
};
