import { Box } from '@mui/material';
import React, { FC } from 'react';
import { DesktopTopBar } from '../DesktopTopBar';
import { MobileTopBar } from '../MobileTopBar';

export type TopBarButtonData = {
  label: string;
  Icon: FC;
  onClick: () => void;
  show: boolean;
  dataCy?: string;
};

export interface TopBarProps {
  buttons: TopBarButtonData[];
  onMobileNavOpen: () => void;
  toolbarClassName?: string;
}

export const TopBar: FC<TopBarProps> = ({
  buttons,
  onMobileNavOpen,
  toolbarClassName = '',
}) => {
  return (
    <>
      <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
        <DesktopTopBar buttons={buttons} toolbarClassName={toolbarClassName} />
      </Box>
      <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
        <MobileTopBar
          buttons={buttons}
          onMobileNavOpen={onMobileNavOpen}
          toolbarClassName={toolbarClassName}
        />
      </Box>
    </>
  );
};
