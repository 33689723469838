import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttonGroup: {
    marginTop: 31,
    marginLeft: 'auto',
    padding: '0 12px',
    display: 'flex',
    justifyContent: 'space-evenly',
    '& .applyBtn': {
      background: '#78CC8A',
      marginLeft: 7,
      height: 37,
      width: 109,
    },
    '& .clearBtn': {
      width: 109,
      height: 37,
    },
    '@media (max-width: 1500px)': {
      flexBasis: '100%',
      justifyContent: 'flex-start',
    },
  },
}));
