import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import * as yup from 'yup';
import { TRoyaltyOwnerFormValues } from './types';

export const royaltyOwnerForm: TRoyaltyOwnerFormValues = (
  t,
  project?: ProjectDTO
) => ({
  formTitle: t('project.register.submission'),
  inputsVariant: 'outlined',
  initialValues: {
    name: '',
    uploads: [],
    ownershipId: project?.ownership?.id,
  },
  validationSchema: yup.object().shape({
    name: yup
      .string()
      .required()
      .label(t('project.register.royaltyOwner.name')),
  }),
  fields: [
    {
      name: 'name',
      label: t('project.register.royaltyOwner.name'),
      required: true,
      inputProps: { ['data-cy']: 'name' },
      placeholder: 'Add Royalty Owner',
      helperText: 'Example  - Operating Partner',
      tooltipLabel: t('project.register.royaltyOwner.name'),
    },
    {
      name: 'uploads',
      file: true,
      label: t('project.register.ownership.uploadMineralRight'),
      required: true,
      inputProps: { ['data-cy']: 'uploads' },
    },
  ],
  validationMode: 'onSubmit',
  buttonText: t('general.buttons.save'),
  customStep: true,
});
