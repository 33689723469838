import { GenericForm } from '@energyweb/origin-ui-core';
import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginPageLayout } from '../../containers';
import { useRequestResetPasswordPageEffects } from './RequestResetPasswordPage.effects';
import { useStyles } from './RequestResetPasswordPage.styles';
import { styles, AuthBackButton } from '../../common';

export interface RequestResetPasswordPageProps {
  bgImage?: string;
}

export const RequestResetPasswordPage: FC<RequestResetPasswordPageProps> = ({
  bgImage,
}) => {
  const { t } = useTranslation();
  const { formProps, navigateToLogin } = useRequestResetPasswordPageEffects();
  const classes = useStyles();
  const formClasses = styles.formStyles.useStyles();

  return (
    <LoginPageLayout bgImage={bgImage} contentClass={classes.pageContainer}>
      <Box className={classes.navigationContainer}>
        <AuthBackButton onClick={navigateToLogin} />
      </Box>
      <Box className={classes.paper}>
        <Typography
          className={formClasses.formSubTitle + ' ' + classes.primaryText}
          variant="h4"
        >
          {t('user.requestResetPassword.formTitle')}
        </Typography>
        <Typography
          className={formClasses.formSubTitle + ' ' + classes.secondaryText}
          variant="h5"
        >
          {t('user.requestResetPassword.formSubtitle')}
        </Typography>
        <GenericForm
          formClass={`${classes.form} ${formClasses.container}`}
          {...formProps}
        />
      </Box>
      <Box></Box>
    </LoginPageLayout>
  );
};
