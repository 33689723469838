import { getProjectVerifierControllerIsProjectVerifierQueryKey } from '@energyweb/issuer-irec-api-react-query-client';
import {
  RegisterProjectVerifierDTO,
  useProjectVerifierControllerUpdate,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useApiUpdateProjectVerifierData = (id: number) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const verifierQueryKey =
    getProjectVerifierControllerIsProjectVerifierQueryKey();

  const { mutate, isLoading } = useProjectVerifierControllerUpdate();

  const submitHandler = (values: RegisterProjectVerifierDTO) => {
    mutate(
      { id, data: { ...values } },
      {
        onSuccess: () => {
          showNotification(
            t('user.projectVerifier.notifications.updatedSuccess'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(verifierQueryKey);
        },
        onError: (error: any) => {
          showNotification(
            `${t('user.projectVerifier.notifications.updatedFailure')}:
                  ${error?.response?.data?.message || ''}
                  `,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return {
    submitHandler,
    isLoading,
  };
};
