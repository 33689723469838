import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: 'none',
    '& .MuiGrid-root': {
      justifyContent: 'flex-end',
      boxShadow: 'none',
      '& .MuiIconButton-root': {
        color: theme.palette.text.primary,
      },
    },
    '& .MuiBox-root': {
      margin: '10px 0',
      '& .MuiTypography-root': {
        fontFamily: theme.typography.fontFamily,
        lineHeight: 1.7,
      },
      '& .MuiTypography-body1': {
        fontSize: 15,
      },
      '& .MuiTypography-h5': {
        padding: '10px 0',
      },
      '& .MuiListItem-root': {
        color: theme.palette.text.primary,
        display: 'list-item',
        padding: '4px 0',
      },
    },
  },
}));
