import {
  ProjectDTO,
  UploadDto,
} from '@energyweb/origin-backend-react-query-client';
import { ProjectStatus, UploadStatus } from '@energyweb/origin-backend-core';
import { useEffect, useState } from 'react';
import {
  Documents,
  NotificationTypeEnum,
  UploadedFile,
  showNotification,
} from '@energyweb/origin-ui-core';
import { handleFileUpload } from '@energyweb/origin-ui-utils';
import { useProjectExecutionHandler } from '@energyweb/origin-ui-organization-data';
import {
  OrganizationModalsActionsEnum,
  useOrgModalsDispatch,
} from '../../context';
import { t } from 'i18next';

export const useProjectAdditionalDocumentsEffects = (project: ProjectDTO) => {
  const [documents, setDocuments] = useState<Documents>({});

  const { submitHandler } = useProjectExecutionHandler(project);
  const dispatchModals = useOrgModalsDispatch();

  const uploads: UploadDto[] = [
    ...project?.uploads,
    ...project?.reservesSubmission?.uploads,
  ];

  useEffect(() => {
    if (uploads?.length) {
      const documents = Object.assign(
        {},
        ...uploads
          .filter(
            (uploadedFile: UploadDto) =>
              uploadedFile.dataCy !== 'wellLevelReserves' &&
              uploadedFile.dataCy !== 'monthlyForecast'
          )
          .map((file: any) => ({
            [file.dataCy]: [{ name: file.name } as File],
          }))
      );
      setDocuments({ ...documents });
    }
  }, [project]);

  const uploadFunction = async (file: File[], dataCy: string) => {
    setDocuments({
      ...documents,
      [dataCy]: file,
    });
    return documents;
  };

  const onChange = (files: UploadedFile[]) => {
    const file = files[files?.length - 1] || ({} as UploadedFile);
    if (file?.removed) {
      delete documents[file?.dataCy];
      setDocuments({ ...documents });
    }
  };

  const getUploadedFiles = (dataCy: string) => {
    const uploadedFiles = uploads || [];
    const file = uploadedFiles?.find((file: any) => file.dataCy === dataCy);
    return file ? [{ name: file?.name } as File] : [];
  };

  const onSubmit = async () => {
    try {
      const uploadedFiles = await handleFileUpload(uploads, documents);
      const changeRequestedFile = uploadedFiles.find(
        (file) => file.status === UploadStatus.ChangeRequest
      );

      if (changeRequestedFile) {
        showNotification(
          `${t('project.register.notifications.executionNotStarted')}`,
          NotificationTypeEnum.Warning
        );
        return;
      }
      if (uploadedFiles?.length)
        submitHandler(uploadedFiles).then(() => {
          dispatchModals({
            type: OrganizationModalsActionsEnum.SHOW_PROJECT_VERIFIED_SUCCESSFUL,
            payload: {
              open: true,
              text: t('organization.modals.projectVerified.projectOwner'),
            },
          });
        });
    } catch (error: any) {
      showNotification(
        `${error?.response?.data?.message || ''}`,
        NotificationTypeEnum.Error
      );
    }
  };

  const showSection = (dataCy: string) => {
    const changeRequestedFile = uploads.some(
      (file: UploadDto) =>
        file.dataCy == dataCy && file.status == UploadStatus.ChangeRequest
    );
    return (
      project?.projectStatus === ProjectStatus.Verified ||
      (project?.projectStatus == ProjectStatus.ChangeRequest &&
        changeRequestedFile)
    );
  };

  return {
    uploadFunction,
    onChange,
    onSubmit,
    showSection,
    getUploadedFiles,
    documents,
    uploads,
  };
};
