import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { useRegisterProjectFormLogic } from '@energyweb/origin-ui-organization-logic';

export const useRegisterPageEffects = (project: ProjectDTO) => {
  const formsLogic = useRegisterProjectFormLogic(project);

  const formsWithDocsUpload = formsLogic.forms.map((form) =>
    form.customStep
      ? {
          ...form,
          component: form.component,
        }
      : form
  );

  const formData = {
    ...formsLogic,
    forms: formsWithDocsUpload,
  };

  return { formData };
};
