import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ZeroSixLogoWhite } from '@energyweb/origin-ui-assets';
import { LoginPageLayout } from '@energyweb/origin-ui-user-view';
import { useStyles } from './DesktopBanner.styles';

interface DesktopBannerProps {
  children: JSX.Element[];
  isAuthenticated: boolean;
  bgImage?: any;
}

export const DesktopBanner: React.FC<DesktopBannerProps> = ({
  children,
  isAuthenticated,
  bgImage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={isAuthenticated ? classes.container : ''}>{children}</div>
      {isAuthenticated && (
        <div className={classes.banner}>
          <LoginPageLayout bgImage={bgImage}>
            <Box className={classes.logoContainer}>
              <ZeroSixLogoWhite />
            </Box>
            <Typography
              className={classes.formSubTitle + ' ' + classes.primaryText}
              variant="h5"
            >
              {t('user.banner.mobileNotSupported')}
            </Typography>
          </LoginPageLayout>
        </div>
      )}
    </>
  );
};
