import { useGetExecutingProjects } from '@energyweb/origin-ui-user-data';
import { useNavigate } from 'react-router';

export const useExecutingProjectsPageEffects = () => {
  const { projects, isLoading } = useGetExecutingProjects();
  const navigate = useNavigate();

  const viewProject = (projectId: number) => {
    if (projectId) {
      navigate(`/admin/executing-projects/${projectId}`);
    }
  };

  return {
    projects,
    pageLoading: isLoading,
    viewProject,
  };
};
