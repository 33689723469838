import {
  useProjectControllerDelete,
  ProjectDTO,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';

export const useDeleteProject = () => {
  const { t } = useTranslation();
  const { mutate } = useProjectControllerDelete();

  const submitHandler = (id: number) => {
    mutate(
      { id },
      {
        onSuccess: () => {
          showNotification('', NotificationTypeEnum.Success);
        },
        onError: (error: any) => {
          showNotification('', NotificationTypeEnum.Error);
        },
      }
    );
  };
  return { submitHandler };
};
