import React, { FC, ReactNode } from 'react';
import { Route, Routes } from 'react-router';
import { PageNotFound } from '@energyweb/origin-ui-core';

import {
  LoginPage,
  PhoneVerificationPage,
  RequestResetPasswordPage,
  ResetPasswordPage,
  RegisterPage,
} from './pages';
import { UserModalsProvider } from './context';
import { AuthModalsCenter } from './containers/modals';

export type LoginRoutesConfig = {
  showLoginPage: boolean;
  showRequestResetPasswordPage: boolean;
  showResetPasswordPage: boolean;
  showPhoneVerificationPage: boolean;
  showRegister: boolean;
};

export interface LoginAppProps {
  routesConfig: LoginRoutesConfig;
  loginPageBgImage?: string;
  loginFormIcon?: ReactNode;
}

export const LoginApp: FC<LoginAppProps> = ({
  routesConfig,
  loginPageBgImage,
  loginFormIcon,
}) => {
  const {
    showLoginPage,
    showRequestResetPasswordPage,
    showResetPasswordPage,
    showPhoneVerificationPage,
    showRegister,
  } = routesConfig;

  return (
    <UserModalsProvider>
      <Routes>
        {showRegister && (
          <Route
            path="register"
            element={
              <RegisterPage
                bgImage={loginPageBgImage}
                formIcon={loginFormIcon}
              />
            }
          />
        )}
        {showLoginPage && (
          <Route
            path="/"
            element={
              <LoginPage bgImage={loginPageBgImage} formIcon={loginFormIcon} />
            }
          />
        )}
        {showRequestResetPasswordPage && (
          <Route
            path="request-password-reset"
            element={<RequestResetPasswordPage bgImage={loginPageBgImage} />}
          />
        )}
        {showResetPasswordPage && (
          <Route
            path="reset-password"
            element={<ResetPasswordPage bgImage={loginPageBgImage} />}
          />
        )}
        {showPhoneVerificationPage && (
          <Route
            path="verify-phone"
            element={<PhoneVerificationPage bgImage={loginPageBgImage} />}
          />
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <AuthModalsCenter />
    </UserModalsProvider>
  );
};
