import { useNotificationControllerGetAll } from '@energyweb/origin-backend-react-query-client';

export const useNotificationGetAll = () => {
  const { data: notifications, refetch: update } =
    useNotificationControllerGetAll();
  return {
    notifications,
    update,
  };
};
