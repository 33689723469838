import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingBottom: 30,
  },
  header: {
    color: '#2B201F',
    fontSize: 24,
    marginBottom: 25,
    marginLeft: 30,
  },
  paper: {
    background: '#FEFFFF',
    boxShadow: '0px 6px 24px rgba(25, 29, 56, 0.16)',
    padding: 36,
  },
  summaryHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '40px',
  },
  projectNameLabel: {
    color: '#CD723E',
  },
  action: {
    padding: '10px 23px',
    borderRadius: '33px',
    '& .Mui-disabled': {
      backgroundColor: '#78CC8A4012',
    },
  },
  actionSecondary: {
    padding: '10px 23px',
    borderRadius: '33px',
    '&& .Mui-disabled': {
      backgroundColor: '#F58025',
    },
  },
  generalAction: {
    padding: '10px 100px !important',
  },
  headerWithSlot: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
