import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  form: {
    background: '#FEFFFF',
    boxShadow: '0px 6px 24px rgba(25, 29, 56, 0.16)',
    padding: '30px 35px',
    position: 'relative',
  },
});
