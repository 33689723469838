import {
  CreateReservesSubmissionDTO,
  CreateWellDTO,
  getProjectControllerGetQueryKey,
  ProjectDTO,
  reservesSubmissionControllerCreate,
  useProjectWellControllerCreate,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useProjectWellRegisterHandler = (project?: ProjectDTO) => {
  const { t } = useTranslation();
  const { mutate } = useProjectWellControllerCreate();

  const queryClient = useQueryClient();
  const projectKey = getProjectControllerGetQueryKey(project?.id);

  const submitHandler = async (values: CreateWellDTO) => {
    const formattedValues: CreateWellDTO = {
      ...values,
    };
    formattedValues.reservesSubmissionId = project?.reservesSubmission?.id;
    const reservesSubmissionData: CreateReservesSubmissionDTO = {
      uploads: [],
      projectId: project?.id,
    };

    if (!formattedValues.reservesSubmissionId) {
      const reservesSubmission = await reservesSubmissionControllerCreate(
        reservesSubmissionData
      );
      if (reservesSubmission?.id) {
        formattedValues.reservesSubmissionId = reservesSubmission.id;
      }
    }
    mutate(
      { data: formattedValues },
      {
        onSuccess: () => {
          showNotification(
            t('project.register.notifications.wellRegisteredSuccess'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(projectKey);
        },
        onError: (error: any) => {
          if (error?.response?.status >= 400) {
            showNotification(
              error?.response?.data?.message,
              NotificationTypeEnum.Error
            );
          } else {
            showNotification(
              `${t('project.register.notifications.wellRegisteredFailure')}:
                ${error?.response?.data?.message || ''}
                `,
              NotificationTypeEnum.Error
            );
          }
        },
      }
    );
  };

  return { submitHandler };
};
