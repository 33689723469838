import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@mui/material';
import { TableComponent } from '@energyweb/origin-ui-core';
import { useStyles } from './ProjectListingFormsLayout.styles';
import { SelectRegular, FiltersForm } from '@energyweb/origin-ui-core';
import { ProjectDTO } from '@energyweb/origin-organization-irec-api-react-query-client';
import dayjs from 'dayjs';

export interface ProjectListingFormsProps {
  projects: ProjectDTO[];
  title?: string;
  header: any;
  headerProps?: any;
  actions: any;
  orderByProps?: any;
  ascProps?: any;
  dataToFilter?: any;
  filterFields?: any;
  applyFilters?: any;
}

export const ProjectListingFormsLayout: FC<ProjectListingFormsProps> = ({
  projects,
  title,
  header,
  actions,
  headerProps,
  dataToFilter,
  filterFields,
  applyFilters,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>{title}</Typography>
      <Grid container spacing={3}>
        <Grid item classes={{ root: classes.paper }}>
          <Box className={classes.box}>
            {dataToFilter && (
              <FiltersForm
                dataToFilter={dataToFilter}
                filterFields={filterFields}
                applyFilters={applyFilters}
              />
            )}
            <TableComponent
              key={projects.length}
              header={header}
              headerProps={headerProps}
              data={
                projects
                  ? projects.map((project, i) => {
                      const formData: any = {
                        id: project.id || i,
                        name: project.name,
                        location: project.location,
                        createdAt: dayjs(project?.createdAt).format(
                          'MM/DD/YYYY'
                        ),
                      };
                      header.projectStatus &&
                        (formData['projectStatus'] = (
                          <div
                            className={
                              classes.status + ' ' + project.projectStatus
                            }
                          >
                            {project.projectStatus}
                          </div>
                        ));
                      header.totalOffset &&
                        (formData['totalOffset'] = (
                          project.totalGrossOilReserves +
                          project.totalGrossGasReserves
                        ).toFixed(2));
                      formData['actions'] = actions;

                      return formData;
                    })
                  : []
              }
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectListingFormsLayout;
