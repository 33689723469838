import { BoxProps, List, Drawer, PaperProps } from '@mui/material';
import React, { FC, ReactNode, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from '@energyweb/origin-ui-user-data';
import { isRole, Role } from '@energyweb/origin-backend-core';

import {
  // UsernameAndOrg,
  UsernameAndOrgProps,
} from '../../layout/UsernameAndOrg';
import { TMenuSection, NavBarSection } from '../NavBarSection';
import { useStyles } from './DesktopNav.styles';

export interface DesktopNavProps {
  userAndOrgData: UsernameAndOrgProps;
  isAuthenticated: boolean;
  menuSections: TMenuSection[];
  icon?: ReactNode;
  iconWrapperProps?: BoxProps;
  sidebarPaperProps?: PaperProps;
}

export const DesktopNav: FC<DesktopNavProps> = ({
  userAndOrgData,
  menuSections = [],
  isAuthenticated,
  sidebarPaperProps,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const exceptions = /\/project\/register|\/project\/\d+\/edit/;

  const additionalClass = open ? '' : ' closed';

  const getProps = exceptions.test(location.pathname)
    ? {
        onMouseEnter: () => setOpen(true),
        onMouseLeave: () => setOpen(false),
      }
    : {};

  useEffect(() => {
    !open && !exceptions.test(location.pathname) && setOpen(true);
  }, [location.pathname, open]);

  return (
    <Drawer
      open
      anchor="left"
      variant="permanent"
      hideBackdrop
      className={classes.drawer + additionalClass}
      PaperProps={sidebarPaperProps}
      {...getProps}
    >
      <List className={classes.list + additionalClass}>
        {menuSections
          .filter((section) => section.show)
          .map((section) => (
            <NavBarSection key={section.sectionTitle} {...section} />
          ))}
      </List>
    </Drawer>
  );
};
