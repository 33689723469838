import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  circleButton: {
    borderRaius: '35px',
    padding: '0 16px',
    height: 30,
    boxShadow: 'none',
    textDecoration: 'none',
    fontSize: '11px',
    '&:hover': {
      boxShadow: 'initial',
    },
    '&.Mui-disabled': {
      opacity: '50%',
    },
  },
  button: {
    padding: '10px 45px',
    minHeight: '45px',
    boxShadow: 'none',
    textDecoration: 'none',
    '&:hover': {
      boxShadow: 'initial',
    },
    '&.Mui-disabled': {
      opacity: '50%',
    },
  },
  secondaryButton: {
    color: '#78CC8A !important',
    backgroundColor: '#78CC8A20 !important',
    '&:hover': {
      backgroundColor: '#78CC8A20',
    },
  },
  primaryButton: {
    color: '#FFFFFF !important',
    backgroundColor: '#78CC8A !important',
    '&:hover': {
      backgroundColor: '#78CC8A',
    },
  },
  criticalButton: {
    color: '#F58025 !important',
    backgroundColor: '#F5802520 !important',
    '&:hover': {
      backgroundColor: '#F5802520',
    },
  },
  circularProgress: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    zIndex: 1,
    position: 'absolute',
  },
}));
