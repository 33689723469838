import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingBottom: 30,
    marginLeft: 23,
  },
  title: {
    color: '#2B201F',
    fontSize: 24,
    position: 'absolute',
    top: 28,
    left: 450,
    zIndex: 2,
  },
  box: {
    minHeight: 700,
    height: 'auto',
    '&.empty': {
      minHeight: 540,
    },
  },
  paper: {
    background: '#FEFFFF',
    boxShadow: '0px 6px 24px rgba(25, 29, 56, 0.16)',
    flexBasis: ' calc(100% - 320px)',
    padding: '40px 36px 55px',
    position: 'relative',
    '& .MuiPagination-ul': {
      justifyContent: 'end',
    },
  },
  notification: {
    position: 'relative',
    paddingLeft: 55,
    marginBottom: 22,
    '&.not-viewed .title': {
      fontSize: 17,
      fontWeight: 'bold',
    },
    '& .title': {
      fontSize: 17,
      fontWeight: 500,
    },
  },
  firstLine: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#2B201F',
    fontSize: 17,
  },
  date: {
    fontSize: 14,
  },
  description: {
    width: 'calc(100% - 130px)',
    color: '#57625C',
    fontSize: 15,
  },
  paginationDescription: {
    position: 'absolute',
    bottom: 25,
    fontSize: 11,
    color: '#B4B2B1',
  },
  pagination: {
    position: 'absolute',
    bottom: 25,
    right: 25,
  },

  emptyList: {
    color: '#57625C',
    textAlign: 'center',
    fontSize: 17,
    marginTop: '25%',
  },
}));
