import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useConnectMetamaskPlaceHolderEffects } from './ConnectMetamaskPlaceholder.effects';
import { useStyles } from './ConnectMetamaskPlaceholder.styles';
import { useCommonStyles } from '@energyweb/origin-ui-core';

export const ConnectMetamaskPlaceholder = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { mobileView, title, clickHandler, buttonText } =
    useConnectMetamaskPlaceHolderEffects();

  return (
    <Box className={classes.container}>
      <Box
        mb={2}
        sx={{
          width: '50%',
          marginRight: '23px',
        }}
      >
        <Typography variant="subtitle1" component="span">
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '50%',
        }}
      >
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={clickHandler}
          disabled={mobileView}
          className={`${commonClasses.button} ${commonClasses.secondaryButton}`}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};
