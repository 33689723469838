import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import {
  useExecuteProjectHandler,
  useFileStatusHandler,
  useProjectChangeRequest,
  useProjectData,
  useProjectDeclineHandler,
} from '@energyweb/origin-ui-organization-data';
import { UploadStatus } from '@energyweb/origin-backend-core';
import {
  ProjectStatus,
  UploadDto,
  useFileControllerGetBaseURL,
} from '@energyweb/origin-backend-react-query-client';
import { UserModalsActionsEnum, useUserModalsDispatch } from '../../context';

export const useProjectDocumentsPageEffects = (projectId: number) => {
  const navigate = useNavigate();
  const dispatchModals = useUserModalsDispatch();

  const openModal = () => {
    dispatchModals({
      type: UserModalsActionsEnum.SHOW_TOKEN_ISSUANCE_STARTED,
      payload: true,
    });
  };

  const [file, setFile] = useState(null);
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false);
  const [acceptButtonDisabled, setAcceptButtonDisabled] =
    useState<boolean>(false);
  const [declineButtonDisabled, setDeclineButtonDisabled] =
    useState<boolean>(false);
  const [projectFiles, setProjectFiles] = useState<UploadDto[]>([]);
  const [changeRequestDialog, setChangeRequestDialog] = useState(false);
  const [declineProjectDialog, setDeclineProjectDialog] = useState(false);

  const { project, isLoading } = useProjectData(Number(projectId));
  const { data: fileDownloadUrls } = useFileControllerGetBaseURL();

  const { submitHandler: fileStatusHandler } = useFileStatusHandler(
    Number(projectId)
  );
  const { submitHandler: declineProjectHandler } =
    useProjectDeclineHandler(projectId);
  const { submitHandler: executeProjectHandler } =
    useExecuteProjectHandler(projectId);
  const { submitHandler: changeRequestHandler } = useProjectChangeRequest(
    Number(projectId)
  );

  useEffect(() => {
    if (project) {
      const uploads: UploadDto[] =
        [...project?.uploads, ...project?.reservesSubmission?.uploads] || [];

      if (uploads?.length) {
        const files = uploads
          .filter(
            (uploadedFile: UploadDto) =>
              uploadedFile.dataCy !== 'wellLevelReserves' &&
              uploadedFile.dataCy !== 'monthlyForecast'
          )
          .map((e) => e);
        setProjectFiles(files);
      }
    }
  }, [project]);

  const onFileDownload = useCallback(
    (file: UploadDto) => () => {
      file?.ipfsHash
        ? window.open(
            `${fileDownloadUrls?.pinataUrl}${file?.ipfsHash}?filename=${file.name}`,
            '_blank'
          )
        : window.open(`${fileDownloadUrls?.baseUrl}${file?.id}`, '_blank');
    },
    [fileDownloadUrls]
  );

  const onFileAccept = useCallback(
    (file) => {
      if (file?.id) {
        fileStatusHandler(
          {
            id: file?.id,
            status: UploadStatus.Accepted,
            comment: '',
          },
          true
        );
      }
    },
    [projectId]
  );

  const onChangeRequest = useCallback((filePayload: any = null) => {
    setChangeRequestDialog(true);

    if (filePayload?.id || filePayload?.ipfsHash) {
      setFile(filePayload);
    } else {
      setFile(null);
    }
  }, []);

  const onChangeRequestClose = useCallback(() => {
    setChangeRequestDialog(false);
  }, []);

  const onDeclineDialogClose = useCallback(() => {
    setDeclineProjectDialog(false);
  }, []);

  const onDeclineProjectSubmit = useCallback(
    () => setDeclineProjectDialog(true),
    []
  );

  const onChangeRequestSubmit = useCallback(
    (message: string) => {
      if (file?.id) {
        fileStatusHandler(
          {
            id: file?.id,
            status: UploadStatus.ChangeRequest,
            comment: message,
          },
          false
        );
      }
      onChangeRequestClose();
      if (project.projectStatus !== ProjectStatus.Change_request) {
        changeRequestHandler(message);
      }
    },
    [file]
  );

  const onProjectDecline = useCallback(() => {
    declineProjectHandler()
      .then(() => onDeclineDialogClose())
      .catch((error) => console.log(error))
      .finally(() => navigate(`/admin/executing-projects`));
  }, [projectId]);

  const onProjectAccept = () => {
    setButtonIsLoading(true);
    openModal();
    executeProjectHandler()
      .then(() => navigate(`/admin/executing-projects`))
      .catch((error) => console.log(error))
      .finally(() => setButtonIsLoading(false));
  };

  useEffect(() => {
    if (project) {
      const acceptedFiles = projectFiles?.every(
        (file: UploadDto) => file.status == UploadStatus.Accepted
      );

      const isAcceptButtonDisabled = !(
        project?.projectStatus === ProjectStatus.Execution && acceptedFiles
      );
      const isDeclineButtonDisabled = acceptedFiles;

      setAcceptButtonDisabled(isAcceptButtonDisabled);
      setDeclineButtonDisabled(isDeclineButtonDisabled);
    }
  }, [project, projectFiles]);

  return {
    projectFiles,
    pageLoading: isLoading,
    acceptButtonDisabled,
    declineButtonDisabled,
    changeRequestDialog,
    declineProjectDialog,
    buttonIsLoading,
    onFileAccept,
    onFileDownload,
    onChangeRequest,
    onChangeRequestClose,
    onChangeRequestSubmit,
    onProjectDecline,
    onProjectAccept,
    onDeclineProjectSubmit,
    onDeclineDialogClose,
  };
};
