import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

import { createProjectInfoForm } from 'libs/organization/logic/src/register/projectInformationForm';
import { useOwnerLocationData } from '@energyweb/origin-ui-organization-data';
import { useEffect } from 'react';

export const useCreateProjectEffects = (project?: ProjectDTO) => {
  const { t } = useTranslation();
  const { location } = useOwnerLocationData();

  const { initialValues, validationSchema, fields, validationMode } =
    createProjectInfoForm(t, project, location);

  const { register, handleSubmit, formState, control, setValue } = useForm({
    mode: validationMode,
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (location) {
      setValue('location', location);
    }
  }, [location]);

  const { isValid, errors, dirtyFields } = formState;

  return {
    errors,
    control,
    isValid,
    fields,
    initialValues,
    dirtyFields,
    handleSubmit,
    register,
  };
};
