import { getProjectControllerGetQueryKey } from '@energyweb/origin-backend-react-query-client';
import {
  ProjectDTO,
  royaltyOwnerControllerDelete,
  RoyaltyOwnerDTO,
} from '@energyweb/origin-backend-react-query-client';
import { NotificationTypeEnum, showNotification, TableActionData } from '@energyweb/origin-ui-core';
import { Edit } from '@mui/icons-material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';


export const useRoyaltyOwnersListingEffects = (project: ProjectDTO) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(project?.id);
  const royaltyOwners: RoyaltyOwnerDTO[] = project?.ownership?.royaltyOwners || [];

  const actions: TableActionData<RoyaltyOwnerDTO['id']> = {
    name: t('general.buttons.delete'),
    onClick: async (id) => {
      royaltyOwnerControllerDelete(id)
        .then(() => {
          queryClient.invalidateQueries(projectQueryKey)
        })
        .catch((error: any) => {
          showNotification(
            `${error?.response?.data?.message || ''}`,
            NotificationTypeEnum.Error
          );
        });
    },
  };

  const header = {
    name: t('project.royaltyOwnerListing.name'),
    agreement: t('project.royaltyOwnerListing.agreement'),
    actions: t('project.royaltyOwnerListing.action'),
  };


  const getOwnersTableData = useCallback(() => {
    return royaltyOwners?.map((royaltyOwner: RoyaltyOwnerDTO) => ({
      id: royaltyOwner?.id,
      name: royaltyOwner?.name,
      agreement: royaltyOwner?.uploads?.[0]?.name,
    }))
  }, [project]);


  return {
    header,
    data: getOwnersTableData(),
    tableTitle: t('project.royaltyOwnerListing.title'),
    actions
  };
};