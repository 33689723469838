import {
  useNotificationControllerGetRecent,
  useNotificationControllerGetNotViewedCount,
} from '@energyweb/origin-backend-react-query-client';

export const useNotificationRecentList = (query?: unknown) => {
  const {
    data: notifications,
    isLoading,
    refetch: update,
  } = useNotificationControllerGetRecent(query);
  return {
    notifications,
    isLoading,
    update,
  };
};

export const getNotViewedNotificationsCount = (query?: unknown) => {
  const { data: count, refetch: update } =
    useNotificationControllerGetNotViewedCount(query);

  return {
    count,
    update,
  };
};
