import React, { FC } from 'react';
import { GenericModal } from '@energyweb/origin-ui-core';
import { useProjectVerifiedSuccessfulEffects } from './projectVerifiedSuccessful.effects';

export const ProjectVerifiedSuccessful: FC = () => {
  const { open, text, buttons, dialogProps } =
    useProjectVerifiedSuccessfulEffects();

  return (
    <GenericModal
      open={open}
      text={text as string}
      buttons={buttons}
      dialogProps={dialogProps}
    />
  );
};
