import { TableCell, TableRow, Button, TextField } from '@mui/material';
import React, { PropsWithChildren, ReactElement } from 'react';
import { TableHeaderData, TableRowData } from '../../../containers';
import { useEditableTableComponentRowEffects } from './EditableTable.effects';
import { useStyles } from './EditableTable.styles';

export interface TableComponentRowProps<Id> {
  key: string;
  row: TableRowData<Id>;
  headerData: TableHeaderData;
  className?: string;
}

export type TTableComponentRow = <Id>(
  props: PropsWithChildren<TableComponentRowProps<Id>>
) => ReactElement;

export type TEditableTableComponentRow = <Id>(
  row: TableRowData<Id>,
  headerData: TableHeaderData
) => ReactElement;

const EditableTableCell: TEditableTableComponentRow = ({
  row,
  name,
  onChange,
}) => {
  const { isEditMode } = row;
  const classes = useStyles();

  const NOT_EDITABLE_FIELDS = ['id', 'status'];

  return (
    <TableCell>
      {!NOT_EDITABLE_FIELDS.includes(name) && isEditMode ? (
        <TextField
          size="small"
          name={name}
          variant="standard"
          value={row[name]}
          onChange={(e) => onChange(e, row)}
          className={classes.noBorder}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

export const EditableTableComponentRow: TTableComponentRow = ({
  row,
  headerData,
  className,
}) => {
  const { onToggleEditMode, onChange, buttonText, currentRow } =
    useEditableTableComponentRowEffects(row);

  return (
    <>
      <TableRow>
        {Object.keys(headerData).map((key) =>
          key === 'actions' ? (
            row.actions?.map((action) => (
              <TableCell>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => onToggleEditMode(currentRow.id, action)}
                >
                  {buttonText}
                </Button>
              </TableCell>
            ))
          ) : (
            <EditableTableCell
              id={currentRow.id}
              row={currentRow}
              name={key}
              onChange={onChange}
            />
          )
        )}
      </TableRow>
    </>
  );
};
