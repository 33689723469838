import {
  getProjectControllerGetQueryKey,
  useProjectControllerDeclineProject,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useProjectDeclineHandler = (id: number) => {
  const { t } = useTranslation();
  const { mutate } = useProjectControllerDeclineProject();
  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(id);

  const submitHandler = () => {
    return new Promise((res, rej) =>
      mutate(
        { id },
        {
          onSuccess: () => {
            showNotification(
              t('projectStatus.notifications.projectDeclineSuccess'),
              NotificationTypeEnum.Success
            );
            queryClient.invalidateQueries(projectQueryKey);
            res(true);
          },
          onError: (error: any) => {
            if (error?.response?.status === 400) {
              showNotification(
                error?.response?.data?.message,
                NotificationTypeEnum.Error
              );
            } else {
              showNotification(
                `${t('projectStatus.notifications.projectDeclineError')}:
                  ${error?.response?.data?.message || ''}
                  `,
                NotificationTypeEnum.Error
              );
            }
            rej();
          },
        }
      )
    );
  };

  return { submitHandler };
};
