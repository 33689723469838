import { TFunction } from 'i18next';
import { TMenuSection } from '@energyweb/origin-ui-core';

export type TGetAdminMenuArgs = {
  t: TFunction;
  isOpen: boolean;
  showSection: boolean;
  showUsers: boolean;
  showClaims?: boolean;
  showTrades?: boolean;
  showAllOrgs?: boolean;
  showProjectOwners: boolean;
  showProjectVerifiers: boolean;
  showVRCompanies: boolean;
  menuButtonClass?: string;
  selectedMenuItemClass?: string;
  showProjectsInExecution: boolean;
};

type TUseAdminMenuFn = (args?: TGetAdminMenuArgs) => TMenuSection;

export const getAdminMenu: TUseAdminMenuFn = ({
  t,
  isOpen,
  showSection,
  showUsers,
  showProjectOwners,
  showProjectVerifiers,
  showVRCompanies,
  menuButtonClass,
  selectedMenuItemClass,
  showProjectsInExecution,
}) => {
  return {
    isOpen,
    dataCy: 'adminMenu',
    sectionTitle: t('navigation.admin.sectionTitle'),
    rootUrl: '/admin',
    show: showSection,
    menuList: [
      {
        url: '/',
        label: t('navigation.admin.users'),
        show: showUsers,
        dataCy: 'adminUsers',
      },
      {
        url: 'projectOwners',
        label: t('navigation.admin.projectOwner'),
        show: showProjectOwners,
        dataCy: 'adminProjectOwners',
      },
      {
        url: 'projectVerifiers',
        label: t('navigation.admin.projectVerifier'),
        show: showProjectVerifiers,
        dataCy: 'adminProjectVerifiers',
      },
      {
        url: 'companies',
        label: t('navigation.admin.vrCompanies'),
        show: showVRCompanies,
        dataCy: 'companies',
      },
      {
        url: 'executing-projects',
        label: t('navigation.admin.projectsInExecution'),
        show: showProjectsInExecution,
        dataCy: 'executingProjects',
      },
    ],
    menuButtonClass,
    selectedMenuItemClass,
  };
};
