import { Box, Skeleton, TableBody } from '@mui/material';
import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { TableHeaderData, TableRowData } from '../../../containers';
import { TableComponentRow } from '../TableComponentRow';
import { EditableTableComponentRow } from '../EditableTable';
import { range } from 'lodash';

export interface TableComponentBodyProps<Id> {
  rowData: TableRowData<Id>[];
  headerData: TableHeaderData;
  pageSize: number;
  loading: boolean;
  editable?: boolean;
  onRowClick?: (id: Id) => void;
  getCustomRowClassName?: (id: Id) => string;
}

export type TTableComponentBody = <Id>(
  props: PropsWithChildren<TableComponentBodyProps<Id>>
) => ReactElement;

export const TableComponentBody: TTableComponentBody = ({
  loading,
  rowData,
  headerData,
  pageSize,
  editable,
  onRowClick,
  getCustomRowClassName,
}) => {
  return (
    <TableBody>
      {loading ? (
        <TableRowsLoadingComponent
          colSpan={Object.keys(headerData).length}
          pageSize={pageSize}
        />
      ) : (
        rowData.map((row) =>
          editable ? (
            <EditableTableComponentRow
              key={row.id.toString()}
              row={row}
              headerData={headerData}
              className={getCustomRowClassName && getCustomRowClassName(row.id)}
            />
          ) : (
            <TableComponentRow
              key={row.id.toString()}
              row={row}
              headerData={headerData}
              onRowClick={onRowClick}
              className={getCustomRowClassName && getCustomRowClassName(row.id)}
            />
          )
        )
      )}
    </TableBody>
  );
};

const TableRowsLoadingComponent: FC<{ pageSize?: number; colSpan: number }> = ({
  pageSize,
  colSpan,
}) => {
  return (
    <>
      {range(pageSize).map((value) => (
        <tr key={value.toString()}>
          <td colSpan={colSpan}>
            <Box m={'10px'}>
              <Skeleton height={'60px'} />
            </Box>
          </td>
        </tr>
      ))}
    </>
  );
};
