import { TFunction, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  ProjectDTO,
  ProjectWell,
} from '@energyweb/origin-backend-react-query-client';
import { CarbonOffsetType } from '@energyweb/origin-backend-core';
import { TTokensForm } from './types';
import { useEffect, useState } from 'react';
import {
  OrganizationModalsActionsEnum,
  useOrgModalsDispatch,
} from 'libs/organization/view/src/context';

const registerTokenForm: TTokensForm = (t: TFunction) => {
  return {
    inputsVariant: 'outlined',
    initialValues: {
      amount: 0,
      wellId: null,
    },
    validationSchema: yup.object().shape({
      wellId: yup.number().required().label(t('projectStatus.selectWells')),
    }),
    buttonText: '',
    fields: [
      {
        name: 'wellId',
        label: t('projectStatus.selectWells'),
        select: true,
        options: [],
        required: true,
        inputProps: { ['data-cy']: 'wellId' },
      },
      {
        name: 'amount',
        label: t('projectStatus.tonsToPurchase'),
        required: true,
        inputProps: { ['data-cy']: 'amount' },
      },
    ],
  };
};

export const useTokenForm = (project: ProjectDTO) => {
  const { t } = useTranslation();
  const [maxAmount, setMaxAmount] = useState<number>(0);
  const [wellInfo, setWellInfo] = useState<any>({
    wellName: '',
    wellAPINumber: '',
    offsetType: '',
  });
  const { initialValues, fields } = registerTokenForm(t);

  const { register, handleSubmit, control, watch, setValue } = useForm({
    defaultValues: initialValues,
  });

  const [wellsField, setWellsField] = useState<any>(
    fields.find((e) => e.name === 'wellId')
  );

  const { wellId } = watch();

  useEffect(() => {
    const projectWells = project?.reservesSubmission?.wells;

    const wells =
      projectWells?.filter((well: ProjectWell) => well.totalCarbonOffset > 0) ||
      [];

    wellsField.options = wells.map((well: any) => ({
      label: well.wellName,
      value: well.id,
      amount: Math.round(well.totalCarbonOffset * 10),
      wellAPINumber: well.wellAPINumber,
      offsetType: CarbonOffsetType.OILAndGAS,
    }));

    setWellsField({ ...wellsField });
  }, [project]);

  useEffect(() => {
    if (typeof wellId === 'number') {
      const well = wellsField?.options?.find(
        (option: any) => option.value === wellId
      );
      setMaxAmount(well?.amount);
      setWellInfo({
        wellName: well?.label,
        wellAPINumber: well?.wellAPINumber,
        offsetType: well?.offsetType,
      });
    }
  }, [wellId]);

  const onChange = (event: any) => {
    const amount = event.target.value;
    setValue('amount', Number(amount));
  };

  const dispatchModals = useOrgModalsDispatch();

  const openModal = (formValues: any) => {
    const values = {
      ...formValues,
      wellName: wellInfo.wellName,
      wellAPINumber: wellInfo.wellAPINumber,
      offsetType: wellInfo.offsetType,
      projectId: project?.id,
    };

    dispatchModals({
      type: OrganizationModalsActionsEnum.SHOW_CONFIRM_TOKEN_PURCHASE,
      payload: {
        open: true,
        tokenToBuy: values,
      },
    });
  };

  return {
    fields,
    wellsField,
    maxAmount,
    onChange,
    register,
    openModal,
    handleSubmit,
    control,
  };
};
