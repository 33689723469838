import { useTranslation } from 'react-i18next';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { Role } from '@energyweb/origin-backend-core';
import { useEffect, useState } from 'react';

export const useRoyaltyOwnersListingEffects = (project: ProjectDTO, role?: Role, showName?: boolean) => {
  const { t } = useTranslation();
  const [showStatus, setShowStatus] = useState<boolean>(false)
  const royaltyOwners = project?.ownership?.royaltyOwners || [];

  const [headers, setHeaders] = useState<any>(
    [
      { id: 'id', label: '#' },
      { id: 'agreement', label: t('project.royaltyOwnerListing.agreement') },
    ]
  )

  useEffect(() => {
    let tableHeaders = [...headers];
    const roleIndex = [Role.Admin, Role.ProjectOwner, Role.ProjectVerifier].indexOf(
      role
    );

    if (roleIndex > -1) {
      setShowStatus(true);
    }
    if (role === Role.ProjectOwner) {
      tableHeaders = showName ?
        [
          { id: "name", label: t('project.royaltyOwnerListing.name') },
          { id: 'agreement', label: t('project.royaltyOwnerListing.agreement') },
          { id: 'status', label: t('project.royaltyOwnerListing.status') },
        ] : [...tableHeaders, { id: 'status', label: t('project.royaltyOwnerListing.status') }]
    }
    if (role === Role.ProjectVerifier) {
      tableHeaders =
        [...tableHeaders,
        { id: 'status', label: t('project.royaltyOwnerListing.status') },
        { id: 'action', label: t('project.royaltyOwnerListing.action') }
        ]
    }
    setHeaders(tableHeaders);
  }, [role])

  return {
    royaltyOwners,
    headers,
    showStatus
  };
};
