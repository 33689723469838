import React, { FC } from 'react';
import { GenericModal } from '@energyweb/origin-ui-core';
import { useDeleteProjectEffects } from './DeleteProject.effects';

export const DeleteProject: FC = () => {
  const { open, text, buttons, dialogProps } = useDeleteProjectEffects();

  return (
    <GenericModal
      open={open}
      text={text}
      buttons={buttons}
      dialogProps={dialogProps}
    />
  );
};
