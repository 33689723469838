import { Button, ListItem, Icon } from '@mui/material';
import clsx from 'clsx';
import React, { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { useStyles } from './NavSectionTitle.styles';
import { DefaultNavIcon } from '@energyweb/origin-ui-assets';

export interface NavSectionTitleProps {
  url: string;
  title: string;
  buttonClass?: string;
  dataCy?: string;
  sectionIcon?: string;
}

export const NavSectionTitle: FC<NavSectionTitleProps> = memo(
  ({ url, title, buttonClass, dataCy, sectionIcon }) => {
    const classes = useStyles();

    return (
      <ListItem className={classes.listItem} disableGutters>
        <Button
          data-cy={dataCy}
          className={clsx(classes.button, buttonClass)}
          component={NavLink}
          to={url}
        >
          <Icon className={classes.icon}>
            <img
              src={sectionIcon ? sectionIcon : DefaultNavIcon}
              alt={sectionIcon ? sectionIcon : DefaultNavIcon}
            />
          </Icon>
          {title}
        </Button>
      </ListItem>
    );
  }
);
