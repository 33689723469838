import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      width: '90%',
    },
    '@media (max-width: 768px) and (max-height: 670px)': {
      width: '90%',
      overflow: 'auto',
      padding: '30px 0',
    },
  },
  primaryText: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
  },
  secondaryText: {
    color: theme.palette.secondary.main,
    fontFamily: theme.typography.fontFamily,
  },
  form: {
    width: '100%',
    '&& .MuiFilledInput-root input': {
      color: theme.palette.primary.contrastText,
      fontFamily: theme.typography.body1.fontFamily,
      '&::placeholder': {
        color: theme.palette.secondary.main,
      },
    },
    '&& .MuiFilledInput-root.Mui-error': {
      border: '1px solid #C92A2A',
    },
    '&& .MuiButton-root': {
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.primary.contrastText,
      '&&:hover': {
        cursor: 'pointer',
        backgroundColor:
          'linear-gradient(0deg, rgba(43, 32, 31, 0.3), rgba(43, 32, 31, 0.3)), #78CC8A !important',
      },
    },
    '&& .MuiSvgIcon-root': {
      backgroundColor: '#E1DBD84D',
      borderRadius: 4,
      '& path': {
        display: 'none',
      },
    },
    '&& .Mui-checked svg path': {
      display: 'inherit',
      fill: '#fff',
    },
    '&& .MuiFormHelperText-root.Mui-error': {
      fontFamily: theme.typography.body1.fontFamily,
      background: '#999966',
      width: 'fit-content',
      padding: '0 5px',
      borderRadius: 6,
    },
  },
}));
