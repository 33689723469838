import React, { FC } from 'react';
import { Paper, Pagination } from '@mui/material';
import { TableComponent } from '@energyweb/origin-ui-core';
import { useWellsListingEffects } from './WellsViewPage.effects';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { useStyles } from './WellsViewPage.styles';

export interface WellListingProps {
  project?: ProjectDTO;
}

export const WellsPage: FC<WellListingProps> = ({ project }) => {
  const { tableData, pageCount, setPage } = useWellsListingEffects(project);
  const classes = useStyles();

  return (
    <Paper classes={{ root: classes.paper }}>
      <TableComponent {...tableData} />
      {pageCount > 1 && (
        <Pagination
          className={classes.pagination}
          size="small"
          shape="rounded"
          defaultPage={1}
          count={pageCount}
          onChange={(e, index) => setPage(index)}
        />
      )}
    </Paper>
  );
};

export default WellsPage;
