import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  CompanyDTO,
  CreateCompanyDTO,
} from '@energyweb/origin-backend-react-query-client';
import {
  GenericFormProps,
  TableActionData,
  TableComponentProps,
  TableRowData,
} from '@energyweb/origin-ui-core';

export type TUseCompaniesTableArgs = {
  companies: CompanyDTO[];
  actions: TableActionData<CompanyDTO['id']>[];
  isLoading: boolean;
};

export type TCompanyFormValues = {
  name: CompanyDTO['name'];
};

export type TFormatCompanies = (
  props: Omit<TUseCompaniesTableArgs, 'isLoading'>
) => TableRowData<CompanyDTO['id']>[];

export type TUseCompaniesTableLogic = (
  props: TUseCompaniesTableArgs
) => TableComponentProps<CompanyDTO['id']>;

const formatCompanies: TFormatCompanies = ({ companies, actions }) => {
  return companies?.map((company: any) => ({
    id: company.id,
    company: company.name,
    members: company?.members.map((e: any) => e?.email).join(', '),
    actions,
  }));
};

export const useCompaniesTableLogic: TUseCompaniesTableLogic = ({
  companies,
  actions,
  isLoading,
}) => {
  const { t } = useTranslation();

  return {
    header: {
      company: t('user.projectVerifier.company'),
      members: t('user.vrCompanies.verifiers'),
      actions: '',
    },
    pageSize: 20,
    loading: isLoading,
    data: formatCompanies({ companies, actions }) ?? [],
  };
};

export const useCompanyFormLogic = (
  company: CompanyDTO
): Omit<GenericFormProps<CreateCompanyDTO>, 'submitHandler'> => {
  const { t } = useTranslation();

  const initialFormData: CreateCompanyDTO = {
    name: company?.name,
  };

  return {
    buttonText: t(`general.buttons.${company?.id ? 'update' : 'create'}`),
    fields: [
      {
        label: t('user.vrCompanies.form.name'),
        name: 'name',
      },
    ],
    buttonWrapperProps: { justifyContent: 'flex-start' },
    initialValues: initialFormData,
    twoColumns: true,
    inputsVariant: 'filled',
    validationSchema: Yup.object().shape({
      name: Yup.string().label(t('user.vrCompanies.form.name')).required(),
    }),
  };
};
