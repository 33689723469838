import { useProjectRegisterStepsHandler } from '@energyweb/origin-ui-organization-data';
import React, { useState, useCallback, useEffect } from 'react';
import { UnpackNestedValue } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GenericForm } from '../GenericForm';
import { MultiStepFormProps } from './MultiStepForm';
import _ from 'lodash';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

type TUseMultiStepEffectsArgs<Merged> = Pick<
  MultiStepFormProps<Merged>,
  | 'forms'
  | 'backButtonText'
  | 'backButtonProps'
  | 'loading'
  | 'nextButtonText'
  | 'project'
>;

type TUseMultiStepEffects = <Merged>(
  args: TUseMultiStepEffectsArgs<Merged>
) => {
  stepperLabels: string[];
  activeStep: number;
  prevStep: number;
  getCurrentForm: (step: number) => JSX.Element;
  navigateToStep: (step: number) => void;
  checkStepStatus: (step: number) => boolean;
};

export const PROJECT_STEPS_MAP = [
  'id',
  'carbonContent',
  'reservesSubmission',
  'reservesValidation',
  'ownership',
  'supplementalData',
  'overview',
];

const getActiveStep = (project?: ProjectDTO) => {
  const currentStep = PROJECT_STEPS_MAP.findIndex((e) =>
    e !== 'id' ? !_.get(project, `${e}.uploads[0]`) : !_.get(project, e)
  );
  return currentStep === -1 ? PROJECT_STEPS_MAP.length - 1 : currentStep;
};

export const useMultiStepFormEffects: TUseMultiStepEffects = ({
  forms,
  backButtonText,
  backButtonProps,
  nextButtonText,
  loading,
  project,
}) => {
  const [activeStep, setActiveStep] = useState(getActiveStep(project));
  const [prevStep, setPrevStep] = useState(activeStep ? activeStep - 1 : 0);

  const { submitHandler, isLoading } = useProjectRegisterStepsHandler(
    activeStep,
    project
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const stepperLabels = [
    t('project.register.infoTitle'),
    t('project.register.carbonContentTitle'),
    t('project.register.submission'),
    t('project.register.validation'),
    t('project.register.ownershipTitle'),
    t('project.register.supplementalDataTitle'),
    t('project.register.overview'),
  ];

  const nextButtonHandler = useCallback((): void => {
    if (activeStep + 1 >= forms.length) {
      return;
    }
    setPrevStep(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [forms, activeStep]);

  const navigateToStep = useCallback(
    (step: number): void => {
      const projectStep =
        _.get(project, PROJECT_STEPS_MAP[step]) || PROJECT_STEPS_MAP.length - 1;
      if (projectStep && forms[step]) {
        setActiveStep(step);
      }
    },
    [forms, activeStep]
  );

  useEffect(() => {
    const step = searchParams.get('step');
    if (step) {
      // Added 1st step processing
      const index = PROJECT_STEPS_MAP.findIndex(
        (e) => `${e}.id` === `${step}.id` || e === step
      );
      index > -1 && navigateToStep(index);
      setSearchParams('');
    }
  }, [searchParams]);

  const saveButtonHandler = useCallback(
    async (values: UnpackNestedValue<any>): Promise<void> => {
      if (values) {
        return submitHandler(values);
      }
    },
    [activeStep, project]
  );

  const backButtonHandler = useCallback((): void => {
    if (activeStep === 0) {
      return;
    }
    setPrevStep(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [activeStep]);

  const checkStepStatus = useCallback(
    (step: number): boolean => {
      const currentStep = PROJECT_STEPS_MAP.findIndex(
        (e) => !_.get(project, e)
      );
      if (currentStep !== -1 && step < currentStep) {
        return true;
      }
    },
    [project]
  );

  const getCurrentForm = (step: number) => {
    const props = forms[step];

    if (props?.customStep && props?.component) {
      const CustomForm = props.component;
      return (
        <CustomForm
          submitHandler={saveButtonHandler}
          secondaryButtons={[
            {
              label: backButtonText,
              onClick: backButtonHandler,
              disabled: activeStep === 0 || loading,
              variant: 'outlined',
              style: { marginRight: 10 },
              ...backButtonProps,
            },
            {
              label: nextButtonText,
              onClick: nextButtonHandler,
              disabled:
                activeStep === 0
                  ? !_.get(project, `${PROJECT_STEPS_MAP[activeStep]}`)
                  : !_.get(
                      project,
                      `${PROJECT_STEPS_MAP[activeStep]}.uploads[0]`
                    ) || loading,
              variant: 'outlined',
              style: { marginLeft: 10 },
              ...backButtonProps,
            },
          ]}
          project={project}
          navigateToStep={navigateToStep}
          loading={loading || isLoading}
          {...props}
        />
      );
    }

    return (
      <GenericForm
        partOfMultiForm={true}
        submitHandler={saveButtonHandler}
        secondaryButtons={[
          {
            label: backButtonText,
            onClick: backButtonHandler,
            disabled: activeStep === 0,
            variant: 'outlined',
            style: { marginRight: 10 },
            ...backButtonProps,
          },
          {
            label: nextButtonText,
            onClick: nextButtonHandler,
            variant: 'outlined',
            disabled: loading,
            style: { marginRight: 10 },
            ...backButtonProps,
          },
        ]}
        loading={loading}
        {...props}
      />
    );
  };

  return {
    stepperLabels,
    activeStep,
    getCurrentForm,
    prevStep,
    navigateToStep,
    checkStepStatus,
  };
};
