import React, { FC } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useUser } from '@energyweb/origin-ui-user-data';
import { Role } from '@energyweb/origin-backend-core';
import { CircularProgress } from '@mui/material';
import { ChangeRequestPopUp } from './components/ChangeRequestPopUp/ChangeRequestPopUp';
import { ConfirmationPopUp } from './components/ChangeRequestPopUp/ConfirmationPopUp';
import { useVerifierProjectDetailsEffect } from './VerifierProjectDetailsPage.effects';
import { ProjectStatusLayout } from '../../containers/layout';

export const VerifierProjectDetailsPage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    isLoading,
    project,
    changeRequestDialog,
    onProjectAccept,
    onProjectDecline,
    onChangeRequest,
    onChangeRequestClose,
    onChangeRequestSubmit,
    isAccepting,
    isDeclining,
    onFileAccept,
    acceptConfirm,
    declineConfirm,
    acceptFileConfirm,
    toggleFileAccept,
    toggleProjectAccept,
    toggleProjectDecline,
  } = useVerifierProjectDetailsEffect(id && +id);
  const { user, userLoading } = useUser();

  if (isLoading || userLoading) return <CircularProgress />;

  if (project?.reservesValidation?.verifier?.user?.id !== user?.id) {
    navigate(`/project/public/${id}`, { replace: true });
    return <></>;
  }

  return (
    <>
      <ProjectStatusLayout
        onAccept={toggleProjectAccept(true)}
        onFileAccept={toggleFileAccept(true)}
        onFileChangeRequest={onChangeRequest}
        onDecline={toggleProjectDecline(true)}
        onChangeRequest={onChangeRequest}
        project={project}
        role={Role.ProjectVerifier}
        isApproving={isAccepting}
        isDeclining={isDeclining}
      />
      <ChangeRequestPopUp
        isOpen={changeRequestDialog}
        onClose={onChangeRequestClose}
        onSubmit={onChangeRequestSubmit}
      />
      <ConfirmationPopUp
        isOpen={acceptConfirm}
        onClose={toggleProjectAccept(false)}
        onSubmit={onProjectAccept}
        message={t('projectStatus.changeRequestPopUp.acceptConfirmationTitle')}
      />

      <ConfirmationPopUp
        isOpen={declineConfirm}
        onClose={toggleProjectDecline(false)}
        onSubmit={onProjectDecline}
        message={t('projectStatus.changeRequestPopUp.declineConfirmationTitle')}
      />

      <ConfirmationPopUp
        isOpen={acceptFileConfirm}
        onClose={toggleFileAccept(false)}
        onSubmit={onFileAccept}
        message={t(
          'projectStatus.changeRequestPopUp.acceptFileConfirmationTitle'
        )}
      />
    </>
  );
};
