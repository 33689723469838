import { ProjectDTO } from '@energyweb/origin-organization-irec-api-react-query-client';
import { Paper, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC } from 'react';
import { useStyles } from './ProjectInfo.styles';

interface ProjectInfoProps {
  project?: ProjectDTO;
}

export const ProjectInfo: FC<ProjectInfoProps> = ({ project }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography variant="h6" my={2}>
        {t('project.register.overviewDetails.summary')}
      </Typography>
      <Typography>{project.name}</Typography>
      <Typography>{project.location}</Typography>
      <Typography>{project.type}</Typography>
      <Typography>{project.description}</Typography>
      <Typography>{project.ownership?.riskOption?.type?.name}</Typography>
    </Paper>
  );
};
