import React, { FC } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Paper, CircularProgress } from '@mui/material';
import { useUser } from '@energyweb/origin-ui-user-data';
import { MultiStepForm } from '@energyweb/origin-ui-core';
import { isRole, Role, UserStatus } from '@energyweb/origin-backend-core';
import { useRegisterPageEffects } from './RegisterPage.effects';
import { useStyles } from './RegisterPage.styles';
import {
  useProjectData,
  useOwnerLocationData,
} from '@energyweb/origin-ui-organization-data';
import {
  ProjectDTO,
  ProjectStatus,
} from '@energyweb/origin-backend-react-query-client';

export const RegisterPage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading, project } = useProjectData(id && +id);
  const { user, userLoading } = useUser();

  if ((id && isLoading) || userLoading) return <CircularProgress />;

  if (project?.createdBy?.id !== user?.id) {
    navigate(`/project/public/${id}`, { replace: true });
    return <></>;
  }

  if (
    [
      ProjectStatus.Rejected,
      ProjectStatus.Verified,
      ProjectStatus.Pending,
      ProjectStatus.Issuance,
    ].includes(project?.projectStatus)
  ) {
    navigate(`/project/${id}/view`, { replace: true });
    return <></>;
  }

  return <RegisterPageLoaded project={{ ...project }} />;
};

export const RegisterPageLoaded: FC<{ project?: ProjectDTO }> = ({
  project,
}) => {
  const classes = useStyles();
  const { formData } = useRegisterPageEffects(project);
  const navigate = useNavigate();
  const { user, userLoading } = useUser();

  if (userLoading) return <CircularProgress />;

  if (!isRole(user, Role.ProjectOwner)) {
    navigate(`/account/project-owner`, { replace: true });
    return <></>;
  }

  return (
    <Paper classes={{ root: classes.paper }}>
      <MultiStepForm {...formData} project={project} />
    </Paper>
  );
};

export default RegisterPage;
