import React, { PropsWithChildren, ReactElement } from 'react';
import {
  FormControl,
  MenuItem,
  Box,
  Chip,
  Typography,
  InputLabel,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormSelectOption } from '../FormSelect';
import { useStyles } from './SelectMultiple.styles';
import { ItemsListWithActions } from '../../../containers';

export type SelectMultipleField<FormValuesType> = {
  name: keyof FormValuesType;
  label: string;
  options?: FormSelectOption[];
  placeholder?: string;
};

export interface SelectMultipleProps<FormValuesType = any> {
  field: SelectMultipleField<FormValuesType>;
  value: string[];
  onChange: (event: any) => void;
}

export type TSelectMultiple = <FormValuesType>(
  props: PropsWithChildren<SelectMultipleProps<FormValuesType>>
) => ReactElement;

export const SelectMultiple: TSelectMultiple = ({ field, value, onChange }) => {
  const classes = useStyles();

  const removeItem = (item: any) => {
    if (item !== 'ANY') {
      const selected = [...value];
      selected.splice(value.indexOf(item), 1);
      onChange({ target: { value: selected } });
    }
  };

  return (
    <div>
      <FormControl className={classes.form}>
        <Typography id="label" className={classes.label}>
          {field.label}
        </Typography>
        <Select
          multiple
          displayEmpty
          className={classes.select}
          value={value}
          onChange={onChange}
          input={<OutlinedInput />}
          inputProps={{ placeholder: 'Select' }}
          IconComponent={KeyboardArrowDownIcon}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  className={classes.chip}
                  label={value}
                  color="primary"
                  onDelete={() => removeItem(value)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              ))}
              {selected[0] == 'ANY' && (
                <InputLabel>{field.placeholder}</InputLabel>
              )}
            </Box>
          )}
        >
          {field.options.map((options) => (
            <MenuItem key={options.value} value={options.value}>
              {options.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
