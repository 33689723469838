import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: 'none',
    background: '#FFF',
    '& .MuiTableRow-footer': {
      display: 'none',
    },
    '& .MuiTable-root': {
      border: '1px solid #7BA284',
      borderRadius: '4px',
      margin: '24px 0',
      '& thead': {
        background: 'rgba(111, 207, 151, 0.3)',
        borderBottom: '1px solid #7BA284',
        '& th': {
          color: '#5E5E5E',
          fontFamily: theme.typography.fontFamily,
          fontSize: '11px',
        },
      },
      '& tbody > tr > td': {
        color: '#2B201F',
        background: '#FFFFFF',
        fontFamily: theme.typography.fontFamily,
      },
    },
  },
}));
