import { useRiskTypeControllerGetAll } from '@energyweb/origin-backend-react-query-client';

export const useRiskTypesData = () => {
  const { data, isLoading } = useRiskTypeControllerGetAll();

  return {
    riskTypes: data,
    isLoading,
  };
};
