import { Box, Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';
import { UploadChip } from './UploadChip';
import { useFileUploadEffects } from './FileUpload.effects';
import { useStyles } from './FileUpload.styles';
import {
  ApiUploadFunction,
  FileUploadOnChangeFunction,
} from './FileUpload.types';
import { UploadIcon } from '@energyweb/origin-ui-assets';

export interface FileUploadProps {
  dropzoneText: string;
  apiUploadFunction: ApiUploadFunction;
  onChange: FileUploadOnChangeFunction;
  heading?: string;
  headingProps?: TypographyProps;
  wrapperProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > & { ['data-cy']?: string };
  dropzoneClassName?: string;
  uploadedFiles?: File[];
  required?: boolean;
  showSection?: boolean;
  mimeType?: string;
}

export const FileUpload: FC<FileUploadProps> = ({
  dropzoneText,
  apiUploadFunction,
  onChange,
  heading,
  headingProps,
  wrapperProps,
  dropzoneClassName,
  uploadedFiles,
  required,
  showSection = true,
  mimeType,
}) => {
  const { getRootProps, getInputProps, files, state, dispatch } =
    useFileUploadEffects({
      apiUploadFunction,
      onChange,
      dataCy: wrapperProps['data-cy'],
      uploadedFiles,
      mimeType,
    });
  const classes = useStyles();
  const dropzoneClasses = `${classes.dropzone} ${dropzoneClassName}`;

  return (
    <Box>
      {showSection && heading && (
        <>
          <Typography {...headingProps}>{heading}</Typography>
          <section className={classes.wrapper} {...wrapperProps}>
            <div
              {...getRootProps({ className: dropzoneClasses })}
              style={{
                ...(getRootProps().style as React.CSSProperties),
              }}
            >
              <input
                {...getInputProps()}
                style={{
                  ...(getInputProps().style as React.CSSProperties),
                }}
                required={required}
                multiple={false}
              />
              <div className={classes.dropzoneText}>
                <UploadIcon />
                <p>
                  {' '}
                  Drag and Drop or <span>Select Files</span>
                </p>
              </div>
            </div>
            <aside className={classes.chipsContainer}>
              {files?.map((file, index) => {
                const uploadedFile = state[index];

                if (!uploadedFile || uploadedFile.removed) {
                  return null;
                }
                return (
                  <UploadChip
                    key={file.name + index}
                    file={file}
                    index={index}
                    uploadedFile={uploadedFile}
                    dispatch={dispatch}
                  />
                );
              })}
            </aside>
          </section>
        </>
      )}
    </Box>
  );
};
