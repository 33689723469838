import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '20px',
    '& .MuiGrid-item': {
      width: '100%',
      textAlign: 'center',
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
    '& .MuiIconButton-root': {
      color: '#8998AC',
    },
  },
  form: {
    '& .MuiFilledInput-root input': {
      backgroundColor: '#fff',
      border: '1px solid #AAB0F3',
      '&::placeholder': {
        color: '#ffffff',
      },
    },
    '& .MuiFilledInput-root.Mui-error': {
      border: '1px solid #C92A2A',
    },
    '& .MuiButton-root': {
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.primary.contrastText,
      fontSize: 16,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  title: {
    fontSize: 24,
    margin: '15px 0',
  },
  content: {
    fontSize: 20,
    '& span': {
      fontSize: 20,
    },
  },
  resentButton: {
    '& span': {
      fontSize: 16,
      '&:last-child': {
        cursor: 'pointer',
        marginLeft: 5,
      },
    },
  },
}));
