import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { TableActionData } from '@energyweb/origin-ui-core';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

import { useFollowingProjectList } from '@energyweb/origin-ui-organization-data';

export const useFollowingProjectListingEffects = () => {
  const [orderBy, setOrderBy] = useState<keyof ProjectDTO>('createdAt');
  const [isASC, setIsASC] = useState(true);
  const [query, setQuery] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { projects: dataToFilter, isLoading } = useFollowingProjectList();
  const { projects } = useFollowingProjectList(query);

  const sortBy =
    (sortField: keyof ProjectDTO | 'totalOffset') =>
    (a: ProjectDTO, b: ProjectDTO) => {
      if (sortField === 'totalOffset') {
        const aOffset = a.totalGrossOilReserves + a.totalGrossGasReserves;
        const bOffset = b.totalGrossGasReserves + b.totalGrossOilReserves;
        if (aOffset < bOffset) return isASC ? 1 : -1;
        if (aOffset > bOffset) return isASC ? -1 : 1;
      }
      const field = sortField as keyof ProjectDTO;
      if (field === 'createdAt') {
        const aDate = +new Date(a.createdAt);
        const bDate = +new Date(b.createdAt);
        if (aDate < bDate) return isASC ? 1 : -1;
        if (aDate > bDate) return isASC ? -1 : 1;
      }
      if (field === 'createdBy') {
        const aOwnerName = a.createdBy?.firstName;
        const bOwnerName = b.createdBy?.firstName;
        if (aOwnerName < bOwnerName) return isASC ? -1 : 1;
        if (aOwnerName > bOwnerName) return isASC ? 1 : -1;
      }
      if (a[field] < b[field]) return isASC ? -1 : 1;
      if (a[field] > b[field]) return isASC ? 1 : -1;
      return 0;
    };

  const actions: TableActionData<ProjectDTO['id']> = {
    name: t('project.listing.actions.view'),
    onClick: (id) => {
      navigate(`/project/${id}/view`);
    },
  };

  const header = {
    name: t('project.listing.name'),
    createdAt: t('project.listing.date'),
    location: t('project.listing.location'),
    totalOffset: t('project.listing.totalOffset'),
    actions: t('project.listing.actions.header'),
  };

  const filterFields = ['type', 'location'];

  const applyFilters = (query: any) => {
    setQuery(query);
  };

  const sortByOrder = (key: keyof ProjectDTO) => {
    if (key === orderBy) {
      setIsASC(!isASC);
      return;
    }
    setOrderBy(key);
  };

  return {
    projects: projects ? projects.sort(sortBy(orderBy)) : [],
    dataToFilter,
    applyFilters,
    filterFields,
    header,
    actions,
    isLoading,
    headerProps: {
      order: isASC ? 'asc' : 'desc',
      orderBy,
      sortByOrder,
    },
  };
};
