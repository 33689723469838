import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  form: {
    background: '#FEFFFF',
    boxShadow: '0px 6px 24px rgba(25, 29, 56, 0.16)',
    padding: '30px 35px',
    '& .MuiFormControl-root': {
      '& label': {
        color: '#2B201F',
        WebkitTextFillColor: '#2B201F',
      },
    },
    '& .MuiInputAdornment-positionEnd': {
      '& p': {
        color: '#AAB0F3',
      },
    },
  },
});
