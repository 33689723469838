import { useCallback, useMemo, useState } from 'react';
import { GenericFormProps, GenericModalProps } from '@energyweb/origin-ui-core';
import { useVerifyCodeFormLogic } from '@energyweb/origin-ui-exchange-logic';
import { useVerifyPhoneFormLogic } from '@energyweb/origin-ui-user-logic';
import {
  VerifyPhoneFormValues,
  useRequestPhoneVerification,
} from '@energyweb/origin-ui-user-data';

import {
  UserModalsActionsEnum,
  useUserModalsStore,
  useUserModalsDispatch,
} from '../../../context';
import { useStyles } from './ChangePasswordOTPModal.styles';

export const useChangePasswordOTPModalEffects = () => {
  const classes = useStyles();
  const { requestHandler, isMutating: isRequesting } =
    useRequestPhoneVerification();
  const [buttonDisabled, setButtonDisabled] = useState(isRequesting);
  const { changePasswordOtpModal } = useUserModalsStore();

  const dispatchModals = useUserModalsDispatch();

  const { phoneNumber, onSubmit, open } = changePasswordOtpModal;

  const requestVerificationCode = useCallback(() => {
    requestHandler({ phone: phoneNumber });
  }, [phoneNumber]);

  const formLogic = useVerifyPhoneFormLogic({
    isMutating: false,
    isRequesting: false,
  });

  const closeModal = useCallback(() => {
    dispatchModals({
      type: UserModalsActionsEnum.SHOW_PASSWORD_CHANGE_OTP,
      payload: {
        open: false,
        phoneNumber: null,
        onSubmit: null,
      },
    });
  }, []);

  const submitModal = (value: any) => {
    if (buttonDisabled) return;
    setButtonDisabled(true);
    onSubmit(value).then(() => setButtonDisabled(false));
  };

  const formProps: GenericFormProps<VerifyPhoneFormValues> = useMemo(
    () => ({ ...formLogic, submitHandler: submitModal }),
    [formLogic, onSubmit]
  );

  const { title, text, buttons } = useVerifyCodeFormLogic(
    onSubmit,
    phoneNumber
  );

  const dialogProps: GenericModalProps['dialogProps'] = {
    maxWidth: 'xs',
    classes,
  };

  return {
    open,
    title,
    text,
    buttons,
    dialogProps,
    formProps,
    closeModal,
    buttonDisabled,
    requestVerificationCode,
  };
};
