import { UserDTO } from '@energyweb/origin-backend-react-query-client';
import { GenericFormProps } from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export type TUpdateUserDataFormValues = {
  firstName: UserDTO['firstName'];
  lastName: UserDTO['lastName'];
  email: UserDTO['email'];
  telephone: UserDTO['telephone'];
};

export const useUpdateUserAccountDataFormConfig = (
  user: UserDTO
): Omit<GenericFormProps<TUpdateUserDataFormValues>, 'submitHandler'> => {
  const { t } = useTranslation();
  const { firstName, lastName, telephone, email } = user;
  const initialFormData: TUpdateUserDataFormValues = {
    firstName,
    lastName,
    telephone,
    email,
  };

  return {
    buttonText: t('user.profile.updateProfile'),
    buttonFullWidth: true,
    fields: [
      {
        label: t('user.profile.firstName'),
        name: 'firstName',
        required: true,
        inputProps: {
          ['data-cy']: 'firstName',
        },
      },
      {
        label: t('user.profile.lastName'),
        name: 'lastName',
        required: true,
        inputProps: {
          ['data-cy']: 'lastName',
        },
      },
      {
        label: t('user.profile.email'),
        name: 'email',
        required: true,
        inputProps: {
          ['data-cy']: 'email',
        },
      },
      {
        label: t('user.profile.telephone'),
        name: 'telephone',
        required: true,
        inputProps: {
          ['data-cy']: 'telephone',
        },
      },
    ],
    buttonWrapperProps: { justifyContent: 'flex-start' },
    buttonProps: {
      ['data-cy']: 'user-data-change-button',
    },
    initialValues: initialFormData,
    inputsVariant: 'outlined',
    validationSchema: Yup.object().shape({
      firstName: Yup.string().label(t('user.profile.firstName')).required(),
      lastName: Yup.string().label(t('user.profile.lastName')).required(),
      email: Yup.string().email().label(t('user.profile.email')).required(),
      telephone: Yup.string()
        .required()
        .min(10)
        .label(t('user.profile.telephone')),
    }),
  };
};
