import { GenericModalProps } from '@energyweb/origin-ui-core';
import { useProjectVerifiedSuccessfulLogic } from '@energyweb/origin-ui-organization-logic';

import {
  OrganizationModalsActionsEnum,
  useOrgModalsDispatch,
  useOrgModalsStore,
} from '../../../context';

export const useProjectVerifiedSuccessfulEffects = () => {
  const { projectVerifiedSuccessful } = useOrgModalsStore();
  const dispatchModals = useOrgModalsDispatch();

  const closeModal = () => {
    dispatchModals({
      type: OrganizationModalsActionsEnum.SHOW_PROJECT_VERIFIED_SUCCESSFUL,
      payload: {
        open: false,
        text: '',
      },
    });
  };

  const { buttons } = useProjectVerifiedSuccessfulLogic(closeModal);

  const dialogProps: GenericModalProps['dialogProps'] = {
    maxWidth: 'sm',
  };

  return {
    text: projectVerifiedSuccessful.text,
    open: projectVerifiedSuccessful.open,
    buttons,
    dialogProps,
  };
};
