import { GenericForm } from '@energyweb/origin-ui-core';
import { Paper } from '@mui/material';
import React from 'react';
import { useStyles } from './ChangePhoneNumberPage.styles';
import { useRequestChangePhoneNumberPageEffects } from './ChangePhoneNumberPage.effects';

export const ChangePhoneNumberPage = () => {
  const { formProps } = useRequestChangePhoneNumberPageEffects();
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <GenericForm {...formProps} />
    </Paper>
  );
};
