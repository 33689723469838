import { useMemo } from 'react';
import { useChangePhoneNumberFormLogic } from '@energyweb/origin-ui-user-logic';
import { GenericFormProps } from '@energyweb/origin-ui-core';
import {
  RequestChangePhoneNumberFormValues,
  useRequestChangePhoneNumberHandler,
} from '@energyweb/origin-ui-user-data';

export const useRequestChangePhoneNumberPageEffects = () => {
  const { requestHandler: submitHandler, isMutating } =
    useRequestChangePhoneNumberHandler();
  const formLogic = useChangePhoneNumberFormLogic(isMutating);

  const formProps: GenericFormProps<RequestChangePhoneNumberFormValues> =
    useMemo(
      () => ({ ...formLogic, submitHandler }),
      [formLogic, submitHandler]
    );

  return {
    formProps,
  };
};
