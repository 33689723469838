import { useTranslation } from 'react-i18next';
import {
  ProjectOwnerDTO,
  ProjectOwnerStatus,
} from '@energyweb/origin-backend-react-query-client';
import {
  TableActionData,
  TableComponentProps,
  TableRowData,
} from '@energyweb/origin-ui-core';

export type TUseProjectOwnersTableArgs = {
  projectOwners: ProjectOwnerDTO[];
  actions: TableActionData<ProjectOwnerDTO['id']>[];
  isLoading: boolean;
};

export type TFormatProjectOwners = (
  props: Omit<TUseProjectOwnersTableArgs, 'isLoading'>
) => TableRowData<ProjectOwnerDTO['id']>[];

export type TUseProjectOwnersTableLogic = (
  props: TUseProjectOwnersTableArgs
) => TableComponentProps<ProjectOwnerDTO['id']>;

const formatProjectOwners: TFormatProjectOwners = ({
  projectOwners,
  actions,
}) => {
  return projectOwners?.map((projectOwner) => ({
    id: projectOwner.id,
    state: projectOwner.state,
    license: projectOwner.license,
    status: projectOwner.status,
    email: projectOwner?.user?.email,
    actions:
      projectOwner.status === ProjectOwnerStatus.Pending ? actions : undefined,
  }));
};

export const useProjectOwnersTableLogic: TUseProjectOwnersTableLogic = ({
  projectOwners,
  actions,
  isLoading,
}) => {
  const { t } = useTranslation();

  return {
    header: {
      state: t('user.projectOwner.state'),
      license: t('user.projectOwner.license'),
      status: t('user.projectOwner.status'),
      email: t('user.projectOwner.email'),
      actions: '',
    },
    pageSize: 20,
    loading: isLoading,
    data: formatProjectOwners({ projectOwners, actions }) ?? [],
  };
};
