import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { CreateSupplementalDataDTO } from '@energyweb/issuer-irec-api-react-query-client';
import {
  ProjectDTO,
  getProjectControllerGetQueryKey,
  useSupplementalDataControllerUpdate,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

export const useUpdateSupplementalDataHandler = (
  id: number,
  project?: ProjectDTO
) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useSupplementalDataControllerUpdate();

  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(project.id);

  const submitHandler = (values: CreateSupplementalDataDTO) => {
    mutate(
      { id, data: { ...values } },
      {
        onSuccess: () => {
          showNotification(
            t('project.register.notifications.supplementalDataUpdatedSuccess'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(projectQueryKey);
        },
        onError: (error: any) => {
          showNotification(
            `${t(
              'project.register.notifications.supplementalDataUpdatedError'
            )}:
                ${error?.response?.data?.message || ''}`,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return { submitHandler, isLoading };
};
