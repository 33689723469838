import { TableRowData } from '../../../containers/TableComponent';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useEditableTableComponentRowEffects = <Id>(
  row: TableRowData<Id>
) => {
  const { t } = useTranslation();
  const [currentRow, setRow] = useState(row);
  const [buttonText, setText] = useState(t('general.buttons.edit'));
  const [fieldValueChanged, setIsValueChanged] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  useEffect(() => {
    if (isEditMode) {
      setText(t('general.buttons.save'));
    } else {
      setText(t('general.buttons.edit'));
    }
  }, [isEditMode]);

  const onToggleEditMode = async (id: any, action: any) => {
    setEditMode(!isEditMode);
    setRow(() => {
      if (currentRow.id === id) {
        return { ...currentRow, isEditMode: !currentRow.isEditMode };
      }
      return row;
    });

    if (fieldValueChanged) {
      delete currentRow.actions;
      const updatedData = { ...currentRow };
      action.onClick(id, updatedData);
      setIsValueChanged(false);
    }
  };

  const onChange = (e: any, row: any) => {
    const value = e.target.value;
    const name = e.target.name;
    if (row.id) {
      setRow({ ...row, [name]: value });
      setIsValueChanged(true);
    }
  };

  return {
    onToggleEditMode,
    onChange,
    currentRow,
    buttonText,
  };
};
