import { GenericModalProps } from '@energyweb/origin-ui-core';

export type ModalLogicFunctionReturnType = Omit<
  GenericModalProps,
  'open' | 'icon'
>;

export type TRemoveConfirmModalLogic = (
  closeModal: () => void,
  submitHandler: (values?: any) => void,
  buttonText?: string
) => ModalLogicFunctionReturnType;

export type TUseVerifyCodeFormLogic = (
  submitHandler: (values?: any) => void,
  phoneNumber: string
) => ModalLogicFunctionReturnType;
