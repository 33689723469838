import { DefaultNavIcon } from '@energyweb/origin-ui-assets';
import { TMenuSection } from '@energyweb/origin-ui-core';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
export type TGetExchangeMenuArgs = {
  t: (tag: string) => string;
  isOpen: boolean;
  showSection?: boolean;
  selectedMenuItemClass?: string;
};

type TGetExchangeMenu = (args?: TGetExchangeMenuArgs) => TMenuSection;

export const getExchangeMenu: TGetExchangeMenu = ({
  t,
  isOpen,
  showSection,
  selectedMenuItemClass,
}) => {
  return {
    isOpen,
    dataCy: 'carbonOffsetMenu',
    sectionTitle: t('navigation.carbonOffset.sectionTitle'),
    show: showSection,
    rootUrl: '/carbon-offset',
    menuList: [
      {
        url: '',
        label: t('navigation.organization.carbonOffset'),
        show: showSection,
        dataCy: 'carbonOffset',
        icon: BusinessCenterOutlinedIcon,
      },
    ],
    sectionIcon: DefaultNavIcon,
    selectedMenuItemClass,
  };
};
