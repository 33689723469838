import { LightenColor } from '@energyweb/origin-ui-theme';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: LightenColor(
      theme.palette.background.paper,
      3,
      theme.palette.mode
    ),
  },
  listItem: {
    padding: 0,
  },
  button: {
    justifyContent: 'flex-start',
    textTransform: 'none',
    padding: '10px 20px 10px 8px',
    width: '100%',
    margin: '0',
    borderRadius: 0,
    color: LightenColor(theme.palette.text.secondary, 5, theme.palette.mode),
    '&:hover': {
      backgroundColor: LightenColor(
        theme.palette.background.paper,
        5,
        theme.palette.mode
      ),
    },
  },
  icon: {
    width: 'auto',
    height: 'auto',
    marginRight: 15,
    '& img': {
      width: 24,
      height: 24,
      verticalAlign: 'middle',
    },
  },
}));
