import { withPrimaryColor } from './primaryColoringHoc';
import { ReactComponent as IconPending } from './images/user-account-icons/icon-pending.svg';
import { ReactComponent as IconChangeRequested } from './images/user-account-icons/icon-change-requested.svg';
import { ReactComponent as IconAccepted } from './images/user-account-icons/icon-accepted.svg';
import { ReactComponent as IconRejected } from './images/user-account-icons/icon-rejected.svg';
import { ReactComponent as IconZero6 } from './images/user-account-icons/icon-zero6.svg';
import { ReactComponent as IconPendingCredit } from './images/user-account-icons/icon-pending-credit.svg';
import { ReactComponent as IconActiveCredit } from './images/user-account-icons/icon-active-credit.svg';
import { ReactComponent as IconGeneratedCredit } from './images/user-account-icons/icon-generated-credit.svg';
import { ReactComponent as IconRetiredCredit } from './images/user-account-icons/icon-retired-credit.svg';

export const PendingIcon = withPrimaryColor(IconPending);
export const ChangeRequestedIcon = withPrimaryColor(IconChangeRequested);
export const AcceptedIcon = withPrimaryColor(IconAccepted);
export const RejectedIcon = withPrimaryColor(IconRejected);
export const Zero6Icon = withPrimaryColor(IconZero6);

export { ReactComponent as Zeor6Logo } from './images/EW-Origin-WhiteText.svg';
export { ReactComponent as EnergyWebLogo } from './images/EW-Origin-WhiteText.svg';
export { ReactComponent as ZeroSixLogoBlack } from './images/ZEROSIX-logo-BlackText.svg';
export { ReactComponent as ZeroSixLogoWhite } from './images/ZEROSIX-logo-WhiteText.svg';
export { ReactComponent as OrganizationAdded } from './images/icon-org-added.svg';
export { ReactComponent as CoordinatesLogo } from './images/coordinates-logo.svg';
export { default as EnergyWebBackground } from './images/bg-origin.png';
export { ReactComponent as IRecLogo } from './images/IREC-logo.svg';
export { ReactComponent as IRecLogoDark } from './images/IREC-logo_dark.svg';
export { ReactComponent as MenuDots } from './images/menu-dots.svg';
export { ReactComponent as SidebarMenu } from './images/sidebar-menu.svg';
export { ReactComponent as ShareIconOutlined } from './images/share-icon.svg';

export { ReactComponent as IconPending } from './images/user-account-icons/icon-pending.svg';
export { ReactComponent as IconChangeRequested } from './images/user-account-icons/icon-change-requested.svg';
export { ReactComponent as IconAccepted } from './images/user-account-icons/icon-accepted.svg';
export { ReactComponent as IconRejected } from './images/user-account-icons/icon-rejected.svg';
export { ReactComponent as IconZero6 } from './images/user-account-icons/icon-zero6.svg';
export { ReactComponent as IconPendingCredit } from './images/user-account-icons/icon-pending-credit.svg';
export { ReactComponent as IconActiveCredit } from './images/user-account-icons/icon-active-credit.svg';
export { ReactComponent as IconGeneratedCredit } from './images/user-account-icons/icon-generated-credit.svg';
export { ReactComponent as IconRetiredCredit } from './images/user-account-icons/icon-retired-credit.svg';
export { ReactComponent as SettingsIcon } from './images/settings.svg';
export { ReactComponent as ProfileSettingsIcon } from './images/profile-settings.svg';
export { ReactComponent as WalletSettingsIcon } from './images/wallet-settings.svg';
export { default as MenuBackground } from './images/user-account-icons/menu-background.svg';

export { default as SmallLogo } from './images/zero6-icon-small.svg';
export { default as Tagline } from './images/tagline.svg';
export { ReactComponent as ArrowLeft } from './images/ArrowLeft.svg';
export { ReactComponent as CarbonOffsetMenuIcon } from './images/carbon-offset-menu.svg';

export { default as Step1 } from './settings-icon/Step1.json';
export { default as Step2 } from './settings-icon/Step2.json';
export { default as Step3 } from './settings-icon/Step3.json';
export { default as Step4 } from './settings-icon/Step4.json';
export { default as Step5 } from './settings-icon/Step5.json';
export { default as Step6 } from './settings-icon/Step6.json';
export { default as Step7 } from './settings-icon/Step7.json';
export { ReactComponent as UploadIcon } from './images/upload-icon.svg';

export { default as DefaultNavIcon } from './images/navbar/default.svg';
export { default as ProjectOwner } from './images/projectOwner.png';
export { default as ProjectVerifier } from './images/projectVerifier.png';
