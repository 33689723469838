import { useUserControllerMe } from '@energyweb/origin-backend-react-query-client';
import {
  getAuthenticationToken,
  removeAuthenticationToken,
} from '@energyweb/origin-ui-shared-state';
import axios from 'axios';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

export const useUser = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const logout = useCallback((navigateRoute = '/') => {
    const token = getAuthenticationToken();
    if (token) {
      removeAuthenticationToken();
      axios.defaults.headers.common['Authorization'] = undefined;
      queryClient.clear();
      navigate('/login');
    }
  }, []);

  const tokenExists = Boolean(getAuthenticationToken());

  const {
    data,
    isLoading: userLoading,
    isSuccess,
  } = useUserControllerMe({
    query: {
      enabled: tokenExists,
    },
  });

  const user = data || null;

  let isPhoneConfirmed = user?.phoneVerificationRequired
    ? user?.phoneConfirmed
    : true;

  if (user?.passwordRecovered) {
    isPhoneConfirmed = false;
  }

  const isLoggedIn = useMemo(
    () => Boolean(tokenExists && isSuccess),
    [tokenExists, isSuccess]
  );

  const isAuthenticated = useMemo(
    () => Boolean(isLoggedIn && isPhoneConfirmed),
    [isLoggedIn, isPhoneConfirmed, user]
  );

  return {
    user,
    userLoading,
    isAuthenticated,
    isLoggedIn,
    isPhoneConfirmed,
    logout,
  };
};
