import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  getOrganizationMenu,
  TGetOrganizationMenuArgs,
} from '@energyweb/origin-ui-organization-logic';

import {
  getExchangeMenu,
  TGetExchangeMenuArgs,
} from '@energyweb/origin-ui-exchange-logic';

import {
  // getAccountMenu,
  getAdminMenu,
  getVerifierMenu,
  TGetAccountMenuArgs,
  TGetVerifierMenuArgs,
  TGetAdminMenuArgs,
  getTopbarButtonList,
} from '@energyweb/origin-ui-user-logic';
import { LoginRoutesConfig } from '@energyweb/origin-ui-user-view';
import {
  useConnectionControllerGetMyConnection,
  useRegistrationControllerGetRegistrations,
} from '@energyweb/origin-organization-irec-api-react-query-client';
import { isRole, Role, UserStatus } from '@energyweb/origin-backend-core';
import { useUser } from '@energyweb/origin-ui-user-data';
import { useActiveMenuTab, useAxiosDefaults } from '../../hooks';
import { useStyles } from './AppContainer.styles';

export type RoutesConfig = {
  projectRoutes: Omit<TGetOrganizationMenuArgs, 't' | 'isOpen' | 'showSection'>;
  carbonOffsetRoutes?: Omit<TGetExchangeMenuArgs, 't' | 'isOpen'>;
  accountRoutes: Omit<TGetAccountMenuArgs, 't' | 'isOpen' | 'showSection'>;
  adminRoutes?: Omit<TGetAdminMenuArgs, 't' | 'isOpen' | 'showSection'>;
  loginRoutes: LoginRoutesConfig;
  verifierRoutes: Omit<TGetVerifierMenuArgs, 't' | 'isOpen' | 'showSection'>;
};

export const useAppContainerEffects = () => {
  useAxiosDefaults();
  const classes = useStyles();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    isAuthenticated,
    isLoggedIn,
    user,
    logout,
    userLoading,
    isPhoneConfirmed,
  } = useUser();
  const topbarButtons = useMemo(
    () => getTopbarButtonList(isAuthenticated, logout, t, navigate),
    [isAuthenticated, logout, t, navigate]
  );

  const { isProjectTabActive, isAccountTabActive, isAdminTabActive } =
    useActiveMenuTab();

  const userHasOrg = Boolean(user?.organization?.id);
  const userIsActive = user && user.status === UserStatus.Active;
  const userIsAdminOrSupport = isRole(user, Role.Admin, Role.SupportAgent);

  const { data: iRecRegistrations, isLoading: isIRecRegistrationsLoading } =
    useRegistrationControllerGetRegistrations({
      query: {
        enabled: isAuthenticated && userHasOrg,
      },
    });
  const { isLoading: isIRecOrgLoading } =
    useConnectionControllerGetMyConnection({
      query: {
        enabled:
          isAuthenticated &&
          userHasOrg &&
          iRecRegistrations?.length > 0 &&
          !userIsAdminOrSupport,
      },
    });

  const userIsProjectOwner = isRole(user, Role.ProjectOwner);
  const userIsProjectVerifier = isRole(user, Role.ProjectVerifier);
  const userIsVerifiedUser =
    !userIsProjectOwner && !userIsProjectVerifier && !userIsAdminOrSupport;
  const userIsActiveOrProjectOwnerOrVerifier =
    (userIsProjectOwner || userIsActive || userIsProjectVerifier) &&
    !userIsAdminOrSupport;

  const projectRoutesConfig: RoutesConfig['projectRoutes'] = useMemo(
    () => ({
      showRegisterOrg: userIsProjectOwner || userIsVerifiedUser,
      showProjectListing: userIsProjectOwner,
      showAllProjectListing: userIsActive,
      showNotPermittedCreateProjectPage: !userIsProjectOwner,
      showVerifierDashboardPage: userIsProjectVerifier,
    }),
    [
      userIsActive,
      userIsProjectOwner,
      userIsProjectVerifier,
      userIsVerifiedUser,
    ]
  );

  const orgMenu = useMemo(
    () =>
      getOrganizationMenu({
        t,
        isOpen: true,
        showSection: userIsActiveOrProjectOwnerOrVerifier,
        menuButtonClass: classes.menuButton,
        selectedMenuItemClass: classes.selectedMenuItem,
        ...projectRoutesConfig,
      }),
    [t, isProjectTabActive, userIsProjectOwner, projectRoutesConfig]
  );

  const accountRoutesConfig: RoutesConfig['accountRoutes'] = useMemo(
    () => ({
      showSettings: true,
      showRegisterProjectOwnerForm: userIsVerifiedUser,
      showProjectRegisterForm: userIsProjectOwner,
      showUserProfile: isAuthenticated,
    }),
    [isAuthenticated, userIsVerifiedUser, userIsProjectOwner]
  );

  const verifierRoutesConfig: RoutesConfig['verifierRoutes'] = useMemo(
    () => ({
      showRegisterVerifierMenu: userIsVerifiedUser,
      showVerifierProjects: userIsProjectVerifier,
    }),
    [isAuthenticated, userIsVerifiedUser, userIsProjectVerifier]
  );

  const verifierMenu = useMemo(
    () =>
      getVerifierMenu({
        t,
        isOpen: true,
        menuButtonClass: classes.menuButton,
        selectedMenuItemClass: classes.selectedMenuItem,
        ...verifierRoutesConfig,
      }),
    [t, userIsActive, verifierRoutesConfig]
  );

  const adminRoutesConfig: RoutesConfig['adminRoutes'] = useMemo(
    () => ({
      showUsers: userIsAdminOrSupport,
      showProjectOwners: userIsAdminOrSupport,
      showProjectVerifiers: userIsAdminOrSupport,
      showVRCompanies: userIsAdminOrSupport,
      showProjectsInExecution: userIsAdminOrSupport,
    }),
    [userIsAdminOrSupport]
  );
  const adminMenu = useMemo(
    () =>
      getAdminMenu({
        t,
        isOpen: isAdminTabActive || true,
        showSection: userIsAdminOrSupport,
        menuButtonClass: classes.menuButton,
        selectedMenuItemClass: classes.selectedMenuItem,
        ...adminRoutesConfig,
      }),
    [t, isAdminTabActive, userIsAdminOrSupport, adminRoutesConfig]
  );

  const loginRoutesConfig: RoutesConfig['loginRoutes'] = useMemo(
    () => ({
      showLoginPage: !isLoggedIn,
      showRequestResetPasswordPage: !isLoggedIn,
      showResetPasswordPage: !isLoggedIn,
      showPhoneVerificationPage: isLoggedIn && !isPhoneConfirmed,
      showRegister: !isLoggedIn,
    }),
    [isLoggedIn, isPhoneConfirmed]
  );

  const carbonOffsetRoutesConfig: RoutesConfig['carbonOffsetRoutes'] = useMemo(
    () => ({
      showSection:
        (userIsProjectOwner || userIsVerifiedUser) && !userIsProjectVerifier,
    }),
    [isAuthenticated, userIsProjectOwner, userIsVerifiedUser]
  );

  const carbonOffsetMenu = useMemo(
    () =>
      getExchangeMenu({
        t,
        isOpen: true,
        ...carbonOffsetRoutesConfig,
        selectedMenuItemClass: classes.selectedMenuItem,
      }),
    [t, userIsActive, userIsProjectOwner]
  );

  const menuSections = useMemo(
    () => [orgMenu, verifierMenu, carbonOffsetMenu, adminMenu],
    [orgMenu, verifierMenu, adminMenu]
  );

  const routesConfig: RoutesConfig = useMemo(
    () => ({
      projectRoutes: projectRoutesConfig,
      adminRoutes: adminRoutesConfig,
      accountRoutes: accountRoutesConfig,
      verifierRoutes: verifierRoutesConfig,
      loginRoutes: loginRoutesConfig,
      carbonOffsetRoutes: carbonOffsetRoutesConfig,
    }),
    [
      adminRoutesConfig,
      projectRoutesConfig,
      accountRoutesConfig,
      loginRoutesConfig,
      verifierRoutesConfig,
      carbonOffsetRoutesConfig,
    ]
  );

  const isLoading =
    userLoading || isIRecOrgLoading || isIRecRegistrationsLoading;

  return {
    topbarButtons,
    isAuthenticated,
    isLoggedIn,
    isPhoneConfirmed,
    menuSections,
    user,
    isLoading,
    routesConfig,
  };
};
