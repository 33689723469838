import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './SettingsLayout.styles';

export interface SettingsLayoutProps {
  children: JSX.Element;
  icon?: JSX.Element;
}

export const SettingsLayout: FC<SettingsLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.navContainer} />
      <Box className={classes.slotContainer}>{children}</Box>
    </Box>
  );
};
