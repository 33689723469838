import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { ProjectsMap } from '../../containers';
import { useStyles } from './UserAccountPage.styles';
import { useUserAccountPageEffects } from './UserAccountPage.effects';
import { IconZero6 } from '@energyweb/origin-ui-assets';
import { ProjectVerifierAccount } from '../ProjectVerifierAccount/ProjectVerifierAccount';
import { VerifiedUserAccount } from '../VerifiedUserAccount/VerifiedUserAccount';
import { ProjectOwnerAccount } from '../ProjectOwnerAccount';

export const UserAccountPage = () => {
  const classes = useStyles();
  const {
    getProjectByStatus,
    user,
    userIsProjectVerifier,
    userIsProjectOwner,
    userIsVerifiedUser,
    title,
    mapTitle,
  } = useUserAccountPageEffects();

  const {
    pendingProjects,
    verifiedProjects,
    rejectedProjects,
    changeRequestedProjects,
  } = getProjectByStatus;

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      <Box px={2} className={classes.wrapper}>
        <Paper elevation={0} className={classes.headerIcon}>
          <IconZero6 />
        </Paper>
        <Box sx={{ marginTop: '15px' }}>
          {userIsProjectVerifier && (
            <ProjectVerifierAccount
              pendingProjects={pendingProjects}
              verifiedProjects={verifiedProjects}
              rejectedProjects={rejectedProjects}
              changeRequestedProjects={changeRequestedProjects}
            />
          )}
          {userIsProjectOwner && (
            <ProjectOwnerAccount
              pendingProjects={pendingProjects}
              verifiedProjects={verifiedProjects}
            />
          )}
          {userIsVerifiedUser && <VerifiedUserAccount />}
        </Box>
        <Box sx={{ marginTop: '30px' }}>
          <ProjectsMap
            pendingProjects={pendingProjects}
            verifiedProjects={verifiedProjects}
            rejectedProjects={rejectedProjects}
            changeRequestedProjects={changeRequestedProjects}
            user={user}
            title={mapTitle}
          />
        </Box>
      </Box>
    </div>
  );
};

export default UserAccountPage;
