import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import {
  IconActiveCredit,
  IconRetiredCredit,
} from '@energyweb/origin-ui-assets';
import React from 'react';
import { t } from 'i18next';
import { useApiAllExchangeCertificates } from '@energyweb/origin-ui-exchange-data';
import { useApiClaimedCertificates } from '@energyweb/origin-ui-user-data';

export type SummaryFormData = {
  status: string;
  count: number;
  icon: ReactNode;
};

export const useVerifiedUserAccountEffects = () => {
  const { exchangeCertificates: activeCarbonCredits } =
    useApiAllExchangeCertificates();
  const { claimedCertificates: retiredCarbonCredits } =
    useApiClaimedCertificates();

  const summaryData: SummaryFormData[] = [
    {
      status: t('user.userProfile.activeCredits'),
      count: activeCarbonCredits?.length || 0,
      icon: <IconActiveCredit />,
    },
    {
      status: t('user.userProfile.retiredCredits'),
      count: retiredCarbonCredits?.length || 0,
      icon: <IconRetiredCredit />,
    },
  ];

  return {
    summaryData,
  };
};
