import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  useProjectOwnerControllerRegister,
  RegisterProjectOwnerDTO,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

export type TCreateProjectOwnerFormValues = {
  state: RegisterProjectOwnerDTO['state'];
  license: RegisterProjectOwnerDTO['license'];
};

export const useApiCreateProjectOwnerData = (
  onSuccess?: any,
  onError?: any,
  onPending?: any
) => {
  const { t } = useTranslation();
  const { mutate } = useProjectOwnerControllerRegister();
  const navigate = useNavigate();

  const submitHandler = (values: TCreateProjectOwnerFormValues) => {
    onPending && onPending();
    mutate(
      { data: { ...values } },
      {
        onSuccess: (response) => {
          showNotification(
            t('user.projectOwner.notifications.registeredSuccess'),
            NotificationTypeEnum.Success
          );
          if (onSuccess) return onSuccess(response);
          navigate('/organization/register');
        },
        onError: (error: any) => {
          showNotification(
            t('user.projectOwner.notifications.registeredFailure'),
            NotificationTypeEnum.Error
          );
          onError && onError(error);
        },
      }
    );
  };

  return {
    submitHandler,
  };
};
