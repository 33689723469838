import React, { FC } from 'react';
import { UnpackNestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import {
  ButtonsGroup,
  FileUpload,
  FormInput,
  FormSelect,
  GenericFormSecondaryButton,
  useCommonStyles,
} from '@energyweb/origin-ui-core';
import { SupplementalDataFormValues } from '@energyweb/origin-ui-organization-logic';
import { ProjectDTO } from '@energyweb/issuer-irec-api-react-query-client';
import { useStyles } from './SupplementalData.styles';
import { useSupplementalDataEffects } from './SupplementalData.effects';
import { isEmpty } from 'lodash';
import { handleFileUpload } from '@energyweb/origin-ui-utils';

export interface SupplementalDataProps {
  submitHandler?: (
    values: UnpackNestedValue<SupplementalDataFormValues>
  ) => Promise<void>;
  secondaryButtons?: GenericFormSecondaryButton[];
  loading?: boolean;
  project?: ProjectDTO;
}

export const SupplementalDataForm: FC<SupplementalDataProps> = ({
  submitHandler,
  secondaryButtons,
  loading,
  project,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const {
    documents,
    fields,
    control,
    errors,
    register,
    handleSubmit,
    uploadFunction,
    onChange,
    getUploadedFiles,
  } = useSupplementalDataEffects(project);

  const onSubmit = async (values: SupplementalDataFormValues) => {
    const uploadedFiles = await handleFileUpload(values.uploads, documents);
    values.uploads = [...uploadedFiles];
    submitHandler(values);
  };

  return (
    <Box position={'relative'}>
      <Backdrop className={commonClasses.circularProgress} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Box>
          <Typography variant="subtitle1">{fields[0]?.label}</Typography>
          <FormSelect
            variant={'outlined'}
            disabled={false}
            register={register}
            control={control}
            field={fields[0]}
            errorExists={!isEmpty((errors as any)[fields[0]?.name])}
            errorText={(errors as any)[fields[0]?.name]?.message ?? ''}
          />
        </Box>
        <Box mt={2}>
          <FileUpload
            heading={t(
              'project.register.supplementalData.uploadMonitoringPlan'
            )}
            dropzoneText={t('file.upload.dropOrClick')}
            apiUploadFunction={uploadFunction}
            onChange={onChange}
            wrapperProps={{ ['data-cy']: 'monitoringPlan' }}
            uploadedFiles={getUploadedFiles('monitoringPlan')}
            required={!documents['monitoringPlan']}
          />

          <FileUpload
            heading={t(
              'project.register.supplementalData.uploadPermanenceReport'
            )}
            dropzoneText={t('file.upload.dropOrClick')}
            apiUploadFunction={uploadFunction}
            onChange={onChange}
            wrapperProps={{ ['data-cy']: 'permanenceReport' }}
            uploadedFiles={getUploadedFiles('permanenceReport')}
            required={!documents['permanenceReport']}
          />
        </Box>
        <Grid container spacing={1} my={3}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">{fields[1].label}</Typography>
            <FormInput
              key={fields[1].label}
              field={fields[1]}
              disabled={false}
              register={register}
              errorExists={!isEmpty((errors as any)[fields[1].name])}
              errorText={(errors as any)[fields[1].name]?.message ?? ''}
              variant={'outlined'}
              {...fields[1].inputProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">{fields[2].label}</Typography>
            <FormInput
              key={fields[2].label}
              field={fields[2]}
              disabled={false}
              register={register}
              errorExists={!isEmpty((errors as any)[fields[2].name])}
              errorText={(errors as any)[fields[2].name]?.message ?? ''}
              variant={'outlined'}
              {...fields[2].inputProps}
            />
          </Grid>
        </Grid>
        <ButtonsGroup secondaryButtons={secondaryButtons} loading={loading} />
      </form>
    </Box>
  );
};
