import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    height: '100vh',
    width: '100%',
  },
  navContainer: {
    backgroundColor: '#fff',
    minWidth: 280,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: '38px',
  },
  navIconContainer: {
    marginTop: '120px',
    marginBottom: '70px',
    height: 95,
    width: 95,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navItem: {
    color: '#78CC8A',
    marginBottom: 25,
    fontSize: 22,
    textDecoration: 'none',
    opacity: 0.8,
    lineHeight: '28px',
  },
  active: {
    textDecoration: 'underline',
    opacity: 1,
  },
  slotContainer: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (min-width: 1080px)': {
      maxWidth: '1072px',
    },
  },
}));
