import {
  ProjectOwnerStatus,
  getProjectOwnerControllerGetAllQueryKey,
  ProjectOwnerDTO,
} from '@energyweb/origin-backend-react-query-client';
import { useQueryClient } from 'react-query';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useProjectOwnerControllerUpdate } from '@energyweb/issuer-irec-api-react-query-client';

export const useUpdateProjectOwnerStatusActions = (
  projectOwners: ProjectOwnerDTO[]
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const projectOwnerKey = getProjectOwnerControllerGetAllQueryKey();

  const { mutate, isLoading: isMutating } = useProjectOwnerControllerUpdate();

  const acceptInvite = (id: ProjectOwnerDTO['id']) => {
    const projectOwner = projectOwners?.find((owner) => owner.id === id);
    return mutate(
      {
        id,
        data: { ...projectOwner, status: ProjectOwnerStatus.Accepted },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(projectOwnerKey);
          showNotification(
            t('user.projectOwner.notifications.acceptedSuccess'),
            NotificationTypeEnum.Success
          );
        },
        onError: (error: any) => {
          showNotification(
            error?.response?.data?.message,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  const rejectInvite = (id: ProjectOwnerDTO['id']) => {
    const projectOwner = projectOwners?.find((owner) => owner.id === id);
    return mutate(
      {
        id,
        data: { ...projectOwner, status: ProjectOwnerStatus.Rejected },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(projectOwnerKey);
          showNotification(
            t('user.projectOwner.notifications.rejectedSuccess'),
            NotificationTypeEnum.Success
          );
        },
        onError: (error: any) => {
          showNotification(
            error?.response?.data?.message,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return { acceptInvite, rejectInvite, isMutating };
};
