import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Documents, UploadedFile } from '@energyweb/origin-ui-core';
import { reservesValidationForm } from 'libs/organization/logic/src/register/reservesValidationForm';
import {
  prepareCompanyMembersOptions,
  prepareCompanyMemberEmail,
} from '@energyweb/origin-ui-utils';
import { useVerifierCompanies } from '@energyweb/origin-ui-user-data';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

export const useReservesValidationEffects = (project: ProjectDTO) => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<Documents>({});
  const { verifierCompanies } = useVerifierCompanies();

  const { initialValues, validationSchema, fields } = reservesValidationForm(
    t,
    verifierCompanies,
    project
  );

  const [_fields, setFields] = useState<any>(fields);

  const { register, handleSubmit, formState, control, watch, setValue } =
    useForm({
      mode: 'onChange',
      resolver: yupResolver(validationSchema),
      defaultValues: initialValues,
    });

  const { errors } = formState;
  const { companyId, verifierId, uploads } = watch();

  const emailIndex = fields.findIndex((field) => field.name === 'email');
  const verifierIndex = fields.findIndex(
    (field) => field.name === 'verifierId'
  );
  const companyIndex = fields.findIndex((field) => field.name === 'companyId');

  useEffect(() => {
    fields[companyIndex].options = verifierCompanies?.map((company) => ({
      label: company.name,
      value: company.id,
    }));
    setFields([...fields]);
  }, [verifierCompanies]);

  useEffect(() => {
    setValue('email', '');
    fields[emailIndex].textFieldProps.value = '';
    fields[verifierIndex].options = prepareCompanyMembersOptions(
      verifierCompanies,
      companyId
    );
    setFields([...fields]);
  }, [companyId, verifierId]);

  useEffect(() => {
    fields[emailIndex].textFieldProps.value = prepareCompanyMemberEmail(
      verifierCompanies,
      verifierId,
      companyId
    );
    setFields([...fields]);
  }, [verifierId]);

  const uploadFunction = async (file: File[], dataCy: string) => {
    setDocuments({
      ...documents,
      [dataCy]: file,
    });
    return documents;
  };

  const onChange = (files: UploadedFile[]) => {
    const file = files[files?.length - 1] || ({} as UploadedFile);
    if (file?.removed) {
      delete documents[file?.dataCy];
      setDocuments({ ...documents });
    }
  };

  useEffect(() => {
    if (uploads?.length) {
      const documents = Object.assign(
        {},
        ...uploads?.map((file: any) => ({
          [file.dataCy]: [{ name: file.name } as File],
        }))
      );
      setDocuments({ ...documents });
    }
  }, [uploads]);

  const getUploadedFiles = (dataCy: string) => {
    const uploadedFiles = uploads || [];
    const file = uploadedFiles?.find((file: any) => file.dataCy === dataCy);
    return file ? [{ name: file?.name } as File] : [];
  };

  return {
    fields: _fields,
    documents,
    control,
    errors,
    register,
    handleSubmit,
    uploadFunction,
    onChange,
    getUploadedFiles,
  };
};
