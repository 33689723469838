import React, { FC } from 'react';
import { UnpackNestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import {
  ButtonsGroup,
  FileUpload,
  FormSelect,
  GenericFormSecondaryButton,
  useCommonStyles,
} from '@energyweb/origin-ui-core';
import { OwnershipFormValues } from '@energyweb/origin-ui-organization-logic';
import {
  ProjectDTO,
  UploadDto,
} from '@energyweb/origin-backend-react-query-client';

import { useStyles } from './Ownership.styles';
import { useOwnershipEffects } from './Ownership.effects';
import { isEmpty } from 'lodash';
import { RegisterRoyaltyOwnerForm } from '../RoyaltyOwnerRegistrationForm/RoyaltyOwnerRegistration';
import { handleFileUpload } from '@energyweb/origin-ui-utils';
import { RoyaltyOwnersListingPage } from '../RoyaltyOwnersListingPage';

export interface OwnershipProps {
  submitHandler?: (
    values: UnpackNestedValue<OwnershipFormValues>
  ) => Promise<void>;
  secondaryButtons?: GenericFormSecondaryButton[];
  loading?: boolean;
  project?: ProjectDTO;
}

export const OwnershipForm: FC<OwnershipProps> = ({
  submitHandler,
  secondaryButtons,
  loading,
  project,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const {
    documents,
    fields,
    control,
    errors,
    register,
    handleSubmit,
    uploadFunction,
    onChange,
    getUploadedFiles,
    formValues,
  } = useOwnershipEffects(project);

  const onSubmit = async (values: OwnershipFormValues) => {
    const uploadedFiles = await handleFileUpload(values.uploads, documents);
    values.uploads = [...uploadedFiles];
    submitHandler(values);
  };

  return (
    <Box position={'relative'}>
      <Backdrop className={commonClasses.circularProgress} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        {fields.map(
          (field: any, index: number) =>
            (field.select && (
              <Grid my={1} key={index}>
                <Typography variant="subtitle1">{field.label}</Typography>
                <FormSelect
                  key={field.label}
                  field={field}
                  control={control}
                  errorExists={!isEmpty((errors as any)[field.name])}
                  errorText={(errors as any)[field.name]?.message ?? ''}
                  variant={'outlined'}
                  disabled={false}
                  register={register}
                />
              </Grid>
            )) ||
            (field.file && (
              <FileUpload
                key={index}
                heading={field.label}
                dropzoneText={t('file.upload.dropOrClick')}
                apiUploadFunction={uploadFunction}
                onChange={onChange}
                wrapperProps={{ ['data-cy']: 'mineralRigthsObligation' }}
                uploadedFiles={getUploadedFiles('mineralRigthsObligation')}
                required={!documents['mineralRigthsObligation']}
              />
            ))
        )}
        <Box sx={{ marginTop: '60px' }}>
          <RoyaltyOwnersListingPage project={project} />
        </Box>
        <Box sx={{ marginTop: '60px' }}>
          <RegisterRoyaltyOwnerForm
            project={project}
            formValues={formValues}
            files={documents}
          />
        </Box>
        <ButtonsGroup secondaryButtons={secondaryButtons} loading={loading} />
      </form>
    </Box>
  );
};
