import {
  getOriginLanguage,
  setOriginLanguage,
} from '@energyweb/origin-ui-shared-state';
import { useSettingsPageLogic } from '@energyweb/origin-ui-user-logic';
import {
  useApiUpdateUserSettings,
  useUser,
} from '@energyweb/origin-ui-user-data';
import { ChangeEvent, useState } from 'react';

export const useSettingsPageEffects = () => {
  const { user } = useUser();
  const [notificationsEnabled, setNotificationsEnabled] = useState(
    user.notifications
  );
  const [language, setLanguage] = useState(getOriginLanguage());
  const { submitHandler } = useApiUpdateUserSettings();

  const handleNotificationsChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    submitHandler({ notifications: checked });
    setNotificationsEnabled(checked);
  };

  const handleLanguageChange = (event: any) => {
    setLanguage(event.target.value);
  };

  const handleUpdateLanguage = () => {
    setOriginLanguage(language);
    window.location.reload();
  };

  const pageLogic = useSettingsPageLogic();

  return {
    notificationsEnabled,
    language,
    handleNotificationsChange,
    handleLanguageChange,
    handleUpdateLanguage,
    ...pageLogic,
  };
};
