import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.paper,
    padding: '58px 35px',
    boxShadow: '0px 6px 24px rgba(25, 29, 56, 0.16)',
  },
  heading: {
    marginBottom: '40px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: '10px',
    fontFamily: theme.typography.fontFamily,
    color: theme?.form?.fontColors?.primary,
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontFamily: 'Gotham',
    fontSize: '16px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 550,
  },
  slotContainer: {
    width: '60%',
  },
  metaContent: {
    position: 'relative',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '4%',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(15, 184, 195, 0.06)',
    height: '148px',
    width: '148px',
    borderRadius: '79px',
    marginBottom: '20px',
  },
  icon: {
    height: '68px',
    width: '78px',
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  descriptionTitle: {
    textAlign: 'center',
    fontFamily: 'Gotham',
    fontSize: '16px',
    maxWidth: '250px',
    color: theme?.form?.fontColors?.primary,
  },
  description: {
    whiteSpace: 'break-spaces',
    color: theme?.form?.fontColors?.secondary,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: '45px',
  },
  submitAction: {
    width: '38%',
    height: '50px',
  },
  errorMessageContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    position: 'absolute',
    bottom: 0,
  },
}));
