import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    background: 'no-repeat center center fixed',
    backgroundSize: 'cover',
  },
  content: {
    width: '30%',
    backdropFilter: 'blur(80px)',
    padding: '50px 70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (max-width: 768px)': {
      width: '100%',
      padding: '0',
    },
  },
  titleContainer: {
    margin: '90px',
    maxHeight: '170px',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  title: {
    marginLeft: '70px',
  },
  titleIcon: {
    height: '150px',
    width: '110px',
  },
});
