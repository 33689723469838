import React, { FC, useState } from 'react';
import {
  ButtonsGroup,
  FileUpload,
  GenericFormSecondaryButton,
  showNotification,
  NotificationTypeEnum,
} from '@energyweb/origin-ui-core';
import { ReservesSubmissionFormValues } from '@energyweb/origin-ui-organization-logic';
import { Backdrop, Box, CircularProgress, Grid } from '@mui/material';
import { useStyles } from './ReservesSubmission.styles';
import { UnpackNestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReservesSubmissionEffects } from './ReservesSubmission.effects';
import { WellsPage } from '../WellsViewPage';
import { ProjectWellForm } from '../ProjectWells/ProjectWellRegistrationPage';
import { AutomatedWellRegistration } from '../AutomatedWellRegistration';
import {
  ProjectDTO,
  projectWellControllerCalculatePredictedCarbonOffsets,
  UploadDto,
} from '@energyweb/origin-backend-react-query-client';
import { handleFileUpload } from '@energyweb/origin-ui-utils';
import { useCommonStyles } from '@energyweb/origin-ui-core';

export interface ReservesSubmissionProps {
  submitHandler?: (
    values: UnpackNestedValue<ReservesSubmissionFormValues>
  ) => Promise<void>;
  secondaryButtons?: GenericFormSecondaryButton[];
  loading?: boolean;
  project?: ProjectDTO;
}

export const ReservesSubmissionForm: FC<ReservesSubmissionProps> = ({
  submitHandler,
  secondaryButtons,
  loading,
  project,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const {
    showProjectWells,
    documents,
    handleSubmit,
    uploadFunction,
    onChange,
    getUploadedFiles,
  } = useReservesSubmissionEffects(project);

  const [isLoading, setIsLoading] = useState(loading);

  const onSubmit = async () => {
    try {
      const uploads = project?.reservesSubmission?.uploads || [];
      const values: ReservesSubmissionFormValues = {
        uploads,
        projectId: project?.id,
      };

      const uploadedFiles = await handleFileUpload(uploads, documents);
      values.uploads = [...uploadedFiles];

      setIsLoading(true);
      projectWellControllerCalculatePredictedCarbonOffsets(
        project?.id,
        values.uploads.find(
          (upload: UploadDto) => upload.dataCy === 'wellLevelReserves'
        )
      )
        .then(() => submitHandler(values))
        .catch((error: any) => {
          showNotification(
            `${error?.response?.data?.message || ''}`,
            NotificationTypeEnum.Error
          );
          return;
        })
        .finally(() => setIsLoading(false));
    } catch (error: any) {
      showNotification(
        `${error?.response?.data?.message || ''}`,
        NotificationTypeEnum.Error
      );
    }
  };

  return (
    <Box className={classes.form}>
      <Backdrop className={commonClasses.circularProgress} open={isLoading}>
        <CircularProgress color="success" />
      </Backdrop>
      {showProjectWells && <WellsPage project={project} />}
      <AutomatedWellRegistration project={project} />
      <ProjectWellForm project={project} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12} mt={5}>
            <FileUpload
              heading={t('project.register.reservesSubmission.uploadReserves')}
              dropzoneText={t('file.upload.dropOrClick')}
              apiUploadFunction={uploadFunction}
              onChange={onChange}
              wrapperProps={{ ['data-cy']: 'wellLevelReserves' }}
              uploadedFiles={getUploadedFiles('wellLevelReserves')}
              required={!documents['wellLevelReserves']}
            />
            <FileUpload
              heading={t('project.register.reservesSubmission.uploadForecast')}
              dropzoneText={t('file.upload.dropOrClick')}
              apiUploadFunction={uploadFunction}
              onChange={onChange}
              wrapperProps={{ ['data-cy']: 'monthlyForecast' }}
              uploadedFiles={getUploadedFiles('monthlyForecast')}
              required={!documents['monthlyForecast']}
            />
          </Grid>
          <ButtonsGroup secondaryButtons={secondaryButtons} loading={loading} />
        </Grid>
      </form>
    </Box>
  );
};
