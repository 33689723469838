import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '30px',
    '&& .MuiTypography-body1 ': {
      color: '#2B201F',
      fontSize: '16px',
      lineHeight: 2.3,
    },
  },
  button: {
    width: 150,
    margin: '30px 20px 0',
  },
}));
