import {
  CreateRoyaltyOwnerDTO,
  getProjectControllerGetQueryKey,
  ProjectDTO,
  useRoyaltyOwnerControllerRegister,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useRoyaltyOwnerRegisterHandler = (project: ProjectDTO) => {
  const { t } = useTranslation();
  const { mutate , isLoading} = useRoyaltyOwnerControllerRegister();

  const queryClient = useQueryClient();
  const projectKey = getProjectControllerGetQueryKey(project?.id);

  const submitHandler = async (values: CreateRoyaltyOwnerDTO) => {
    const formattedValues: CreateRoyaltyOwnerDTO = {
      ...values,
    };
    mutate(
      { data: formattedValues },
      {
        onSuccess: () => {
          showNotification(
            t('project.register.notifications.royaltyOwnerRegisteredSuccess'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(projectKey);
        },
        onError: (error: any) => {
          if (error?.response?.status === 400) {
            showNotification(
              error?.response?.data?.message,
              NotificationTypeEnum.Error
            );
          } else {
            showNotification(
              `${t(
                'project.register.notifications.royaltyOwnerRegisteredError'
              )}:
                  ${error?.response?.data?.message || ''}
                  `,
              NotificationTypeEnum.Error
            );
          }
        },
      }
    );
  };

  return { submitHandler, isLoading };
};
