import {
  useVRCompanyControllerGet,
  useVRCompanyControllerGetOne,
  CompanyDTO,
} from '@energyweb/issuer-irec-api-react-query-client';

export const useVerifierCompanies = () => {
  const { data: verifierCompanies, isLoading } = useVRCompanyControllerGet();

  return { verifierCompanies, isLoading };
};

export const useVerifierCompany = (id: CompanyDTO['id']) => {
  const { data: company, isLoading } = useVRCompanyControllerGetOne(id);

  return { company, isLoading };
};
