import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useStyles } from './StatusIndicator.styles';

interface StatusIndicatorProps {
  status:
    | 'Draft'
    | 'Accepted'
    | 'Pending'
    | 'Rejected'
    | 'Verified'
    | 'Issuance'
    | 'Change request'
    | 'Executed'
    | 'Execution';
}

export const StatusIndicator: FC<StatusIndicatorProps> = ({ status }) => {
  const classes = useStyles();

  return (
    <Typography variant="body1" className={classes.status + ' ' + status}>
      {status}
    </Typography>
  );
};
