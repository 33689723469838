import {
  CreateProjectDTO,
  useProjectControllerCreate,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const useProjectRegisterHandler = () => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useProjectControllerCreate();
  const navigate = useNavigate();
  const submitHandler = (values: CreateProjectDTO) => {
    mutate(
      { data: { ...values } },
      {
        onSuccess: (result) => {
          showNotification(
            t('project.register.notifications.registeredSuccess'),
            NotificationTypeEnum.Success
          );
          if (result.id) {
            navigate(`/project/${result.id}/edit?step=id`, {
              replace: true,
            });
          }
        },
        onError: (error: any) => {
          if (error?.response?.status === 400) {
            showNotification(
              error?.response?.data?.message,
              NotificationTypeEnum.Error
            );
          } else {
            showNotification(
              `${t('project.register.notifications.registeredFailure')}:
              ${error?.response?.data?.message || ''}
              `,
              NotificationTypeEnum.Error
            );
          }
        },
      }
    );
  };

  return { submitHandler, isLoading };
};
