import {
  useReservesSubmissionControllerUpdate,
  ProjectDTO,
  getProjectControllerGetQueryKey,
} from '@energyweb/origin-backend-react-query-client';
import { CreateReservesSubmissionDTO } from '@energyweb/origin-organization-irec-api-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useUpdateReservesSubmissionHandler = (
  id: number,
  project?: ProjectDTO
) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useReservesSubmissionControllerUpdate();

  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(project.id);

  const submitHandler = (values: CreateReservesSubmissionDTO) => {
    mutate(
      { id, data: { ...values } },
      {
        onSuccess: () => {
          showNotification(
            t(
              'project.register.notifications.reservesSubmissionUpdatedSuccess'
            ),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(projectQueryKey);
        },
        onError: (error: any) => {
          showNotification(
            `${t(
              'project.register.notifications.reservesSubmissionUpdatedError'
            )}:
              ${error?.response?.data?.message || ''}`,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return { submitHandler, isLoading };
};
