import { TMenuSection } from '@energyweb/origin-ui-core';
import { TFunction } from 'i18next';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';

export type TGetVerifierMenuArgs = {
  t: TFunction;
  isOpen: boolean;
  showRegisterVerifierMenu: boolean;
  showVerifierProjects: boolean;
  menuButtonClass?: string;
  selectedMenuItemClass?: string;
};

type TGetVerifierMenuFn = (args?: TGetVerifierMenuArgs) => TMenuSection;

export const getVerifierMenu: TGetVerifierMenuFn = ({
  t,
  isOpen,
  showRegisterVerifierMenu,
  showVerifierProjects,
  selectedMenuItemClass,
  menuButtonClass,
}) => ({
  isOpen,
  dataCy: 'verifierMenu',
  sectionTitle: t('user.projectVerifier.menuItem'),
  rootUrl: '/verifier',
  show: showRegisterVerifierMenu || showVerifierProjects,
  menuList: [
    {
      url: 'register',
      label: t('user.projectVerifier.registerMenuItem'),
      show: showRegisterVerifierMenu,
      dataCy: 'project-verifier',
      icon: PersonAddAlt1OutlinedIcon,
    },
    {
      url: 'project-list',
      label: t('project.listing.validationProjects'),
      show: showVerifierProjects,
      dataCy: 'validaton-project',
      icon: LibraryAddCheckOutlinedIcon,
    },
  ],
  menuButtonClass,
  selectedMenuItemClass,
});
