import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: 28,
    width: 44,
    height: 44,
    position: 'relative',
    '& svg': {
      padding: 10,
      width: 24,
      height: 24,
      fill: '#261F00 !important',
    },
    '&.with-badge:after': {
      content: '"•"',
      position: 'absolute',
      color: '#FF5756',
      top: 0,
      right: 10,
      fontSize: 26,
    },
  },
  menuDefault: {
    '& .MuiMenu-list': {
      display: 'flex',
      flexDirection: 'column',
      width: 250,
      background: '#ECF6EE',
      padding: '21px 24px',
    },
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 24,
  },
  notification: {
    position: 'relative',
    paddingLeft: 10,
    marginBottom: 20,
    '& .title': {
      fontSize: 17,
      fontWeight: 400,
      color: '#2B201F',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .datetime': {
      fontSize: 14,
      color: '#57625C',
    },
  },
  empty: {
    color: '#2B201F',
    textAlign: 'center',
    marginBottom: 20,
    fontSize: 16,
  },
  viewAllButton: {
    background: '#78CC8A',
    width: 211,
    color: '#FFFFFF',
    alignSelf: 'center',
  },
}));
