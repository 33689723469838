import React, { FC, ReactNode } from 'react';
import { Typography, Button } from '@mui/material';
import { useStyles } from './RegistrationFormsLayout.styles';

export interface RegistrationFormsLayoutProps {
  children: ReactNode;
  title: string;
  subTitle?: string;
  icon: any;
  descriptionTitle?: string;
  description?: string;
  onNextClick?: () => void;
  nextButtonText?: string;
  nextButtonDisabled?: boolean;
  error?: any;
}

export const RegistrationFormsLayout: FC<RegistrationFormsLayoutProps> = ({
  children,
  title,
  subTitle,
  description,
  descriptionTitle,
  icon,
  onNextClick,
  nextButtonText,
  nextButtonDisabled = false,
  error,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <Typography className={classes.title} variant="h4">
          {title}
        </Typography>
        <Typography
          className={classes.subTitle}
          variant="subtitle1"
          gutterBottom
        >
          {subTitle}
        </Typography>
      </div>
      <div className={classes.content}>
        <div className={classes.slotContainer}>{children}</div>
        <div className={classes.metaContent}>
          <div className={classes.iconContainer}>
            <img src={icon} className={classes.icon} />
          </div>
          <div className={classes.descriptionContainer}>
            <Typography
              className={classes.descriptionTitle}
              variant="subtitle1"
              gutterBottom
            >
              {descriptionTitle}
            </Typography>
            <Typography
              className={classes.description}
              variant="body2"
              gutterBottom
              dangerouslySetInnerHTML={{ __html: description }}
            ></Typography>
          </div>
          {error && (
            <div className={classes.errorMessageContainer}>{error}</div>
          )}
        </div>
      </div>
      <div className={classes.actions}>
        {onNextClick && (
          <Button
            onClick={onNextClick}
            variant="outlined"
            size="large"
            disabled={nextButtonDisabled}
            className={classes.submitAction}
          >
            {nextButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};
