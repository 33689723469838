import React, { FC, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import {
  GenericFormSecondaryButton,
  NotificationTypeEnum,
  showNotification,
  useCommonStyles,
} from '@energyweb/origin-ui-core';
import { UnpackNestedValue } from 'react-hook-form';
import { OverviewFormValues } from '@energyweb/origin-ui-organization-logic';
import {
  ProjectDTO,
  ProjectStatus,
  UploadDto,
} from '@energyweb/origin-backend-react-query-client';
import { useTranslation } from 'react-i18next';
import { ProjectInfo } from '../../containers/project-overview/project-info';
import { useStyles } from './Overview.styles';
import { UploadedFilesPage } from '../../containers/project-overview/uploaded-files';
import { ProjectRoyaltyOwners } from '../../containers';
import { Role } from '@energyweb/origin-backend-core';
import _ from 'lodash';

export interface OverviewProps {
  submitHandler?: (
    values: UnpackNestedValue<OverviewFormValues>
  ) => Promise<void>;
  secondaryButtons?: GenericFormSecondaryButton[];
  loading?: boolean;
  project?: ProjectDTO;
  navigateToStep?: () => void;
}

export const OverviewPage: FC<OverviewProps> = ({
  submitHandler,
  secondaryButtons,
  loading,
  project,
  navigateToStep,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [buttonDisabled, setFormDisabled] = useState(
    project.projectStatus === ProjectStatus.Pending
  );
  const commonClasses = useCommonStyles();

  const onSubmit = () => {
    if (project) {
      const projectFiles = Object.keys(project).reduce((result, key) => {
        return [
          ...result,
          ...(typeof _.get(project, key) === 'object'
            ? _.get(project, `${key}.uploads`, []).filter(
                (file: UploadDto) => file.status === 'Change request'
              )
            : []),
        ];
      }, []);

      if (projectFiles?.length > 0) {
        showNotification(
          `${t('project.register.overviewDetails.projectVerificationWarning')}`,
          NotificationTypeEnum.Warning
        );
        setFormDisabled(true);
        return;
      }
      if (!project?.createdBy?.blockchainAccountAddress) {
        showNotification(
          t('user.profile.notifications.noBlockchainConnection'),
          NotificationTypeEnum.Warning
        );
        return;
      }
      submitHandler(project?.id);
    }
  };

  return (
    <Box className={classes.form}>
      <Backdrop className={commonClasses.circularProgress} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <Box my={3}>
        <Typography color="textSecondary" variant="h5">
          {t('project.register.overviewDetails.checkInfo')}
        </Typography>
        <ProjectInfo project={project} />
      </Box>
      <Box my={3}>
        <Divider />
      </Box>
      <Box my={3}>
        <Typography variant="h6">
          {t('project.register.overviewDetails.uploadedFiles')}
        </Typography>
        <UploadedFilesPage project={project} navigateToStep={navigateToStep} />
      </Box>
      <Box my={3}>
        <ProjectRoyaltyOwners
          project={project}
          showName={true}
          role={Role.ProjectOwner}
          warningText={t('project.register.overviewDetails.checkOwnersStatus')}
        />
      </Box>

      <Box my={6} className={classes.footer}>
        <Typography variant="subtitle1">
          {t('project.register.overviewDetails.contract')}
        </Typography>
        <Typography color="textSecondary">
          {t('project.register.overviewDetails.legalInfo')}
        </Typography>
        <Typography>
          {t('project.register.overviewDetails.description')}
        </Typography>
        <Typography>
          {t('project.register.overviewDetails.additional')}
        </Typography>
      </Box>

      <div className={classes.verificationButton}>
        <Button
          color="primary"
          name="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
          disabled={buttonDisabled}
        >
          {t('project.register.overviewDetails.submitButtonText')}
        </Button>
      </div>
    </Box>
  );
};

export default OverviewPage;
