import React, { FC, MouseEventHandler } from 'react';
import { ButtonBase } from '@mui/material';
import { ZeroSixLogoWhite, ArrowLeft } from '@energyweb/origin-ui-assets';
import { useStyles } from './AuthBackButton.styles';

export interface AuthBackButtonProps {
  onClick?: MouseEventHandler;
}

const AuthBackButton: FC<AuthBackButtonProps> = ({ onClick }) => {
  const classes = useStyles();
  return (
    <ButtonBase onClick={onClick} className={classes.container}>
      <ArrowLeft className={classes.arrowIcon} />
      <ZeroSixLogoWhite className={classes.logoIcon} />
    </ButtonBase>
  );
};

export { AuthBackButton };
