import React, { FC } from 'react';
import { GenericModal } from '@energyweb/origin-ui-core';
import { useConfirmTokenOrderEffects } from './ConfirmTokenOrder.effects';
import { Box, Button, DialogActions, Typography } from '@mui/material';
import { useStyles } from './ConfirmTokenOrder.styles';
import { useTranslation } from 'react-i18next';

export const ConfirmTokenOrder: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { open, tokenToBuy, dialogProps, closeModal, onSubmit } =
    useConfirmTokenOrderEffects();

  return (
    <GenericModal
      open={open}
      closeButton={true}
      handleClose={closeModal}
      dialogProps={dialogProps}
      customContent={
        <Box>
          <Box>
            <Typography className={classes.title}> Please Confirm </Typography>
            <Typography className={classes.content}>
              Are you sure you want to buy
              <Typography component="span" color="textSecondary">
                {' '}
                {tokenToBuy?.amount}{' '}
              </Typography>
              carbon credits from
              <Typography component="span" color="textSecondary">
                {' '}
                {tokenToBuy?.wellName}{' '}
              </Typography>
              well?
            </Typography>
          </Box>
          <Box my={3}>
            <DialogActions>
              <Button size="large" variant="outlined" onClick={closeModal}>
                {t('general.buttons.edit')}
              </Button>
              <Button size="large" variant="outlined" onClick={onSubmit}>
                {t('general.buttons.confirm')}
              </Button>
            </DialogActions>
          </Box>
        </Box>
      }
    />
  );
};
