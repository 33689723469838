import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '200px',
  },
  logoIcon: {
    height: '22px',
  },
  arrowIcon: {
    height: '35px',
    width: '35px',
  },
}));
