import { IOrganizationModalsStore, TOrganizationModalsAction } from './types';

export enum OrganizationModalsActionsEnum {
  SHOW_RISK_FAIL_OPTION = 'SHOW_RISK_FAIL_OPTION',
  SHOW_CONFIRM_TOKEN_PURCHASE = 'SHOW_CONFIRM_TOKEN_PURCHASE',
  SHOW_DELETE_PROJECT_OPTION = 'SHOW_DELETE_PROJECT_OPTION',
  SHOW_PROJECT_VERIFIED_SUCCESSFUL = 'SHOW_PROJECT_VERIFIED_SUCCESSFUL',
}

export const orgModalsInitialState: IOrganizationModalsStore = {
  riskFailOption: false,
  confirmTokenPurchase: {
    open: false,
    tokenToBuy: null,
  },
  deleteProjectOption: {
    open: false,
    projectId: null,
    additionalAction: () => {},
  },
  projectVerifiedSuccessful: {
    open: false,
    text: '',
  },
};

export const orgModalsReducer = (
  state = orgModalsInitialState,
  action: TOrganizationModalsAction
): IOrganizationModalsStore => {
  switch (action.type) {
    case OrganizationModalsActionsEnum.SHOW_RISK_FAIL_OPTION:
      return { ...state, riskFailOption: action.payload };

    case OrganizationModalsActionsEnum.SHOW_CONFIRM_TOKEN_PURCHASE:
      return { ...state, confirmTokenPurchase: { ...action.payload } };

    case OrganizationModalsActionsEnum.SHOW_DELETE_PROJECT_OPTION:
      return { ...state, deleteProjectOption: action.payload };

    case OrganizationModalsActionsEnum.SHOW_PROJECT_VERIFIED_SUCCESSFUL:
      return { ...state, projectVerifiedSuccessful: action.payload };
  }
};
