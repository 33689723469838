import {
  Typography,
  TypographyVariant,
  ButtonProps,
  Grid,
  Box,
} from '@mui/material';
import React, { PropsWithChildren, ReactElement, FC } from 'react';
import { StepReport } from '../../components/form';
import { GenericFormProps, GenericFormSecondaryButton } from '../GenericForm';
import { useMultiStepFormEffects } from './MultiStepForm.effects';
import { SettingsIcon } from '../../components/icons/SettingsIcon';
import { useStyles } from './MultiStepForm.styles';
import { UnpackNestedValue } from 'react-hook-form';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

export type MultiStepFormItem<FormValuesType> = Omit<
  GenericFormProps<FormValuesType>,
  'submitHandler' | 'loading' | 'secondaryButtons'
> & {
  customStep?: boolean;
  component?: FC<{
    submitHandler: (values: UnpackNestedValue<FormValuesType>) => Promise<void>;
    secondaryButtons?: GenericFormSecondaryButton[];
    loading?: boolean;
    project?: ProjectDTO;
    navigateToStep?: (step: number) => void;
  }>;
};

export interface MultiStepFormProps<FormValuesMerged> {
  heading: string;
  headingVariant?: TypographyVariant;
  forms: MultiStepFormItem<any>[];
  backButtonText: string;
  nextButtonText?: string;
  backButtonProps?: ButtonProps;
  loading?: boolean;
  project?: ProjectDTO;
}

export type TMultiStepForm = <FormValuesMerged>(
  props: PropsWithChildren<MultiStepFormProps<FormValuesMerged>>
) => ReactElement;

export const MultiStepForm: TMultiStepForm = ({
  heading,
  headingVariant,
  forms,
  backButtonText,
  nextButtonText,
  backButtonProps,
  loading,
  project,
}) => {
  const {
    stepperLabels,
    activeStep,
    getCurrentForm,
    prevStep,
    navigateToStep,
    checkStepStatus,
  } = useMultiStepFormEffects({
    forms,
    backButtonText,
    nextButtonText,
    backButtonProps,
    loading,
    project,
  });
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3} className={classes.menu}>
        <Box pt={3} className={classes.multistepMenu}>
          <SettingsIcon step={activeStep} prevStep={prevStep} />
          <StepReport
            labels={stepperLabels}
            activeStep={activeStep}
            navigateToStep={navigateToStep}
            checkStepStatus={checkStepStatus}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={9} className={classes.content}>
        {activeStep === 0 && (
          <Box className={classes.header}>
            <Typography
              variant={headingVariant ?? 'h5'}
              className={classes.heading}
            >
              {heading}
            </Typography>
          </Box>
        )}
        {getCurrentForm(activeStep)}
      </Grid>
    </Grid>
  );
};
