import { GenericFormProps } from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface RetireTokenArgs {
  beneficiary: string;
}

export type TUseRetireTokenArgs = () => Omit<
  GenericFormProps<RetireTokenArgs>,
  'submitHandler'
>;

export const useRetireTokenFormLogic: TUseRetireTokenArgs = () => {
  const { t } = useTranslation();
  return {
    inputsVariant: 'outlined',
    initialValues: {
      beneficiary: '',
    },
    validationSchema: yup.object().shape({
      beneficiary: yup.string().required(),
    }),
    fields: [
      {
        name: 'beneficiary',
        label: t('token.transfer.beneficiary'),
        required: true,
        inputProps: { ['data-cy']: 'beneficiary' },
        placeholder: 'Name of the entity for which the retirement took place',
      },
    ],
    buttonText: t('general.buttons.save'),
  };
};
