import React, { FC } from 'react';

import { GenericForm } from '@energyweb/origin-ui-core';
import { useUpdateUserPasswordEffects } from './UpdateUserPassword.effects';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { SettingsIcon } from '@energyweb/origin-ui-assets';
import { useStyles } from './UpdateUserPassword.styles';
import { useCommonStyles } from '@energyweb/origin-ui-core';

export const UpdateUserPassword: FC = () => {
  const { t } = useTranslation();
  const { formProps } = useUpdateUserPasswordEffects();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <>
      <Typography variant="h5">
        {t('user.profile.changePasswordTitle')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ width: '50%' }}>
          <Typography variant="subtitle1" className={classes.description}>
            {t('user.profile.changePasswordDescription')}
          </Typography>
        </Box>
        <SettingsIcon />
      </Box>
      <GenericForm
        {...formProps}
        buttonProps={{
          className: `${commonClasses.button} ${commonClasses.secondaryButton}`,
          variant: 'contained',
          ['data-cy']: 'submitButton',
        }}
      />
    </>
  );
};
