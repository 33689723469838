import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@mui/material';
import { DriveFileRenameOutline } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import {
  ProjectDTO,
  ProjectStatus,
} from '@energyweb/origin-backend-react-query-client';
import { FormInput } from '@energyweb/origin-ui-core';
import { Role } from '@energyweb/origin-backend-core';
import { useStyles } from './ProjectDetails.styles';
import { StatusIndicator } from '../../../components';
import { useProjectDetailsEffect } from './ProjectDetails.effects';
import { useCommonStyles } from '@energyweb/origin-ui-core';

export interface ProjectDetailsProps {
  project: ProjectDTO;
  role?: Role;
  onEdit?: any;
  onChangeRequest?: any;
  isApproving?: boolean;
  verifierSigned?: boolean;
}

export const ProjectDetails: FC<ProjectDetailsProps> = ({
  project,
  role,
  onEdit,
  onChangeRequest,
  isApproving = false,
  verifierSigned = false,
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const { form, register, errors, dirtyFields } =
    useProjectDetailsEffect(project);

  return (
    <Box>
      <Typography variant="h6">
        {t('projectStatus.detailsOfThisProject.title')}
      </Typography>
      <Box className={classes.container}>
        <form className={classes.form}>
          <Typography variant="subtitle1">
            {t('project.register.carbonContent.nglComposition')}
          </Typography>
          {form?.fields.map((field) => (
            <FormInput
              key={field.name}
              field={field}
              disabled
              register={register}
              errorExists={!isEmpty((errors as any)[field.name])}
              errorText={(errors as any)[field.name]?.message ?? ''}
              isDirty={!!(dirtyFields as any)[field.name]}
              variant="outlined"
              {...field.inputProps}
            />
          ))}
        </form>
      </Box>
      {[Role.Admin, Role.ProjectOwner, Role.ProjectVerifier].indexOf(role) >
        -1 && (
        <Box display="flex" flexDirection="row">
          <Box className={classes.actionsContainer}>
            <>
              {role === Role.ProjectOwner ? (
                <Button
                  className={`${classes.action} ${commonClasses.button} ${commonClasses.primaryButton}`}
                  onClick={onEdit}
                  variant="contained"
                  disabled={
                    [
                      ProjectStatus.Pending,
                      ProjectStatus.Verified,
                      ProjectStatus.Rejected,
                      ProjectStatus.Issuance,
                      ProjectStatus.Execution,
                    ].includes(project?.projectStatus) ||
                    (project?.projectStatus === ProjectStatus.Change_request &&
                      verifierSigned)
                  }
                  startIcon={<DriveFileRenameOutline />}
                >
                  {t('projectStatus.edit')}
                </Button>
              ) : role === Role.ProjectVerifier ? (
                <>
                  {onChangeRequest && (
                    <Button
                      className={`${classes.actionSecondary} ${commonClasses.button} ${commonClasses.criticalButton}`}
                      variant="contained"
                      color="error"
                      onClick={onChangeRequest}
                      disabled={
                        ![ProjectStatus.Pending].includes(
                          project.projectStatus
                        ) || isApproving
                      }
                      startIcon={<DriveFileRenameOutline />}
                    >
                      {t('projectStatus.changeRequest')}
                    </Button>
                  )}
                </>
              ) : null}
            </>
          </Box>
          <Box>
            <Typography>{t('projectStatus.status')}:</Typography>
            <StatusIndicator status={project?.projectStatus} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
