import { makeStyles } from '@mui/styles';
import { LightenColor } from '@energyweb/origin-ui-theme';

export const useStyles = makeStyles((theme) => ({
  logo: {
    margin: '20px',
  },
  logobar: {
    backgroundColor: '#fff',
    position: 'fixed',
    width: 355,
    padding: '5px 0',
    textAlign: 'center',
    zIndex: 10,
  },
  toolbar: {
    marginTop: 9,
    position: 'absolute',
    width: '100%',
    padding: 0,
  },
  userMenu: {
    marginRight: 28,
  },
  button: {
    width: 'fit-content',
    color: LightenColor(theme.palette?.text.secondary, 5, theme.palette?.mode),
    '&:hover': {
      backgroundColor: LightenColor(
        theme.palette?.background.paper,
        10,
        theme.palette?.mode
      ),
    },
  },
}));
