import { Button, ListItem, Icon } from '@mui/material';
import clsx from 'clsx';
import React, { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { useStyles } from './MenuItem.styles';
import SvgIcon from '@mui/material/SvgIcon';
import { DefaultNavIcon } from '@energyweb/origin-ui-assets';

export interface MenuItemProps {
  label: string;
  url: string;
  selected: boolean;
  closeMobileNav?: () => void;
  selectedClass?: string;
  buttonClass?: string;
  dataCy?: string;
  showMenuIcon?: boolean;
  disabled?: boolean;
  menuIcon?: React.ElementType;
}

export const MenuItem: FC<MenuItemProps> = memo(
  ({
    label,
    url,
    dataCy,
    selected = false,
    closeMobileNav,
    selectedClass,
    buttonClass,
    menuIcon,
    showMenuIcon = true,
    disabled = false,
  }) => {
    const classes = useStyles();
    return (
      <ListItem
        className={`${classes.listItem} ${
          selected && (selectedClass || classes.selected)
        }`}
      >
        <Button
          data-cy={dataCy}
          onClick={closeMobileNav}
          className={clsx(classes.button, buttonClass)}
          component={NavLink}
          to={url}
          disabled={disabled}
        >
          {showMenuIcon && (
            <Icon className={classes.icon}>
              {menuIcon ? (
                <SvgIcon component={menuIcon} inheritViewBox />
              ) : (
                <img src={DefaultNavIcon} alt={DefaultNavIcon} />
              )}
            </Icon>
          )}
          {label}
        </Button>
      </ListItem>
    );
  }
);
