import { ProjectOwnerStatus } from '@energyweb/origin-backend-core';
import { useProjectOwnerControllerIsProjectOwner } from '@energyweb/origin-organization-irec-api-react-query-client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useProjectOwnerEffects = () => {
  const { t } = useTranslation();

  const [status, setStatus] = useState<string>('');
  const [titleText, setTitleTest] = useState<string>(
    t('project.permission.noOwnerStatusTitle')
  );
  const [statusDescription, setStatusDescription] = useState<string>(
    t('project.permission.noOwnerStatusDescription')
  );
  const [redirectUrl, setRedirectUrl] = useState<string>(
    '/account/project-owner/'
  );
  const [buttonText, setButtonText] = useState<string>(
    t('general.buttons.register')
  );

  const { data: projectOwnerData } = useProjectOwnerControllerIsProjectOwner();

  useEffect(() => {
    if (projectOwnerData) {
      const { status } = projectOwnerData;

      if (status === ProjectOwnerStatus.Pending) {
        setStatus(ProjectOwnerStatus.Pending);
        setTitleTest(t('project.permission.pendingStatusTitle'));
        setStatusDescription(t('project.permission.pendingStatusDescription'));
      } else {
        setStatus(ProjectOwnerStatus.Rejected);
        setRedirectUrl(`/account/project-owner/${projectOwnerData.id}`);
        setButtonText(t('general.buttons.update'));
        setTitleTest(t('project.permission.rejectedStatusTitle'));
        setStatusDescription(t('project.permission.rejectedStatusDescription'));
      }
    }
  }, [projectOwnerData, projectOwnerData?.status]);

  return {
    status,
    titleText,
    statusDescription,
    redirectUrl,
    buttonText,
  };
};
