import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { ReservesSubmissionForm } from '@energyweb/origin-ui-organization-view';
import React from 'react';
import { TReservesSubmissionForm } from './types';

const ReservesSubmissionFormWrapper = (project: ProjectDTO) => (props: any) =>
  <ReservesSubmissionForm {...props} project={project} />;

export const reservesSubmissionForm: TReservesSubmissionForm = (
  t,
  project?: ProjectDTO
) => ({
  formTitle: t('organization.register.docsFormTitle'),
  formTitleVariant: 'h5',
  initialValues: {
    uploads: project?.reservesSubmission?.uploads || [],
    projectId: project?.id,
  },
  fields: [],
  validationSchema: null,
  customStep: true,
  component: ReservesSubmissionFormWrapper(project),
  buttonText: t('general.buttons.submit'),
});
