import { useEffect, useReducer, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { initialState, reducer } from './FileUpload.reducer';
import {
  ApiUploadFunction,
  FileUploadOnChangeFunction,
} from './FileUpload.types';

const FILE_SUPPORTED_MIMETYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/zip',
];

export type TUseFileUploadEffectsArgs = {
  apiUploadFunction: ApiUploadFunction;
  onChange: FileUploadOnChangeFunction;
  dataCy?: string;
  uploadedFiles?: File[];
  mimeType?: string;
};

export const useFileUploadEffects = ({
  apiUploadFunction,
  onChange,
  dataCy = '',
  uploadedFiles,
  mimeType = '',
}: TUseFileUploadEffectsArgs) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [files, setFiles] = useState<File[]>([...uploadedFiles] || []);
  const [disabled, setInputDisabled] = useState<boolean>(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: FILE_SUPPORTED_MIMETYPES,
    onDrop: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    disabled: disabled,
  });

  const upload = async (file: File) => {
    const fileIndex = files.indexOf(file);

    try {
      if (file.size > 5242880) {
        showNotification(
          `${file.name} is not valid. Please, try to upload another document!`,
          NotificationTypeEnum.Error
        );
        throw new Error();
      }

      if (mimeType) {
        if (file.type && file.type !== mimeType) {
          const extractedFileType = mimeType.split('/')[1].toUpperCase();

          showNotification(
            `Invalid file type. Please, try to upload ${extractedFileType} document!`,
            NotificationTypeEnum.Error
          );
          throw new Error();
        }
      }

      await apiUploadFunction([file as File], dataCy);
      dispatch({
        type: 'setFile',
        payload: {
          id: fileIndex,
          file: {
            ...state[fileIndex],
            uploadedName: file.name,
            cancelToken: null,
            uploadProgress: 100,
            cancelled: false,
            dataCy,
          },
        },
      });
    } catch (error) {
      files.splice(fileIndex, 1);
      console.error(`Error while uploading file in FileUpload: ${error}`);
    }
  };

  useEffect(() => {
    files.forEach((f) => {
      const fileIndex = files.indexOf(f);
      const uploadedFile = state && state[fileIndex];

      if (typeof uploadedFile === 'undefined') {
        upload(f);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    onChange(Object.entries(state).map(([, value]) => value));
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // check input values in state by limit. Currently the limit is 1
    const inputValues = Object.values(state).filter(
      (value) => value.dataCy == dataCy && !value.removed
    );
    inputValues?.length >= 1 ? setInputDisabled(true) : setInputDisabled(false);
  }, [state]);

  return { getRootProps, getInputProps, files, state, dispatch };
};
