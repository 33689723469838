import React from 'react';
import { ProjectOwnerStatus } from '@energyweb/origin-backend-core';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useProjectOwnerEffects } from './NoProjectOwner.effects';
import { useStyles } from './NoProjectOwner.styles';

export const NoProjectOwner = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { status, titleText, statusDescription, redirectUrl, buttonText } =
    useProjectOwnerEffects();

  return (
    <Paper className={classes.paper}>
      <Typography textAlign="center" variant="h5" marginBottom={3}>
        {titleText}
      </Typography>
      {status === ProjectOwnerStatus.Pending ? (
        <Typography textAlign="center">{statusDescription}</Typography>
      ) : (
        <>
          <Typography textAlign="center">{statusDescription}</Typography>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="space-between"
            flexDirection="row"
          >
            <Button
              color="primary"
              variant="outlined"
              className={classes.button}
              component={NavLink}
              to={redirectUrl}
              data-cy="register"
            >
              {buttonText}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              className={classes.button}
              component={NavLink}
              to={'/'}
              data-cy="cancel"
            >
              {t('general.buttons.cancel')}
            </Button>
          </Box>
        </>
      )}
    </Paper>
  );
};
