import {
  getProjectControllerGetQueryKey,
  useProjectControllerVerifyProject,
  VerifyProjectDTO,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useWeb3 } from '@energyweb/origin-ui-web3';
import { signTypedMessage } from '@energyweb/utils-general';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useUser } from '../fetching';

export const useProjectVerifyHandler = (id: number) => {
  const { t } = useTranslation();
  const { mutate } = useProjectControllerVerifyProject();
  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(id);

  const { user } = useUser();
  const blockchainAddress = user?.blockchainAccountAddress;
  const { web3, account } = useWeb3();

  const submitHandler = (values: VerifyProjectDTO) => {
    if (blockchainAddress?.toLowerCase() !== account?.toLowerCase()) {
      showNotification(
        t('user.profile.notifications.wrongBlockchainAccount'),
        NotificationTypeEnum.Info
      );
      return new Promise((res, rej) => rej('Wrong Metamask connected'));
    }
    return new Promise((res, rej) =>
      signTypedMessage(account, values.verifierSignature, web3)
        .then((signedMessage) => {
          showNotification(
            t('projectStatus.notifications.projectVerificationSuccess'),
            NotificationTypeEnum.Success
          );
          mutate(
            { id: String(id), data: { verifierSignature: signedMessage } },
            {
              onSuccess: () => {
                queryClient.invalidateQueries(projectQueryKey);
                res(true);
              },
              onError: (error: any) => {
                const status = Number(error?.response?.status);
                if (status >= 400 && status < 500) {
                  showNotification(
                    `${t(
                      'projectStatus.notifications.projectVerificationError'
                    )}:
                  ${error?.response?.data?.message || ''}
                  `,
                    NotificationTypeEnum.Error
                  );
                }
                rej(error);
              },
            }
          );
        })
        .catch((error) => rej(error))
    );
  };

  return { submitHandler };
};
