import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  action: {
    padding: '10px 23px',
    borderRadius: '33px',
    '& .Mui-disabled': {
      backgroundColor: '#78CC8A4012',
    },
  },
  uploadsTable: {
    borderCollapse: 'collapse',
    borderBottom: 'none',
    '&& .MuiTableCell-root': {
      color: theme.form.fontColors.secondary,
    },
  },
  defaultHead: {
    '&& th': {
      padding: 0,
      top: '0 !important',
    },
  },
  uploadsTableBody: {
    '&& .MuiTableRow-root': {
      borderTop: `1px solid ${theme.palette.primary.light}`,
    },
    '&& .MuiTableRow-root:last-child': {
      borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
    '&& .MuiTableRow-root:nth-of-type(1n)': {
      backgroundColor: '#F8FDFE',
    },
    '&& .MuiTableRow-root:nth-of-type(2n)': {
      backgroundColor: '#F8FDFE30',
    },
  },
}));
