import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {},
  close: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  headerConteiner: {
    display: 'flex',
    marginTop: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    fontSize: 24,
    lineHeight: '30px',
  },
  subtitle: {
    fontSize: 20,
    lineHeight: '26px',
    marginBottom: 35,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 35,
    marginTop: 30,
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 50px',
  },
}));
