import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  map: {
    height: '340px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    marginBottom: '15px',
  },
  mapInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    boxShadow: 'none',
    margin: '10px 0',
  },
  status: {
    fontSize: '17px',
    color: '#757575',
    '&:before': {
      fill: 'black',
      marginRight: 7,
      verticalAlign: 'middle',
    },
    '&.changeRequest:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23F58025'}'/></svg>")`,
    },
    '&.pending:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23BBCECB'}'/></svg>")`,
    },
    '&.accepted:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%2389D8B2'}'/></svg>")`,
    },
    '& span': {
      marginLeft: '8px',
    },
  },
}));
