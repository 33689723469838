import {
  getProjectControllerGetQueryKey,
  useProjectControllerFinishExecution,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useExecuteProjectHandler = (id: number) => {
  const { t } = useTranslation();
  const { mutate } = useProjectControllerFinishExecution();
  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(id);

  const submitHandler = () => {
    return new Promise((res, rej) =>
      mutate(
        { id: String(id) },
        {
          onSuccess: () => {
            showNotification(
              t('projectStatus.notifications.projectExecutionSuccess'),
              NotificationTypeEnum.Success
            );
            queryClient.invalidateQueries(projectQueryKey);
            res(true);
          },
          onError: (error: any) => {
            const status = Number(error?.response?.status);
            if (status >= 400 && status < 500) {
              showNotification(
                `${t('projectStatus.notifications.projectExecutionError')}:
                  ${error?.response?.data?.message || ''}
                  `,
                NotificationTypeEnum.Error
              );
            }
            rej(error);
          },
        }
      )
    );
  };

  return { submitHandler };
};
