import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  banner: {
    display: 'none',
    '@media (max-width: 768px)': {
      display: 'initial',
    },
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      width: '90%',
    },
  },
  logoContainer: {
    margin: '50px',
  },
  primaryText: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
  },
  formSubTitle: {
    margin: '50px',
    textAlign: 'center',
  },
}));
