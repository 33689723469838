import React, { FC, useState } from 'react';
import { Box, Typography, Menu, Button } from '@mui/material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useNotificationMenuEffects } from './NotificationMenu.effects';
import { useStyles } from './NotificationMenu.styles';

export const NotificationMenu: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const { notifications, updateNotifications } = useNotificationMenuEffects();
  const navigate = useNavigate();
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    updateNotifications();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        onClick={handleClick}
        className={
          classes.container + (notifications?.length ? ' with-badge' : '')
        }
      >
        <NotificationsOutlinedIcon />
      </Box>
      <Menu
        className={classes.menuDefault}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Typography className={classes.title}>
          {t('notifications.menu.title')}
        </Typography>

        {notifications?.length ? (
          notifications.map((notification: any) => (
            <div
              className={classes.notification}
              key={notification.id + '_' + notification.title}
            >
              <Typography className="title">{notification.title}</Typography>
              <Typography className="datetime">
                {dayjs(notification?.createdAt).format('MM/DD/YYYY hh:mma')}
              </Typography>
            </div>
          ))
        ) : (
          <Typography className={classes.empty}>
            {t('notifications.menu.empty')}
          </Typography>
        )}

        <Button
          className={classes.viewAllButton}
          onClick={(_) => navigate('/account/notifications')}
        >
          {t('notifications.menu.viewAll')}
        </Button>
      </Menu>
    </>
  );
};
