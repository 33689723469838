import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import {
  ProjectDTO,
  ProjectStatus,
} from '@energyweb/origin-backend-react-query-client';
import {
  IconChangeRequested,
  IconPending,
  IconAccepted,
  IconRejected,
} from '@energyweb/origin-ui-assets';
import React from 'react';

export interface ProjectVerifierAccountProps {
  pendingProjects: ProjectDTO[];
  verifiedProjects: ProjectDTO[];
  rejectedProjects: ProjectDTO[];
  changeRequestedProjects: ProjectDTO[];
}

export type TProjectVerifierAccount = (
  props: PropsWithChildren<ProjectVerifierAccountProps>
) => {
  summaryData: SummaryFormData[];
};

export type SummaryFormData = {
  status: string;
  count: number;
  icon: ReactNode;
};

export const useProjectVerifierAccountEffects: TProjectVerifierAccount = ({
  pendingProjects,
  verifiedProjects,
  rejectedProjects,
  changeRequestedProjects,
}) => {
  const summaryData: SummaryFormData[] = [
    {
      status: ProjectStatus.Pending,
      count: pendingProjects?.length || 0,
      icon: <IconPending />,
    },
    {
      status: ProjectStatus.Change_request,
      count: changeRequestedProjects?.length || 0,
      icon: <IconChangeRequested />,
    },
    {
      status: ProjectStatus.Rejected,
      count: rejectedProjects?.length || 0,
      icon: <IconRejected />,
    },
    {
      status: ProjectStatus.Verified,
      count: verifiedProjects?.length || 0,
      icon: <IconAccepted />,
    },
  ];

  return {
    summaryData,
  };
};
