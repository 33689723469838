import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UnpackNestedValue } from 'react-hook-form';
import { isEmpty } from 'lodash';
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';

import {
  ButtonsGroup,
  FormSelect,
  GenericFormSecondaryButton,
  FileUpload,
  FormInput,
  useCommonStyles,
} from '@energyweb/origin-ui-core';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { RegisterCarbonFormValues } from '@energyweb/origin-ui-organization-logic';
import { handleFileUpload } from '@energyweb/origin-ui-utils';

import { useStyles } from './CarbonContent.styles';
import { useCarbonContentEffects } from './CarbonContent.effects';

export interface CarbonContentProps {
  submitHandler?: (
    values: UnpackNestedValue<RegisterCarbonFormValues>
  ) => Promise<void>;
  secondaryButtons?: GenericFormSecondaryButton[];
  loading?: boolean;
  project?: ProjectDTO;
}

export const CarbonContentForm: FC<CarbonContentProps> = ({
  submitHandler,
  secondaryButtons,
  loading,
  project,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const {
    errors,
    control,
    dirtyFields,
    selectField,
    firstColumn,
    secondColumn,
    gasShrinkageField,
    documents,
    onChange,
    uploadFunction,
    handleSubmit,
    register,
    getUploadedFiles,
  } = useCarbonContentEffects(project);

  const onSubmit = async (values: RegisterCarbonFormValues) => {
    const uploadedFiles = await handleFileUpload(values.uploads, documents);
    values.uploads = [...uploadedFiles];
    submitHandler(values);
  };

  return (
    <Box position={'relative'}>
      <Backdrop className={commonClasses.circularProgress} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Grid item xs={12} md={12}>
          {selectField && (
            <>
              <Typography variant="subtitle1">
                {t('project.register.carbonContent.projectType')}
              </Typography>
              <FormSelect
                key={selectField.label}
                field={selectField}
                control={control}
                errorExists={!isEmpty((errors as any)[selectField.name])}
                errorText={(errors as any)[selectField.name]?.message ?? ''}
                variant={'outlined'}
                disabled={false}
                register={register}
              />
            </>
          )}
        </Grid>
        <Grid container spacing={10} className={classes.block}>
          <Grid item xs={12} lg={5}>
            {firstColumn.map((field, index) => (
              <FormInput
                key={index}
                field={field}
                disabled={false}
                register={register}
                errorExists={!isEmpty((errors as any)[field.name])}
                errorText={(errors as any)[field.name]?.message ?? ''}
                isDirty={!!(dirtyFields as any)[field.name]}
                variant="outlined"
                {...field.inputProps}
              />
            ))}
            <Box className={classes.inputGroup}>
              <Box justifyContent={'space-between'} display={'flex'} mb={1}>
                <Typography variant="subtitle1">
                  {t('project.register.carbonContent.nglComposition')}
                </Typography>
                <Typography variant="subtitle1" marginRight={7}>
                  {' '}
                  {t('project.register.carbonContent.mole')}
                </Typography>
              </Box>
              {secondColumn.map((field, index) => (
                <FormInput
                  key={index}
                  field={field}
                  disabled={false}
                  register={register}
                  errorExists={!isEmpty((errors as any)[field.name])}
                  errorText={(errors as any)[field.name]?.message ?? ''}
                  isDirty={!!(dirtyFields as any)[field.name]}
                  variant="outlined"
                  {...field.inputProps}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} lg={7} mt={4}>
            <Box>
              <FileUpload
                dropzoneText={t('file.upload.dropOrClick')}
                heading={t('project.register.carbonContent.uploadSalesReceipt')}
                apiUploadFunction={uploadFunction}
                onChange={onChange}
                wrapperProps={{ ['data-cy']: 'oilSalesReceipt' }}
                uploadedFiles={getUploadedFiles('oilSalesReceipt')}
                required={!documents['oilSalesReceipt']}
                dropzoneClassName={classes.dropzone}
              />
              <FileUpload
                dropzoneText={t('file.upload.dropOrClick')}
                heading={t(
                  'project.register.carbonContent.uploadGasAnalysisReport'
                )}
                apiUploadFunction={uploadFunction}
                onChange={onChange}
                wrapperProps={{ ['data-cy']: 'gasAnalysisReport' }}
                uploadedFiles={getUploadedFiles('gasAnalysisReport')}
                required={!documents['gasAnalysisReport']}
                dropzoneClassName={classes.dropzone}
              />
            </Box>
            <Grid xs={12} lg={8}>
              {gasShrinkageField && (
                <FormInput
                  field={gasShrinkageField}
                  disabled={false}
                  register={register}
                  errorExists={
                    !isEmpty((errors as any)[gasShrinkageField.name])
                  }
                  errorText={
                    (errors as any)[gasShrinkageField.name]?.message ?? ''
                  }
                  isDirty={!!(dirtyFields as any)[gasShrinkageField.name]}
                  variant="outlined"
                  {...gasShrinkageField.inputProps}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <ButtonsGroup secondaryButtons={secondaryButtons} loading={loading} />
      </form>
    </Box>
  );
};
