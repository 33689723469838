import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { reservesSubmissionForm } from 'libs/organization/logic/src/register/reservesSubmissionForm';
import { useEffect, useState } from 'react';
import { Documents, UploadedFile } from '@energyweb/origin-ui-core';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

export const useReservesSubmissionEffects = (project: ProjectDTO) => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<Documents>({});

  const { initialValues, validationSchema, fields, validationMode } =
    reservesSubmissionForm(t, project);

  const { handleSubmit, watch } = useForm({
    mode: validationMode,
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const showProjectWells = !!project?.reservesSubmission?.wells?.length;
  const { uploads } = watch();

  const uploadFunction = async (file: File[], dataCy: string) => {
    setDocuments({
      ...documents,
      [dataCy]: file,
    });
    return documents;
  };

  const onChange = (files: UploadedFile[]) => {
    const file = files[files?.length - 1] || ({} as UploadedFile);
    if (file?.removed) {
      delete documents[file?.dataCy];
      setDocuments({ ...documents });
    }
  };

  useEffect(() => {
    if (uploads?.length) {
      const documents = Object.assign(
        {},
        ...uploads?.map((file: any) => ({
          [file.dataCy]: [{ name: file.name } as File],
        }))
      );
      setDocuments({ ...documents });
    }
  }, [uploads]);

  const getUploadedFiles = (dataCy: string) => {
    const uploadedFiles = uploads || [];
    const file = uploadedFiles?.find((file: any) => file.dataCy === dataCy);
    return file ? [{ name: file?.name } as File] : [];
  };

  return {
    fields,
    documents,
    showProjectWells,
    handleSubmit,
    uploadFunction,
    getUploadedFiles,
    onChange,
  };
};
