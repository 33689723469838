import React, { FC } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Skeleton,
  Button,
  IconButton,
} from '@mui/material';
import {
  CheckCircle,
  Download,
  DriveFileRenameOutline,
} from '@mui/icons-material';

import {
  ChangeRequestPopUp,
  ConfirmationPopUp,
  StatusIndicator,
} from '@energyweb/origin-ui-organization-view';
import {
  UploadDto,
  UploadStatus,
} from '@energyweb/origin-backend-react-query-client';
import { useCommonStyles } from '@energyweb/origin-ui-core';

import { useStyles } from './ProjectDocumentsPage.styles';
import { useProjectDocumentsPageEffects } from './ProjectDocumentsPage.effects';
import { LoadingButton } from '@mui/lab';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: number) => string;
}

const columns: Column[] = [
  {
    id: 'fileName',
    label: 'projectStatus.uploadedFilesInThisProject.name',
    align: 'left',
  },
  {
    id: 'status',
    label: 'projectStatus.wellsTable.status',
  },
  { id: 'actions', label: 'projectStatus.uploadedFilesInThisProject.actions' },
];

export const ProjectDocumentsPage: FC = () => {
  const { id } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();

  const {
    projectFiles,
    pageLoading,
    changeRequestDialog,
    declineProjectDialog,
    acceptButtonDisabled,
    declineButtonDisabled,
    buttonIsLoading,
    onFileAccept,
    onFileDownload,
    onChangeRequest,
    onProjectDecline,
    onProjectAccept,
    onChangeRequestClose,
    onChangeRequestSubmit,
    onDeclineProjectSubmit,
    onDeclineDialogClose,
  } = useProjectDocumentsPageEffects(Number(id));

  if (pageLoading) {
    return <Skeleton height={200} width="100%" />;
  }

  return (
    <Box style={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table className={classes.uploadsTable} stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{t(column.label)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.uploadsTableBody}>
            {projectFiles?.map((file: UploadDto, index: number) => (
              <TableRow key={file?.id || file?.ipfsHash}>
                <TableCell>
                  <IconButton onClick={onFileDownload(file)} color="primary">
                    <Download />
                  </IconButton>
                  {file.name}
                </TableCell>
                <TableCell>
                  <StatusIndicator status={file?.status || 'Pending'} />
                </TableCell>
                <TableCell>
                  <Button
                    className={`${classes.action} ${commonClasses.circleButton} ${commonClasses.secondaryButton}`}
                    variant="contained"
                    onClick={() => onFileAccept(file)}
                    sx={{ marginRight: '10px' }}
                    startIcon={<CheckCircle />}
                    disabled={file?.status !== UploadStatus.Pending}
                  >
                    {t('projectStatus.accept')}
                  </Button>
                  <Button
                    variant="contained"
                    className={`${classes.action} ${commonClasses.circleButton} ${commonClasses.criticalButton}`}
                    onClick={() => onChangeRequest(file)}
                    color="error"
                    startIcon={<DriveFileRenameOutline />}
                    disabled={file?.status !== UploadStatus.Pending}
                  >
                    {t('projectStatus.changeRequest')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2} display="flex" justifyContent="flex-end">
        <LoadingButton
          className={`${classes.projectAction} ${commonClasses.secondaryButton}`}
          variant="contained"
          loading={buttonIsLoading}
          loadingPosition="start"
          startIcon={<CheckCircle />}
          disabled={acceptButtonDisabled}
          onClick={onProjectAccept}
        >
          {t('general.buttons.accept')}
        </LoadingButton>

        <Button
          className={`${classes.projectAction} ${commonClasses.criticalButton}`}
          variant="contained"
          disabled={declineButtonDisabled}
          onClick={onDeclineProjectSubmit}
        >
          {t('general.buttons.decline')}
        </Button>
      </Box>

      <ChangeRequestPopUp
        isOpen={changeRequestDialog}
        onClose={onChangeRequestClose}
        onSubmit={onChangeRequestSubmit}
      />
      <ConfirmationPopUp
        isOpen={declineProjectDialog}
        onClose={onDeclineDialogClose}
        onSubmit={onProjectDecline}
        message={`${t(
          'projectStatus.changeRequestPopUp.declineConfirmationTitle'
        )}`}
      />
    </Box>
  );
};
