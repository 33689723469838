import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { royaltyOwnerForm } from 'libs/organization/logic/src/register/royaltyOwner';
import { useRoyaltyOwnerRegisterHandler } from '@energyweb/origin-ui-organization-data';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { Documents, UploadedFile } from '@energyweb/origin-ui-core';

export const useRoyaltyOwnerEffects = (project: ProjectDTO) => {
  const { t } = useTranslation();

  const {
    initialValues,
    validationSchema,
    fields,
    validationMode,
    formInputsProps,
  } = royaltyOwnerForm(t, project);

  const { submitHandler,isLoading } = useRoyaltyOwnerRegisterHandler(project);
  const { register, handleSubmit, formState, reset, watch } = useForm({
    mode: validationMode,
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const { isValid, errors, dirtyFields } = formState;
  const { uploads } = watch();
  const [documents, setDocuments] = useState<Documents>({});

  const uploadFunction = async (file: File[], dataCy: string) => {
      setDocuments({
        ...documents,
        [dataCy]: file,
      });
    return documents;
  };

  const onChange = (files: UploadedFile[]) => {
    const file = files[files?.length - 1] || ({} as UploadedFile);
    if (file?.removed) {
      delete documents[file?.dataCy];
      setDocuments({ ...documents });
    }
  };

  useEffect(() => {
    if (uploads?.length) {
      const documents = Object.assign(
        {},
        ...uploads?.map((file: any) => ({
          [file.dataCy]: [{ name: file.name } as File],
        }))
      );
      setDocuments({ ...documents });
    }
  }, [uploads]);

  const getUploadedFiles = (dataCy: string) => {
    const uploadedFiles = uploads || [];
    const file = uploadedFiles?.find((file: any) => file.dataCy === dataCy);
    return file ? [{ name: file?.name } as File] : [];
  };

  return {
    errors,
    isValid,
    formInputsProps,
    dirtyFields,
    fields,
    documents,
    reset,
    handleSubmit,
    submitHandler,
    register,
    uploadFunction,
    getUploadedFiles,
    onChange,
    isLoading
  };
};
