import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from './RegisterErrorMessage.styles';

interface IRegisterErrorMessageProps {
  specUrl: string;
}

export const RegisterErrorMessage: React.FC<IRegisterErrorMessageProps> = ({
  specUrl,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title} variant="body2" gutterBottom>
        {t('common.registration.errorTitle')}
      </Typography>
      <Link variant="body2" gutterBottom target="_blank" href={specUrl}>
        {t('common.registration.licenseInfoSpecLabel')}
      </Link>
    </Box>
  );
};
