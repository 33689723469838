import React, { FC } from 'react';
import { ConfirmTokenOrder } from './ConfirmTokenOrder';
import { RiskFailOption } from './RiskFailOption';
import { DeleteProject } from './DeleteProject';
import { ProjectVerifiedSuccessful } from './projectVerifiedSuccessful';

export const OrganizationModalsCenter: FC = () => {
  return (
    <>
      <RiskFailOption />
      <ConfirmTokenOrder />
      <DeleteProject />
      <ProjectVerifiedSuccessful />
    </>
  );
};
