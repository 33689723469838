import React from 'react';
import { GenericForm } from '@energyweb/origin-ui-core';
import { CircularProgress, Paper } from '@mui/material';
import { useStyles } from './VRCompanyFormPage.styles';
import { useVRCompanyFormPageEffects } from './VRCompanyFormPage.effects';

export const VRCompanyFormPage = () => {
  const { formProps, isLoading } = useVRCompanyFormPageEffects();
  const classes = useStyles();

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Paper className={classes.paper}>
      <GenericForm {...formProps} />
    </Paper>
  );
};

export default VRCompanyFormPage;
