import { ProjectWell } from '@energyweb/exchange-irec-react-query-client';
import {
  getProjectControllerGetQueryKey,
  ProjectDTO,
  projectWellControllerUpdate,
  ProjectWellDTO,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
  TableActionData,
} from '@energyweb/origin-ui-core';
import { paginate } from '@energyweb/origin-ui-utils';
import { Edit } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useWellsListingEffects = (project: ProjectDTO) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(project?.id);
  const [page, setPage] = useState(1);

  const actions: TableActionData<ProjectWellDTO['id']>[] = [
    {
      icon: <Edit data-cy="editIcon" />,
      name: t('general.buttons.edit'),
      onClick: async (id, data) => {
        try {
          data.reservesSubmissionId = project?.reservesSubmission?.id;
          await projectWellControllerUpdate(id, data);
        } catch (error: any) {
          showNotification(
            `${error?.response?.data?.message || ''}`,
            NotificationTypeEnum.Error
          );
        }
        queryClient.invalidateQueries(projectQueryKey);
      },
    },
  ];

  const wells = (project?.reservesSubmission?.wells || [])?.map(
    (well: ProjectWell) => ({
      id: well?.id,
      wellName: well?.wellName,
      wellAPINumber: well?.wellAPINumber,
      qualifiedCredit: well?.qualifiedCredit,
      holeLatitude: well?.holeLatitude,
      holeLongitude: well?.holeLongitude,
      actions,
    })
  );

  const paginatedWells = wells?.length ? paginate(wells, 10, page) : wells;

  const header = {
    wellName: t('project.register.reservesSubmission.wellName'),
    wellAPINumber: t('project.register.reservesSubmission.wellAPINumber'),
    qualifiedCredit: t('project.register.reservesSubmission.qualifiedCredit'),
    holeLatitude: t('project.register.reservesSubmission.holeLatitude'),
    holeLongitude: t('project.register.reservesSubmission.holeLongitude'),
    actions: 'Actions',
  };
  const tableData = {
    header,
    data: paginatedWells,
    editable: true,
  };

  return {
    tableData,
    pageCount: wells?.length > 0 ? Math.ceil(wells?.length / 10) : 0,
    setPage,
  };
};
