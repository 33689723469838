import { useEffect, useState, useCallback } from 'react';
import {
  useFollowingProjectList,
  useFollowProjectActions,
} from '@energyweb/origin-ui-organization-data';
import {
  ProjectDTO,
  ProjectStatus,
  UploadDto,
  UploadStatus,
  RoyaltyOwner,
} from '@energyweb/origin-backend-react-query-client';
import _ from 'lodash';

const extractUploads = (project: ProjectDTO): UploadDto[] =>
  project
    ? Object.keys(project).reduce((result, key) => {
        return [
          ...result,
          ...(typeof _.get(project, key) === 'object'
            ? _.get(project, `${key}.uploads`, []).map((e: any) => ({
                ...e,
                step: key,
              }))
            : []),
        ];
      }, [])
    : [];

export const useProjectStatusLayoutEffects = (
  project: ProjectDTO = <ProjectDTO>{}
) => {
  const { projects, isLoading } = useFollowingProjectList();
  const [isFollowed, setIsFollowed] = useState<boolean>(false);
  const { followProject, unfollowProject } = useFollowProjectActions();
  const verifierSignature =
    project?.reservesValidation?.verifierSignature || '';

  const [verifierActionsDisabled, setVerifierActionsDisabled] = useState(false);

  const projectFiles = _.get(project, `uploads`, []);

  const uploads = extractUploads(project).concat(projectFiles);

  const agreements =
    project?.ownership?.royaltyOwners?.flatMap(
      (royaltyOwner: RoyaltyOwner) => royaltyOwner.uploads
    ) || [];
  const isReadyForAcceptance = [...uploads, ...agreements].every(
    (upload: any) => upload.status === UploadStatus.Accepted
  );

  const onFollowButtonClick = useCallback(() => {
    setIsFollowed(!isFollowed);
    isFollowed ? unfollowProject(project?.id) : followProject(project?.id);
  }, [isFollowed, project]);

  useEffect(() => {
    const isProjectFollowed = projects?.find(
      (followingProject: ProjectDTO) => followingProject.id === project?.id
    );
    if (isProjectFollowed) {
      setIsFollowed(true);
    }
    setVerifierActionsDisabled(
      project?.projectStatus !== ProjectStatus.Pending
    );
  }, [isLoading, project]);

  return {
    onFollowButtonClick,
    isFollowed,
    uploads,
    isReadyForAcceptance,
    verifierActionsDisabled,
    verifierSigned:
      verifierSignature !== null && verifierSignature.length !== 0,
  };
};
