import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  useApiRegisterProjectVerifierData,
  useApiUpdateProjectVerifierData,
  useVerifier,
} from '@energyweb/origin-ui-user-data';
import { useProjectVerifierFormConfig } from '@energyweb/origin-ui-user-logic';
import _ from 'lodash';
import { ProjectVerifierStatus } from '@energyweb/origin-backend-react-query-client';

export const FORM_SUBMIT_STATE = {
  default: 'primaryButton',
  error: 'errorButton',
  pending: 'pendingButton',
};

export const useProjectVerifierPageEffects = () => {
  const { projectVerifier, isLoading: isVerifierLoading } = useVerifier();

  const navigate = useNavigate();

  const [nextDisabled, setNextDisable] = useState(true);
  const [showErrorMessage, setErrorMessageVisibility] = useState(false);
  const [formSubmitButtonText, setFormSubmitButtonText] = useState<string>(
    'user.projectVerifier.formPendingSubmit'
  );
  const [formSubmitState, setFormSubmitState] = useState(
    FORM_SUBMIT_STATE.default
  );

  useEffect(() => {
    projectVerifier?.status === ProjectVerifierStatus.Pending &&
      onProjectVerifierCreating();
  }, [projectVerifier]);

  const onStatusReset = useCallback(() => {
    setFormSubmitState(FORM_SUBMIT_STATE.default);
    setFormSubmitButtonText('user.projectVerifier.formDefaultSubmit');
  }, []);

  const onNextClick = useCallback(() => {
    navigate('/project/project-list');
  }, []);

  const onProjectVerifierCreateSucess = useCallback(() => {
    setNextDisable(false);
  }, []);

  const onProjectVerifierCreateError = useCallback(() => {
    setNextDisable(true);
    setFormSubmitState(FORM_SUBMIT_STATE.error);
    setFormSubmitButtonText('user.projectVerifier.formErrorSubmit');
    setErrorMessageVisibility(true);
  }, []);

  const onProjectVerifierCreating = useCallback(() => {
    setFormSubmitState(FORM_SUBMIT_STATE.pending);
    setFormSubmitButtonText('user.projectVerifier.formPendingSubmit');
    setErrorMessageVisibility(false);
  }, []);

  const { submitHandler: handleUpdate, isLoading: isUpdated } =
    useApiUpdateProjectVerifierData(projectVerifier?.id);
  const { submitHandler: handleCreate, isLoading: isCreated } =
    useApiRegisterProjectVerifierData(
      onProjectVerifierCreateSucess,
      onProjectVerifierCreateError,
      onProjectVerifierCreating
    );
  const isLoading = isUpdated || isCreated;

  const formLogic = useProjectVerifierFormConfig(projectVerifier, isLoading);

  const formProps = {
    ...formLogic,
    submitHandler: projectVerifier ? handleUpdate : handleCreate,
  };

  useEffect(() => {
    if (projectVerifier?.status === ProjectVerifierStatus.Accepted) {
      navigate('/', { replace: true });
    }
  }, [projectVerifier]);

  return {
    formProps,
    nextDisabled,
    onNextClick,
    formSubmitButtonText,
    buttonColor: formSubmitState,
    onStatusReset,
    showErrorMessage,
    isVerifierLoading,
    formDisabled:
      isVerifierLoading ||
      formSubmitState === FORM_SUBMIT_STATE.pending ||
      projectVerifier?.status === 'Pending',
  };
};
