import React from 'react';

export const Logo = () => {
  return (
    <svg
      width="175"
      height="25"
      viewBox="0 0 175 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.5119 14.4607H44.1091V10.3014H32.5079V5.83795H47.1506V1.77344H28.2441V22.2027H47.2071V18.0947H32.5119V14.4607Z"
        fill="#2B201F"
      />
      <path
        d="M0.601035 5.93671H13.2551C13.1874 6.0149 13.1146 6.08878 13.0373 6.1579C8.69422 10.5397 4.34845 14.9189 0 19.2955V22.2145H20.3224V18.0829H7.16809C7.53517 17.6879 7.7651 17.4509 8.00713 17.2099C11.9737 13.2204 15.9403 9.22967 19.9069 5.23757C20.1489 4.99662 20.5241 4.72802 20.5281 4.44757C20.5281 3.42848 20.5523 2.72933 20.5604 1.77344H0.601035V5.93671Z"
        fill="#2B201F"
      />
      <path
        d="M145.964 1.77344H141.729V22.2145H145.964V1.77344Z"
        fill="#2B201F"
      />
      <path
        d="M132.895 12.1819C132.253 11.686 131.528 11.3025 130.753 11.0483C129.813 10.737 128.852 10.4941 127.876 10.3215L123.439 9.46037C122.925 9.36657 122.417 9.24258 121.919 9.08907C121.578 8.9892 121.256 8.83865 120.963 8.64272C120.761 8.51261 120.598 8.33275 120.491 8.12133C120.375 7.87369 120.32 7.60332 120.329 7.33133C120.315 6.961 120.406 6.59408 120.591 6.27079C120.776 5.9475 121.048 5.68033 121.378 5.49855C122.157 5.01271 123.395 4.76385 125.037 4.76385C126.678 4.76385 127.889 5.0285 128.667 5.55385C129.446 6.07919 129.797 6.82179 129.877 7.92383V7.97518H133.923V7.86853C133.923 6.86364 133.714 5.86929 133.31 4.94556C132.921 4.08823 132.328 3.33498 131.58 2.74937C130.766 2.1366 129.836 1.68816 128.845 1.43008C127.667 1.12284 126.453 0.975317 125.234 0.991639C123.936 0.974298 122.64 1.11763 121.378 1.41823C120.34 1.66392 119.36 2.10095 118.49 2.70592C117.722 3.25186 117.101 3.97322 116.683 4.80731C116.269 5.67207 116.061 6.6174 116.074 7.57229C116.074 9.23522 116.63 10.5585 117.723 11.5025C118.817 12.4465 120.353 13.043 122.439 13.4024L127.469 14.3109C127.962 14.3881 128.448 14.5044 128.921 14.6585C129.25 14.7605 129.558 14.9168 129.833 15.1207C130.033 15.2695 130.193 15.465 130.297 15.6895C130.405 15.9542 130.457 16.2378 130.45 16.5229C130.46 16.9229 130.359 17.3181 130.157 17.666C129.955 18.014 129.66 18.3015 129.304 18.4979C128.453 19.0272 127.15 19.2879 125.428 19.2879C123.468 19.2879 121.999 18.9521 121.067 18.2806C120.136 17.6091 119.716 16.6454 119.668 15.2392V15.1799H115.416V15.2708C115.464 17.8422 116.34 19.8132 118.018 21.1365C119.696 22.4597 122.096 23.0838 125.259 23.0838C126.585 23.1008 127.908 22.9508 129.196 22.6375C130.257 22.376 131.259 21.9247 132.152 21.3063C132.947 20.7416 133.589 19.996 134.024 19.1338C134.452 18.2409 134.667 17.2644 134.653 16.278C134.673 15.4677 134.527 14.6617 134.222 13.908C133.928 13.2318 133.471 12.6354 132.891 12.17"
        fill="#2B201F"
      />
      <path
        d="M165.926 11.7747L174.135 1.77734H169.137L163.518 8.68189L158.326 1.77734H153.224L160.993 11.7866L152.449 22.2184H157.677L163.365 14.7925L169.089 22.2184H174.26L165.926 11.7747Z"
        fill="#2B201F"
      />
      <path
        d="M104.928 4.28967L108.381 0H103.383L101.653 2.12508C99.9165 1.34109 98.0266 0.936725 96.1145 0.940094C89.3054 0.940094 83.7871 5.88545 83.7871 12C83.7975 13.4567 84.1158 14.8955 84.7218 16.2259C85.3279 17.5562 86.2086 18.7493 87.3086 19.7301L83.8274 24H89.0714L90.7333 21.9342C92.4238 22.6746 94.2545 23.0582 96.1064 23.0599C102.911 23.0599 108.43 18.1106 108.43 12C108.419 10.5481 108.102 9.11408 107.5 7.78743C106.897 6.46078 106.022 5.27006 104.928 4.28967ZM88.067 12C88.067 7.71824 91.6651 5.22975 96.1064 5.22975C97.0274 5.22518 97.9448 5.34346 98.8332 5.58131L89.9548 16.4635C89.3508 15.8809 88.8719 15.1858 88.5463 14.4193C88.2208 13.6528 88.055 12.8302 88.0589 12M96.0983 18.7703C95.2265 18.7728 94.3583 18.6612 93.5167 18.4384L102.318 7.51679C102.909 8.10893 103.374 8.81023 103.686 9.57989C103.997 10.3495 104.15 11.1722 104.134 12C104.134 16.0843 100.536 18.7703 96.0983 18.7703Z"
        fill="#2B201F"
      />
      <path
        d="M70.1891 1.77344H55.9941V22.2106H60.2054V15.385H65.4494L70.7135 22.2145H75.8849L70.4271 15.3732C72.1085 15.303 73.6975 14.6008 74.8635 13.4127C76.0296 12.2245 76.6829 10.6419 76.6876 8.99399V8.16054C76.6823 6.47226 75.9968 4.85426 74.7799 3.65824C73.563 2.46223 71.9132 1.78488 70.1891 1.77344ZM72.3755 8.83993C72.3723 9.5384 72.0872 10.2073 71.5825 10.7008C71.0777 11.1944 70.3942 11.4725 69.6809 11.4746H66.1553C64.9452 11.4746 63.735 11.4746 62.5249 11.4746H60.2377V5.86561H69.6688C70.3828 5.86769 71.0669 6.14635 71.5718 6.64073C72.0767 7.13511 72.3612 7.80503 72.3634 8.50418L72.3755 8.83993Z"
        fill="#2B201F"
      />
    </svg>
  );
};

export default Logo;
