import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: '30px',
    background: 'rgba(15, 184, 195, 0.04)',
    borderRadius: 0,
    boxShadow: 'initial',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&& .MuiTypography-root': {
      textAlign: 'center',
      marginBottom: '15px',
    },
  },
  formContainer: {
    width: '100%',
    padding: '40px',
  },
  formTitle: {
    fontFamily: theme.typography.fontFamily,
    color: theme?.form?.fontColors?.primary,
  },
  primaryButton: {
    backgroundColor: theme.palette.primary.main,
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
    },
  },
  errorButton: {
    backgroundColor: '#FF584B !important',
  },
  pendingButton: {
    backgroundColor: '#AAB0F380 !important',
  },
}));
