import {
  useProjectControllerGet,
  ProjectDTO,
} from '@energyweb/origin-backend-react-query-client';

export const useProjectData = (projectId?: ProjectDTO['id']) => {
  if (projectId === undefined) {
    return {
      isLoading: false,
      project: <ProjectDTO>{},
      isProjectCreated: false,
    };
  }
  const { data, isLoading, isSuccess } = useProjectControllerGet(projectId);

  return {
    project: data,
    isLoading,
    isProjectCreated: isSuccess,
  };
};
