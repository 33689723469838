import { GenericFormProps } from '@energyweb/origin-ui-core';
import { RequestChangePhoneNumberFormValues } from '@energyweb/origin-ui-user-data';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

export const useChangePhoneNumberFormLogic = (
  isMutating: boolean
): Omit<
  GenericFormProps<RequestChangePhoneNumberFormValues>,
  'submitHandler'
> => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return {
    formTitle: t('user.requestChangePhone.title'),
    formTitleVariant: 'h6',
    loading: isMutating,
    initialValues: { verificationCode: '', phoneNumber: '' },
    validationSchema: yup.object().shape({
      verificationCode: yup
        .string()
        .label(t('user.requestChangePhone.code'))
        .required(),
      phoneNumber: yup
        .string()
        .label(t('user.requestChangePhone.phone'))
        .required(),
    }),
    fields: [
      {
        name: 'verificationCode',
        label: t('user.requestChangePhone.code'),
        required: true,
      },
      {
        name: 'phoneNumber',
        label: t('user.requestChangePhone.phone'),
        required: true,
      },
    ],
    inputsVariant: 'filled',
    validationMode: 'onSubmit',
    buttonWrapperProps: { display: 'flex', justifyContent: 'space-between' },
    buttonText: t('general.buttons.submit'),
  };
};
