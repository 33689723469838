import { TMenuSection, TModuleMenuItem } from '@energyweb/origin-ui-core';
import { DefaultNavIcon } from '@energyweb/origin-ui-assets';
import { TFunction } from 'i18next';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';

export type TGetOrganizationMenuArgs = {
  t: TFunction;
  isOpen: boolean;
  showSection: boolean;
  showRegisterOrg: boolean;
  showProjectListing: boolean;
  showAllProjectListing: boolean;
  menuButtonClass?: string;
  selectedMenuItemClass?: string;
  showNotPermittedCreateProjectPage: boolean;
  showVerifierDashboardPage?: boolean;
};

type TGetOrganizationMenu = (args?: TGetOrganizationMenuArgs) => TMenuSection;

export const getOrganizationMenu: TGetOrganizationMenu = ({
  t,
  isOpen,
  showSection,
  showRegisterOrg,
  showProjectListing,
  showAllProjectListing,
  menuButtonClass,
  selectedMenuItemClass,
  showNotPermittedCreateProjectPage,
}) => {
  const menuList: TModuleMenuItem[] = [
    {
      url: 'all-project-list',
      label: t('navigation.organization.allProjectListing'),
      show: showAllProjectListing,
      dataCy: 'allProjectListing',
      icon: TableChartOutlinedIcon,
    },
    {
      url: 'project-list',
      label: t('navigation.organization.projectListing'),
      show: showProjectListing,
      dataCy: 'projectListing',
    },
    {
      url: 'following-project-list',
      label: t('navigation.organization.followingProjectListing'),
      show: true,
      dataCy: 'followingProjectListing',
      icon: BookmarkAddOutlinedIcon,
    },
    {
      url: 'register',
      label: t('navigation.organization.register'),
      show: showRegisterOrg,
      dataCy: 'organizationRegister',
      icon: InventoryOutlinedIcon,
    },
  ];

  return {
    isOpen,
    dataCy: 'organizationMenu',
    sectionTitle: t('navigation.organization.sectionTitle'),
    show: showSection,
    rootUrl: '/project',
    menuList,
    selectedMenuItemClass,
    menuButtonClass,
    sectionIcon: DefaultNavIcon,
  };
};
