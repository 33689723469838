import {
  ProjectDTO,
  CreateCarbonContentDTO,
  getProjectControllerGetQueryKey,
  useCarbonContentControllerCreate,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { __values } from 'tslib';

export const useRegisterCarbonContentHandler = (project?: ProjectDTO) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useCarbonContentControllerCreate();
  const projectId = project?.id;

  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(projectId);

  const submitHandler = (values: CreateCarbonContentDTO) => {
    mutate(
      { data: { ...values, projectId } },
      {
        onSuccess: () => {
          showNotification(
            t('project.register.notifications.carbonContentRegisteredSuccess'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(projectQueryKey);
        },
        onError: (error: any) => {
          if (error?.response?.status === 400) {
            showNotification(
              error?.response?.data?.message,
              NotificationTypeEnum.Error
            );
          } else {
            showNotification(
              `${t(
                'project.register.notifications.carbonContentRegisteredFailure'
              )}:
                ${error?.response?.data?.message || ''}
                `,
              NotificationTypeEnum.Error
            );
          }
        },
      }
    );
  };
  return { submitHandler, isLoading };
};
