import {
  CreateOwnershipDTO,
  getProjectControllerGetQueryKey,
  ProjectDTO,
  useOwnershipControllerUpdate,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useOwnershipUpdateHandler = (id: number, project: ProjectDTO) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useOwnershipControllerUpdate();

  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(project?.id);

  const submitHandler = (values: CreateOwnershipDTO) => {
    mutate(
      { id, data: { ...values } },
      {
        onSuccess: () => {
          showNotification(
            t('project.register.notifications.ownershipUpdatedSuccess'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(projectQueryKey);
        },
        onError: (error: any) => {
          if (error?.response?.status === 400) {
            showNotification(
              error?.response?.data?.message,
              NotificationTypeEnum.Error
            );
          } else {
            showNotification(
              `${t('project.register.notifications.ownershipUpdatedError')}:
                  ${error?.response?.data?.message || ''}
                  `,
              NotificationTypeEnum.Error
            );
          }
        },
      }
    );
  };

  return { submitHandler, isLoading };
};
