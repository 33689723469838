import {
  UploadDto,
  useProjectControllerStartExecution,
} from '@energyweb/origin-backend-react-query-client';
import {
  ProjectDTO,
  getProjectControllerGetQueryKey,
} from '@energyweb/origin-organization-irec-api-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useProjectExecutionHandler = (project?: ProjectDTO) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useProjectControllerStartExecution();

  const projectId = Number(project?.id);
  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(projectId);

  const submitHandler = (values: UploadDto[]) => {
    return new Promise((res, rej) =>
      mutate(
        { id: projectId, data: [...values] },
        {
          onSuccess: () => {
            showNotification(
              t('project.register.notifications.executionSuccess'),
              NotificationTypeEnum.Success
            );
            queryClient.invalidateQueries(projectQueryKey);
            res(true);
          },
          onError: (error: any) => {
            showNotification(
              `${t('project.register.notifications.executionFailure')}:
                ${error?.response?.data?.message || ''}`,
              NotificationTypeEnum.Error
            );
            rej(error);
          },
        }
      )
    );
  };

  return { submitHandler, isLoading };
};
