import { GenericModalProps } from '@energyweb/origin-ui-core';
import { useRiskFailOptionLogic } from '@energyweb/origin-ui-organization-logic';
import { useStyles } from './RiskFailOption.styles';

import {
  OrganizationModalsActionsEnum,
  useOrgModalsDispatch,
  useOrgModalsStore,
} from '../../../context';

export const useRiskFailOptionEffects = () => {
  const classes = useStyles();
  const { riskFailOption: open } = useOrgModalsStore();
  const dispatchModals = useOrgModalsDispatch();

  const closeModal = () => {
    dispatchModals({
      type: OrganizationModalsActionsEnum.SHOW_RISK_FAIL_OPTION,
      payload: false,
    });
  };

  const { title, text, buttons } = useRiskFailOptionLogic(closeModal);

  const dialogProps: GenericModalProps['dialogProps'] = {
    maxWidth: 'sm',
    classes,
  };

  return { title, text, buttons, open, dialogProps };
};
