import React, { FC } from 'react';
import { CreateExchangeAddress } from './CreateExchangeAddress';
import { LoginRegisterOrg } from './LoginRegisterOrg';
import { TermsAndConditions } from './TermsAndConditions/TermsAndConditions';
import { UserRegistered } from './UserRegistered';

export const AuthModalsCenter: FC = () => {
  return (
    <>
      <UserRegistered />
      <LoginRegisterOrg />
      <CreateExchangeAddress />
      <TermsAndConditions />
    </>
  );
};
