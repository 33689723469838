import {
  MenuItem,
  TextField,
  TextFieldProps,
  InputBaseProps,
  Tooltip,
} from '@mui/material';
import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { FormSelectOption } from '../FormSelect';

export type SelectRegularField<FormValuesType> = {
  name: keyof FormValuesType;
  label: string;
  options?: FormSelectOption[];
  placeholder?: string;
  required?: boolean;
  helperText?: string;
  additionalInputProps?: {
    valueToOpen: FormSelectOption['value'];
    name: string;
    label: string;
    required?: boolean;
    inputProps?: InputBaseProps['inputProps'];
  };
  textFieldProps?: TextFieldProps;
  inputProps?: InputBaseProps['inputProps'];
  tooltipLabel?: string;
};

export interface SelectRegularProps<FormValuesType = any> {
  field: SelectRegularField<FormValuesType>;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorExists?: boolean;
  errorText?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  register?: UseFormRegister<FormValuesType>;
  disabled?: boolean;
}

export type TSelectRegular = <FormValuesType>(
  props: PropsWithChildren<SelectRegularProps<FormValuesType>>
) => ReactElement;

export const SelectRegular: TSelectRegular = ({
  field,
  onChange,
  errorExists = false,
  errorText = '',
  variant = 'standard',
  value = '',
  disabled = false,
  register = null,
}) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const additionalInputRegistration =
    field.additionalInputProps?.name &&
    register &&
    register(field.additionalInputProps.name as any);
  const options = field.options || [];
  return (
    <Tooltip
      title={field.tooltipLabel ? field.tooltipLabel : ''}
      placement="top-start"
      open={isTooltipOpen}
      onMouseEnter={(_) => setTooltipOpen(true)}
      onMouseLeave={(_) => setTooltipOpen(false)}
    >
      <div>
        <TextField
          select
          fullWidth
          name={`${field.name}`}
          label={field.label}
          error={errorExists}
          helperText={field.helperText ? field.helperText : errorText}
          margin="normal"
          variant={variant}
          value={value}
          defaultValue={value}
          onChange={onChange}
          onClick={(_) => setTooltipOpen(false)}
          disabled={disabled}
          required={field.required}
          inputProps={{
            ...field.inputProps,
          }}
          {...field.textFieldProps}
          sx={{
            '& label.MuiInputLabel-shrink ': {
              display: 'none',
            },
            '& legend': {
              display: 'none',
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {!isEmpty(field.additionalInputProps) &&
          !!field.additionalInputProps?.valueToOpen &&
          field.additionalInputProps?.valueToOpen === value && (
            <TextField
              fullWidth
              variant={variant}
              label={field.additionalInputProps?.label}
              name={additionalInputRegistration?.name}
              onClick={(_) => setTooltipOpen(false)}
              inputRef={additionalInputRegistration?.ref}
              onChange={additionalInputRegistration?.onChange}
              onBlur={additionalInputRegistration?.onBlur}
              inputProps={field.additionalInputProps?.inputProps}
              {...field.textFieldProps}
            />
          )}
      </div>
    </Tooltip>
  );
};
