import { Upload } from '@energyweb/origin-backend-core';
import {
  FormSelectOption,
  MultiStepFormItem,
  MultiStepFormProps,
} from '@energyweb/origin-ui-core';
import { TFunction } from 'i18next';
import {
  ProjectDTO,
  UploadDto,
} from '@energyweb/origin-backend-react-query-client';

export type DocsUploadFormValues = {
  projectId: string[];
  documentIds: string[];
};

export type TCreateDocsUploadForm = (
  t: TFunction,
  projectData: ProjectDTO[]
) => MultiStepFormItem<DocsUploadFormValues>;

export type OrganizationInfoFormValues = {
  name: string;
  address: string;
  businessType: string;
  city: string;
  zipCode: string;
  country: FormSelectOption[];
  tradeRegistryCompanyNumber: string;
  vatNumber: string;
};

export type TCreateOrgInfoForm = (
  t: TFunction
) => MultiStepFormItem<OrganizationInfoFormValues>;

export type SignatoryInfoFormValues = {
  signatoryAddress: string;
  signatoryCity: string;
  signatoryCountry: FormSelectOption[];
  signatoryEmail: string;
  signatoryFullName: string;
  signatoryPhoneNumber: string;
  signatoryZipCode: string;
};

export type TCreateSignatoryInfoForm = (
  t: TFunction
) => MultiStepFormItem<SignatoryInfoFormValues>;

export type ProjectInfoFormValues = {
  name: string;
  location: string;
  description: string;
  type: string;
};

export type TCreateProjectInfoForm = (
  t: TFunction,
  project?: ProjectDTO,
  location?: string,
  flag?: boolean
) => MultiStepFormItem<ProjectInfoFormValues>;

export type RegisterCarbonFormValues = {
  typeId: number;
  gasHeatingValue: number;
  oilApiGravity: number;
  ethane: number;
  propane: number;
  isoButane: number;
  nButane: number;
  isoPentane: number;
  nPentane: number;
  hexanes: number;
  gasShrinkage: number;
  projectId: number;
  uploads: UploadDto[];
};

export type TRegisterCarbonForm = (
  t: TFunction,
  projectTypes?: any,
  project?: ProjectDTO
) => MultiStepFormItem<RegisterCarbonFormValues>;

export type WellFormValues = {
  wellName: string;
  wellAPINumber: string;
  qualifiedCredit: string;
  holeLatitude: number;
  holeLongitude: number;
  reservesSubmissionId: number;
};

export type TProjectWellForm = (
  t: TFunction,
  project?: ProjectDTO
) => MultiStepFormItem<WellFormValues>;

export type OwnershipFormValues = {
  riskOptionId: number;
  riskTypeId: number;
  projectId: number;
  uploads: UploadDto[];
};

export type TOwnershipForm = (
  t: TFunction,
  project?: ProjectDTO,
  riskTypes?: any
) => MultiStepFormItem<OwnershipFormValues>;

export type ReservesSubmissionFormValues = {
  projectId: number;
  uploads: UploadDto[];
};

export type TReservesSubmissionForm = (
  t: TFunction,
  project?: ProjectDTO
) => MultiStepFormItem<ReservesSubmissionFormValues>;

export type ReservesValidationFormValues = {
  companyId: number;
  verifierId: number;
  email: string;
  uploads: UploadDto[];
  projectId: number;
};

export type TReservesValidationForm = (
  t: TFunction,
  verifierCompanies?: any,
  project?: ProjectDTO
) => MultiStepFormItem<ReservesValidationFormValues>;

export type RoyaltyOwnerFormValues = {
  name: string;
  uploads: UploadDto[];
  ownershipId: number;
};

export type TRoyaltyOwnerFormValues = (
  t: TFunction,
  project?: ProjectDTO
) => MultiStepFormItem<RoyaltyOwnerFormValues>;

export type SupplementalDataFormValues = {
  oilReservesEstimation: number;
  gasReservesEstimation: number;
  riskOptionId: number;
  projectId: number;
  uploads: UploadDto[];
};

export type TSupplementalDataForm = (
  t: TFunction,
  project?: ProjectDTO
) => MultiStepFormItem<SupplementalDataFormValues>;

export type OverviewFormValues = {};

export type TOverviewForm = (
  t: TFunction,
  project?: ProjectDTO
) => MultiStepFormItem<OverviewFormValues>;

export type FormMergedType = TCreateProjectInfoForm &
  TRegisterCarbonForm &
  TReservesSubmissionForm &
  TReservesValidationForm &
  TOwnershipForm &
  TSupplementalDataForm &
  TOverviewForm;

export type TUseRegisterProjectFormLogic = (
  project?: ProjectDTO
) => MultiStepFormProps<FormMergedType>;
