import React, { FC } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  InputAdornment,
} from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useTranslation } from 'react-i18next';
import { Info } from '@mui/icons-material';
import { IconPopover, IconSize } from '@energyweb/origin-ui-core';
import { withMetamask } from '@energyweb/origin-ui-web3';
import { ConnectMetamaskPlaceholder } from '../ConnectMetamaskPlaceholder';
import { useOrganizationBlockchainAddressEffects } from './OrganizationBlockchainAddress.effects';
import { useStyles } from './OrganizationBlockchainAddress.styles';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

const Component: FC = () => {
  const classes = useStyles();
  const {
    submitHandler,
    isLoading,
    blockchainAddress,
    isUpdating,
    title,
    buttonText,
    popoverText,
  } = useOrganizationBlockchainAddressEffects();

  const { t } = useTranslation();

  if (isLoading) return <CircularProgress />;

  const copyValue = () => {
    navigator.clipboard.writeText(blockchainAddress);
    showNotification('Copied', NotificationTypeEnum.Info);
  };

  return (
    <div className={classes.fieldWrapper}>
      {blockchainAddress ? (
        <TextField
          value={blockchainAddress}
          disabled
          variant="outlined"
          label={t('user.profile.metamaskWallet')}
          fullWidth
          className={`${classes.field}`}
          inputProps={{ ['data-cy']: 'exchange-deposit-address' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className={classes.copyAdornment}>
                <ContentCopyRoundedIcon
                  className={classes.copyIcon}
                  onClick={copyValue}
                />
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <>
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={isUpdating}
            onClick={submitHandler}
          >
            {buttonText}
          </Button>
          {isUpdating && <CircularProgress className={classes.loader} />}
        </>
      )}
      <IconPopover
        icon={Info}
        iconSize={IconSize.Large}
        popoverText={popoverText}
        className={classes.iconPopover}
      />
    </div>
  );
};

export const OrganizationBlockchainAddress = withMetamask(
  Component,
  ConnectMetamaskPlaceholder
);

export const getOrganizationBlockchainAddressComponent = (
  isAddressAttached: boolean
) => {
  const component = isAddressAttached
    ? Component
    : OrganizationBlockchainAddress;
  return component;
};
