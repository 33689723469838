import { useState } from 'react';
import { UploadedFile } from '@energyweb/origin-ui-core';
import { useAutomatedWellRegistrationHandler } from '@energyweb/origin-ui-organization-data';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

export const useAutomatedWellRegistrationEffects = (project: ProjectDTO) => {
  const [documents, setDocuments] = useState<File[]>([]);
  const { submitHandler } = useAutomatedWellRegistrationHandler(project);

  const handleSubmit = () => {
    submitHandler(documents);
  };

  const uploadFunction = async (file: File[]) => {
    const files = [...documents, ...file];
    setDocuments(files);
    return files;
  };

  const onChange = (files: UploadedFile[]) => {
    const file = files[files?.length - 1] || ({} as UploadedFile);
    if (file?.removed) {
      setDocuments([]);
    }
  };

  const getUploadedFiles = () => {
    return documents || [];
  };

  const downloadXLSX = (e: any) => {
    e.preventDefault();
    const a = document.createElement('a');
    a.href =
      process.env.NX_BACKEND_URL +
      (process.env.NX_BACKEND_URL.slice(-1) === '/' ? '' : '/') +
      'api/public/get-template';
    a.target = '_blank';
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  return {
    documents,
    handleSubmit,
    uploadFunction,
    onChange,
    getUploadedFiles,
    downloadXLSX,
  };
};
