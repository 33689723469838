import React, { FC } from 'react';
import {
  ButtonsGroup,
  FileUpload,
  FormInput,
  FormSelect,
  GenericFormSecondaryButton,
  useCommonStyles,
} from '@energyweb/origin-ui-core';
import { ReservesValidationFormValues } from '@energyweb/origin-ui-organization-logic';
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { useStyles } from './ReservesValidation.styles';
import { UnpackNestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReservesValidationEffects } from './ReservesValidation.effects';
import { isEmpty } from 'lodash';
import { ProjectDTO } from '@energyweb/issuer-irec-api-react-query-client';
import { handleFileUpload } from '@energyweb/origin-ui-utils';

export interface ReservesValidationProps {
  submitHandler?: (
    values: UnpackNestedValue<ReservesValidationFormValues>
  ) => Promise<void>;
  secondaryButtons?: GenericFormSecondaryButton[];
  loading?: boolean;
  project?: ProjectDTO;
}

export const ReservesValidationForm: FC<ReservesValidationProps> = ({
  submitHandler,
  secondaryButtons,
  loading,
  project,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const {
    documents,
    fields,
    register,
    handleSubmit,
    control,
    errors,
    uploadFunction,
    onChange,
    getUploadedFiles,
  } = useReservesValidationEffects(project);

  const onSubmit = async (values: ReservesValidationFormValues) => {
    if (Object.keys(documents)?.length === 3) {
      const uploadedFiles = await handleFileUpload(values.uploads, documents);
      values.uploads = [...uploadedFiles];
      submitHandler(values);
    }
  };

  const fileOptions = [
    {
      dataCy: 'reservesReport',
    },
    {
      dataCy: 'financialModelSupportDoc',
      mimeType: 'application/pdf',
    },
    {
      dataCy: 'forecastPlots',
      mimeType: 'application/pdf',
    },
  ];

  return (
    <Box position={'relative'}>
      <Backdrop className={commonClasses.circularProgress} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        {fields.map(
          (field: any, index: number) =>
            (field.select && (
              <Grid my={1} key={index}>
                <Typography variant="subtitle1">{field.label}</Typography>
                <FormSelect
                  field={field}
                  control={control}
                  errorExists={!isEmpty((errors as any)[field.name])}
                  errorText={(errors as any)[field.name]?.message ?? ''}
                  variant={'outlined'}
                  disabled={false}
                  register={register}
                />
              </Grid>
            )) ||
            (field.file &&
              fileOptions.map((option, i) => (
                <FileUpload
                  key={i}
                  heading={t(
                    `project.register.reservesValidation.${option.dataCy}`
                  )}
                  dropzoneText={t('file.upload.dropOrClick')}
                  apiUploadFunction={uploadFunction}
                  onChange={onChange}
                  wrapperProps={{ ['data-cy']: option.dataCy }}
                  uploadedFiles={getUploadedFiles(option.dataCy)}
                  required={!documents[option.dataCy]}
                  mimeType={option.mimeType}
                />
              ))) || (
              <Grid my={1} key={index}>
                <Typography variant="subtitle1">{field.label}</Typography>
                <FormInput
                  key={field.label}
                  field={field}
                  disabled={true}
                  register={register}
                  errorExists={!isEmpty((errors as any)[field.name])}
                  errorText={(errors as any)[field.name]?.message ?? ''}
                  variant={'outlined'}
                  {...field.inputProps}
                />
              </Grid>
            )
        )}
        <ButtonsGroup secondaryButtons={secondaryButtons} loading={loading} />
      </form>
    </Box>
  );
};
