import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { TableActionData } from '@energyweb/origin-ui-core';
import {
  ProjectDTO,
  ProjectStatus,
} from '@energyweb/origin-backend-react-query-client';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import {
  OrganizationModalsActionsEnum,
  useOrgModalsDispatch,
} from '../../context';

import { useProjectList } from '@energyweb/origin-ui-organization-data';

export const useProjectListingEffects = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [query, setQuery] = useState({});
  const [orderBy, setOrderBy] = useState<keyof ProjectDTO>('createdAt');
  const [isASC, setIsASC] = useState(true);
  const { projects: dataToFilter } = useProjectList();
  const { projects } = useProjectList(query);

  const dispatchModals = useOrgModalsDispatch();

  const sortBy =
    (field: keyof ProjectDTO) => (a: ProjectDTO, b: ProjectDTO) => {
      if (field === 'createdAt') {
        const aDate = +new Date(a.createdAt);
        const bDate = +new Date(b.createdAt);
        if (aDate < bDate) return isASC ? 1 : -1;
        if (aDate > bDate) return isASC ? -1 : 1;
      }
      if (a[field] < b[field]) return isASC ? -1 : 1;
      if (a[field] > b[field]) return isASC ? 1 : -1;
      return 0;
    };

  const actions: TableActionData<ProjectDTO['id']>[] = [
    {
      name: t('project.listing.actions.view'),
      onClick: (id) => {
        navigate(`/project/${id}/view`);
      },
    },
    {
      name: '',
      icon: <DeleteForeverOutlinedIcon />,
      class: 'deleteAction',
      isShown: (id) =>
        projects.find((project: ProjectDTO) => project.id === id)
          .projectStatus === ProjectStatus.Draft,
      onClick: (id) => {
        dispatchModals({
          type: OrganizationModalsActionsEnum.SHOW_DELETE_PROJECT_OPTION,
          payload: {
            open: true,
            projectId: id,
            additionalAction: () => {
              projects.splice(
                projects.findIndex((project: ProjectDTO) => project.id === id),
                1
              );
              setQuery({});
            },
          },
        });
      },
    },
  ];

  const header = {
    name: t('project.listing.name'),
    createdAt: t('project.listing.asOfDate'),
    location: t('project.listing.location'),
    projectStatus: t('project.listing.status'),
    actions: t('project.listing.actions.header'),
  };

  const applyFilters = (query: any) => {
    setQuery(query);
  };

  const sortByOrder = (key: keyof ProjectDTO) => {
    if (key === orderBy) {
      setIsASC(!isASC);
      return;
    }
    setOrderBy(key);
  };

  const filterFields = ['type', 'location'];

  return {
    projects: projects ? projects?.sort(sortBy(orderBy)) : [],
    dataToFilter,
    header,
    actions,
    applyFilters,
    filterFields,
    headerProps: {
      order: isASC ? 'asc' : 'desc',
      orderBy,
      sortByOrder,
    },
  };
};
