import React, { PropsWithChildren, ReactElement } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useStyles } from './ProjectsSummary.styles';

interface ProjectsSummaryProps {
  title: string;
  summaryData: any[];
}

type TProjectsSummary = (
  props: PropsWithChildren<ProjectsSummaryProps>
) => ReactElement;

export const ProjectsSummary: TProjectsSummary = ({ title, summaryData }) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.title}>{title}</Typography>
      <Grid container spacing={2} my={3}>
        {summaryData?.map((data: any, index: number) => (
          <Grid item xs={6} md={3} key={index}>
            <Paper className={classes.paper}>
              {data.icon}
              <Typography className={classes.status}>{data.status}</Typography>
              <Typography className={classes.count}>{data.count}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
