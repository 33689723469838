import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useProjectOwnerControllerIsProjectOwner } from '@energyweb/origin-backend-react-query-client';
import {
  useApiCreateProjectOwnerData,
  useApiUpdateProjectOwnerData,
} from '@energyweb/origin-ui-user-data';
import { useProjectOwnerFormConfig } from '@energyweb/origin-ui-user-logic';

export const FORM_SUBMIT_STATE = {
  default: 'primaryButton',
  error: 'errorButton',
  pending: 'pendingButton',
};

export const useCreateProjectOwnerPageEffects = () => {
  const navigate = useNavigate();

  const [nextDisabled, setNextDisable] = useState(true);
  const [showErrorMessage, setErrorMessageVisibility] = useState(false);
  const [formSubmitButtonText, setFormSubmitButtonText] = useState<string>(
    'user.projectOwner.formDefaultSubmit'
  );
  const [formSubmitState, setFormSubmitState] = useState(
    FORM_SUBMIT_STATE.default
  );

  const { data: projectOwnerData, isLoading } =
    useProjectOwnerControllerIsProjectOwner();
  const formConfig = useProjectOwnerFormConfig(projectOwnerData);

  const onStatusReset = useCallback(() => {
    setFormSubmitState(FORM_SUBMIT_STATE.default);
    setFormSubmitButtonText('user.projectOwner.formDefaultSubmit');
  }, []);

  const onNextClick = useCallback(() => {
    navigate('/organization/register');
  }, []);

  const onProjectOwnerCreateSuccess = useCallback(() => {
    setNextDisable(false);
  }, []);

  const onProjectOwnerCreateError = useCallback(() => {
    setNextDisable(true);
    setFormSubmitState(FORM_SUBMIT_STATE.error);
    setFormSubmitButtonText('user.projectOwner.formErrorSubmit');
    setErrorMessageVisibility(true);
  }, []);

  const onProjectOwnerCreating = useCallback(() => {
    setFormSubmitState(FORM_SUBMIT_STATE.pending);
    setFormSubmitButtonText('user.projectOwner.formPendingSubmit');
    setErrorMessageVisibility(false);
  }, []);

  let submitHandler = (() => {
    if (projectOwnerData?.id) {
      return useApiUpdateProjectOwnerData(projectOwnerData.id).submitHandler;
    } else {
      return useApiCreateProjectOwnerData(
        onProjectOwnerCreateSuccess,
        onProjectOwnerCreateError,
        onProjectOwnerCreating
      ).submitHandler;
    }
  })();

  const formProps = {
    ...formConfig,
    submitHandler,
  };

  return {
    formProps,
    nextDisabled,
    onNextClick,
    formSubmitButtonText,
    buttonColor: formSubmitState,
    onStatusReset,
    showErrorMessage,
    projectOwnerData,
    isLoading,
    navigate,
  };
};
