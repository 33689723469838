import {
  Step,
  StepButton,
  StepLabel,
  Stepper,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { FC } from 'react';
import { useStyles } from './StepReport.styles';

export interface StepReportProps {
  activeStep: number;
  labels: string[];
  navigateToStep: (step: number) => void;
  checkStepStatus: (step: number) => boolean;
}

export const StepReport: FC<StepReportProps> = ({
  labels,
  activeStep,
  navigateToStep,
  checkStepStatus,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Stepper
      alternativeLabel={smallScreen}
      className={classes.root}
      activeStep={activeStep}
      orientation="vertical"
      nonLinear
    >
      {labels.map((label, index) => {
        const stepProps: { completed?: boolean; disabled?: boolean } = {
          disabled: !checkStepStatus(index),
        };
        const labelProps: { optional?: React.ReactNode } = {};
        return (
          <Step key={label} {...stepProps}>
            <StepButton color="inherit" onClick={() => navigateToStep(index)}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
};
