import { useTranslation } from 'react-i18next';
import { TDeleteProjectLogic } from './types';

export const useDeleteProjectLogic: TDeleteProjectLogic = (
  cancelClick,
  closeModal
) => {
  const { t } = useTranslation();
  return {
    text: t('organization.modals.deleteProject.text'),
    buttons: [
      {
        label: t('general.buttons.yes'),
        onClick: cancelClick,
      },
      {
        label: t('general.buttons.no'),
        onClick: closeModal,
      },
    ],
  };
};
