import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import { CompanyDTO } from '@energyweb/origin-backend-react-query-client';
import { useCompaniesTableLogic } from '@energyweb/origin-ui-user-logic';
import { TableActionData } from '@energyweb/origin-ui-core';
import { useVerifierCompanies } from '@energyweb/origin-ui-user-data';
import { useNavigate } from 'react-router';

export const useCompaniesPageEffects = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { verifierCompanies, isLoading } = useVerifierCompanies();

  const actions: TableActionData<CompanyDTO['id']>[] = [
    {
      icon: <Edit data-cy="editIcon" />,
      name: t('user.vrCompanies.company.update'),
      onClick: (id) => {
        navigate(`/admin/companies/${id}`);
      },
    },
  ];

  const onCreateClick = useCallback(() => {
    navigate('/admin/companies/create');
  }, [navigate]);

  const pageLoading = isLoading;

  const tableData = useCompaniesTableLogic({
    companies: verifierCompanies,
    actions: actions,
    isLoading: pageLoading,
  });

  return { pageLoading, tableData, onCreateClick };
};
