import { useTranslation } from 'react-i18next';
import { TRemoveConfirmModalLogic } from './types';

export const useConfirmTransferFormLogic: TRemoveConfirmModalLogic = (
  closeModal,
  submitHandler,
  buttonText
) => {
  const { t } = useTranslation();

  return {
    title: t('token.transfer.modal.title'),
    buttons: [
      {
        label: t('general.buttons.edit'),
        onClick: closeModal,
      },
      {
        label: buttonText,
        onClick: submitHandler,
      },
    ],
  };
};
