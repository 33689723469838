import { useTranslation } from 'react-i18next';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { TUseRegisterProjectFormLogic } from './types';
import { createProjectInfoForm } from './projectInformationForm';
import { registerCarbonForm } from './carbonContentForm';
import { reservesSubmissionForm } from './reservesSubmissionForm';
import { reservesValidationForm } from './reservesValidationForm';
import { ownershipForm } from './ownership';
import { supplementalDataForm } from './supplementalDataForm';
import { overviewForm } from './overview';

export const useRegisterProjectFormLogic: TUseRegisterProjectFormLogic = (
  project?: ProjectDTO
) => {
  const { t } = useTranslation();
  return {
    heading: t(
      `project.register.generalInformation.${
        project?.id ? 'editTitle' : 'title'
      }`
    ),
    forms: [
      createProjectInfoForm(t, project),
      registerCarbonForm(t, [], project),
      reservesSubmissionForm(t, project),
      reservesValidationForm(t, [], project),
      ownershipForm(t, project),
      supplementalDataForm(t, project),
      overviewForm(t, project),
    ],
    backButtonText: t('general.buttons.back'),
    nextButtonText: t('general.buttons.next'),
  };
};
