import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  status: {
    textTransform: 'uppercase',
    fontWeight: 325,
    fontSize: 13,
    color: theme.palette.text.primary,
    '&:before': {
      verticalAlign: 'middle',
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23FF0000'}'/></svg>")`,
      fill: 'black',
      marginRight: 7,
    },
    '&.Pending:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23909090'}'/></svg>")`,
    },
    '&.Issuance:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23FF9800'}'/></svg>")`,
    },
    '&.Draft:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%234EE2EC'}'/></svg>")`,
    },
    '&.Rejected:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%232c2d34'}'/></svg>")`,
    },
    '&.Verified:before, &.Accepted:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23DDA0DD'}'/></svg>")`,
    },
    '&.Executed:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%2378CC8A'}'/></svg>")`,
    },
  },
}));
