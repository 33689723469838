import { GenericForm } from '@energyweb/origin-ui-core';
import React, { FC } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoginPageLayout } from '../../containers';
import { usePhoneVerificationPageEffects } from './PhoneVerificationPage.effects';
import { useStyles } from './PhoneVerificationPage.styles';
import { styles, AuthBackButton } from '../../common';

export interface PhoneVerificationPageProps {
  bgImage?: string;
}

export const PhoneVerificationPage: FC<PhoneVerificationPageProps> = ({
  bgImage,
}) => {
  const {
    formProps,
    navigateToLogin,
    phone,
    requestVerificationCode,
    isRequesting,
    left
  } = usePhoneVerificationPageEffects();
  const classes = useStyles();
  const formClasses = styles.formStyles.useStyles();
  const { t } = useTranslation();

  return (
    <LoginPageLayout bgImage={bgImage} contentClass={classes.pageContainer}>
      <Box className={classes.navigationContainer}>
        <AuthBackButton onClick={navigateToLogin} />
      </Box>
      <Box className={classes.paper}>
        <Typography
          className={formClasses.formSubTitle + ' ' + classes.primaryText}
          variant="h4"
        >
          {t('user.verifyPhone.formTitle')}
        </Typography>
        <Typography
          className={formClasses.formSubTitle + ' ' + classes.secondaryText}
          variant="h5"
        >
          {t('user.verifyPhone.formSubtitle', {
            phoneEnding: phone.substr(phone.length - 5),
          })}
        </Typography>
        <GenericForm
          formClass={`${classes.form} ${formClasses.container}`}
          {...formProps}
        />
        <Box className={formClasses.bottomActions}>
          <Button
            variant="text"
            onClick={requestVerificationCode}
            disabled={isRequesting || left > 0}
            sx={{ opacity: isRequesting || left > 0 ? 0.5 : 1 }}
          >
            {t('user.verifyPhone.requestCode')} {left > 0 && `(${left})`}
          </Button>
        </Box>
      </Box>
      <Box></Box>
    </LoginPageLayout>
  );
};
