import React, { PropsWithChildren, ReactElement } from 'react';
import {
  ProjectOwnerAccountProps,
  useProjectOwnerAccountEffects,
} from './ProjectOwnerAccount.effects';
import { ProjectsSummary } from '../../containers/user-account/ProjectsSummary';
import { t } from 'i18next';

type TProjectOwnerAccount = (
  props: PropsWithChildren<ProjectOwnerAccountProps>
) => ReactElement;

export const ProjectOwnerAccount: TProjectOwnerAccount = ({
  pendingProjects,
  verifiedProjects,
}) => {
  const { summaryData } = useProjectOwnerAccountEffects({
    pendingProjects,
    verifiedProjects,
  });

  return (
    <ProjectsSummary
      summaryData={summaryData}
      title={t('user.userProfile.creditsSummary')}
    />
  );
};
