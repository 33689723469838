import { isRole, Role } from '@energyweb/origin-backend-core';
import {
  ProjectDTO,
  ProjectStatus,
} from '@energyweb/origin-backend-react-query-client';
import {
  useProjectList,
  useValidationProjectList,
} from '@energyweb/origin-ui-organization-data';
import { useUser } from '@energyweb/origin-ui-user-data';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useUserAccountPageEffects = () => {
  const { t } = useTranslation();

  let projects: ProjectDTO[] = [];
  const [title, setTitle] = useState<string>(t('user.userProfile.userAccount'));
  const [mapTitle, setMapTitle] = useState<string>(
    t('user.userProfile.creditsMap')
  );

  const { user } = useUser();

  const userIsProjectVerifier = isRole(user, Role.ProjectVerifier);
  const userIsProjectOwner = isRole(user, Role.ProjectOwner);
  const userIsVerifiedUser = !userIsProjectOwner && !userIsProjectVerifier;

  const projectsByStatus = (projects: ProjectDTO[]) => {
    const pendingProjects = projects?.filter(
      (project: ProjectDTO) => project.projectStatus === ProjectStatus.Pending
    );
    const changeRequestedProjects = projects?.filter(
      (project: ProjectDTO) =>
        project.projectStatus === ProjectStatus.Change_request
    );
    const verifiedProjects = projects?.filter(
      (project: ProjectDTO) => project.projectStatus === ProjectStatus.Verified
    );
    const rejectedProjects = projects?.filter(
      (project: ProjectDTO) => project.projectStatus === ProjectStatus.Rejected
    );
    return {
      pendingProjects,
      changeRequestedProjects,
      verifiedProjects,
      rejectedProjects,
    };
  };

  useEffect(() => {
    if (userIsProjectVerifier) {
      setTitle(t('user.userProfile.verifierAccount'));
      setMapTitle(t('user.userProfile.projectsMap'));
    }
    if (userIsProjectOwner) {
      setTitle(t('user.userProfile.ownerAccount'));
      setMapTitle(t('user.userProfile.statusMap'));
    }
  }, []);

  if (userIsProjectVerifier) {
    projects = useValidationProjectList().projects;
  } else if (userIsProjectOwner) {
    projects = useProjectList().projects;
  }

  return {
    getProjectByStatus: projectsByStatus(projects),
    user,
    userIsProjectVerifier,
    userIsProjectOwner,
    userIsVerifiedUser,
    title,
    mapTitle,
  };
};
