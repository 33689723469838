import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

type Details =
  | 'gasHeatingValue'
  | 'oilApiGravity'
  | 'ethane'
  | 'propane'
  | 'isoButane'
  | 'nButane'
  | 'isoPentane'
  | 'nPentane'
  | 'hexanes'
  | 'gasShrinkage'
  | 'oilRemaining'
  | 'gasRemaining';

export const useProjectDetailsEffect = (project: ProjectDTO) => {
  const { t } = useTranslation();

  const { carbonContent, supplementalData } = project || ({} as ProjectDTO);

  const initialValues = {
    gasHeatingValue: carbonContent?.gasHeatingValue || 0,
    oilApiGravity: carbonContent?.oilApiGravity || 0,
    ethane: carbonContent?.ethane || 0,
    propane: carbonContent?.propane || 0,
    isoButane: carbonContent?.isoButane || 0,
    nButane: carbonContent?.nButane || 0,
    isoPentane: carbonContent?.isoPentane || 0,
    nPentane: carbonContent?.nPentane || 0,
    hexanes: carbonContent?.hexanes || 0,
    gasShrinkage: carbonContent?.gasShrinkage || 0,
    oilRemaining: supplementalData?.oilReservesEstimation || 0,
    gasRemaining: supplementalData?.gasReservesEstimation || 0,
  };

  const { register, formState } = useForm({
    defaultValues: initialValues,
  });

  const { isValid, errors, dirtyFields } = formState;

  const form = {
    inputsVariant: 'outlined',
    initialValues,
    fields: [
      {
        name: 'oilApiGravity' as Details,
        label: t('project.register.carbonContent.oilApiGravity'),
        required: true,
        inputProps: { ['data-cy']: 'oilApiGravity' },
        startAdornment: t('project.register.carbonContent.oilApiGravity'),
        endAdornment: { isValidCheck: false, element: ' ' },
        placeholder: t('project.register.carbonContent.oilApiGravity'),
      },
      {
        name: 'gasHeatingValue' as Details,
        label: t('project.register.carbonContent.gasHeatingValue'),
        required: true,
        inputProps: { ['data-cy']: 'gasHeatingValue' },
        startAdornment: t('project.register.carbonContent.gasHeatingValue'),
        endAdornment: { isValidCheck: false, element: ' ' },
        placeholder: t('project.register.carbonContent.gasHeatingValue'),
      },
      {
        name: 'ethane' as Details,
        label: t('project.register.carbonContent.ethane'),
        required: false,
        startAdornment: t('project.register.carbonContent.ethane'),
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'ethane' },
        placeholder: t('project.register.carbonContent.ethane'),
      },
      {
        name: 'propane' as Details,
        label: t('project.register.carbonContent.propane'),
        required: false,
        startAdornment: t('project.register.carbonContent.propane'),
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'propane' },
        placeholder: t('project.register.carbonContent.propane'),
      },
      {
        name: 'isoButane' as Details,
        label: t('project.register.carbonContent.isoButane'),
        required: false,
        startAdornment: t('project.register.carbonContent.isoButane'),
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'isoButane' },
        placeholder: t('project.register.carbonContent.isoButane'),
      },
      {
        name: 'nButane' as Details,
        label: t('project.register.carbonContent.nButane'),
        required: false,
        startAdornment: t('project.register.carbonContent.nButane'),
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'nButane' },
        placeholder: t('project.register.carbonContent.nButane'),
      },
      {
        name: 'isoPentane' as Details,
        label: t('project.register.carbonContent.isoPentane'),
        required: false,
        startAdornment: t('project.register.carbonContent.isoPentane'),
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'isoPentane' },
        placeholder: t('project.register.carbonContent.isoPentane'),
      },
      {
        name: 'nPentane' as Details,
        label: t('project.register.carbonContent.nPentane'),
        required: false,
        startAdornment: t('project.register.carbonContent.nPentane'),
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'nPentane' },
        placeholder: t('project.register.carbonContent.nPentane'),
      },
      {
        name: 'hexanes' as Details,
        label: t('project.register.carbonContent.hexanes'),
        required: false,
        startAdornment: t('project.register.carbonContent.hexanes'),
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'hexanes' },
        placeholder: t('project.register.carbonContent.hexanes'),
      },
      {
        name: 'gasShrinkage' as Details,
        label: t('project.register.carbonContent.gasShrinkage'),
        required: true,
        startAdornment: t('project.register.carbonContent.gasShrinkage'),
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'gasShrinkage' },
        placeholder: t('project.register.carbonContent.gasShrinkage'),
      },
      {
        name: 'oilRemaining' as Details,
        label: t('project.register.oilRemainingETA'),
        placeholder: t('project.register.oilRemainingETA'),
        startAdornment: t('project.register.oilRemainingETA'),
        required: true,
        endAdornment: { isValidCheck: false, element: ' ' },
        inputProps: { ['data-cy']: 'oilRemaining' },
      },
      {
        name: 'gasRemaining' as Details,
        label: t('project.register.gasRemainingETA'),
        placeholder: t('project.register.gasRemainingETA'),
        startAdornment: t('project.register.gasRemainingETA'),
        required: true,
        endAdornment: { isValidCheck: false, element: ' ' },
        inputProps: { ['data-cy']: 'gasRemaining' },
      },
    ],
  };

  return {
    form,
    register,
    isValid,
    errors,
    dirtyFields,
  };
};
