import { PageNotFound } from '@energyweb/origin-ui-core';
import {
  UserAppEnvProvider,
  UserEnvVariables,
} from '@energyweb/origin-ui-user-view';
import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { OrganizationModalsCenter, NoProjectOwner } from './containers';
import { OrganizationModalsProvider } from './context';
import {
  RegisterPage,
  AllProjectsListingPage,
  RegisterPageLoaded,
  ProjectsListingPage,
  ProjectStatusPage,
  FollowingProjectsListingPage,
  VerifierProjectDetailsPage,
  ProjectPublicPage,
} from './pages';

export interface OrganizationAppProps {
  routesConfig: {
    showRegisterOrg: boolean; // PO && PV
    showProjectListing: boolean; // PO
    showAllProjectListing: boolean;
    showNotPermittedCreateProjectPage: boolean;
    showVerifierDashboardPage?: boolean;
  };
  envVariables: UserEnvVariables;
}

export const OrganizationApp: FC<OrganizationAppProps> = ({
  routesConfig,
  envVariables,
}) => {
  const {
    showRegisterOrg,
    showProjectListing,
    showAllProjectListing,
    showNotPermittedCreateProjectPage,
    showVerifierDashboardPage = false,
  } = routesConfig;

  return (
    <OrganizationModalsProvider>
      <UserAppEnvProvider variables={envVariables}>
        <Routes>
          {showProjectListing && (
            <Route path="project-list" element={<ProjectsListingPage />} />
          )}
          {showAllProjectListing && (
            <Route
              path="following-project-list"
              element={<FollowingProjectsListingPage />}
            />
          )}
          {showAllProjectListing && (
            <Route
              path="all-project-list"
              element={<AllProjectsListingPage />}
            />
          )}
          {(showNotPermittedCreateProjectPage || showProjectListing) && (
            <Route path="register" element={<RegisterPageLoaded />} />
          )}
          {showVerifierDashboardPage && (
            <Route path="/:id/view" element={<VerifierProjectDetailsPage />} />
          )}
          {showRegisterOrg && (
            <Route path="/:id/edit" element={<RegisterPage />} />
          )}
          {showRegisterOrg && (
            <Route path="/:id/view" element={<ProjectStatusPage />} />
          )}
          <Route path="/public/:id" element={<ProjectPublicPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <OrganizationModalsCenter />
      </UserAppEnvProvider>
    </OrganizationModalsProvider>
  );
};
