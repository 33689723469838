import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check, Clear } from '@mui/icons-material';
import { ProjectOwnerDTO } from '@energyweb/origin-backend-react-query-client';
import { useProjectOwnersTableLogic } from '@energyweb/origin-ui-user-logic';
import {
  TableActionData,
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { Role } from '@energyweb/origin-backend-core';
import {
  useProjectOwnersData,
  useUpdateProjectOwnerStatusActions,
} from '@energyweb/origin-ui-user-data';

export const useProjectOwnersPageEffects = () => {
  const { t } = useTranslation();

  const { projectOwners, isLoading } = useProjectOwnersData();
  const { acceptInvite, rejectInvite, isMutating } =
    useUpdateProjectOwnerStatusActions(projectOwners);

  const checkUserRole = (id: ProjectOwnerDTO['id']) => {
    if (
      projectOwners.find((po) => po.id === id).user?.rights ===
      Role.ProjectVerifier
    ) {
      showNotification(
        t('user.projectOwner.userIsVR'),
        NotificationTypeEnum.Info
      );
      return;
    }
    acceptInvite(id);
  };

  const actions: TableActionData<ProjectOwnerDTO['id']>[] = [
    {
      icon: <Check data-cy="checkIcon" />,
      name: t('user.projectOwner.accept'),
      onClick: checkUserRole,
      loading: isMutating,
    },
    {
      icon: <Clear data-cy="clearIcon" />,
      name: t('user.projectOwner.reject'),
      onClick: rejectInvite,
      loading: isMutating,
    },
  ];

  const pageLoading = isLoading;

  const tableData = useProjectOwnersTableLogic({
    projectOwners: projectOwners,
    actions: actions,
    isLoading: pageLoading,
  });

  return { pageLoading, tableData };
};
