import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '10px',
    height: '100%',
  },
  map: {
    height: '100%',
  },
}));
