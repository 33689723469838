import { GenericFormProps } from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface TransferTokenArgs {
  email: string;
  address: string;
}

export type TUseTransferTokenArgs = () => Omit<
  GenericFormProps<TransferTokenArgs>,
  'submitHandler'
>;

export const useTransferTokenFormLogic: TUseTransferTokenArgs = () => {
  const { t } = useTranslation();
  return {
    inputsVariant: 'outlined',
    initialValues: {
      email: '',
      address: '',
    },
    validationSchema: yup.object().shape(
      {
        email: yup.string().when('address', {
          is: (address: any) => !address || address.length === 0,
          then: yup
            .string()
            .email()
            .required(t('general.requirements.requirementsField')),
        }),
        address: yup.string().when('email', {
          is: (email: any) => !email || email.length === 0,
          then: yup
            .string()
            .required(t('general.requirements.requirementsField')),
        }),
      },
      [['email', 'address']]
    ),

    fields: [
      {
        name: 'email',
        label: t('token.transfer.email'),
        inputProps: { ['data-cy']: 'email' },
        placeholder: 'Email',
      },
      {
        name: 'address',
        label: t('token.transfer.address'),
        inputProps: { ['data-cy']: 'address' },
        placeholder: 'Blockchain Address',
      },
    ],
    buttonText: t('general.buttons.save'),
  };
};
