import { PageNotFound } from '@energyweb/origin-ui-core';
import React, { FC } from 'react';
import { Route, Routes } from 'react-router';
import {
  AdminUpdateUserPage,
  AdminUsersPage,
  ProjectOwnersPage,
  ProjectVerifiersPage,
  VRCompaniesPage,
  VRCompanyFormPage,
} from './pages';
import { ExecutingProjectsPage } from './pages/ExecutingProjectsPage';
import { ProjectDocumentsPage } from './pages/ProjectDocumentsPage';
import { UserModalsProvider } from './context';
import { AdminModalsCenter } from './containers';

export interface AdminAppProps {
  routesConfig: {
    showUsers: boolean;
    showAllOrgs?: boolean;
    showProjectOwners: boolean;
    showProjectVerifiers: boolean;
    showVRCompanies: boolean;
    showProjectsInExecution: boolean;
  };
}

export const AdminApp: FC<AdminAppProps> = ({ routesConfig }) => {
  const {
    showUsers,
    showAllOrgs,
    showProjectOwners,
    showProjectVerifiers,
    showVRCompanies,
    showProjectsInExecution,
  } = routesConfig;
  return (
    <UserModalsProvider>
      <Routes>
        {showUsers && <Route path="/" element={<AdminUsersPage />} />}
        {showUsers && (
          <Route path="update-user/:id" element={<AdminUpdateUserPage />} />
        )}
        {showProjectOwners && (
          <Route path="projectOwners" element={<ProjectOwnersPage />} />
        )}
        {showProjectVerifiers && (
          <Route path="projectVerifiers" element={<ProjectVerifiersPage />} />
        )}
        {showVRCompanies && (
          <Route path="companies" element={<VRCompaniesPage />} />
        )}
        {showVRCompanies && (
          <Route path="companies/create" element={<VRCompanyFormPage />} />
        )}
        {showVRCompanies && (
          <Route path="companies/:id" element={<VRCompanyFormPage />} />
        )}
        {showProjectsInExecution && (
          <Route
            path="executing-projects"
            element={<ExecutingProjectsPage />}
          />
        )}
        {showProjectsInExecution && (
          <Route
            path="executing-projects/:id"
            element={<ProjectDocumentsPage />}
          />
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <AdminModalsCenter />
    </UserModalsProvider>
  );
};
