import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerCarbonForm } from 'libs/organization/logic/src/register/carbonContentForm';
import { UploadedFile, Documents } from '@energyweb/origin-ui-core';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { useProjectTypesData } from '@energyweb/origin-ui-organization-data';

export const useCarbonContentEffects = (project?: ProjectDTO) => {
  const { t } = useTranslation();

  const [documents, setDocuments] = useState<Documents>({});
  const { projectTypes } = useProjectTypesData();

  const { initialValues, validationSchema, fields, validationMode } =
    registerCarbonForm(t, projectTypes, project);

  const { register, handleSubmit, formState, control, watch } = useForm({
    mode: validationMode,
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const { isValid, errors, dirtyFields } = formState;
  const { uploads } = watch();

  const selectField = fields.find((field) => field.name === 'typeId');
  const firstColumn = fields.filter(
    (field) =>
      field.name === 'gasHeatingValue' || field.name === 'oilApiGravity'
  );
  const secondColumn = fields.slice(3, fields.length - 1);
  const gasShrinkageField = fields.find(
    (field) => field.name === 'gasShrinkage'
  );

  const uploadFunction = async (file: File[], dataCy: string) => {
    setDocuments({
      ...documents,
      [dataCy]: file,
    });
    return documents;
  };

  const onChange = (files: UploadedFile[]) => {
    const file = files[files?.length - 1] || ({} as UploadedFile);
    if (file?.removed) {
      delete documents[file?.dataCy];
      setDocuments({ ...documents });
    }
  };

  useEffect(() => {
    if (uploads?.length) {
      const documents = Object.assign(
        {},
        ...uploads?.map((file: any) => ({
          [file.dataCy]: [{ name: file.name } as File],
        }))
      );
      setDocuments({ ...documents });
    }
  }, [uploads]);

  const getUploadedFiles = (dataCy: string) => {
    const uploadedFiles = uploads || [];
    const file = uploadedFiles?.find((file: any) => file.dataCy === dataCy);
    return file ? [{ name: file?.name } as File] : [];
  };

  return {
    errors,
    isValid,
    fields,
    selectField,
    firstColumn,
    secondColumn,
    gasShrinkageField,
    dirtyFields,
    control,
    documents,
    uploadFunction,
    onChange,
    handleSubmit,
    register,
    getUploadedFiles,
  };
};
