import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: '30px',
  },
  button: {
    marginTop: 10,
    // color: theme.palette.background.paper,
  },
}));
