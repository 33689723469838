import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '10px',
    boxShadow: 'none',
    textAlign: 'center',
  },
  status: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    lineHeight: 3,
  },
  count: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontWeight: 500,
    color: '#78CC8A',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
  },
}));
