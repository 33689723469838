import React, { FC } from 'react';
import { GenericModal } from '@energyweb/origin-ui-core';
import { useStartTokenIssuanceEffects } from './StartTokenIssuance.effects';

export const StartTokenIssuance: FC = () => {
  const { open, text, buttons, dialogProps } = useStartTokenIssuanceEffects();

  return (
    <GenericModal
      open={open}
      text={text as string}
      buttons={buttons}
      dialogProps={dialogProps}
    />
  );
};
