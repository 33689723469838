import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  form: {
    background: '#FEFFFF',
    boxShadow: '0px 6px 24px rgba(25, 29, 56, 0.16)',
    padding: '30px 35px',

    '& .MuiOutlinedInput-root': {
      background: '#F8FFF9',
      '&:hover fieldset': {
        borderColor: '#AAB0F3',
      },
      '& .Mui-focused fieldset': {
        borderColor: '#AAB0F3',
      },
      '& .MuiInputAdornment-positionStart': {
        width: '100%',
        '& p': {
          color: '#AAB0F3',
        },
      },
      '& .MuiSelect-select': {
        background: '#FFF',
      },
    },
    '& input, select': {
      border: '1px solid #AAB0F3',
      borderRadius: '4px',
      padding: 15,
    },

    '& .MuiTextField-root': {
      width: '100%',
      marginTop: 10,
    },
    '& .MuiInputBase-inputAdornedStart': {
      background: '#FFFFFF',
      textAlign: 'right',
      borderRight: 'none',
      borderTop: 'none',
      width: '25%',
    },
    '& fieldset': {
      borderColor: '#AAB0F3',
    },
  },

  block: {
    '& .MuiTouchRipple-root, & .active, & .MuiTouchRipple-root:hover': {
      background: '#FFFFFF',
      border: '1px dashed #AAB0F3',
      borderRadius: '6px',
    },
  },
  inputGroup: {
    marginTop: 25,
    '&  .MuiTextField-root': {
      marginTop: 0,
      marginBottom: 0,
      '&:nth-child(2) > div': {
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
      },
      '&:last-child > div': {
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        '& >fieldset, input': {
          borderBottom: '1px solid #AAB0F3',
        },
      },
      '& > div': {
        borderRadius: 0,
        '& > fieldset, input': {
          borderBottom: 'none',
        },
      },
    },
  },
  dropzone: {
    padding: '10px 0',
  },
});
