import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  list: {
    padding: 0,
    '& .MuiListItem-root': {
      fontSize: 15,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  item: {
    '& .MuiSvgIcon-root': {
      fill: '#261F00',
      width: 23,
      height: 23,
      verticalAlign: 'text-top',
    },
  },
});
