import { useTranslation } from 'react-i18next';
import {
  ProjectVerifierDTO,
  ProjectVerifierStatus,
} from '@energyweb/origin-backend-react-query-client';
import {
  TableActionData,
  TableComponentProps,
  TableRowData,
} from '@energyweb/origin-ui-core';

export type TUseProjectVerifiersTableArgs = {
  projectVerifiers: ProjectVerifierDTO[];
  actions: TableActionData<ProjectVerifierDTO['id']>[];
  isLoading: boolean;
};

export type TFormatProjectVerifiers = (
  props: Omit<TUseProjectVerifiersTableArgs, 'isLoading'>
) => TableRowData<ProjectVerifierDTO['id']>[];

export type TUseProjectVerifiersTableLogic = (
  props: TUseProjectVerifiersTableArgs
) => TableComponentProps<ProjectVerifierDTO['id']>;

const formatProjectVerifiers: TFormatProjectVerifiers = ({
  projectVerifiers,
  actions,
}) => {
  return projectVerifiers?.map((projectVerifier) => ({
    id: projectVerifier.id,
    company: projectVerifier.company.name,
    email: projectVerifier.email,
    state: projectVerifier.state,
    license: projectVerifier.license,
    status: projectVerifier.status,
    actions:
      projectVerifier.status === ProjectVerifierStatus.Pending
        ? actions
        : undefined,
  }));
};

export const useProjectVerifiersTableLogic: TUseProjectVerifiersTableLogic = ({
  projectVerifiers,
  actions,
  isLoading,
}) => {
  const { t } = useTranslation();

  return {
    header: {
      company: t('user.projectVerifier.company'),
      email: t('user.projectVerifier.email'),
      state: t('user.projectVerifier.state'),
      license: t('user.projectVerifier.license'),
      status: t('user.projectVerifier.status'),
      actions: '',
    },
    pageSize: 20,
    loading: isLoading,
    data: formatProjectVerifiers({ projectVerifiers, actions }) ?? [],
  };
};
