import { useEffect, useState } from 'react';
import L from 'leaflet';
import { ProjectWell } from '@energyweb/origin-backend-react-query-client';

const greenIcon = new L.Icon({
  iconSize: [25, 41],
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
});

const wellsToMarkers = (wells: ProjectWell[]) => {
  return wells?.map((e) =>
    L.marker({ lat: e.holeLatitude, lng: e.holeLongitude }, { icon: greenIcon })
  );
};

export const useProjectWellsMapHook = (wells?: ProjectWell[]) => {
  const [markers, setMarkers] = useState(wellsToMarkers(wells));

  useEffect(() => {
    setMarkers(wellsToMarkers(wells));
  }, [wells]);

  useEffect(() => {
    let container: any = L.DomUtil.get('map');

    if (container != null) {
      container._leaflet_id = null;
    }
    if (markers?.length) {
      const map = L.map('map', { zoom: 4 });
      map.dragging.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();

      const group = L.featureGroup(markers).addTo(map);
      map.fitBounds(group.getBounds(), { maxZoom: 5 });

      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(map);
    }
  }, [markers]);

  return {};
};
