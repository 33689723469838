import { GenericForm } from '@energyweb/origin-ui-core';
import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginPageLayout } from '../../containers';
import { useResetPasswordPageEffects } from './ResetPasswordPage.effects';
import { useStyles } from './ResetPasswordPage.styles';
import { styles } from '../../common';

export interface ResetPasswordPageProps {
  bgImage?: string;
}

export const ResetPasswordPage: FC<ResetPasswordPageProps> = ({ bgImage }) => {
  const { t } = useTranslation();
  const { formProps } = useResetPasswordPageEffects();
  const classes = useStyles();
  const formClasses = styles.formStyles.useStyles();

  return (
    <LoginPageLayout bgImage={bgImage}>
      <Box className={classes.paper}>
        <Typography
          className={formClasses.formSubTitle + ' ' + classes.primaryText}
          variant="h4"
        >
          {t('user.resetPassword.formTitle')}
        </Typography>
        <GenericForm
          formClass={`${classes.form} ${formClasses.container}`}
          {...formProps}
        />
      </Box>
    </LoginPageLayout>
  );
};
