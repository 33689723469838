import {
  useFollowingProjectControllerFollowProject,
  ProjectDTO,
  useFollowingProjectControllerUnfollowProject,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';

export const useFollowProjectActions = () => {
  const { t } = useTranslation();

  const { mutate: followProjectMutate } =
    useFollowingProjectControllerFollowProject();
  const { mutate: unfollowProjectMutate } =
    useFollowingProjectControllerUnfollowProject();

  const followProject = (id: ProjectDTO['id']) =>
    followProjectMutate(
      {
        data: {
          projectId: id,
        },
      },
      {
        onSuccess: () => {
          showNotification(
            t('project.register.notifications.followProjectSuccess'),
            NotificationTypeEnum.Success
          );
        },
        onError: (error: any) => {
          showNotification(
            `${error?.response?.data?.message || ''}
              `,
            NotificationTypeEnum.Error
          );
        },
      }
    );

  const unfollowProject = (id: ProjectDTO['id']) =>
    unfollowProjectMutate(
      { id },
      {
        onSuccess: () => {
          showNotification(
            t('project.register.notifications.unfollowProjectSuccess'),
            NotificationTypeEnum.Success
          );
        },
        onError: (error: any) => {
          showNotification(
            `${error?.response?.data?.message || ''}
                `,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  return { followProject, unfollowProject };
};
