import {
  getProjectControllerGetQueryKey,
  useProjectControllerChangeRequest,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useProjectChangeRequest = (id: number) => {
  const { t } = useTranslation();
  const { mutate } = useProjectControllerChangeRequest();
  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(id);

  const submitHandler = (comment: string) => {
    mutate(
      { id, data: { comment } },
      {
        onSuccess: () => {
          showNotification(
            t('projectStatus.notifications.projectChangeRequestSuccess'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(projectQueryKey);
        },
        onError: (error: any) => {
          if (error?.response?.status === 400) {
            showNotification(
              error?.response?.data?.message,
              NotificationTypeEnum.Error
            );
          } else {
            showNotification(
              `${t('projectStatus.notifications.projectChangeRequestError')}:
                    ${error?.response?.data?.message || ''}
                    `,
              NotificationTypeEnum.Error
            );
          }
        },
      }
    );
  };

  return { submitHandler };
};
