import React, { FC, useState } from 'react';
import {
  Box,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
} from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { isRole, Role } from '@energyweb/origin-backend-core';
import { useNavigate } from 'react-router';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import LogoutRoundedIcon from '@mui/icons-material/Logout';
import { useUser } from '@energyweb/origin-ui-user-data';
import { useTranslation } from 'react-i18next';
import { useUserMenuEffects } from './UserMenu.effects';
import { useStyles } from './UserMenu.styles';

export const UserMenu: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, logout } = useUser();
  const classes = useStyles();
  const {
    exchangeAddress,
    blockchainAddress,
    notificationsCount,
    updateCount,
    copyValue,
  } = useUserMenuEffects();
  const userIsProjectOwner = isRole(user, Role.ProjectOwner);
  const userIsAdmin = isRole(user, Role.Admin);
  const userIsVerifiedUser = isRole(user, Role.OrganizationAdmin);
  const userIsVR = isRole(user, Role.ProjectVerifier);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    updateCount();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box onClick={handleClick} className={classes.container}>
        <Box className={classes.userInfo}>
          <Typography className="status">
            {t('navigation.account.userStatus')}
          </Typography>
          <Typography>
            {userIsProjectOwner
              ? t('navigation.account.projectOwner')
              : userIsVerifiedUser
              ? t('navigation.account.verifiedUser')
              : userIsAdmin
              ? t('navigation.account.admin')
              : t('navigation.account.projectVerifier')}
          </Typography>
        </Box>
        <Avatar className={classes.userAvatar}>
          {user.firstName.substring(0, 1)}
        </Avatar>
      </Box>
      <Menu
        className={classes.menuDefault}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <div className={classes.userBlock}>
          <Typography className="username">
            {user.firstName} {user.lastName}
          </Typography>
          <Typography className="email">{user.email}</Typography>
        </div>
        <div className={classes.menuBlock}>
          {!userIsVR && (
            <TextField
              label={t('navigation.account.custodialAddress')}
              value={exchangeAddress.replace(
                exchangeAddress.substring(6, 38),
                '...'
              )}
              disabled
              variant="standard"
              className={classes.addressField}
              style={{ marginBottom: 10 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.copyAdornment}
                  >
                    <ContentCopyRoundedIcon
                      className={classes.copyIcon}
                      onClick={(_) => copyValue(exchangeAddress)}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
          {!!blockchainAddress && (
            <TextField
              label={t('navigation.account.noncustodialAddress')}
              value={blockchainAddress.replace(
                blockchainAddress.substring(6, 38),
                '...'
              )}
              disabled
              variant="standard"
              className={classes.addressField}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.copyAdornment}
                  >
                    <ContentCopyRoundedIcon
                      className={classes.copyIcon}
                      onClick={(_) => copyValue(blockchainAddress)}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <MenuItem onClick={(_) => navigate('/account/settings/profile')}>
            <AccountCircleOutlinedIcon className={classes.menuIcon} />
            {t('navigation.account.profile')}
          </MenuItem>
          {!userIsAdmin && (
            <MenuItem onClick={(_) => navigate('/account/settings/wallet')}>
              <AccountBalanceWalletOutlinedIcon className={classes.menuIcon} />
              {t('navigation.account.walletSettings')}
            </MenuItem>
          )}
          <MenuItem onClick={(_) => navigate('/account/notifications')}>
            <NotificationsOutlinedIcon className={classes.menuIcon} />
            {t('navigation.account.notifications')}
            {!!notificationsCount && (
              <div className={classes.notificationBadge}>
                {notificationsCount}
              </div>
            )}
          </MenuItem>
          <MenuItem className={classes.signOutBtn} onClick={logout}>
            <LogoutRoundedIcon className={classes.signOutIcon} />
            {t('navigation.account.signOut')}
          </MenuItem>
        </div>
      </Menu>
    </>
  );
};
