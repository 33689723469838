import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import { enUS, plPL } from '@mui/material/locale';
import { LightenColor } from '../utils/colors';
import {
  createStyleConfig,
  IOriginStyleConfig,
} from '../utils/createStyleConfig';
import { ThemeModeEnum } from '../utils';
import {
  OriginUiThemeVariables,
  variables_darkTheme,
  variables_lightTheme,
} from './variables';

const getThemeConfig = (
  styleConfig: IOriginStyleConfig,
  themeMode?: ThemeModeEnum
): ThemeOptions & { form: any } => {
  return {
    palette: {
      primary: {
        main: styleConfig.PRIMARY_COLOR,
        contrastText: styleConfig.MAIN_BACKGROUND_COLOR,
        light: styleConfig.PRIMARY_COLOR_SECOND,
      },
      secondary: {
        main: styleConfig.FORM_FIELD_COLOR,
      },
      background: {
        paper: styleConfig.MAIN_BACKGROUND_COLOR,
      },
      text: {
        primary: styleConfig.SIMPLE_TEXT_COLOR,
        secondary: styleConfig.TEXT_COLOR_DEFAULT,
        disabled: styleConfig.TEXT_COLOR_DEFAULT,
      },
      mode: ThemeModeEnum.Light,
    },

    form: {
      fontColors: styleConfig.FORM_FONT_COLORS,
    },

    typography: {
      fontFamily: styleConfig.FONT_FAMILY_PRIMARY,
      fontSize: styleConfig.FONT_SIZE,
      body1: {
        fontFamily: styleConfig.FONT_FAMILY_SECONDARY,
      },
    },

    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent !important',
            color: 'initial',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            '&& .MuiBackdrop-root': {
              opacity: '0 !important',
            },
          },
          paper: {
            backgroundColor: '#fff !important',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 100px #fff inset`,
              WebkitTextFillColor: styleConfig.SIMPLE_TEXT_COLOR,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: styleConfig.FONT_FAMILY_PRIMARY,
            marginLeft: 0,
            color: '#AAB0F3',
            '&&.Mui-error': {
              color: '#d32f2f',
            },
            '&&.Mui-disabled': {
              color: '#AAB0F3',
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '#918C89 0px 0px 0px 100px inset',
              boxShadow: '#918C89 0px 0px 0px 100px inset',
              '-webkit-text-fill-color': 'rgb(255, 255, 255)',
            },
          },
          root: {
            backgroundColor: LightenColor(
              styleConfig.MAIN_BACKGROUND_COLOR,
              -10
            ),
            borderRadius: 5,
            '&.Mui-disabled': {
              backgroundColor: LightenColor(
                styleConfig.MAIN_BACKGROUND_COLOR,
                -12
              ),
            },
            '&.Mui-focused': {
              backgroundColor: LightenColor(
                styleConfig.MAIN_BACKGROUND_COLOR,
                -10
              ),
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: { fontSize: variables_darkTheme.fontSize },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            marginRight: '10px',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: styleConfig.MAIN_BACKGROUND_COLOR,
            color: styleConfig.TEXT_COLOR_DEFAULT,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundImage: 'none',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            '&& button': {
              color: '#ffffff',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: styleConfig.FONT_FAMILY_PRIMARY,
            color: styleConfig.PRIMARY_COLOR,
            fill: styleConfig.PRIMARY_COLOR,
            textTransform: 'uppercase',
          },
          contained: {
            '&.Mui-disabled': {
              color: styleConfig.TEXT_COLOR_DEFAULT,
            },
            color: styleConfig.MAIN_BACKGROUND_COLOR,
          },
        },
      },
      // MuiCheckbox: {
      //   styleOverrides: {
      //     root: {
      //       '&& .MuiSvgIcon-root': {
      //         backgroundColor: '#E1DBD84D',
      //         borderRadius: 4,
      //       },
      //       '&& path': {
      //         display: 'none',
      //       },
      //       '&&.Mui-checked svg path': {
      //         display: 'inherit',
      //         fill: '#fff',
      //       },
      //       '&&:hover': {
      //         backgroundColor: 'none',
      //       },
      //     },
      //   },
      // },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            '&& .MuiTypography-root': {
              color: '#E1DBD8',
              fontSize: 18,
            },

            '&& .MuiLink-root': {
              color: '#fff',
              textDecoration: 'none',
              '&&:hover': {
                textDecoration: 'underline',
              },
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            borderBottom: `2px solid ${styleConfig.PRIMARY_COLOR}`,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: LightenColor(styleConfig.MAIN_BACKGROUND_COLOR, 0),
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            'tr:nth-of-type(1n)': {
              backgroundColor: LightenColor(
                styleConfig.MAIN_BACKGROUND_COLOR,
                -7
              ),
            },
            'tr:nth-of-type(2n)': {
              backgroundColor: LightenColor(
                styleConfig.MAIN_BACKGROUND_COLOR,
                -3
              ),
            },
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            background: LightenColor(styleConfig.MAIN_BACKGROUND_COLOR, 0),
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: 16,
            borderBottom: 'none',
          },
          body: {
            color: styleConfig.TEXT_COLOR_DEFAULT,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            border: 'none',
            color: styleConfig.SIMPLE_TEXT_COLOR,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            '&& div div': {
              border: 'none',
            },
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            border: 'none',
            margin: '10px 0',
            '&& .MuiButton-textPrimary': {
              color: '#2B201F',
            },
            '&& .MuiButton-root': {
              fontFamily: styleConfig.FONT_FAMILY_PRIMARY,
              textTransform: 'capitalize',
              fontWeight: 300,
              fontSize: 20,
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: styleConfig.SIMPLE_TEXT_COLOR,
          },
          h4: {
            fontFamily: styleConfig.FONT_FAMILY_PRIMARY,
          },
          h5: {
            fontFamily: styleConfig.FONT_FAMILY_PRIMARY,
          },
          h6: {
            fontFamily: styleConfig.FONT_FAMILY_PRIMARY,
          },
          body1: {
            fontFamily: styleConfig.FONT_FAMILY_PRIMARY,
          },
          subtitle1: {
            fontFamily: styleConfig.FONT_FAMILY_PRIMARY,
          },
          gutterBottom: {
            marginBottom: '1rem',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          popper: {
            position: 'absolute',
            left: '120px !important',
          },
          tooltip: {
            backgroundColor: styleConfig.PRIMARY_COLOR,
          },
        },
      },
    },
  };
};

type MakeOriginUIThemeArgs = {
  themeMode?: ThemeModeEnum;
  colorsConfig?: OriginUiThemeVariables;
};

type MakeOriginUITheme = (args: MakeOriginUIThemeArgs) => Theme;

export const makeOriginUiTheme: MakeOriginUITheme = ({
  themeMode,
  colorsConfig,
}) => {
  const colors = variables_lightTheme;

  const styleConfig = colorsConfig
    ? createStyleConfig(colorsConfig)
    : createStyleConfig(colors);

  const materialTheme = createMaterialThemeForOrigin(
    styleConfig,
    'en',
    themeMode
  );

  return materialTheme;
};

export const createMaterialThemeForOrigin = (
  styleConfig: IOriginStyleConfig,
  language: 'en' | 'pl',
  themeMode?: ThemeModeEnum
): Theme => {
  const materialLocale =
    {
      pl: plPL,
      en: enUS,
    }[language] ?? enUS;

  return createTheme(getThemeConfig(styleConfig, themeMode), materialLocale);
};
