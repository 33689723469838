import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  wrapper: {
    background: '#fff',
    padding: '20px 35px',
    [theme.breakpoints.down('lg')]: {
      padding: 0,
      justifyContent: 'center',
    },
  },
  headerIcon: {
    position: 'absolute',
    right: '65px',
    boxShadow: 'none',
  },
  title: {
    color: '#2B201F',
    fontSize: 24,
    position: 'absolute',
    zIndex: 1,
    top: 28,
    left: 450,
  },
}));
