import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check, Clear } from '@mui/icons-material';
import { ProjectVerifierDTO } from '@energyweb/origin-backend-react-query-client';
import { useProjectVerifiersTableLogic } from '@energyweb/origin-ui-user-logic';
import {
  TableActionData,
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { Role } from '@energyweb/origin-backend-core';

import {
  useAllProjectVerifiers,
  useUpdateProjectVerifierStatusActions,
} from '@energyweb/origin-ui-user-data';

export const useProjectVerifiersPageEffects = () => {
  const { t } = useTranslation();

  const { allProjectVerifiers, isLoading } = useAllProjectVerifiers();
  const { acceptRequest, rejectRequest, isMutating } =
    useUpdateProjectVerifierStatusActions(allProjectVerifiers);

  const checkUserRole = (id: ProjectVerifierDTO['id']) => {
    if (
      allProjectVerifiers.find((vr) => vr.id === id).user?.rights ===
      Role.ProjectOwner
    ) {
      showNotification(
        t('user.projectOwner.userIsPO'),
        NotificationTypeEnum.Info
      );
      return;
    }
    acceptRequest(id);
  };
  const actions: TableActionData<ProjectVerifierDTO['id']>[] = [
    {
      icon: <Check data-cy="checkIcon" />,
      name: t('user.projectVerifier.accept'),
      onClick: checkUserRole,
      loading: isMutating,
    },
    {
      icon: <Clear data-cy="clearIcon" />,
      name: t('user.projectVerifier.reject'),
      onClick: rejectRequest,
      loading: isMutating,
    },
  ];

  const pageLoading = isLoading;

  const tableData = useProjectVerifiersTableLogic({
    projectVerifiers: allProjectVerifiers,
    actions: actions,
    isLoading: pageLoading,
  });

  return { pageLoading, tableData };
};
