import { useEffect, useState } from 'react';
import { useNotificationRecentList } from '@energyweb/origin-ui-organization-data';
import { useLocation } from 'react-router';

export const useNotificationMenuEffects = () => {
  let {
    notifications: notificationsList,
    isLoading,
    update,
  } = useNotificationRecentList();
  const [notifications, setNotifications] = useState([]);
  const [isMethodRunning, setIsMethodRunning] = useState(false);
  const location = useLocation();

  useEffect(() => {
    updateByLocation();
  }, [location.pathname]);

  useEffect(() => {
    if (
      !isLoading &&
      notificationsList.filter(
        (notify1: any) =>
          !notifications.some((notify2: any) => notify1.id === notify2.id)
      ).length
    ) {
      setNotifications(notificationsList);
    }
  }, [isLoading, notificationsList]);

  const updateNotifications = (timeout: number) => () => {
    if (isMethodRunning) return;
    setIsMethodRunning(true);
    update().then((value: any) => {
      if (
        !value.data.length ||
        value.data.filter(
          (notify1: any) =>
            !notifications.some((notify2: any) => notify1.id === notify2.id)
        ).length
      ) {
        setNotifications(value.data);
      }
      setTimeout(() => setIsMethodRunning(false), timeout);
    });
  };

  const updateByLocation = updateNotifications(300000);

  return {
    notifications,
    updateNotifications: updateNotifications(10000),
  };
};
