import {
  fileControllerUpload,
  UploadDto,
} from '@energyweb/origin-backend-react-query-client';
import {
  Documents,
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

export const handleFileUpload = async (
  uploads: UploadDto[],
  documents: Documents
) => {
  const newFiles = Object.entries(documents).map(([key, value]) => ({
    file: value.filter((file: File) => file?.size),
    key,
  }));

  const uploadedFiles = newFiles?.map(async (data) => {
    if (data?.file?.length > 0) {
      try {
        const uploadedFile: UploadDto[] = await fileControllerUpload({
          files: data.file,
          dataCy: data.key,
        });
        return uploadedFile;
      } catch (error: any) {
        showNotification(
          `${error?.response?.data?.message || ''}`,
          NotificationTypeEnum.Error
        );
      }
    }
  });
  return Promise.all(uploadedFiles).then((result) => {
    const newFiles = result?.flat()?.filter((res) => res?.dataCy);
    const oldFiles = uploads.filter(
      (uploadedFile: UploadDto) =>
        !newFiles?.some(
          (file: UploadDto) => file?.dataCy == uploadedFile?.dataCy
        )
    );
    return [...oldFiles, ...newFiles];
  });
};
