import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    '& > .MuiGrid-root': {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '15px 0',
      '& .MuiGrid-item': {
        width: '100%',
        textAlign: 'center',
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
      },
      '& .MuiButton-root': {
        backgroundColor: theme.palette.text.disabled,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  },
}));
