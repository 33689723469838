import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    maxWidth: '56px',
    display: 'flex',
  },
  cell: {
    width: 106,
  },
  speedDial: {
    position: 'absolute',
    bottom: 'calc(50% - 14px)',
    left: 0,
    right: 0,
  },
  speedDialButton: {
    width: '28px',
    height: '28px',
    minHeight: '28px',
    backgroundColor: 'inherit',
    boxShadow: 'none',
    color: theme.palette.primary.main,
  },
  speedDialIcon: {
    fontSize: '16px',
    backgroundColor: 'inherit',
  },
  speedDialActionButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  speedDialActionTooltip: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    whiteSpace: 'nowrap',
  },
  actionButton: {
    background: '#78CC8A33',
    borderRadius: 33,
    color: '#78CC8A',
    padding: 1,

    '&.deleteAction': {
      position: 'absolute',
      right: -54,
      background: '#F5802533',
      minWidth: 34,
      marginLeft: 14,
      '& .MuiButton-startIcon': {
        margin: 0,
        '& path': {
          fill: '#F58025',
        },
      },
    },
  },
}));
