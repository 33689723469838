import React, { FC } from 'react';
import { ChangePasswordOTPModal } from './ChangePasswordOTPModal';

export const AccountModalsCenter: FC = () => {
  return (
    <>
      <ChangePasswordOTPModal />
    </>
  );
};
