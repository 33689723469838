import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  menu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 304,
  },
  multistepMenu: {
    position: 'fixed',
  },
  content: {
    backgroundColor: '#ECF6EE',
    flexBasis: ' calc(100% - 304px)',
    maxWidth: '100%',
  },

  heading: {
    fontSize: '30px',
    fontFamily: theme.typography.fontFamily,
    color: '#2B201F',
  },
  header: {
    [theme.breakpoints.up('lg')]: {
      padding: '15px 63px',
    },
  },
  button: {
    background: 'rgba(120, 204, 138, 0.4)',
    borderRadius: '4px',
  },
}));
