import React, { FC } from 'react';
import { useRoyaltyOwnersListingEffects } from './ProjectRoyaltyOwners.effects';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { RoyaltyOwnersFormsLayout } from '../../layout';
import { Role } from '@energyweb/origin-backend-core';

export interface RoyaltyOwnersProps {
  project?: ProjectDTO;
  actionsSlot?: JSX.Element;
  role?: Role,
  showName?: boolean
  warningText?: string
}

export const ProjectRoyaltyOwners: FC<RoyaltyOwnersProps> = ({
  project,
  role,
  actionsSlot,
  showName = false,
  warningText
}) => {
  const { royaltyOwners, headers, showStatus } = useRoyaltyOwnersListingEffects(project, role, showName);

  return (
    <div>
      <RoyaltyOwnersFormsLayout
        project={project}
        royaltyOwners={royaltyOwners}
        headers={headers}
        showStatus={showStatus}
        showName={showName}
        actionsSlot={actionsSlot}
        warningText={warningText}
        role={role}
      />
    </div>
  );
};

export default ProjectRoyaltyOwners;
