import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  StarBorder,
  DriveFileRenameOutline,
  CheckCircle,
  HighlightOff,
} from '@mui/icons-material';
import {
  ProjectDTO,
  ProjectStatus,
} from '@energyweb/origin-backend-react-query-client';
import { Role, UploadStatus } from '@energyweb/origin-backend-core';
import { useCommonStyles } from '@energyweb/origin-ui-core';
import {
  ProjectDetails,
  ProjectRoyaltyOwners,
  ProjectTotals,
  ProjectUploadedFiles,
  ProjectWellsMap,
  ProjectWellsReport,
} from '../../project-status';
import { useStyles } from './ProjectStatusLayout.styles';
import { useProjectStatusLayoutEffects } from './ProjectStatusLayout.effects';
import ProjectAdditionalDocuments from '../../additional-documents/ProjectAdditionalDocuments';

export interface ProjectStatusLayoutProps {
  project: ProjectDTO;
  role?: Role;
  onEdit?: any;
  onAccept?: any;
  onDecline?: any;
  onChangeRequest?: any;
}

const Actions = ({
  file,
  role,
  onEdit,
  onAccept,
  onChangeRequest,
  projectStatus,
  verifierSigned = false,
}: any) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  const verifierActionDisabled =
    !(
      file?.status === UploadStatus.Pending &&
      projectStatus === ProjectStatus.Pending
    ) ||
    [
      ProjectStatus.Rejected,
      ProjectStatus.Verified,
      ProjectStatus.Change_request,
      ProjectStatus.Issuance,
      ProjectStatus.Execution,
      ProjectStatus.Executed,
    ].includes(projectStatus);

  const ownerActionDisabled =
    file?.status !== UploadStatus.ChangeRequest ||
    (file?.status === UploadStatus.ChangeRequest && verifierSigned) ||
    [
      ProjectStatus.Rejected,
      ProjectStatus.Verified,
      ProjectStatus.Pending,
      ProjectStatus.Issuance,
      ProjectStatus.Execution,
      ProjectStatus.Executed,
    ].includes(projectStatus);

  const onEditCb = useCallback(() => {
    onEdit?.(file);
  }, [file]);

  const onAcceptCb = useCallback(() => {
    onAccept?.(file);
  }, [file]);

  const onChangeRequestCb = useCallback(() => {
    onChangeRequest?.(file);
  }, [file]);

  if (role === Role.ProjectVerifier) {
    return (
      <>
        <Button
          className={`${classes.action} ${commonClasses.circleButton} ${commonClasses.secondaryButton}`}
          variant="contained"
          onClick={onAcceptCb}
          sx={{ marginRight: '10px' }}
          startIcon={<CheckCircle />}
          disabled={verifierActionDisabled}
        >
          {t('projectStatus.accept')}
        </Button>
        <Button
          variant="contained"
          className={`${classes.action} ${commonClasses.circleButton} ${commonClasses.criticalButton}`}
          onClick={onChangeRequestCb}
          color="error"
          startIcon={<DriveFileRenameOutline />}
          disabled={verifierActionDisabled}
        >
          {t('projectStatus.changeRequest')}
        </Button>
      </>
    );
  } else if (role === Role.ProjectOwner) {
    return (
      <Button
        className={`${classes.action} ${commonClasses.circleButton} ${commonClasses.secondaryButton}`}
        variant="contained"
        onClick={onEditCb}
        startIcon={<DriveFileRenameOutline />}
        disabled={ownerActionDisabled}
      >
        {t('projectStatus.edit')}
      </Button>
    );
  }
  return null;
};

export interface ProjectStatusLayoutProps {
  project: ProjectDTO;
  role?: Role;
  onFileEdit?: any;
  onFileAccept?: any;
  onFileChangeRequest?: any;
  onEdit?: any;
  onAccept?: any;
  onDecline?: any;
  onChangeRequest?: any;
  children?: JSX.Element;
  isApproving?: boolean;
  isDeclining?: boolean;
}

export const ProjectStatusLayout: FC<ProjectStatusLayoutProps> = ({
  project,
  role,
  onFileEdit,
  onEdit,
  onAccept,
  onDecline,
  onChangeRequest,
  children,
  isApproving,
  isDeclining,
  onFileAccept,
  onFileChangeRequest,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const {
    onFollowButtonClick,
    isFollowed,
    isReadyForAcceptance,
    uploads,
    verifierActionsDisabled,
    verifierSigned,
  } = useProjectStatusLayoutEffects(project);

  const postVerificationDocumentsAreVisible =
    project?.projectStatus === ProjectStatus.Verified ||
    (project?.projectStatus === ProjectStatus.Change_request && verifierSigned);

  return (
    <Box className={classes.root}>
      <Typography className={classes.header}>
        {t('projectStatus.projectDetails')}
      </Typography>
      <Paper classes={{ root: classes.paper }}>
        <Box className={classes.summaryHeader}>
          <Typography variant="h5">{t('projectStatus.summary')}</Typography>
          <IconButton aria-label="delete" onClick={onFollowButtonClick}>
            <StarBorder
              sx={{ fontSize: 40 }}
              color={isFollowed ? 'success' : 'disabled'}
            />
          </IconButton>
        </Box>
        <Box className={!!children && !role && classes.headerWithSlot}>
          <Box>
            <Typography variant="h6">{project?.location}</Typography>
            <Typography variant="h4" className={classes.projectNameLabel}>
              {project?.name}
            </Typography>
            <Typography variant="h6" sx={{ padding: '20px 0' }}>
              {dayjs(project?.createdAt).format('MM/DD/YYYY')}
            </Typography>
            <Typography variant="body1" sx={{ paddingTop: '22px' }}>
              {project?.description}
            </Typography>
          </Box>
          {!!children && !role && (
            <Box sx={{ maxWidth: '50%' }}>{children}</Box>
          )}
        </Box>
        <Box sx={{ marginTop: '30px' }}>
          <ProjectTotals
            oilGasType={project?.carbonContent?.type?.name}
            carbonType={project?.type}
            gasTotal={project?.totalGrossGasReserves}
            oilTotal={project?.totalGrossOilReserves}
          />
        </Box>
        {project?.reservesSubmission?.wells && (
          <Box sx={{ marginTop: '60px' }}>
            <ProjectWellsReport wells={project?.reservesSubmission?.wells} />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{ marginTop: '60px' }}
        >
          <Box sx={{ width: '49%' }}>
            <ProjectDetails
              project={project}
              role={role}
              onEdit={onEdit}
              onChangeRequest={onChangeRequest}
              isApproving={isApproving}
              verifierSigned={verifierSigned}
            />
          </Box>
          <Box sx={{ width: '49%' }}>
            <ProjectWellsMap wells={project?.reservesSubmission?.wells} />
          </Box>
        </Box>
        <Box sx={{ marginTop: '60px' }}>
          {uploads?.length > 0 && (
            <ProjectUploadedFiles
              uploads={uploads}
              showStatus={
                [Role.Admin, Role.ProjectOwner, Role.ProjectVerifier].indexOf(
                  role
                ) > -1
              }
              actionsSlot={
                role ? (
                  <Actions
                    role={role}
                    onEdit={onFileEdit}
                    onAccept={onFileAccept}
                    onChangeRequest={onFileChangeRequest}
                    projectStatus={project?.projectStatus}
                    verifierSigned={verifierSigned}
                  />
                ) : null
              }
            />
          )}
        </Box>
        <Box sx={{ marginTop: '60px' }}>
          {project?.ownership?.royaltyOwners?.length > 0 && (
            <ProjectRoyaltyOwners
              project={project}
              role={role}
              actionsSlot={
                role === Role.ProjectVerifier ? (
                  <Actions
                    role={role}
                    onEdit={onFileEdit}
                    onAccept={onFileAccept}
                    onChangeRequest={onFileChangeRequest}
                    projectStatus={project?.projectStatus}
                  />
                ) : null
              }
            />
          )}
        </Box>
        <Box sx={{ marginTop: '60px' }}>
          {role === Role.ProjectOwner &&
            postVerificationDocumentsAreVisible && (
              <ProjectAdditionalDocuments
                project={project}
                submitButtonEnabled={postVerificationDocumentsAreVisible}
              />
            )}
        </Box>
        {role === Role.ProjectVerifier && (
          <Box sx={{ marginTop: '40px' }}>
            <Tooltip
              title={t('projectStatus.acceptTooltip')}
              placement="top"
              disableHoverListener={isReadyForAcceptance}
              disableFocusListener={isReadyForAcceptance}
            >
              <span>
                <LoadingButton
                  variant="contained"
                  size="large"
                  className={`${classes.generalAction} ${commonClasses.button} ${commonClasses.secondaryButton}`}
                  onClick={onAccept}
                  sx={{ marginRight: '10px' }}
                  startIcon={<CheckCircle />}
                  loadingPosition="start"
                  loading={isApproving}
                  disabled={verifierActionsDisabled || !isReadyForAcceptance}
                >
                  {t('projectStatus.accept')}
                </LoadingButton>
              </span>
            </Tooltip>
            <LoadingButton
              variant="contained"
              size="large"
              className={`${classes.generalAction} ${commonClasses.button} ${commonClasses.criticalButton}`}
              onClick={onDecline}
              color="error"
              startIcon={<HighlightOff />}
              loadingPosition="start"
              loading={isDeclining}
              disabled={verifierActionsDisabled || isApproving}
            >
              {t('projectStatus.decline')}
            </LoadingButton>
          </Box>
        )}
      </Paper>
    </Box>
  );
};
