import { useCallback, useEffect, useState } from 'react';
import {
  useProjectData,
  useProjectVerifyHandler,
  useProjectChangeRequest,
  useProjectDeclineHandler,
  useFileStatusHandler,
} from '@energyweb/origin-ui-organization-data';
import { UploadStatus } from '@energyweb/origin-backend-core';
import { useConnectMetamaskPlaceHolderEffects } from '@energyweb/origin-ui-user-view';
import { useWeb3 } from '@energyweb/origin-ui-web3';
import { useTranslation } from 'react-i18next';

export const useVerifierProjectDetailsEffect = (id: number) => {
  const { project, isLoading } = useProjectData(id);
  const { submitHandler } = useProjectVerifyHandler(id);
  const { submitHandler: changeRequestHandler } = useProjectChangeRequest(id);
  const { submitHandler: declineHandler } = useProjectDeclineHandler(id);
  const { submitHandler: fileStatusHandler } = useFileStatusHandler(id);
  const { clickHandler } = useConnectMetamaskPlaceHolderEffects();
  const { t } = useTranslation();

  const { web3 } = useWeb3();
  const [isAccepting, setAccepting] = useState(false);
  const [isDeclining, setDeclining] = useState(false);
  const [file, setFile] = useState(null);

  const [acceptConfirm, setAcceptConfirm] = useState(false);
  const [acceptFileConfirm, setAcceptFileConfirm] = useState(false);
  const [declineConfirm, setDeclineConfirm] = useState(false);

  const [changeRequestDialog, setChangeRequestDialog] = useState(false);

  useEffect(() => {
    if (acceptConfirm) clickHandler();
  }, [acceptConfirm]);

  const onFileAccept = useCallback(() => {
    if (file?.id) {
      fileStatusHandler(
        {
          id: file?.id,
          status: UploadStatus.Accepted,
          comment: '',
        },
        true
      );
    }
    setAcceptFileConfirm(false);
    setFile(null);
  }, [file]);

  const toggleProjectAccept = useCallback(
    (value: boolean) => () => {
      setFile(null);
      setAcceptConfirm(value);
    },
    []
  );

  const toggleFileAccept = useCallback(
    (value: boolean) => (filePayload: any) => {
      if (filePayload?.id || filePayload?.ipfsHash) {
        setFile(filePayload);
      } else {
        setFile(null);
      }
      setAcceptFileConfirm(value);
    },
    []
  );

  const toggleProjectDecline = useCallback(
    (value: boolean) => () => {
      setDeclineConfirm(value);
    },
    []
  );

  const onProjectAccept = useCallback(() => {
    const verifier = project?.reservesValidation?.verifier;
    const projectName = project?.name;
    const verifierFullName = `${verifier?.user?.firstName} ${verifier?.user?.lastName}`;
    const verifierPublicKey = verifier?.user?.blockchainAccountAddress;
    const verifierCompany =
      project?.reservesValidation?.verifier?.company?.name;
    const verifierMessageToSign = `The independent verifier ${verifierFullName} with public key ${verifierPublicKey} from ${verifierCompany} has verified and approved the project ${projectName}`;

    setAccepting(true);
    submitHandler({ verifierSignature: verifierMessageToSign })
      .then(() => {})
      .catch(console.error)
      .finally(() => {
        setAccepting(false);
      });
    setAcceptConfirm(false);
  }, [id, web3]);

  const onProjectDecline = useCallback(() => {
    setDeclining(true);
    declineHandler().finally(() => {
      setDeclining(false);
    });
    setDeclineConfirm(false);
  }, [id]);

  const onChangeRequest = useCallback((filePayload: any = null) => {
    setChangeRequestDialog(true);
    if (filePayload?.id || filePayload?.ipfsHash) {
      setFile(filePayload);
    } else {
      setFile(null);
    }
  }, []);

  const onChangeRequestClose = useCallback(() => {
    setChangeRequestDialog(false);
  }, []);

  const onChangeRequestSubmit = useCallback(
    (message: string) => {
      if (file?.id) {
        fileStatusHandler(
          {
            id: file?.id,
            status: UploadStatus.ChangeRequest,
            comment: message,
          },
          false
        );
      }
      changeRequestHandler(message);
      onChangeRequestClose();
    },
    [changeRequestHandler, file]
  );

  return {
    project,
    isLoading,
    changeRequestDialog,
    onProjectAccept,
    onProjectDecline,
    onChangeRequest,
    onChangeRequestClose,
    onChangeRequestSubmit,
    isAccepting,
    isDeclining,
    onFileAccept,
    acceptConfirm,
    declineConfirm,
    acceptFileConfirm,
    toggleFileAccept,
    toggleProjectAccept,
    toggleProjectDecline,
  };
};
