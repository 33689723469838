import React, { FC } from 'react';
import { Global, css } from '@emotion/react';
import { variables_lightTheme } from '@energyweb/origin-ui-theme';
import { useTheme } from '@mui/material';

export const OriginGlobalStyles: FC = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css({
        body: {
          backgroundColor: variables_lightTheme.bodyBackgroundColor,
          margin: 0,
        },
      })}
    />
  );
};
