import React, { FC } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { FormSelect } from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { useStyles } from './TokensForm.styles';
import { useTokenForm } from './TokensForm.effects';
import { useCommonStyles } from '@energyweb/origin-ui-core';

export interface TokensFormProps {
  project?: ProjectDTO;
}

export const TokensForm: FC<TokensFormProps> = ({ project }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  const {
    control,
    wellsField,
    maxAmount,
    onChange,
    register,
    handleSubmit,
    openModal,
  } = useTokenForm(project);

  const onSubmit = (values: any) => {
    openModal(values);
  };

  return (
    <form className={classes.container} onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <FormSelect
          key={wellsField.label}
          field={wellsField}
          control={control}
          variant={'outlined'}
          disabled={false}
          register={register}
        />
      </Box>
      <Box className={classes.tonsContainer}>
        <TextField
          type="number"
          inputProps={{ max: maxAmount, min: 1, step: '0.1' }}
          className={`${classes.dataField} ${classes.dataFieldOffset} ${classes.tonsInput}`}
          defaultValue={0}
          label={t('projectStatus.tonsToPurchase')}
          variant="standard"
          onChange={onChange}
          fullWidth
          helperText={`Max amount ${maxAmount}`}
        />
        <Box>
          <Button
            variant="contained"
            type="submit"
            className={`${classes.submitAction} ${commonClasses.button} ${commonClasses.primaryButton}`}
          >
            {t('projectStatus.orderCredit')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
