import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

enum ActiveMenuItem {
  Device = 1,
  Certificate = 2,
  CarbonOffset = 3,
  Project = 4,
  Admin = 5,
  Account = 6,
  Verifier = 7,
}

const useGetActiveTabFromLocation = (): ActiveMenuItem => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<ActiveMenuItem>(null);

  useEffect(() => {
    const segment = location.pathname.split('/')[1].toString().toLowerCase();
    switch (segment) {
      case 'device':
        setActiveTab(ActiveMenuItem.Device);
        return;
      case 'certificate':
        setActiveTab(ActiveMenuItem.Certificate);
        return;
      case 'carbon-offset':
        setActiveTab(ActiveMenuItem.CarbonOffset);
        return;
      case 'project':
        setActiveTab(ActiveMenuItem.Project);
        return;
      case 'admin':
        setActiveTab(ActiveMenuItem.Admin);
        return;
      case 'account':
        setActiveTab(ActiveMenuItem.Account);
        return;
      case 'verifier':
        setActiveTab(ActiveMenuItem.Verifier);
        return;
    }
  }, [location]);
  return activeTab;
};

export const useActiveMenuTab = () => {
  const activeTab = useGetActiveTabFromLocation();

  const isDeviceTabActive = activeTab === ActiveMenuItem.Device;
  const isCertificateTabActive = activeTab === ActiveMenuItem.Certificate;
  const isCarbonOffsetTabActive = activeTab === ActiveMenuItem.CarbonOffset;
  const isProjectTabActive = activeTab === ActiveMenuItem.Project;
  const isAdminTabActive = activeTab === ActiveMenuItem.Admin;
  const isAccountTabActive = activeTab === ActiveMenuItem.Account;
  const isVerifierTabActive = activeTab === ActiveMenuItem.Verifier;

  return useMemo(
    () => ({
      isDeviceTabActive,
      isCertificateTabActive,
      isCarbonOffsetTabActive,
      isProjectTabActive,
      isAdminTabActive,
      isAccountTabActive,
      isVerifierTabActive,
    }),
    [activeTab]
  );
};
