import {
  ProjectStatus,
  useProjectControllerGetAll,
} from '@energyweb/origin-backend-react-query-client';

export const useAllProjectList = (query?: any) => {
  const { data, isLoading } = useProjectControllerGetAll({ ...query });
  return {
    projects: data,
    isLoading,
  };
};
