import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Documents, UploadedFile } from '@energyweb/origin-ui-core';
import { ownershipForm } from 'libs/organization/logic/src/register/ownership';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { useRiskTypesData } from '@energyweb/origin-ui-organization-data';
import { prepairRiskOptions } from 'libs/organization/logic/src/select-options';
import {
  OrganizationModalsActionsEnum,
  useOrgModalsDispatch,
} from '../../context';

export const useOwnershipEffects = (project?: ProjectDTO) => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<Documents>({});
  const { riskTypes } = useRiskTypesData();
  const dispatchModals = useOrgModalsDispatch();

  const { initialValues, validationSchema, fields, validationMode } =
    ownershipForm(t, project);
  const [_fields, setFields] = useState<any>(fields);
  const [formValues, setFormValues] = useState<any>(initialValues);

  const { register, handleSubmit, formState, control, watch, setValue } =
    useForm({
      mode: 'onChange',
      resolver: yupResolver(validationSchema),
      defaultValues: initialValues,
    });

  const { errors } = formState;
  const { riskTypeId, riskOptionId, uploads } = watch();

  const riskTypeIndex = fields.findIndex(
    (field) => field.name === 'riskTypeId'
  );
  const riskOptionIndex = fields.findIndex(
    (field) => field.name === 'riskOptionId'
  );

  const openModal = () => {
    dispatchModals({
      type: OrganizationModalsActionsEnum.SHOW_RISK_FAIL_OPTION,
      payload: true,
    });
  };

  useEffect(() => {
    const selectedOption = _fields[riskOptionIndex]?.options?.find(
      (option: any) => option.value === riskOptionId
    );
    if (selectedOption?.percent === 'Fail') {
      openModal();
    }
    setFormValues({ ...formValues, riskOptionId });
  }, [riskOptionId]);

  useEffect(() => {
    fields[riskTypeIndex].options = riskTypes?.map((riskType) => ({
      label: riskType.name,
      value: riskType.id,
    }));

    const riskOptions = prepairRiskOptions(riskTypes, riskTypeId);
    fields[riskOptionIndex].options = riskOptions;
    setFields([...fields]);
  }, [riskTypes, riskTypeId]);

  const uploadFunction = async (file: File[], dataCy: string) => {
    setDocuments({
      ...documents,
      [dataCy]: file,
    });
    return documents;
  };

  const onChange = (files: UploadedFile[]) => {
    const file = files[files?.length - 1] || ({} as UploadedFile);
    if (file?.removed) {
      delete documents[file?.dataCy];
      setDocuments({ ...documents });
    }
  };

  useEffect(() => {
    if (uploads?.length) {
      const documents = Object.assign(
        {},
        ...uploads?.map((file: any) => ({
          [file.dataCy]: [{ name: file.name } as File],
        }))
      );
      setDocuments({ ...documents });
    }
  }, [uploads]);

  const getUploadedFiles = (dataCy: string) => {
    const uploadedFiles = uploads || [];
    const file = uploadedFiles?.find((file: any) => file.dataCy === dataCy);
    return file ? [{ name: file?.name } as File] : [];
  };

  return {
    fields: _fields,
    validationMode,
    documents,
    control,
    errors,
    register,
    handleSubmit,
    uploadFunction,
    onChange,
    getUploadedFiles,
    formValues,
  };
};
