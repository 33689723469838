import React, { FC } from 'react';
import { Paper } from '@mui/material';
import { TableComponent, useCommonStyles } from '@energyweb/origin-ui-core';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { useStyles } from './RoyaltyOwnersListingPage.styles';
import { useRoyaltyOwnersListingEffects } from './RoyaltyOwnersListingPage.effects';

export interface RoyaltyOwnersListingPageProps {
  project?: ProjectDTO;
}

export const RoyaltyOwnersListingPage: FC<RoyaltyOwnersListingPageProps> = ({ project }) => {
  const { header, data, tableTitle, actions } = useRoyaltyOwnersListingEffects(project);
  const classes = useStyles();

  return (
    <Paper classes={{ root: classes.paper }}>
      <TableComponent
        header={header}
        tableTitle={tableTitle}
        data={
          data
            ? data.map((royaltyOwner, i) => {
              const formData: any = {
                id: royaltyOwner.id || i,
                name: royaltyOwner.name,
                agreement: royaltyOwner.agreement,
              };
              formData['actions'] = actions;

              return formData;
            })
            : []
        }
      />
    </Paper>
  );
};

export default RoyaltyOwnersListingPageProps;
