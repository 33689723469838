import { isRole, Role } from '@energyweb/origin-backend-core';
import {
  userControllerMe,
  LoginDataDTO,
  useAppControllerLogin,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { setAuthenticationToken } from '@energyweb/origin-ui-shared-state';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

export const useUserLogin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate } = useAppControllerLogin();

  return (values: LoginDataDTO) => {
    mutate(
      { data: values },
      {
        onSuccess: async ({ accessToken }) => {
          setAuthenticationToken(accessToken);
          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${accessToken}`;

          try {
            const user = await userControllerMe();
            isRole(user, Role.Admin)
              ? navigate('/admin')
              : navigate('/account');
            queryClient.resetQueries();
          } catch (error) {
            console.error(error);
          }
        },
        onError: (error: any) => {
          const { message } = error.response.data;
          showNotification(
            message == 'Internal server error'
              ? t('user.login.notifications.wrongCredentials')
              : t(message),
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };
};
