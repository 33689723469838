import React from 'react';
import * as yup from 'yup';
import { TCreateProjectInfoForm } from './types';
import { PROJECT_CARBON_TYPES_OPTIONS } from '../select-options/projectTypes';
import { ProjectRegistrationForm } from '@energyweb/origin-ui-organization-view';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

const ProjectRegistrationFormWrapper = (project: ProjectDTO) => (props: any) =>
  <ProjectRegistrationForm {...props} project={project} />;

export const createProjectInfoForm: TCreateProjectInfoForm = (
  t,
  project?: ProjectDTO,
  location?: string
) => {
  return {
    formTitle: t('project.register.infoTitle'),
    inputsVariant: 'outlined',
    initialValues: {
      name: project?.name || '',
      location: project?.location || location,
      description: project?.description || '',
      type: project?.type || '',
    },
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .required()
        .min(2)
        .max(150)
        .label(t('project.register.generalInformation.name')),
      location: yup
        .string()
        .required()
        .label(t('project.register.generalInformation.location')),
      description: yup
        .string()
        .min(2)
        .max(300)
        .required()
        .label(t('project.register.generalInformation.description')),
      type: yup
        .string()
        .required()
        .label(t('project.register.generalInformation.type')),
    }),
    fields: [
      {
        name: 'name',
        label: t('project.register.generalInformation.name'),
        required: true,
        inputProps: { ['data-cy']: 'name' },
        placeholder: 'Enter your project name',
        helperText: 'Your Project Name',
        tooltipLabel: t('project.register.tooltips.projectName'),
      },
      {
        name: 'location',
        label: t('project.register.generalInformation.location'),
        required: true,
        inputProps: { ['data-cy']: 'location' },
        placeholder: 'Enter location',
        helperText: 'Location Information',
        tooltipLabel: t('project.register.tooltips.projectLocation'),
        textFieldProps: {
          disabled: !!project?.location || !!location,
        },
      },
      {
        name: 'description',
        label: t('project.register.generalInformation.description'),
        required: true,
        multiline: true,
        inputProps: { ['data-cy']: 'description' },
        placeholder: 'Insert your descritption',
        helperText:
          'Project Description contains basic information that guides project implementation',
        tooltipLabel: t('project.register.tooltips.projectDescription'),
      },
      {
        name: 'type',
        label: t('project.register.generalInformation.type'),
        select: true,
        options: PROJECT_CARBON_TYPES_OPTIONS,
        required: true,
        inputProps: { ['data-cy']: 'type' },
        placeholder: 'Find your type of carbon offset project',
        helperText: 'Select an option',
        tooltipLabel: t('project.register.tooltips.typeOfCarbonCredit'),
      },
    ],
    buttonText: t('general.buttons.save'),
    customStep: true,
    component: ProjectRegistrationFormWrapper(project),
  };
};
