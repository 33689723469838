import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TextField } from '@mui/material';
import { useStyles } from './ProjectTotals.styles';
import { ReservesSubmission } from '@energyweb/origin-backend-react-query-client';

export interface ProjectTotalsProps {
  carbonType: string;
  oilGasType: string;
  oilTotal: number;
  gasTotal: number;
}

export const ProjectTotals: FC<ProjectTotalsProps> = ({
  oilTotal = 0,
  gasTotal = 0,
  carbonType,
  oilGasType,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <TextField
        className={`${classes.dataField} ${classes.dataFieldOffset}`}
        value={oilTotal.toFixed(3)}
        label={t('projectStatus.totalGrossOil')}
        variant="standard"
        disabled
        fullWidth
      />
      <TextField
        className={`${classes.dataField} ${classes.dataFieldOffset}`}
        value={gasTotal.toFixed(3)}
        label={t('projectStatus.totalGrossGas')}
        variant="standard"
        disabled
        fullWidth
      />
      <TextField
        className={`${classes.dataField} ${classes.dataFieldOffset}`}
        value={carbonType}
        label={t('projectStatus.typeOfCarbonOffset')}
        variant="standard"
        disabled
        fullWidth
      />
      <TextField
        className={classes.dataField}
        value={oilGasType}
        label={t('projectStatus.typeOfOilAndGas')}
        variant="standard"
        disabled
        fullWidth
      />
    </Box>
  );
};
