import {
  useVerifierCompany,
  useApiCreateCompanyData,
  useApiUpdateCompanyData,
} from '@energyweb/origin-ui-user-data';
import { useCompanyFormLogic } from '@energyweb/origin-ui-user-logic';
import { useParams } from 'react-router';

export const useVRCompanyFormPageEffects = () => {
  const { id } = useParams();
  const companyId = id && +id;
  const { company, isLoading } = useVerifierCompany(companyId);
  const { submitHandler } = companyId
    ? useApiUpdateCompanyData(companyId)
    : useApiCreateCompanyData();
  const formConfig = useCompanyFormLogic(company);

  const formProps = {
    ...formConfig,
    submitHandler,
  };

  return { formProps, isLoading };
};
