import { GenericFormProps } from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export type TCreateUserBankAccountFormValues = {
  name: string;
  IBAN: string;
  creditCard: string;
  debitCard: string;
};

type TUseCreateUserBankAccountFormConfig = () => Omit<
  GenericFormProps<TCreateUserBankAccountFormValues>,
  'submitHandler'
>;

const INITIAL_FORM_VALUES = {
  name: '',
  IBAN: '',
  creditCard: '',
  debitCard: '',
};

export const useCreateUserAccountDataFormConfig: TUseCreateUserBankAccountFormConfig =
  () => {
    const { t } = useTranslation();

    return {
      buttonText: t('general.buttons.save'),
      fields: [
        {
          label: t('user.account.name'),
          name: 'name',
          required: true,
          inputProps: {
            ['data-cy']: 'name',
          },
        },
        {
          label: t('user.account.iban'),
          name: 'IBAN',
          required: true,
          inputProps: {
            ['data-cy']: 'iban',
          },
        },
        {
          label: t('user.account.credit'),
          name: 'creditCard',
          inputProps: {
            ['data-cy']: 'creditCard',
          },
        },
        {
          label: t('user.account.debit'),
          name: 'debitCard',
          inputProps: {
            ['data-cy']: 'debitCard',
          },
        },
      ],
      buttonWrapperProps: { justifyContent: 'flex-start' },
      initialValues: INITIAL_FORM_VALUES,
      inputsVariant: 'filled' as any,
      validationSchema: Yup.object().shape({
        name: Yup.string().label(t('user.account.name')).required(),
        IBAN: Yup.string().label(t('user.account.iban')).required(),
      }),
    };
  };
