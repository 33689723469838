import { TableCell, Button } from '@mui/material';
import React, { PropsWithChildren, ReactElement } from 'react';
import { TableActionData } from '../../../containers';
import { useStyles } from './TableComponentActions.styles';

export interface TableComponentActionProps<Id> {
  id: Id;
  action: TableActionData<Id> | TableActionData<Id>[];
}

export type TTableComponentAction = <Id>(
  props: PropsWithChildren<TableComponentActionProps<Id>>
) => ReactElement;

export const TableComponentAction: TTableComponentAction = ({ id, action }) => {
  const classes = useStyles();
  if (!action) return <TableCell />;

  const isArray = Array.isArray(action);

  return (
    <TableCell className={classes.cell}>
      <div className={classes.wrapper}>
        {isArray ? (
          action.map((button, index) =>
            button.isShown ? (
              button.isShown(id) && (
                <Button
                  key={index}
                  className={classes.actionButton + ' ' + button.class}
                  startIcon={button?.icon}
                  onClick={(_) => button.onClick(id)}
                >
                  {button.name}
                </Button>
              )
            ) : (
              <Button
                key={index}
                className={classes.actionButton + ' ' + button.class}
                startIcon={button?.icon}
                onClick={(_) => button.onClick(id)}
              >
                {button.name}
              </Button>
            )
          )
        ) : (
          <Button
            className={classes.actionButton + ' ' + action?.class}
            startIcon={action?.icon}
            onClick={(_) => action.onClick(id)}
          >
            {action.name}
          </Button>
        )}
      </div>
    </TableCell>
  );
};
