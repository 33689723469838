import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  form: {
    background: '#FEFFFF',
    boxShadow: '0px 6px 24px rgba(25, 29, 56, 0.16)',
    padding: '30px 35px',
    position: 'relative',
    '& .MuiFormControl-root': {
      '& label': {
        color: '#2B201F',
      },
    },
    '& .MuiTypography-root': {
      fontFamily: 'Gotham!important',
    },
  },
  footer: {
    '& .MuiTypography-root': {
      fontStyle: 'normal',
      margin: '24px 0',
      fontSize: 14,
      fontWeight: 500,
    },
    '& .MuiTypography-subtitle1 ': {
      fontSize: 16,
      fontWeight: 325,
    },
  },
  verificationButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
