import { GenericFormProps } from '@energyweb/origin-ui-core';
import { RequestResetPasswordFormValues } from '@energyweb/origin-ui-user-data';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

export const useRequestResetPasswordFormLogic = (
  isMutating: boolean
): Omit<GenericFormProps<RequestResetPasswordFormValues>, 'submitHandler'> => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return {
    loading: isMutating,
    initialValues: { email: '' },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email()
        .label(t('user.requestResetPassword.email'))
        .required(),
    }),
    fields: [
      {
        name: 'email',
        label: '',
        required: true,
        inputProps: {
          placeholder: t('user.requestResetPassword.email'),
        },
      },
    ],
    inputsVariant: 'filled',
    validationMode: 'onSubmit',
    buttonFullWidth: true,
    buttonWrapperProps: { display: 'flex', justifyContent: 'space-between' },
    buttonText: t('user.requestResetPassword.submitButton'),
  };
};
