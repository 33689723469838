import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Skeleton,
  Button,
} from '@mui/material';

import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { useCommonStyles } from '@energyweb/origin-ui-core';

import { useStyles } from './ExecutingProjectsPage.styles';
import { useExecutingProjectsPageEffects } from './ExecutingProjectsPage.effects';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: number) => string;
}

const columns: Column[] = [
  {
    id: 'projectName',
    label: 'project.listing.name',
  },
  {
    id: 'owner',
    label: 'project.listing.owner',
  },
  { id: 'actions', label: 'projectStatus.uploadedFilesInThisProject.actions' },
];

export const ExecutingProjectsPage: FC = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  const { projects, pageLoading, viewProject } =
    useExecutingProjectsPageEffects();

  if (pageLoading) {
    return <Skeleton height={200} width="100%" />;
  }

  return (
    <Box style={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 400, marginBottom: 15 }}>
        <Table className={classes.uploadsTable} stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{t(column.label)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.uploadsTableBody}>
            {projects?.map((data: ProjectDTO) => (
              <TableRow key={data?.id}>
                <TableCell>{data?.name}</TableCell>
                <TableCell>{data?.createdBy?.email}</TableCell>

                <TableCell>
                  <Button
                    className={`${classes.action} ${commonClasses.circleButton} ${commonClasses.secondaryButton}`}
                    variant="contained"
                    onClick={() => viewProject(data?.id)}
                    sx={{ marginRight: '10px' }}
                  >
                    {t('general.buttons.view')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
