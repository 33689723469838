import { useProjectVerifierControllerIsProjectVerifier } from '@energyweb/origin-backend-react-query-client';

export const useVerifier = () => {
  const { data: projectVerifier, isLoading } =
    useProjectVerifierControllerIsProjectVerifier();

  return {
    projectVerifier,
    isLoading,
  };
};
