import React, { FC, useCallback } from 'react';
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
} from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router';
import { Download, DriveFileRenameOutline } from '@mui/icons-material';

import { useCommonStyles } from '@energyweb/origin-ui-core';
import { Role } from '@energyweb/origin-backend-core';
import {
  RoyaltyOwnerDTO,
  ProjectDTO,
  useFileControllerGetBaseURL,
  UploadDto,
  ProjectStatus,
} from '@energyweb/origin-backend-react-query-client';

import { useStyles } from './RoyaltyOwnersFormsLayout.styles';
import { StatusIndicator } from '../../../components';

export interface RoyaltyOwnersFormsProps {
  project?: ProjectDTO;
  royaltyOwners: RoyaltyOwnerDTO[];
  headers: any[];
  warningText?: string;
  actionsSlot?: JSX.Element;
  showStatus?: boolean;
  showName?: boolean;
  role?: Role;
}

export const RoyaltyOwnersFormsLayout: FC<RoyaltyOwnersFormsProps> = ({
  royaltyOwners,
  headers,
  warningText,
  showStatus,
  showName = true,
  actionsSlot,
  project,
  role,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const navigate = useNavigate();
  const { data: fileDownloadUrls } = useFileControllerGetBaseURL();

  const onFileDownload = useCallback(
    (file: UploadDto) => () => {
      file?.ipfsHash
        ? window.open(
            `${fileDownloadUrls?.pinataUrl}${file?.ipfsHash}?filename=${file.name}`,
            '_blank'
          )
        : window.open(`${fileDownloadUrls?.baseUrl}${file?.id}`, '_blank');
    },
    [fileDownloadUrls]
  );

  const navigateToStep = useCallback(() => {
    const id = project?.id;
    navigate(`/project/${id}/edit?step=ownership`);
  }, [project]);

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        {t('project.royaltyOwnerListing.title')}
      </Typography>
      <TableContainer>
        <Table className={classes.ownersTable}>
          <TableHead className={classes.defaultHead}>
            <TableRow>
              {headers.map((column: any) => (
                <TableCell key={column.id}>{t(column.label)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.ownersTableBody}>
            {royaltyOwners?.map((royaltyOwner: RoyaltyOwnerDTO, i: number) => {
              const file = { ...royaltyOwner?.uploads?.[0] };
              const status = file?.status || 'Pending';
              const name = file?.name || '';

              return (
                <TableRow key={i}>
                  {!showName && <TableCell>{i + 1}</TableCell>}
                  {showName && <TableCell>{royaltyOwner?.name}</TableCell>}
                  <TableCell>
                    <IconButton onClick={onFileDownload(file)} color="primary">
                      <Download />
                    </IconButton>
                    {name}
                  </TableCell>
                  {showStatus && (
                    <TableCell>
                      <StatusIndicator status={status} />
                    </TableCell>
                  )}
                  {actionsSlot && (
                    <TableCell>
                      {React.isValidElement(actionsSlot)
                        ? React.cloneElement(actionsSlot, { file } as any)
                        : actionsSlot}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {warningText && (
        <Typography className={classes.warningText}>{warningText}</Typography>
      )}
      <Box mt={3}>
        {role === Role.ProjectOwner && (
          <Button
            className={commonClasses.secondaryButton}
            size="large"
            type="button"
            onClick={() => navigateToStep()}
            startIcon={<DriveFileRenameOutline />}
            disabled={[
              ProjectStatus.Pending,
              ProjectStatus.Verified,
              ProjectStatus.Rejected,
              ProjectStatus.Issuance,
            ].includes(project?.projectStatus)}
          >
            {t('project.royaltyOwnerListing.editButton')}
          </Button>
        )}
      </Box>
    </Box>
  );
};
