import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { useFollowingProjectListingEffects } from './FollowingProjectsListingPage.effects';
import { ProjectListingFormsLayout } from '../../containers/layout/ProjectListingFormWrapper';

export const FollowingProjectsListingPage: FC = () => {
  const { t } = useTranslation();
  const {
    projects,
    header,
    actions,
    headerProps,
    isLoading,
    dataToFilter,
    filterFields,
    applyFilters,
  } = useFollowingProjectListingEffects();

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <ProjectListingFormsLayout
      projects={projects}
      title={t('project.listing.followingProjects')}
      header={header}
      actions={actions}
      headerProps={headerProps}
      dataToFilter={dataToFilter}
      filterFields={filterFields}
      applyFilters={applyFilters}
    />
  );
};

export default FollowingProjectsListingPage;
