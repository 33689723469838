import React, { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useStyles } from './ChangeRequestPopUp.styles';
import { useCommonStyles } from '@energyweb/origin-ui-core';
import { ConfirmationPopUp } from './ConfirmationPopUp';

export interface ChangeRequestPopUpProps {
  isOpen: boolean;
  onClose: any;
  onSubmit: any;
}

export const ChangeRequestPopUp: FC<ChangeRequestPopUpProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  const [confirm, setConfirm] = useState(false);
  const [comment, setComment] = useState('');

  const onDialogClose = useCallback(() => {
    onClose?.();
    setComment('');
    setConfirm(false);
  }, [onClose]);

  const onConfirmToggle = useCallback(
    (flag: boolean) => () => {
      onClose?.();
      setConfirm(flag);
    },
    [onClose]
  );

  const onCommentChange = useCallback((event) => {
    setComment(event?.target?.value);
  }, []);

  const onChangeRequestSubmit = useCallback(() => {
    onSubmit(comment);
    setComment('');
    setConfirm(false);
  }, [onSubmit, comment]);

  return (
    <>
      <ConfirmationPopUp
        isOpen={confirm}
        onClose={onDialogClose}
        onSubmit={onChangeRequestSubmit}
        message={t('projectStatus.changeRequestPopUp.confirmTitle')}
      />

      <Dialog open={isOpen} onClose={onDialogClose} maxWidth="sm" fullWidth>
        <IconButton className={classes.close} onClick={onDialogClose}>
          <Close />
        </IconButton>
        <DialogTitle className={classes.headerConteiner}>
          <Typography className={classes.header}>
            {t('projectStatus.changeRequest')}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.main}>
          <Typography className={classes.subtitle}>
            {t('projectStatus.changeRequestPopUp.description')}
          </Typography>
          <TextField
            multiline
            fullWidth
            minRows={5}
            onChange={onCommentChange}
            value={comment}
          />
        </DialogContent>
        <DialogActions className={classes.actionsContainer}>
          <Button
            variant="contained"
            onClick={onDialogClose}
            className={`${commonClasses.button} ${commonClasses.secondaryButton}`}
          >
            {t('general.buttons.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={onConfirmToggle(true)}
            className={`${commonClasses.button} ${commonClasses.primaryButton}`}
          >
            {t('general.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
