import React, { FC } from 'react';
import { CircularProgress, Grid, TextField, InputAdornment } from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useTranslation } from 'react-i18next';
import { Info } from '@mui/icons-material';
import { IconPopover, IconSize } from '@energyweb/origin-ui-core';
import { useStyles } from './UserExchangeDepositAddress.styles';
import { useUserExchangeDepositAddressEffects } from './UserExchangeDepositAddress.effects';
import { useCommonStyles } from '@energyweb/origin-ui-core';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

export const UserExchangeDepositAddress: FC = () => {
  const commonClasses = useCommonStyles();
  const { exchangeAddress, isLoading, title, popoverText } =
    useUserExchangeDepositAddressEffects();
  const classes = useStyles({
    exchangeAddressExists: Boolean(exchangeAddress),
  });
  const { t } = useTranslation();

  if (isLoading) return <CircularProgress />;

  const copyValue = () => {
    navigator.clipboard.writeText(exchangeAddress)
    showNotification('Copied', NotificationTypeEnum.Info)
  }

  return (
    <div className={classes.gridContainer}>
      {exchangeAddress ? (
        <div className={classes.fieldWrapper}>
          <TextField
            value={exchangeAddress}
            disabled
            variant="outlined"
            label={t('user.profile.walletOnZero6')}
            fullWidth
            className={`${classes.field}`}
            inputProps={{ ['data-cy']: 'exchange-deposit-address' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.copyAdornment}>
                  <ContentCopyRoundedIcon className={classes.copyIcon} onClick={copyValue}/>
                </InputAdornment>
              ),
            }}
          />
          <IconPopover
            icon={Info}
            iconSize={IconSize.Large}
            popoverText={popoverText}
            className={classes.iconPopover}
            iconProps={{ ['data-cy']: 'exchange-address-info-icon' }}
          />
        </div>
      ) : null}
    </div>
  );
};
