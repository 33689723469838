import { PropsWithChildren, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectMultipleProps } from '@energyweb/origin-ui-core';

export interface FilterFormProps {
  dataToFilter: any[];
  filterFields: string[];
  applyFilters: (arg0: any) => void;
}

export type TFiltersFormEffects = (
  props: PropsWithChildren<FilterFormProps>
) => {
  filters: SelectMultipleProps[];
  clearFilters: () => void;
  applyBtnClick: () => void;
};

interface IFilterMap {
  [index: string]: string[];
}

export const useFiltersFormEffects: TFiltersFormEffects = ({
  dataToFilter,
  filterFields,
  applyFilters,
}) => {
  const { t } = useTranslation();
  const defaultValues = filterFields.reduce(
    (o, key) => Object.assign(o, { [key]: ['ANY'] }),
    {}
  );

  const [selectedFilters, setSelectedFilters] =
    useState<IFilterMap>(defaultValues);

  const setMultiselect = (values: string[], filter: any) => {
    if (values.length === 0) values.push('ANY');
    else if (values.includes('ANY')) values.splice(values.indexOf('ANY'), 1);
    setSelectedFilters({
      ...selectedFilters,
      [filter]: values,
    });
  };

  const filters: SelectMultipleProps[] =
    dataToFilter &&
    Object.keys(selectedFilters).map((filter: string) => {
      const options = dataToFilter
        ?.map((data: any) =>
          filter !== 'verifierName'
            ? data[filter]
            : data?.reservesValidation?.verifier?.user?.firstName
        )
        .filter((value, index, array) => array.indexOf(value) === index)
        .map((option) => ({
          label: option,
          value: option,
        }));
      return {
        field: {
          name: filter,
          label: t('organization.filters.' + filter),
          placeholder: `Select ${t('organization.filters.' + filter)}`,
          options,
        },
        value: selectedFilters[filter],
        onChange: (e) => setMultiselect(e.target.value, filter),
      };
    });

  const applyBtnClick = () => {
    const getFiltersQuery = Object.keys(selectedFilters).reduce(
      (o, key) =>
        Object.assign(o, {
          [key]: selectedFilters[key].includes('ANY')
            ? ''
            : selectedFilters[key].join('|'),
        }),
      {}
    );
    console.log('Filters -> ', getFiltersQuery);
    applyFilters(getFiltersQuery);
  };

  const clearFilters = () => setSelectedFilters(defaultValues);

  return {
    filters,
    clearFilters,
    applyBtnClick,
  };
};
