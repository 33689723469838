import { useProjectControllerGetValidationProjects } from '@energyweb/origin-backend-react-query-client';

export const useValidationProjectList = (query?: any) => {
  const { data, isLoading } = useProjectControllerGetValidationProjects({
    ...query,
  });
  return {
    projects: data,
    isLoading,
  };
};
