import React, { FC, memo } from 'react';
import { AppBar, IconButton, Toolbar, Box } from '@mui/material';
import { Menu } from '@mui/icons-material';
import clsx from 'clsx';
import { useStyles } from './MobileTopBar.styles';
import { TopBarButtonData } from '../TopBar';

export interface MobileTopBarProps {
  buttons: TopBarButtonData[];
  onMobileNavOpen: () => void;
  toolbarClassName?: string;
}

export const MobileTopBar: FC<MobileTopBarProps> = memo(
  ({ buttons, onMobileNavOpen, toolbarClassName }) => {
    const classes = useStyles();
    const allowedButtons = buttons?.filter((button) => button.show);
    return (
      <AppBar>
        <Toolbar className={clsx(classes.toolbar, toolbarClassName)}>
          <IconButton onClick={onMobileNavOpen}>
            <Menu />
          </IconButton>
          <Box>
            {allowedButtons?.map((button) => {
              const { Icon, onClick, label, dataCy } = button;
              return (
                <IconButton
                  data-cy={dataCy}
                  key={`mobile-topbar-button-${label}`}
                  onClick={onClick}
                >
                  <Icon />
                </IconButton>
              );
            })}
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
);
