import React, { PropsWithChildren, ReactElement } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from './FiltersForm.styles';
import { SelectMultiple } from '../SelectMultiple';
import { useFiltersFormEffects, FilterFormProps } from './FiltersForm.effects';

type TFiltersForm = (props: PropsWithChildren<FilterFormProps>) => ReactElement;

export const FiltersForm: TFiltersForm = ({
  dataToFilter,
  filterFields,
  applyFilters,
}) => {
  const { filters, clearFilters, applyBtnClick } = useFiltersFormEffects({
    dataToFilter,
    filterFields,
    applyFilters,
  });
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {filters &&
        filters.map((filter) => (
          <SelectMultiple key={filter.field.label} {...filter} />
        ))}
      <div className={classes.buttonGroup}>
        <Button variant="outlined" className="clearBtn" onClick={clearFilters}>
          {t('organization.filters.clearAll')}
        </Button>
        <Button
          variant="contained"
          className="applyBtn"
          onClick={applyBtnClick}
        >
          {t('organization.filters.apply')}
        </Button>
      </div>
    </Box>
  );
};
