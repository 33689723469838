import {
  ProjectOwnerDTO,
  ProjectOwnerStatus,
  useProjectOwnerControllerIsProjectOwner,
} from '@energyweb/origin-backend-react-query-client';
import { GenericFormProps } from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { UsaStates } from 'usa-states';
import * as Yup from 'yup';

export type TProjectOwnerFormValues = {
  state: ProjectOwnerDTO['state'];
  license: ProjectOwnerDTO['license'];
  status: ProjectOwnerDTO['status'];
};

type TUseCreateProjectOwnerFormConfig = (
  data: ProjectOwnerDTO
) => Omit<GenericFormProps<TProjectOwnerFormValues>, 'submitHandler'>;

const usStates = new UsaStates();
const statesNames = usStates.arrayOf('names');
const STATES_OPTION = statesNames.map((state) => ({
  label: state,
  value: state,
}));

export const useProjectOwnerFormConfig: TUseCreateProjectOwnerFormConfig = (
  projectOwnerData: ProjectOwnerDTO
) => {
  const { t } = useTranslation();

  return {
    formTitle: t('user.projectOwner.formTitle'),
    buttonText: t('general.buttons.save'),
    fields: [
      {
        name: 'state',
        label: t('user.projectOwner.state'),
        select: true,
        options: STATES_OPTION,
        required: true,
        inputProps: { ['data-cy']: 'state' },
      },
      {
        label: t('user.projectOwner.license'),
        name: 'license',
        required: true,
        inputProps: {
          ['data-cy']: 'license',
        },
        placeholder: 'Enter the Operating License #',
      },
    ],
    buttonFullWidth: true,
    buttonWrapperProps: { justifyContent: 'flex-start' },
    initialValues: {
      state: projectOwnerData?.state || '',
      license: projectOwnerData?.license || '',
      status: ProjectOwnerStatus.Pending,
    },
    inputsVariant: 'outlined' as any,
    validationSchema: Yup.object().shape({
      state: Yup.string().label(t('user.projectOwner.state')).required(),
      license: Yup.string()
        .label(t('user.projectOwner.license'))
        .required()
        .max(200),
    }),
  };
};
