import React from 'react';
import { CircularProgress, Paper, Box, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  UpdateUserData,
  UpdateUserPassword,
  BlockchainAddressesContainer,
  SettingsLayout,
} from '../../containers';
import { useStyles } from './ProfilePage.styles';
import { useProfilePageEffects } from './ProfilePage.effects';

export const ProfilePage = () => {
  const classes = useStyles();
  const { user, userLoading } = useProfilePageEffects();
  const { t } = useTranslation();

  if (userLoading) return <CircularProgress />;

  return (
    <SettingsLayout>
      <Box px={2} width="100%">
        <Paper classes={{ root: classes.paper }}>
          <Typography variant="h4" sx={{ marginBottom: '50px' }}>
            {t('user.profile.profileSettings')}
          </Typography>
          <Stack spacing={10} direction="row">
            <Box sx={{ width: '50%', maxWidth: '50%' }}>
              <UpdateUserData userAccountData={user} />
            </Box>
            <Box sx={{ width: '50%', maxWidth: '50%' }}>
              <UpdateUserPassword />
            </Box>
          </Stack>
        </Paper>
        {/* <Paper classes={{ root: classes.paper }}>
        <UserBankAccount />
      </Paper>
      <BlockchainAddressesContainer /> */}
      </Box>
    </SettingsLayout>
  );
};

export default ProfilePage;
