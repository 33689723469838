import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingBottom: 30,
    marginLeft: 23,
    '& .MuiGrid-root': {
      paddingTop: 22,
      //width: 'calc(100% + 254px)',
      '& .MuiGrid-item': {
        padding: 0,
      },
    },
  },
  header: {
    color: '#2B201F',
    fontSize: 24,
    position: 'absolute',
    top: 28,
    left: 450,
    zIndex: 2,
  },
  menu: {
    position: 'fixed',
    background: '#FEFFFF',
    width: 281,
    height: '100%',
    '& .MuiSelect-select': {
      borderColor: '#78CC8A',
    },
  },
  box: {
    padding: 36,
    minHeight: 700,
    height: 'auto',
  },
  paper: {
    background: '#FEFFFF',
    marginLeft: 300,
    boxShadow: '0px 6px 24px rgba(25, 29, 56, 0.16)',
    flexBasis: ' calc(100% - 320px)',
    padding: 36,

    '&& .MuiTable-root': {
      borderBottom: 'none',
    },

    '&& .MuiTableHead-root': {
      background: '#EBF6EE',
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      fontWeight: 325,
      color: '#2B201F',
      borderBottom: 'none',
    },
    '&& .MuiTableBody-root tr': {
      backgroundColor: '#fff',
      borderBottom: '1px solid #E4E7EB',
      fontFamily: theme.typography.fontFamily,
      fontSize: 13,
      '&:hover': {
        boxShadow: '0px 7.83465px 38.3898px rgba(0, 0, 0, 0.13)',
      },
    },
    '&& .MuiTableCell-root': {
      color: '#5E5E5E',
    },
    '&& .MuiTableRow-footer': {
      display: 'none',
    },
  },

  status: {
    textTransform: 'uppercase',
    fontWeight: 325,
    fontSize: 13,
    '&:before': {
      verticalAlign: 'middle',
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23FF0000'}'/></svg>")`,
      fill: 'black',
      marginRight: 7,
    },
    '&.Pending:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23909090'}'/></svg>")`,
    },
    '&.Issuance:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23FF9800'}'/></svg>")`,
    },
    '&.Draft:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%234EE2EC'}'/></svg>")`,
    },
    '&.Rejected:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%232c2d34'}'/></svg>")`,
    },
    '&.Verified:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23DDA0DD'}'/></svg>")`,
    },
    '&.Executed:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%2378CC8A'}'/></svg>")`,
    },
  },
}));
