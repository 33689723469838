import React, { FC, ReactNode } from 'react';
import {
  EnergyWebBackground,
  SmallLogo,
  Tagline,
} from '@energyweb/origin-ui-assets';
import { useStyles } from './LoginPageLayout.styles';

export interface LoginPageLayoutProps {
  children: ReactNode;
  bgImage?: string;
  contentClass?: string;
}

export const LoginPageLayout: FC<LoginPageLayoutProps> = ({
  bgImage,
  children,
  contentClass,
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={classes.container}
        style={{ backgroundImage: `url(${bgImage || EnergyWebBackground})` }}
      >
        <div className={classes.titleContainer}>
          <img className={classes.titleIcon} src={SmallLogo} />
          <img className={classes.title} src={Tagline} />
        </div>
        <div className={`${classes.content} ${contentClass && contentClass}`}>
          {children}
        </div>
      </div>
    </>
  );
};
