import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  IconButton,
} from '@mui/material';
import { Download } from '@mui/icons-material';
import {
  UploadDto,
  useFileControllerGetBaseURL,
} from '@energyweb/origin-backend-react-query-client';
import { useStyles } from './ProjectUploadedFiles.styles';
import { StatusIndicator } from '../../../components';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: number) => string;
}

const _columns: Column[] = [
  { id: '#', label: '#' },
  {
    id: 'name',
    label: 'projectStatus.uploadedFilesInThisProject.name',
    align: 'left',
  },
  {
    id: 'projectStatus',
    label: 'projectStatus.wellsTable.status',
  },
  { id: 'actions', label: 'projectStatus.uploadedFilesInThisProject.actions' },
];

export interface ProjectUploadedFilesProps {
  uploads: UploadDto[];
  showStatus?: boolean;
  actionsSlot?: JSX.Element;
}

export const ProjectUploadedFiles: FC<ProjectUploadedFilesProps> = ({
  uploads,
  showStatus = false,
  actionsSlot = null,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [columns, setColumns] = useState(_columns);

  const { data: fileDownloadUrls } = useFileControllerGetBaseURL();

  const onFileDownload = useCallback(
    (file: UploadDto) => () => {
      file?.ipfsHash ? window.open(`${fileDownloadUrls?.pinataUrl}${file?.ipfsHash}?filename=${file.name}`, '_blank')
        : window.open(`${fileDownloadUrls?.baseUrl}${file?.id}`, '_blank');
    },
    [fileDownloadUrls]
  );

  useEffect(() => {
    setColumns(
      _columns
        .filter((e) => (showStatus ? true : e.id !== 'projectStatus'))
        .filter((e) => (actionsSlot ? true : e.id !== 'actions'))
    );
  }, [showStatus, actionsSlot]);

  return (
    <Box>
      <Typography variant="h6">
        {t('projectStatus.uploadedFilesInThisProject.title')}
      </Typography>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table className={classes.uploadsTable} stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{t(column.label)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.uploadsTableBody}>
            {uploads?.map((file: UploadDto, index) => (
              <TableRow key={file?.id || file?.ipfsHash}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={onFileDownload(file)}
                    color="primary"
                  >
                    <Download />
                  </IconButton>
                  {file.name}
                </TableCell>
                {showStatus && (
                  <TableCell>
                    <StatusIndicator status={file?.status || 'Pending'} />
                  </TableCell>
                )}
                {actionsSlot && (
                  <TableCell>
                    {React.isValidElement(actionsSlot)
                      ? React.cloneElement(actionsSlot, { file: {...file} } as any)
                      : actionsSlot}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
