import {
  ProjectVerifierDTO,
  ProjectVerifierStatus,
  getProjectVerifierControllerGetAllQueryKey,
  useProjectVerifierControllerUpdate,
} from '@energyweb/origin-backend-react-query-client';
import { useQueryClient } from 'react-query';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';

export const useUpdateProjectVerifierStatusActions = (
  projectVerifiers: ProjectVerifierDTO[]
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const projectVerifierKey = getProjectVerifierControllerGetAllQueryKey();

  const { mutate, isLoading: isMutating } =
    useProjectVerifierControllerUpdate();

  const acceptRequest = (id: ProjectVerifierDTO['id']) => {
    const projectVerifier = projectVerifiers?.find((owner) => owner.id === id);
    return mutate(
      {
        id,
        data: {
          ...projectVerifier,
          companyId: projectVerifier.company?.id,
          status: ProjectVerifierStatus.Accepted,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(projectVerifierKey);
          showNotification(
            t('user.projectVerifier.notifications.acceptedSuccess'),
            NotificationTypeEnum.Success
          );
        },
        onError: (error: any) => {
          showNotification(
            error?.response?.data?.message,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  const rejectRequest = (id: ProjectVerifierDTO['id']) => {
    const projectVerifier = projectVerifiers?.find((owner) => owner.id === id);
    return mutate(
      {
        id,
        data: {
          ...projectVerifier,
          companyId: projectVerifier.company?.id,
          status: ProjectVerifierStatus.Rejected,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(projectVerifierKey);
          showNotification(
            t('user.projectVerifier.notifications.rejectedSuccess'),
            NotificationTypeEnum.Success
          );
        },
        onError: (error: any) => {
          showNotification(
            error?.response?.data?.message,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return { acceptRequest, rejectRequest, isMutating };
};
