import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  wellTable: {
    borderCollapse: 'collapse',
    borderBottom: 'none',
    '&& .MuiTableCell-root': {
      color: theme.form.fontColors.secondary,
    },
  },
  defaultHead: {
    '&& th': {
      padding: 0,
      top: '0 !important',
    },
  },
  wellTablePrimaryHead: {},
  wellTableSecondaryHead: {
    '&& th': {
      fontWeight: 300,
      fontFamily: theme.typography.fontFamily,
    },
  },
  wellTableBody: {
    '&& .MuiTableRow-root': {
      borderTop: `1px solid ${theme.palette.primary.light}`,
    },
    '&& .MuiTableRow-root:last-child': {
      borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
    '&& .MuiTableRow-root:nth-of-type(1n)': {
      backgroundColor: '#F8FDFE',
    },
    '&& .MuiTableRow-root:nth-of-type(2n)': {
      backgroundColor: '#F8FDFE30',
    },
  },
}));
