import React from 'react';
import * as yup from 'yup';
import { TRegisterCarbonForm } from './types';
import { CarbonContentForm } from '@energyweb/origin-ui-organization-view';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

const CarbonContentFormWrapper = (project: ProjectDTO) => (props: any) =>
  <CarbonContentForm {...props} project={project} />;

export const registerCarbonForm: TRegisterCarbonForm = (
  t,
  projectTypes?: any,
  project?: ProjectDTO
) => {
  const currentStepData = project?.carbonContent;
  const PROJECT_TYPE_OPTIONS =
    projectTypes?.map((type: any) => ({ label: type.name, value: type.id })) ||
    [];

  return {
    formTitle: t('project.register.carbonContentTitle'),
    inputsVariant: 'outlined',
    initialValues: {
      typeId: currentStepData?.type?.id || null,
      gasHeatingValue: currentStepData?.gasHeatingValue || null,
      oilApiGravity: currentStepData?.oilApiGravity || null,
      ethane: currentStepData?.ethane || null,
      propane: currentStepData?.propane || null,
      isoButane: currentStepData?.isoButane || null,
      nButane: currentStepData?.nButane || null,
      isoPentane: currentStepData?.isoPentane || null,
      nPentane: currentStepData?.nPentane || null,
      hexanes: currentStepData?.hexanes || null,
      gasShrinkage: currentStepData?.gasShrinkage || null,
      uploads: currentStepData?.uploads || [],
      projectId: project?.id,
    },
    validationSchema: yup.object().shape({
      typeId: yup
        .number()
        .required()
        .label(t('project.register.carbonContent.type')),
      gasHeatingValue: yup
        .number()
        .min(0)
        .max(9999)
        .required()
        .label(t('project.register.carbonContent.gasHeatingValue')),
      oilApiGravity: yup
        .number()
        .min(0)
        .max(99)
        .required()
        .label(t('project.register.carbonContent.oilApiGravity')),
      gasShrinkage: yup
        .number()
        .min(0)
        .max(100)
        .required()
        .label(t('project.register.carbonContent.gasShrinkage')),
      ethane: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.ethane')),
      propane: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.propane')),
      isoButane: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.isoButane')),
      nButane: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.nButane')),
      isoPentane: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.isoPentane')),
      nPentane: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.nPentane')),
      hexanes: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.hexanes')),
    }),
    fields: [
      {
        name: 'typeId',
        label: t('project.register.carbonContent.type'),
        select: true,
        options: PROJECT_TYPE_OPTIONS,
        required: true,
        inputProps: { ['data-cy']: 'typeId' },
        helperText: 'Select an option',
        tooltipLabel: t('project.register.tooltips.typeId'),
      },
      {
        name: 'oilApiGravity',
        label: t('project.register.carbonContent.oilApiGravity'),
        required: true,
        inputProps: { ['data-cy']: 'oilApiGravity' },
        startAdornment: 'Oil API Gravity',
        placeholder: '0',
        helperText: 'Float number between 0 and 99',
        tooltipLabel: t('project.register.tooltips.oilApiGravity'),
      },
      {
        name: 'gasHeatingValue',
        label: t('project.register.carbonContent.gasHeatingValue'),
        required: true,
        inputProps: { ['data-cy']: 'gasHeatingValue' },
        startAdornment: 'Gas Heating Value',
        placeholder: '0',
        helperText: 'Integer value between 0 and 9999',
        tooltipLabel: t('project.register.tooltips.gasHeatingValue'),
        endAdornment: { isValidCheck: false, element: 'MMBtu/Mscf' },
      },
      {
        name: 'ethane',
        label: t('project.register.carbonContent.ethane'),
        required: false,
        startAdornment: 'Ethane',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'ethane' },
        placeholder: '0',
        tooltipLabel: t('project.register.tooltips.ethane'),
      },
      {
        name: 'propane',
        label: t('project.register.carbonContent.propane'),
        required: false,
        startAdornment: 'Propane',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'propane' },
        placeholder: '0',
        tooltipLabel: t('project.register.tooltips.propane'),
      },
      {
        name: 'isoButane',
        label: t('project.register.carbonContent.isoButane'),
        required: false,
        startAdornment: 'Iso-Butane',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'isoButane' },
        placeholder: '0',
        tooltipLabel: t('project.register.tooltips.isoButane'),
      },
      {
        name: 'nButane',
        label: t('project.register.carbonContent.nButane'),
        required: false,
        startAdornment: 'N-Butane',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'nButane' },
        placeholder: '0',
        tooltipLabel: t('project.register.tooltips.nButane'),
      },
      {
        name: 'isoPentane',
        label: t('project.register.carbonContent.isoPentane'),
        required: false,
        startAdornment: 'Iso-Pentane',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'isoPentane' },
        placeholder: '0',
        tooltipLabel: t('project.register.tooltips.isoPentane'),
      },
      {
        name: 'nPentane',
        label: t('project.register.carbonContent.nPentane'),
        required: false,
        startAdornment: 'N-Pentane',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'nPentane' },
        placeholder: '0',
        tooltipLabel: t('project.register.tooltips.nPentane'),
      },
      {
        name: 'hexanes',
        label: t('project.register.carbonContent.hexanes'),
        required: false,
        startAdornment: 'Hexanes+',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'hexanes' },
        placeholder: '0',
        tooltipLabel: t('project.register.tooltips.hexanes'),
      },
      {
        name: 'gasShrinkage',
        label: t('project.register.carbonContent.gasShrinkage'),
        required: true,
        startAdornment: 'Gas Shrinkage in Operations',
        inputProps: { ['data-cy']: 'gasShrinkage' },
        placeholder: '0',
        helperText: 'Percentage (0-100%)',
        endAdornment: { isValidCheck: false, element: '%' },
        tooltipLabel: t('project.register.tooltips.gasShrinkage'),
      },
    ],
    buttonText: t('general.buttons.save'),
    validationMode: 'onChange',
    customStep: true,
    component: CarbonContentFormWrapper(project),
  };
};
