import React, {
  PropsWithChildren,
  ReactElement,
  useState,
  useEffect,
} from 'react';
import { Player, PlayerDirection } from '@lottiefiles/react-lottie-player';

import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
} from '@energyweb/origin-ui-assets';

export interface SettingsIconProps<FormValuesMerged> {
  step: number;
  prevStep?: number;
}

export type TSettingsIcon = <FormValuesMerged>(
  props: PropsWithChildren<SettingsIconProps<FormValuesMerged>>
) => ReactElement;

export const SettingsIcon: TSettingsIcon = ({ step, prevStep = 0 }) => {
  const steps = [Step1, Step2, Step3, Step4, Step5, Step6, Step7];

  const [direction, setDirection] = useState<PlayerDirection>(-1);
  const [src, setSrc] = useState(steps[0]);

  useEffect(() => {
    if (prevStep > step) {
      setDirection(-1);
    } else {
      setDirection(1);
    }
    setSrc(steps[step]);
  }, [step, prevStep]);

  return (
    <Player
      autoplay
      keepLastFrame
      direction={direction}
      src={src}
      style={{ height: '100px', width: '89px' }}
    />
  );
};

export default SettingsIcon;
