import { useCallback, useState } from 'react';
import { useUser } from '@energyweb/origin-ui-exchange-data';
import { useUpdateUserAccountPasswordFormConfig } from '@energyweb/origin-ui-user-logic';
import {
  TUpdateUserPasswordFormValues,
  useApiUpdateUserAccountPassword,
} from '@energyweb/origin-ui-user-data';
import { useRequestPhoneVerification } from '@energyweb/origin-ui-user-data';
import { UserModalsActionsEnum, useUserModalsDispatch } from '../../../context';

export const useUpdateUserPasswordEffects = () => {
  const { user } = useUser();
  const dispatchModals = useUserModalsDispatch();
  const { requestHandler: requestVerificationCode } =
    useRequestPhoneVerification();

  const { submitHandler } = useApiUpdateUserAccountPassword();
  const formConfig = useUpdateUserAccountPasswordFormConfig();

  const resetPasswordHandler = useCallback(
    (passwordValues: TUpdateUserPasswordFormValues) => (values: any) => {
      return new Promise((resolve) => {
        submitHandler?.({
          ...passwordValues,
          verificationCode: values?.code,
        })
          .then(() => {
            dispatchModals?.({
              type: UserModalsActionsEnum.SHOW_PASSWORD_CHANGE_OTP,
              payload: {
                open: false,
                phoneNumber: null,
                onSubmit: null,
              },
            });
            resolve(true);
          })
          .catch((error) => resolve(error));
      });
    },
    [dispatchModals]
  );

  const formHandler = useCallback(
    (values: TUpdateUserPasswordFormValues) => {
      requestVerificationCode?.({ phone: user?.telephone }).then(() => {
        dispatchModals?.({
          type: UserModalsActionsEnum.SHOW_PASSWORD_CHANGE_OTP,
          payload: {
            open: true,
            phoneNumber: user?.telephone,
            onSubmit: resetPasswordHandler(values),
          },
        });
      });
    },
    [dispatchModals, user]
  );

  const formProps = {
    ...formConfig,
    submitHandler: formHandler,
  };

  return { formProps };
};
