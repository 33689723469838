import { GenericModalProps } from '@energyweb/origin-ui-core';
import { useDeleteProjectLogic } from '@energyweb/origin-ui-organization-logic';
import { useDeleteProject } from '@energyweb/origin-ui-organization-data';
import {
  OrganizationModalsActionsEnum,
  useOrgModalsDispatch,
  useOrgModalsStore,
} from '../../../context';

export const useDeleteProjectEffects = () => {
  const { deleteProjectOption } = useOrgModalsStore();
  const { open, projectId, additionalAction } = deleteProjectOption;
  const { submitHandler } = useDeleteProject();
  const dispatchModals = useOrgModalsDispatch();

  const closeModal = () => {
    dispatchModals({
      type: OrganizationModalsActionsEnum.SHOW_DELETE_PROJECT_OPTION,
      payload: {
        open: false,
        projectId: null,
        additionalAction: () => {
          return;
        },
      },
    });
  };

  const cancelClick = () => {
    submitHandler(projectId);
    additionalAction();
    closeModal();
  };

  const { text, buttons } = useDeleteProjectLogic(cancelClick, closeModal);

  const dialogProps: GenericModalProps['dialogProps'] = {
    maxWidth: 'sm',
  };

  return { open, text, buttons, dialogProps };
};
