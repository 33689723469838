import {
  getProjectControllerGetQueryKey,
  useProjectControllerUpdateFileStatus,
  UpdateFileStatusDTO,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useFileStatusHandler = (id: number) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useProjectControllerUpdateFileStatus();
  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(id);

  const submitHandler = (
    data: UpdateFileStatusDTO,
    showNotifications?: boolean
  ) => {
    return new Promise((res, rej) =>
      mutate(
        { id, data },
        {
          onSuccess: () => {
            showNotifications &&
              showNotification(
                t('projectStatus.notifications.projectFileVerificationSuccess'),
                NotificationTypeEnum.Success
              );
            queryClient.invalidateQueries(projectQueryKey);
            res(true);
          },
          onError: (error: any) => {
            if (error?.response?.status === 400) {
              showNotification(
                error?.response?.data?.message,
                NotificationTypeEnum.Error
              );
            } else {
              showNotification(
                `${t(
                  'projectStatus.notifications.projectFileVerificationError'
                )}:
                  ${error?.response?.data?.message || ''}
                  `,
                NotificationTypeEnum.Error
              );
            }
          },
        }
      )
    );
  };

  return { submitHandler, isLoading };
};
