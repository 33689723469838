import { useProjectTypeControllerGetAll } from '@energyweb/issuer-irec-api-react-query-client';

export const useProjectTypesData = () => {
  const { data, isLoading } = useProjectTypeControllerGetAll();

  return {
    projectTypes: data,
    isLoading,
  };
};
