import {
  getUserControllerMeQueryKey,
  UserDTO,
  UserStatus,
  useUserControllerUpdateOwnPassword,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useUser } from '../fetching';

export type TUpdateUserPasswordFormValues = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm?: string;
};

export const useApiUpdateUserAccountPassword = () => {
  const { t } = useTranslation();
  const { logout } = useUser();
  const queryClient = useQueryClient();
  const userQueryKey = getUserControllerMeQueryKey();

  const { mutate } = useUserControllerUpdateOwnPassword();

  const submitHandler = (
    values: TUpdateUserPasswordFormValues & { verificationCode: string }
  ): Promise<void> => {
    const user: UserDTO = queryClient.getQueryData(userQueryKey);
    const { status } = user;
    if (status !== UserStatus.Active) {
      showNotification(
        t('user.profile.notifications.onlyActiveUserCan', {
          status: user.status,
        }),
        NotificationTypeEnum.Error
      );
      return;
    }
    return new Promise((res, rej) =>
      mutate(
        { data: values },
        {
          onSuccess: () => {
            showNotification(
              t('user.profile.notifications.userPasswordUpdateSuccess'),
              NotificationTypeEnum.Success
            );
            res();
            logout();
          },
          onError: (error: any) => {
            const { message, errors } = error?.response?.data;
            showNotification(
              `${t('user.profile.notifications.userPasswordUpdateError')}:
            ${message || errors || ''}
            `,
              NotificationTypeEnum.Error
            );
            rej(error);
          },
        }
      )
    );
  };

  return {
    submitHandler,
  };
};
