import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '30px',
    marginTop: '10px',
  },
  action: {
    padding: '10px 40px',
  },
  actionSecondary: {
    padding: '10px 40px',
    '&& .Mui-disabled': {
      backgroundColor: '#F58025',
    },
  },
  actionsContainer: {
    marginRight: '25px',
  },
  form: {
    borderBottom: '1px solid #AAB0F3',
    '& .MuiOutlinedInput-root': {
      background: '#F8FDFE',
      color: theme.palette.text.primary,
      '& fieldset': {
        borderRadius: 0,
        borderColor: '#AAB0F3',
        borderBottom: 'none',
      },
      '& .Mui-focused fieldset': {
        borderColor: '#AAB0F3',
      },
      '& .Mui-disabled fieldset': {
        borderColor: '#AAB0F3',
      },
      '& .MuiInputAdornment-positionStart ': {
        maxHeight: '100%',
        height: 'min-content',
        minHeight: 40,
        padding: '5px 8px 5px 0',
        borderRight: '1px solid #AAB0F3',
        marginRight: 0,
      },
      '& .MuiInputAdornment-positionStart': {
        width: '100%',
        '& p': {
          color: theme.palette.text.primary,
          whiteSpace: 'break-spaces',
        },
      },
      '& .MuiSelect-select': {
        background: '#FFF',
      },
    },
    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
    },
    '& input, select': {
      border: '1px solid #AAB0F3',
      borderRadius: 0,
      padding: 15,
      color: theme.palette.text.primary,
    },

    '& .MuiTextField-root': {
      width: '100%',
      marginTop: 0,
      marginBottom: 0,
    },
    '& .MuiInputBase-inputAdornedStart': {
      background: '#FFFFFF',
      textAlign: 'right',
      border: 'none',
      // borderLeft: '1px solid #AAB0F3',
      width: '20%',
      color: theme.palette.text.primary,
      ['-webkit-text-fill-color']: theme.palette.text.primary,
    },
    '& .MuiInputAdornment-positionEnd': {
      background: '#FFFFFF',
      minWidth: '10px',
      color: theme.palette.text.primary,
    },
    '& fieldset': {
      borderColor: '#AAB0F3 !important',
    },
  },
}));
