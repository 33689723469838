import { makeStyles } from '@mui/styles';
import { MenuBackground } from '@energyweb/origin-ui-assets';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    border: '1px solid #78CC8A',
    borderRadius: 4,
    padding: '2px 12px',
    marginRight: 28,
  },
  userInfo: {
    textAlign: 'left',
    marginRight: 15,
    '& .status': {
      color: '#78CC8A',
      fontSize: 12,
      fontWeight: 500,
    },
  },
  userAvatar: {
    width: 27,
    height: 27,
    backgroundColor: '#78CC8A',
    fontSize: 19,
    fontFamily: theme.typography.fontFamily,
  },
  menuDefault: {
    fontFamily: 'Barlow',
    '& .MuiPaper-root': {
      boxShadow: '0px 6px 24px rgba(25, 29, 56, 0.16)',
      borderRadius: 10,
      top: '70px !important',
      width: 320,
    },
    '& .MuiMenu-list': {
      background: '#FEFFFF',
      padding: '0',
    },
    '& .MuiMenuItem-root': {
      padding: '17px 30px',
      fontWeight: 300,
      fontStyle: 'normal',
      fontSize: 18,
      borderRadius: 14,
      marginTop: 5,
    },
  },
  menuInfo: {
    color: '#57625C',
    fontSize: 18,
    '&.address': {
      width: 190,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  userBlock: {
    backgroundImage: `url(${MenuBackground})`,
    backgroundRepeat: 'no-repeat',
    height: 129,
    borderBottomLeftRadius: '80px 20px',
    paddingTop: 10,
    position: 'relative',

    '& .username': {
      color: '#2B201F',
      textAlign: 'center',
      fontSize: 18,
      marginTop: 15,
    },
    '& .email': {
      color: '#2B201F',
      textAlign: 'center',
      fontSize: 16,
      marginTop: 4,
    },
  },

  menuBlock: {
    padding: '20px 35px 40px',
  },

  menuIcon: {
    marginRight: 15,
    fill: '#57625C',
  },
  addressField: {
    width: 180,
    padding: '0 30px',
    '& label': {
      color: '#57625C !important',
      fontSize: 14,
      paddingLeft: 38,
    },
    '& .MuiInput-input': {
      width: '100%',
    },
    '& .MuiInput-root': {
      color: 'none',
      '&:before': {
        borderBottom: 'none',
      },
    },
    '& input': {
      color: '#57625C',
      fontSize: 16,
      width: 100,
      '-webkit-text-fill-color': '#57625C !important',
    },
  },
  copyAdornment: {
    height: 'min-content',
    padding: 4,
    borderRadius: '50%',
    '&:hover': {
      background: '#f2f2f2',
    },
  },
  copyIcon: {
    fill: '#57625C',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  notificationBadge: {
    width: 20,
    height: 20,
    fontSize: 14,
    color: '#FFFFFF',
    background: '#FF5756',
    borderRadius: 200,
    textAlign: 'center',
    marginLeft: 20,
  },
  signOutBtn: {
    background: '#ECF6EE',
    borderRadius: 14,
    color: '#78CC8A',
  },
  signOutIcon: {
    marginRight: 15,
    fill: '#78CC8A',
  },
}));
