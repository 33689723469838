import React, { FC, memo } from 'react';

import { GenericForm } from '@energyweb/origin-ui-core';
import { useUpdateUserDataEffects } from './UpdateUserData.effects';
import { UserDTO } from '@energyweb/origin-backend-react-query-client';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCommonStyles } from '@energyweb/origin-ui-core';
import { UserResendConfirmationEmail } from '../UserResendConfirmationEmail';

export interface UpdateUserDataProps {
  userAccountData: UserDTO;
}

export const UpdateUserData: FC<UpdateUserDataProps> = memo(
  ({ userAccountData }) => {
    const { t } = useTranslation();
    const { formProps } = useUpdateUserDataEffects(userAccountData);
    const commonClasses = useCommonStyles();
    return (
      <>
        <Typography variant="h5" gutterBottom>
          {t('user.profile.profile')}
        </Typography>
        {userAccountData.email && !userAccountData.emailConfirmed && (
          <UserResendConfirmationEmail />
        )}
        <GenericForm
          {...formProps}
          buttonProps={{
            className: `${commonClasses.button} ${commonClasses.secondaryButton}`,
            variant: 'contained',
            ['data-cy']: 'submitButton',
          }}
        />
      </>
    );
  }
);
