import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    '& .MuiFilledInput-root': {
      backgroundColor: '#E1DBD84D !important',
      opacity: 30,
      borderRadius: '4px',
      border: '1px solid transparent',
      '&:before, &:hover, &:after, &:hover:not(.Mui-disabled):before': {
        border: '1px solid transparent',
      },
      '&.Mui-focused': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '& .MuiFilledInput-input': {
        borderRadius: '4px',
        opacity: 20,
      },
    },
    '& .MuiFilledInput-input': {
      paddingTop: '15px',
      paddingBottom: '15px',
      boxShadow: '#918C89 0px 0px 0px 100px inset',
      '-webkit-text-fill-color': 'rgb(255, 255, 255)',
    },
  },
  formSubTitle: {
    marginBottom: '50px',
    textAlign: 'center',
  },
  bottomActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiButton-root': {
      '&:hover': {
        background: 'transparent',
        textDecoration: 'underline',
      },
      paddingLeft: '10px',
      color: 'white',
      fontWeight: 'bold',
      minWidth: 0,
      textTransform: 'none',
      fontSize: '0.8rem',
    },
  },
}));

export { useStyles };
