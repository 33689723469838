import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    background: '#FEFFFF',
    boxShadow: 'none',
  },
  form: {
    margin: '24px 0',
    '& .MuiFormControl-root': {
      '& label': {
        color: '#2B201F',
      },
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#AAB0F3',
      },
      '& .Mui-focused fieldset': {
        borderColor: '#AAB0F3',
      },
    },
  },
  dropzone: {
    lineHeight: '18px',
  },
  actionButton: {
    padding: '12px 24px',
  },
}));
