import { GenericModalProps } from '@energyweb/origin-ui-core';
import { useProjectVerifiedSuccessfulLogic } from '@energyweb/origin-ui-organization-logic';

import {
  UserModalsActionsEnum,
  useUserModalsDispatch,
  useUserModalsStore,
} from '../../../context';
import { t } from 'i18next';

export const useStartTokenIssuanceEffects = () => {
  const { tokenIssuanceStarted: open } = useUserModalsStore();
  const dispatchModals = useUserModalsDispatch();

  const closeModal = () => {
    dispatchModals({
      type: UserModalsActionsEnum.SHOW_TOKEN_ISSUANCE_STARTED,
      payload: false,
    });
  };

  const { buttons } = useProjectVerifiedSuccessfulLogic(closeModal);

  const dialogProps: GenericModalProps['dialogProps'] = {
    maxWidth: 'sm',
  };

  return {
    text: t('organization.modals.projectVerified.platformAdmin'),
    open,
    buttons,
    dialogProps,
  };
};
