import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
} from '@mui/material';
import { ProjectWell } from '@energyweb/origin-backend-react-query-client';
import { useStyles } from './ProjectWellsReport.styles';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: ProjectWell) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'projectStatus.wellsTable.wellCount' },
  { id: 'wellName', label: 'projectStatus.wellsTable.wellName', align: 'left' },
  {
    id: 'totalCarbonCredits',
    label: 'projectStatus.wellsTable.totalCarbonCredits',
    format: (value) => `${(value?.totalCarbonOffset).toFixed(3)}`,
  },
  {
    id: 'totalCost',
    label: 'projectStatus.wellsTable.totalCost',
    format: (value) => `${(value?.totalCarbonOffset * 25).toFixed(3)}`,
  },
  {
    id: 'netReserves',
    label: 'projectStatus.wellsTable.netCarbonCredits',
    format: (value) => `${(value?.netCarbonOffset).toFixed(3)}`,
  },
  {
    id: 'netRevenue',
    label: 'projectStatus.wellsTable.projectNetCashflow',
    format: (value) => `${(value?.netCarbonOffset * 25).toFixed(3)}`,
  },
];

export interface ProjectWellsReportProps {
  wells: ProjectWell[];
}

export const ProjectWellsReport: FC<ProjectWellsReportProps> = ({ wells }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer sx={{ maxHeight: 500 }}>
      <Table className={classes.wellTable} stickyHeader>
        <TableHead>
          <TableRow
            className={`${classes.wellTablePrimaryHead} ${classes.defaultHead}`}
          >
            <TableCell align="left" colSpan={2}>
              {t('projectStatus.wellsTable.wellsSummaryReport')}
            </TableCell>
            <TableCell align="left" colSpan={2}>
              {t('projectStatus.wellsTable.projectTotals')}
            </TableCell>
            <TableCell align="left" colSpan={2}>
              {t('projectStatus.wellsTable.projectOwnerNet')}
            </TableCell>
          </TableRow>
          <TableRow
            className={`${classes.wellTableSecondaryHead} ${classes.defaultHead}`}
          >
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ top: 57, minWidth: column.minWidth }}
              >
                {t(column.label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.wellTableBody}>
          {wells?.map((row, index) => (
            <TableRow key={row?.id}>
              {columns?.map((column) => (
                <TableCell key={column?.id} align={column?.align}>
                  {column?.id === 'id'
                    ? index + 1
                    : column?.format
                    ? column?.format(row)
                    : row[column?.id as keyof ProjectWell]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
