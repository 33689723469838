import {
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect
} from 'react';
import { useVerifyPhoneFormLogic } from '@energyweb/origin-ui-user-logic';
import { GenericFormProps } from '@energyweb/origin-ui-core';
import {
  VerifyPhoneFormValues,
  useVerifyPhoneHandler,
  useRequestPhoneVerification,
} from '@energyweb/origin-ui-user-data';
import { useUser } from '@energyweb/origin-ui-user-data';

const RESEND_TIMEOUT = 30;

export const usePhoneVerificationPageEffects = () => {
  const [left, setLeft] = useState(RESEND_TIMEOUT);
  const timer = useRef<any>();

  const { user, logout } = useUser();
  const { requestHandler, isMutating: isRequesting } =
    useRequestPhoneVerification();
  const { verifyHandler, isMutating } = useVerifyPhoneHandler();

  const requestVerificationCode = useCallback(() => {
    requestHandler({ phone: user.telephone });
    setLeft(RESEND_TIMEOUT);
  }, [user]);

  const verifyCodeHandler = useCallback(
    (values) => {
      verifyHandler({
        ...values,
        phone: user.telephone,
        redirectPage: true,
        showNotifications: true,
      });
    },
    [user]
  );

  const formLogic = useVerifyPhoneFormLogic({
    isMutating,
    isRequesting,
  });

  const formProps: GenericFormProps<VerifyPhoneFormValues> = useMemo(
    () => ({ ...formLogic, submitHandler: verifyCodeHandler }),
    [formLogic, verifyCodeHandler]
  );

  const navigateToLogin = () => {
    logout('/login');
  };

  useEffect(() => {
    if (left > 0) {
      timer.current = setTimeout(() => setLeft(prev => prev - 1), 1000);
    } else {
      clearTimeout(timer.current);
    }
    return () => clearTimeout(timer.current);
  }, [left]);

  return {
    formProps,
    navigateToLogin,
    phone: user.telephone,
    isRequesting,
    requestVerificationCode,
    left
  };
};
