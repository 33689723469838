import React, { FC } from 'react';
import { useParams } from 'react-router';
import { CircularProgress, Box, Button } from '@mui/material';
import { useProjectPublicEffect } from './ProjectPublicPage.effects';
import { ProjectStatusLayout } from '../../containers/layout';
import { TokensForm } from './components/TokensForm/TokensForm';

export const ProjectPublicPage: FC = () => {
  const { id } = useParams();

  const { isLoading, project, formDisabled } = useProjectPublicEffect(
    id && +id
  );

  if (isLoading) return <CircularProgress />;

  return (
    <ProjectStatusLayout project={project}>
      {!formDisabled && <TokensForm project={project} />}
    </ProjectStatusLayout>
  );
};
