import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Box, Paper, CircularProgress } from '@mui/material';
import { GenericForm } from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { ProjectOwner } from '@energyweb/origin-ui-assets';
import { ProjectOwnerStatus } from '@energyweb/origin-backend-react-query-client';
import { useStyles } from './RegisterProjectOwnerPage.styles';
import {
  useCreateProjectOwnerPageEffects,
  FORM_SUBMIT_STATE,
} from './RegisterProjectOwnerPage.effects';
import { RegistrationFormsLayout } from '../../containers';
import { RegisterErrorMessage } from '../../common/components';

export const RegisterProjectOwnerPage: FC = () => {
  const navigate = useNavigate();

  const classes: any = useStyles();
  const { t } = useTranslation();
  const {
    formProps,
    nextDisabled,
    formSubmitButtonText,
    buttonColor,
    showErrorMessage,
    projectOwnerData,
    isLoading,
  } = useCreateProjectOwnerPageEffects();

  useEffect(() => {
    if (
      !isLoading &&
      projectOwnerData?.status === ProjectOwnerStatus.Accepted
    ) {
      navigate('/project/register', { replace: true });
    }
  }, [projectOwnerData, isLoading]);

  return (
    <Box flex={1}>
      <RegistrationFormsLayout
        title={t('user.projectOwner.mainFormTitle')}
        subTitle={t('user.projectOwner.mainFormSubtitle')}
        descriptionTitle={t('user.projectOwner.descriptionTitle')}
        description={t('user.projectOwner.description')}
        icon={ProjectOwner}
        nextButtonText={t('user.projectOwner.submitNewProject')}
        nextButtonDisabled={nextDisabled}
        error={showErrorMessage && <RegisterErrorMessage specUrl={'#'} />}
      >
        <Paper className={classes.paper}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <GenericForm
              {...formProps}
              formTitleClassName={classes.formTitle}
              formClass={classes.formContainer}
              buttonText={t(formSubmitButtonText)}
              buttonProps={{
                disabled:
                  projectOwnerData?.status === 'Pending' ||
                  buttonColor === FORM_SUBMIT_STATE.pending,
                variant: 'contained',
                'data-cy': 'project-owner-submit',
                className: classes[buttonColor],
              }}
              initialValues={{
                license: projectOwnerData?.license || '',
                state: projectOwnerData?.state || '',
                status: ProjectOwnerStatus.Pending,
              }}
              formDisabled={
                projectOwnerData?.status === 'Pending' ||
                buttonColor === FORM_SUBMIT_STATE.pending
              }
            />
          )}
        </Paper>
      </RegistrationFormsLayout>
    </Box>
  );
};
