import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  form: {
    background: '#FEFFFF',
    boxShadow: '0px 6px 24px rgba(25, 29, 56, 0.16)',
    padding: '30px 35px',
    '& label.Mui-focused': {
      color: '#5E5E5E',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#AAB0F3',
    },
    '& .MuiFormControl-root': {
      '& label': {
        color: '#2B201F',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#AAB0F3',
      },
      '&:hover fieldset': {
        borderColor: '#AAB0F3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#AAB0F3',
      },
    },
  },
  title: {
    color: '#A1BFA8',
    fontSize: '16px',
    margin: '15px 0',
    fontFamily: theme.typography.fontFamily,
  },
}));
