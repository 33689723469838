import {
  useVRCompanyControllerUpdate,
  CreateCompanyDTO,
  CompanyDTO,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const useApiUpdateCompanyData = (id: CompanyDTO['id']) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate, isLoading } = useVRCompanyControllerUpdate();

  const submitHandler = (values: CreateCompanyDTO) => {
    mutate(
      {
        id,
        data: { ...values },
      },
      {
        onSuccess: () => {
          showNotification(
            t(`user.vrCompanies.notifications.updatingSuccess`),
            NotificationTypeEnum.Success
          );
          navigate('/admin/companies');
        },
        onError: (error: any) => {
          showNotification(
            `${t(`user.vrCompanies.notifications.updatingFailure`)}:
                  ${error?.response?.data?.message || ''}
                  `,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return {
    submitHandler,
    isLoading,
  };
};
