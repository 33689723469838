import { useTranslation } from 'react-i18next';
import { TUseVerifyCodeFormLogic } from './types';

export const useVerifyCodeFormLogic: TUseVerifyCodeFormLogic = (
  submitHandler,
  phoneNumber
) => {
  const { t } = useTranslation();
  const phoneNumberLastCharacters = phoneNumber?.slice(-4);

  return {
    title: t('token.transfer.modal.enterPassword'),
    text: `${t('token.transfer.modal.enterCode')} ${phoneNumberLastCharacters}`,
    buttons: [
      {
        label: t('general.buttons.confirm'),
        onClick: submitHandler,
      },
    ],
  };
};
