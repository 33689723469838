import { OrderCreditDTO } from '@energyweb/issuer-irec-api-react-query-client';
import { useProjectControllerOrderCredits } from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';

export const useOrderCreditHandler = () => {
  const { t } = useTranslation();
  const { mutate } = useProjectControllerOrderCredits();

  const submitHandler = (values: OrderCreditDTO) => {
    mutate(
      { data: { ...values } },
      {
        onSuccess: () => {
          showNotification(
            t('projectStatus.notifications.orderCreditSuccess'),
            NotificationTypeEnum.Success
          );
        },
        onError: (error: any) => {
          if (error?.response?.status === 400) {
            showNotification(
              error?.response?.data?.message,
              NotificationTypeEnum.Error
            );
          } else {
            showNotification(
              `${t('projectStatus.notifications.orderCreditError')}:
                ${error?.response?.data?.message || ''}
                `,
              NotificationTypeEnum.Error
            );
          }
        },
      }
    );
  };

  return { submitHandler };
};
