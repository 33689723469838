import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    height: 160,
    marginBottom: 15,
  },
  grid: {
    flexBasis: '45%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&:not(:last-child)': {
      marginRight: 'auto',
    },
    '& .MuiBox-root': {
      width: '100%',
    },
  },
  subtitle: {
    color: '#A1BFA8',
    fontSize: 16,
  },
  text: {
    fontSize: 10,
    marginTop: 5,
  },
  formBtn: {
    background: '#78CC8A33',
    color: '#78CC8A',
    padding: '5px 40px',
    textTransform: 'capitalize',
    marginTop: 'auto',
    '&.Mui-disabled': {
      opacity: 0.4,
    },
    '& icon': {
      marginRight: 10,
    },
  },
  dropzone: {
    lineHeight: '10px',
  },
});
