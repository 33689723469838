import { useNotificationControllerUpdate } from '@energyweb/origin-backend-react-query-client';

export const useUpdateNotificationsStatus = () => {
  const { mutate } = useNotificationControllerUpdate();

  const submitHandler = (ids: any) => {
    mutate({ data: { ids } });
  };
  return {
    submitHandler,
  };
};
