import React from 'react';

import { Paper, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserExchangeDepositAddress } from '../UserExchangeDepositAddress';
import { getOrganizationBlockchainAddressComponent } from '../OrganizationBlockchainAddress';
import { useBlockchainAddressesEffects } from './BlockchainAddressesContainer.effects';

export const BlockchainAddressesContainer = () => {
  const { t } = useTranslation();

  const { userHasBlockchainAddressAttached } = useBlockchainAddressesEffects();
  const OrgBlockchainAddressComponent =
    getOrganizationBlockchainAddressComponent(userHasBlockchainAddressAttached);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" component="span">
        {t('user.profile.blockchainAddresses')}
      </Typography>
      <UserExchangeDepositAddress />
      <OrgBlockchainAddressComponent />
    </Box>
  );
};
