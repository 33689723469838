import React, { FC } from 'react';
import { useVerifiedUserAccountEffects } from './VerifiedUserAccount.effects';
import { ProjectsSummary } from '../../containers/user-account/ProjectsSummary';
import { t } from 'i18next';

export const VerifiedUserAccount: FC = () => {
  const { summaryData } = useVerifiedUserAccountEffects();

  return (
    <ProjectsSummary
      summaryData={summaryData}
      title={t('user.userProfile.creditsSummary')}
    />
  );
};
