import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { projectWellsForm } from 'libs/organization/logic/src/register/projectWellForm';
import { useProjectWellRegisterHandler } from '@energyweb/origin-ui-organization-data';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

export const useProjectWellEffects = (project: ProjectDTO) => {
  const { t } = useTranslation();

  const {
    initialValues,
    validationSchema,
    fields,
    validationMode,
    formInputsProps,
  } = projectWellsForm(t, project);

  const { submitHandler } = useProjectWellRegisterHandler(project);
  const { register, handleSubmit, formState, control, reset } = useForm({
    mode: validationMode,
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const { isValid, errors, dirtyFields } = formState;

  const firstSliceRange = Math.ceil(fields.length / 2);
  const firstColumn = fields.slice(1, firstSliceRange);
  const secondColumn = fields.slice(firstSliceRange, fields.length);

  return {
    errors,
    isValid,
    firstColumn,
    secondColumn,
    formInputsProps,
    initialValues,
    dirtyFields,
    control,
    fields,
    reset,
    handleSubmit,
    submitHandler,
    register,
  };
};
