import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      width: '90%',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      lineHeight: '10px',
    },
  },
  navigationContainer: {
    width: '100%',
    '@media (max-width: 768px)': {
      width: '90%',
      padding: '30px 0',
    },
  },
  pageContainer: {
    justifyContent: 'space-between',
  },
  primaryText: {
    color: theme.palette.primary.contrastText,
  },
  secondaryText: {
    color: theme.palette.secondary.main,
    fontFamily: theme.typography.fontFamily,
  },
  form: {
    width: '100%',
    '&& .MuiInputLabel-root': {
      color: theme.palette.secondary.main,
      '&&.Mui-error': {
        color: '#C92A2A !important',
      },
    },
    '&& .MuiFilledInput-root input': {
      color: theme.palette.primary.contrastText,
      fontFamily: theme.typography.body1.fontFamily,
      '&::placeholder': {
        color: '#ffffff',
      },
    },
    '&& .MuiFilledInput-root.Mui-error': {
      border: '1px solid #C92A2A',
    },
    '&& .MuiButton-root': {
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.primary.contrastText,
      '&&:hover': {
        cursor: 'pointer',
      },
    },
    '&& .MuiFormHelperText-root.Mui-error': {
      fontFamily: theme.typography.body1.fontFamily,
      background: '#999966',
      width: 'fit-content',
      padding: '0 5px',
      borderRadius: 6,
    },
  },
}));
