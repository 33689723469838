import React, { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import { CheckCircle, Close } from '@mui/icons-material';
import { useStyles } from './ChangeRequestPopUp.styles';
import { useCommonStyles } from '@energyweb/origin-ui-core';

export interface ConfirmationPopUpProps {
  isOpen: boolean;
  onClose: any;
  onSubmit: any;
  message: string;
}

export const ConfirmationPopUp: FC<ConfirmationPopUpProps> = ({
  isOpen,
  onClose,
  onSubmit,
  message,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  const onDialogClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onDialogClose}
        maxWidth="sm"
        fullWidth
        className={classes.container}
      >
        <IconButton className={classes.close} onClick={onDialogClose}>
          <Close />
        </IconButton>

        <DialogTitle className={classes.headerConteiner}>
          <Typography className={classes.header}>
            {t('projectStatus.changeRequestPopUp.pleaseConfirm')}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.main}>
          <Typography className={classes.subtitle}>{message}</Typography>
        </DialogContent>
        <DialogActions className={classes.actionsContainer}>
          <Button
            variant="contained"
            onClick={onDialogClose}
            className={`${commonClasses.button} ${commonClasses.secondaryButton}`}
          >
            {t('general.buttons.cancel')}
          </Button>
          <Button
            variant="contained"
            startIcon={<CheckCircle />}
            onClick={onSubmit}
            className={`${commonClasses.button} ${commonClasses.primaryButton}`}
          >
            {t('projectStatus.accept')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
