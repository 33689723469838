import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ProjectWell } from '@energyweb/origin-backend-react-query-client';
import { useStyles } from './ProjectWellsMap.styles';
import { useProjectWellsMapHook } from './ProjectWellsMap.effects';

export interface ProjectWellsMapProps {
  wells?: ProjectWell[];
}

export const ProjectWellsMap: FC<ProjectWellsMapProps> = ({ wells }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  useProjectWellsMapHook(wells);

  return (
    <Box sx={{ height: '100%' }}>
      <Typography variant="h6">
        {t('projectStatus.mapOfProjectWells.title')}
      </Typography>
      <Box className={classes.container}>
        <div id="map" className={classes.map}></div>
      </Box>
    </Box>
  );
};
