import React, { FC, ReactNode } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GenericForm } from '@energyweb/origin-ui-core';

import { useStyles } from './RegisterPage.styles';
import { LoginPageLayout } from '../../containers';
import { useRegisterPageEffects } from './RegisterPage.effects';
import { styles } from '../../common';

export interface RegisterPageProps {
  bgImage?: string;
  formIcon?: ReactNode;
}

export const RegisterPage: FC<RegisterPageProps> = ({ bgImage, formIcon }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = styles.formStyles.useStyles();

  const { formConfig, navigateToLogin } = useRegisterPageEffects();
  return (
    <LoginPageLayout bgImage={bgImage}>
      <Box className={classes.paper}>
        <Typography
          className={formClasses.formSubTitle + ' ' + classes.primaryText}
          variant="h4"
        >
          {t('user.register.formTitle')}
        </Typography>
        <GenericForm
          formClass={`${classes.form} ${formClasses.container}`}
          {...formConfig}
        />
        <Box className={formClasses.bottomActions}>
          <Typography className={classes.secondaryText}>
            {t('user.register.haveAnAccount')}
          </Typography>
          <Button data-cy="register-now-button" onClick={navigateToLogin}>
            {t('user.register.login')}
          </Button>
        </Box>
      </Box>
    </LoginPageLayout>
  );
};
