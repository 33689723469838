import { FollowingProject } from '@energyweb/issuer-irec-api-react-query-client';
import { useFollowingProjectControllerGetAll } from '@energyweb/origin-backend-react-query-client';

export const useFollowingProjectList = (query?: any) => {
  const { data: followingProjects, isLoading } =
    useFollowingProjectControllerGetAll({ ...query });
  return {
    projects: followingProjects?.map((data: FollowingProject) => data.project),
    isLoading,
  };
};
