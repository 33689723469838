import React, { FC } from 'react';
import { StartTokenIssuance } from './StartTokenIssuance';

export const AdminModalsCenter: FC = () => {
  return (
    <>
      <StartTokenIssuance />
    </>
  );
};
