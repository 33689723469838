import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { OverviewPage } from '@energyweb/origin-ui-organization-view';
import React from 'react';
import { TOverviewForm } from './types';

const OverviewFormWrapper = (project: ProjectDTO) => (props: any) =>
  <OverviewPage {...props} project={project} />;

export const overviewForm: TOverviewForm = (t, project?: ProjectDTO) => ({
  formTitle: t('organization.register.overview'),
  formTitleVariant: 'h5',
  initialValues: {
    projectId: project?.id,
  },
  fields: [],
  validationSchema: null,
  customStep: true,
  component: OverviewFormWrapper(project),
  buttonText: t('general.buttons.submit'),
});
