import { useNavigate } from 'react-router';
import { useUserSignInFormConfig } from '@energyweb/origin-ui-user-logic';
import { useApiRegisterUser } from '@energyweb/origin-ui-user-data';
import { UserModalsActionsEnum, useUserModalsDispatch } from '../../context';

export const useRegisterPageEffects = () => {
  const navigate = useNavigate();
  const dispatchModals = useUserModalsDispatch();

  const openUserRegisteredModal = () =>
    dispatchModals({
      type: UserModalsActionsEnum.SHOW_USER_REGISTERED,
      payload: true,
    });

  const { submitHandler } = useApiRegisterUser(openUserRegisteredModal);
  const formConfig = useUserSignInFormConfig(submitHandler);

  const navigateToLogin = () => {
    navigate('/login');
  };

  return { formConfig, navigateToLogin };
};
