import { GenericModalProps } from '@energyweb/origin-ui-core';
import {
  UserModalsActionsEnum,
  useUserModalsDispatch,
  useUserModalsStore,
} from '../../../context';
import { useStyles } from './TermsAndConditions.styles';

export const useTermsAndConditionsEffects = () => {
  const { termsConditions: open } = useUserModalsStore();
  const dispatchModals = useUserModalsDispatch();
  const classes = useStyles();

  const closeModal = () => {
    dispatchModals({
      type: UserModalsActionsEnum.SHOW_TERMS_CONDITIONS,
      payload: false,
    });
  };

  const dialogProps: GenericModalProps['dialogProps'] = {
    maxWidth: 'md',
    classes,
  };

  return { closeModal, open, dialogProps };
};
