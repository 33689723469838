import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { getProjectControllerGetQueryKey } from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useProjectControllerSendForVerification } from '@energyweb/origin-organization-irec-api-react-query-client';
import {
  OrganizationModalsActionsEnum,
  useOrgModalsDispatch,
} from 'libs/organization/view/src/context';

export const useUpdateProjectStatusHandler = (id: number) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useProjectControllerSendForVerification();
  const dispatchModals = useOrgModalsDispatch();

  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(id);

  const submitHandler = () => {
    mutate(
      { id },
      {
        onSuccess: () => {
          showNotification(
            t('project.register.notifications.requestVerificationSuccess'),
            NotificationTypeEnum.Success
          );
          dispatchModals({
            type: OrganizationModalsActionsEnum.SHOW_PROJECT_VERIFIED_SUCCESSFUL,
            payload: {
              open: true,
              text: t('organization.modals.projectVerified.projectOwner'),
            },
          });
          queryClient.invalidateQueries(projectQueryKey);
        },
        onError: (error: any) => {
          showNotification(
            `${t('project.register.notifications.requestVerificationError')}:
                ${error?.response?.data?.message || ''}`,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return { submitHandler, isLoading };
};
