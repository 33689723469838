import { useState, useEffect } from 'react';
import { useUser } from '@energyweb/origin-ui-user-data';
import { useProjectData } from '@energyweb/origin-ui-organization-data';
import { isRole, Role } from '@energyweb/origin-backend-core';

export const useProjectPublicEffect = (id: number) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const { project, isLoading: isProjectLoading } = useProjectData(id);
  const { user, userLoading: isUserLoading } = useUser();

  useEffect(() => {
    if (isRole(user, Role.ProjectVerifier)) {
      setFormDisabled(true);
    }
  }, [user]);

  return {
    project,
    formDisabled,
    isLoading: isProjectLoading || isUserLoading,
  };
};
