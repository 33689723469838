import React from 'react';
import { GenericForm, GenericModal } from '@energyweb/origin-ui-core';
import { useChangePasswordOTPModalEffects } from './ChangePasswordOTPModal.effects';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useStyles } from './ChangePasswordOTPModal.styles';
import { t } from 'i18next';

export const ChangePasswordOTPModal = () => {
  const {
    open,
    text,
    title,
    dialogProps,
    formProps,
    closeModal,
    buttonDisabled,
    requestVerificationCode,
  } = useChangePasswordOTPModalEffects();
  const classes = useStyles();

  return (
    <GenericModal
      dialogProps={dialogProps}
      open={open}
      closeButton={true}
      handleClose={closeModal}
      customContent={
        <Paper elevation={0}>
          <Box my={2}>
            <Typography className={classes.title}>{title}</Typography>
          </Box>
          <Box>
            <Typography className={classes.content}>{text}</Typography>
          </Box>
          <GenericForm
            formClass={`${classes.form}`}
            {...formProps}
            loading={buttonDisabled}
          />
          <Box className={classes.resentButton}>
            <Typography component="span">
              {t('user.verifyPhone.requestCodeForTransations')}
            </Typography>
            <Typography
              onClick={requestVerificationCode}
              component="span"
              color="textSecondary"
            >
              {t('user.verifyPhone.resendCode')}
            </Typography>
          </Box>
        </Paper>
      }
    />
  );
};
