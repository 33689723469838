import {
  RequestBatchClaimDTO,
  useTransferControllerRequestBatchClaim,
} from '@energyweb/exchange-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { __values } from 'tslib';

export const useRetireTokenHandler = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate } = useTransferControllerRequestBatchClaim();

  const submitHandler = (values: RequestBatchClaimDTO) => {
    return new Promise((res, rej) =>
      mutate(
      { data: { ...values } },
      {
        onSuccess: () => {
          showNotification(
            t('token.notifications.retiredSuccess'),
            NotificationTypeEnum.Success
          );
          navigate('/carbon-offset/', {
            state: {
              activeTab: 'historyList',
            },
          });
          res(true);
       },
          onError: (error: any) => {
            if (
              error?.response?.status === 400 ||
              error?.response?.status === 403
            ) {
              showNotification(
                error?.response?.data?.message,
                NotificationTypeEnum.Error
              );
            } else {
              showNotification(
                `${t('token.notifications.retiredFailure')}:
                  ${error?.response?.data?.message || ''}
                  `,
                NotificationTypeEnum.Error
              );
            }
            rej(error);
          },
        }
      )
    );
  };
  return { submitHandler };
};
