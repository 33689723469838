import React, { FC } from 'react';
import {
  Documents,
  FileUpload,
  FormInput,
  NotificationTypeEnum,
  showNotification,
  useCommonStyles,
} from '@energyweb/origin-ui-core';
import { Paper, Grid, Typography } from '@mui/material';
import { useStyles } from './RoyaltyOwnerRegistration.styles';
import { useRoyaltyOwnerEffects } from './RoyaltyOwnerRegistration.effects';
import { isEmpty } from 'lodash';
import {
  CreateOwnershipDTO,
  ProjectDTO,
} from '@energyweb/origin-backend-react-query-client';
import { ownershipControllerCreate } from '@energyweb/origin-backend-react-query-client';
import { UploadDto } from '@energyweb/origin-backend-react-query-client';
import { handleFileUpload } from '@energyweb/origin-ui-utils';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

export interface RoyaltyOwnerProps {
  project?: ProjectDTO;
  files?: Documents;
  formValues?: CreateOwnershipDTO;
}

export const RegisterRoyaltyOwnerForm: FC<RoyaltyOwnerProps> = ({
  project,
  files,
  formValues,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  const {
    errors,
    dirtyFields,
    fields,
    formInputsProps,
    documents,
    isLoading,
    reset,
    handleSubmit,
    register,
    submitHandler,
    uploadFunction,
    getUploadedFiles,
    onChange,
  } = useRoyaltyOwnerEffects(project);

  const onSubmit = async (values: any) => {
    try {
      let uploadedFiles: UploadDto[] = [];

      if (!project?.ownership) {
        uploadedFiles = await handleFileUpload([], files);

        if (uploadedFiles.length) {
          formValues.uploads = uploadedFiles;
          const ownership = await ownershipControllerCreate(formValues);
          values.ownershipId = ownership?.id;
        }
      }
      handleFileUpload(values.uploads, documents).then((result) => {
        values.uploads = [...result];
        submitHandler(values);
        reset();
      })
    } catch (error: any) {
      showNotification(
        error?.response?.data?.message,
        NotificationTypeEnum.Error
      );
    }
  };

  return (
    <Paper className={classes.paper}>
      <form className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">{fields[0].label}</Typography>
            <FormInput
              field={fields[0]}
              disabled={false}
              register={register}
              errorExists={!isEmpty((errors as any)[fields[0].name])}
              errorText={(errors as any)[fields[0].name]?.message ?? ''}
              isDirty={!!(dirtyFields as any)[fields[0].name]}
              variant={'outlined'}
              {...formInputsProps}
            />
          </Grid>
          <Grid item xs={6}>
            <FileUpload
              heading={t('project.register.royaltyOwner.agreement')}
              dropzoneText={t('file.upload.dropOrClick')}
              dropzoneClassName={classes.dropzone}
              apiUploadFunction={uploadFunction}
              onChange={onChange}
              wrapperProps={{ ['data-cy']: 'agreement' }}
              uploadedFiles={getUploadedFiles('agreement')}
              required={!documents['agreement']}
            />
          </Grid>
          <Grid item xs>
            <LoadingButton
              className={`${classes.actionButton} ${commonClasses.secondaryButton}`}
              type="submit"
              disabled={!documents['agreement'] || isLoading}
              loading={isLoading}
              loadingPosition="start"
              onClick={handleSubmit(onSubmit)}
            >
              {t('project.register.royaltyOwner.submit')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
