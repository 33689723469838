import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectListingFormsLayout } from '../../containers/layout/ProjectListingFormWrapper';
import { useProjectListingEffects } from './ProjectsListingPage.effects';

export const ProjectsListingPage: FC = () => {
  const { t } = useTranslation();
  const {
    projects,
    dataToFilter,
    header,
    actions,
    filterFields,
    applyFilters,
    headerProps,
  } = useProjectListingEffects();

  return (
    <ProjectListingFormsLayout
      key={projects.length}
      projects={projects}
      title={t('project.listing.title')}
      header={header}
      actions={actions}
      headerProps={headerProps}
      dataToFilter={dataToFilter}
      filterFields={filterFields}
      applyFilters={applyFilters}
    />
  );
};

export default ProjectsListingPage;
