import { IUserModalsStore, TUserModalsAction } from './types';

export enum UserModalsActionsEnum {
  SHOW_USER_REGISTERED = 'SHOW_USER_REGISTERED',
  SHOW_LOGIN_REGISTER_ORG = 'SHOW_LOGIN_REGISTER_ORG',
  SHOW_CREATE_EXCHANGE_ADDRESS = 'SHOW_CREATE_EXCHANGE_ADDRESS',
  SHOW_TERMS_CONDITIONS = 'SHOW_TERMS_CONDITIONS',
  SHOW_PASSWORD_CHANGE_OTP = 'SHOW_PASSWORD_CHANGE_OTP',
  SHOW_TOKEN_ISSUANCE_STARTED = 'SHOW_TOKEN_ISSUANCE_STARTED',
}

export const userModalsInitialState: IUserModalsStore = {
  userRegistered: false,
  loginRegisterOrg: false,
  createExchangeAddress: false,
  termsConditions: false,
  tokenIssuanceStarted: false,
  changePasswordOtpModal: {
    open: false,
    phoneNumber: null,
    onSubmit: null,
  },
};

export const userModalsReducer = (
  state = userModalsInitialState,
  action: TUserModalsAction
): IUserModalsStore => {
  switch (action.type) {
    case UserModalsActionsEnum.SHOW_USER_REGISTERED:
      return { ...state, userRegistered: action.payload };

    case UserModalsActionsEnum.SHOW_LOGIN_REGISTER_ORG:
      return { ...state, loginRegisterOrg: action.payload };

    case UserModalsActionsEnum.SHOW_CREATE_EXCHANGE_ADDRESS:
      return { ...state, createExchangeAddress: action.payload };

    case UserModalsActionsEnum.SHOW_TERMS_CONDITIONS:
      return { ...state, termsConditions: action.payload };

    case UserModalsActionsEnum.SHOW_PASSWORD_CHANGE_OTP:
      return { ...state, changePasswordOtpModal: action.payload };

    case UserModalsActionsEnum.SHOW_TOKEN_ISSUANCE_STARTED:
      return { ...state, tokenIssuanceStarted: action.payload };
  }
};
