import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '510px',
    '& .MuiFormControl-root': {
      '& label': {
        color: '#676767',
      },
    },
  },
  dataField: {
    '&& label': {
      color: '#676767',
      fontSize: '14px',
    },
    '&& input': {
      color: '#3F4246',
      textFillColor: '#3F4246',
    },
    '&& .Mui-disabled:before': {
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.primary.light,
    },
  },
  dataFieldOffset: {
    paddingRight: '45px',
  },
  tonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tonsInput: {
    '&& .MuiInput-input': {
      textAlign: 'end',
      appearance: 'textfield',
    },
  },
  submitAction: {
    height: '50px',
    minWidth: '250px',
  },
}));
