import React from 'react';
import { CircularProgress, Paper, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  UpdateUserData,
  UpdateUserEmail,
  UpdateUserPassword,
  BlockchainAddressesContainer,
  SettingsLayout,
} from '../../containers';
import { useProfileWalletPageEffects } from './ProfileWalletPage.effects';
import { useStyles } from './ProfileWalletPage.styles';

export const ProfileWalletPage = () => {
  const classes = useStyles();
  const { user, userLoading } = useProfileWalletPageEffects();
  const { t } = useTranslation();

  if (userLoading) return <CircularProgress />;

  return (
    <SettingsLayout>
      <Box px={2} width="100%">
        <Paper classes={{ root: classes.paper }}>
          <Typography variant="h4" sx={{ marginBottom: '50px' }}>
            {t('user.profile.walletTitle')}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            {/* <Box>
                        <UserBankAccount />
                    </Box> */}
            <Box sx={{ width: '100%' }}>
              <BlockchainAddressesContainer />
            </Box>
          </Box>
        </Paper>
      </Box>
    </SettingsLayout>
  );
};
