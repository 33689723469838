import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Box, CircularProgress } from '@mui/material';
import { GenericForm } from '@energyweb/origin-ui-core';
import { ProjectVerifier } from '@energyweb/origin-ui-assets';
import { useStyles } from './RegisterVerifierPage.styles';
import {
  useProjectVerifierPageEffects,
  FORM_SUBMIT_STATE,
} from './RegisterVerifierPage.effects';
import { RegistrationFormsLayout } from '../../containers';
import { RegisterErrorMessage } from '../../common/components';

export const RegisterProjectVerifierPage: FC = () => {
  const classes: any = useStyles();
  const {
    formProps,
    nextDisabled,
    formSubmitButtonText,
    buttonColor,
    showErrorMessage,
    formDisabled,
    isVerifierLoading,
  } = useProjectVerifierPageEffects();
  const { t } = useTranslation();

  return (
    <Box flex={1}>
      <RegistrationFormsLayout
        title={t('user.projectVerifier.mainFormTitle')}
        subTitle={t('user.projectVerifier.mainFormSubtitle')}
        descriptionTitle={t('user.projectVerifier.descriptionTitle')}
        icon={ProjectVerifier}
        nextButtonText={t('user.projectVerifier.validateAccount')}
        nextButtonDisabled={nextDisabled}
        error={showErrorMessage && <RegisterErrorMessage specUrl={'#'} />}
      >
        <Paper
          className={classes.paper}
          key={formProps.initialValues.companyId}
        >
          {isVerifierLoading ? (
            <CircularProgress />
          ) : (
            <GenericForm
              {...formProps}
              formTitleClassName={classes.formTitle}
              formClass={classes.formContainer}
              buttonText={t(formSubmitButtonText)}
              buttonProps={{
                disabled: formDisabled,
                variant: 'contained',
                'data-cy': 'project-owner-submit',
                className: classes[buttonColor],
              }}
              formDisabled={formDisabled}
              buttonDisabled={formDisabled}
            />
          )}
        </Paper>
      </RegistrationFormsLayout>
    </Box>
  );
};
