import { GenericModalProps } from '@energyweb/origin-ui-core';

type ModalLogicFunctionReturnType = Omit<GenericModalProps, 'open' | 'icon'>;

export type TProjectVerifiedSuccessfulLogic = (
  closeModal: () => void
) => ModalLogicFunctionReturnType;

export type TRiskFailOptionLogic = (
  closeModal: () => void
) => ModalLogicFunctionReturnType;

export type TDeleteProjectLogic = (
  closeModal: () => void,
  cancelClick: () => void
) => ModalLogicFunctionReturnType;
