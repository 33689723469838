import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      width: '90%',
    },
  },
  navigationContainer: {
    width: '100%',
    '@media (max-width: 768px)': {
      width: '90%',
      padding: '30px 0',
    },
  },
  pageContainer: {
    justifyContent: 'space-between',
  },
  primaryText: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
  },
  secondaryText: {
    color: theme.palette.secondary.main,
    fontFamily: theme.typography.fontFamily,
  },
  form: {
    width: '100%',
    '&& .MuiFilledInput-root input': {
      color: theme.palette.background.paper,
      fontFamily: theme.typography.body1.fontFamily,
      '&::placeholder': {
        color: theme.palette.secondary.main,
      },
    },
    '&& .MuiFilledInput-root.Mui-error': {
      border: '1px solid #C92A2A',
    },
    '&& .MuiButton-root': {
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.background.paper,
      '&&:hover': {
        cursor: 'pointer',
        backgroundColor:
          'linear-gradient(0deg, rgba(43, 32, 31, 0.3), rgba(43, 32, 31, 0.3)), #78CC8A !important',
      },
    },
    '&& .MuiFormHelperText-root.Mui-error': {
      fontFamily: theme.typography.body1.fontFamily,
      background: '#999966',
      width: 'fit-content',
      padding: '0 5px',
      borderRadius: 6,
    },
  },
}));
