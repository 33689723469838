import { LightenColor } from '@energyweb/origin-ui-theme';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  menuButton: {
    // '&:hover': {
    //   backgroundColor: LightenColor(theme.palette.text.secondary, 7),
    //   color: '#fff',
    // },
  },
  selectedMenuItem: {
    '&& .MuiButton-root': {
      color: '#78CC8A',
      '& .MuiSvgIcon-root': {
        fill: '#78CC8A',
      },
    },
  },
}));
