import React, { PropsWithChildren, ReactElement } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useProjectMapEffects, ProjectsMapProps } from './ProjectsMap.effects';
import { useStyles } from './ProjectsMap.styles';
import { useTranslation } from 'react-i18next';

type TProjectsMap = (
  props: PropsWithChildren<ProjectsMapProps>
) => ReactElement;

export const ProjectsMap: TProjectsMap = ({
  pendingProjects,
  verifiedProjects,
  rejectedProjects,
  changeRequestedProjects,
  user,
  title,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    pendingProjectsCount,
    changeRequestedProjectsCount,
    verifiedProjectsCount,
    userIsProjectOwner,
    noCarbonCredit,
  } = useProjectMapEffects({
    pendingProjects,
    verifiedProjects,
    rejectedProjects,
    changeRequestedProjects,
    user,
  });
  return (
    <Box>
      <Typography className={classes.title}>{title}</Typography>
      <div id="map" className={classes.map}></div>
      <Paper className={classes.mapInfo}>
        {(noCarbonCredit && (
          <Typography className={classes.status}>
            {t('user.userProfile.noCarbonCredit')}
          </Typography>
        )) || (
          <>
            <Typography className={classes.status + ' changeRequest'}>
              Change request{' '}
              <Typography component="span">
                {changeRequestedProjectsCount}
              </Typography>
            </Typography>
            <Typography className={classes.status + ' pending'}>
              Pending{' '}
              <Typography component="span">{pendingProjectsCount}</Typography>
            </Typography>
            {userIsProjectOwner && (
              <Typography className={classes.status + ' accepted'}>
                Accepted{' '}
                <Typography component="span">
                  {verifiedProjectsCount}
                </Typography>
              </Typography>
            )}
          </>
        )}
      </Paper>
    </Box>
  );
};
