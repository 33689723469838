import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React, { FC } from 'react';
import { TableHeaderData } from '../../../containers';

export interface TableComponentHeaderProps {
  headerData: TableHeaderData;
  order?: 'asc' | 'desc';
  orderBy?: string;
  sortByOrder?: (key: string) => void;
}

export const TableComponentHeader: FC<TableComponentHeaderProps> = ({
  headerData,
  order,
  orderBy,
  sortByOrder,
}) => {
  return (
    <TableHead>
      <TableRow>
        {Object.entries(headerData).map(([key, headerItem]) => {
          if (!order || key === 'actions') {
            return <TableCell key={headerItem}>{headerItem}</TableCell>;
          }
          return (
            <TableCell
              key={key}
              sortDirection={orderBy === key ? order : false}
            >
              <TableSortLabel
                active={orderBy === key}
                direction={order}
                onClick={(_) => sortByOrder(key)}
              >
                {headerItem}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
