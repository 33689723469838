import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: 'none',
    background: '#FFF',
    '& .MuiTable-root': {
      border: '1px solid #78CC8A',
      borderRadius: '4px',
    },

    '& .MuiTableBody-root tr, th': {
      backgroundColor: '#fff',
      borderBottom: '1px solid #E4E7EB',
      fontFamily: theme.typography.fontFamily,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    '& .MuiTableCell-root': {
      color: '#5E5E5E',
    },
    '& .MuiTableRow-footer': {
      display: 'none',
    },
    '& .MuiTableHead-root tr th': {
      backgroundColor: 'rgba(111, 207, 151, 0.3)',
    },
  },
}));
