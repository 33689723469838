import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    marginBottom: '46px',
    flexDirection: 'column',
  },
  title: {
    color: theme?.form?.fontColors?.secondary,
    marginBottom: 0,
  },
}));
