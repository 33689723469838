import { PageNotFound } from '@energyweb/origin-ui-core';
import React, { FC } from 'react';
import { Route, Routes } from 'react-router';
import {
  UserAppEnvProvider,
  UserEnvVariables,
  UserModalsProvider,
} from './context';
import { AccountModalsCenter } from './containers/modals';
import {
  ChangePhoneNumberPage,
  ProfilePage,
  RegisterProjectOwnerPage,
  SettingsPage,
  UserAccountPage,
  ProfileWalletPage,
  NotificationsPage,
} from './pages';

export interface AccountAppProps {
  routesConfig: {
    showUserProfile: boolean;
    showSettings: boolean;
    showRegisterProjectOwnerForm: boolean;
    showProjectRegisterForm: boolean;
  };
  envVariables: UserEnvVariables;
}

export const AccountApp: FC<AccountAppProps> = ({
  routesConfig,
  envVariables,
}) => {
  const {
    showUserProfile,
    showSettings,
    showRegisterProjectOwnerForm,
    showProjectRegisterForm,
  } = routesConfig;
  return (
    <UserModalsProvider>
      <UserAppEnvProvider variables={envVariables}>
        <Routes>
          {showUserProfile && <Route path="/" element={<UserAccountPage />} />}
          {showUserProfile && (
            <Route path="settings/profile" element={<ProfilePage />} />
          )}
          {showUserProfile && (
            <Route path="settings/wallet" element={<ProfileWalletPage />} />
          )}
          {showSettings && <Route path="settings" element={<SettingsPage />} />}
          {(showRegisterProjectOwnerForm || showProjectRegisterForm) && (
            <Route
              path="project-owner"
              element={<RegisterProjectOwnerPage />}
            />
          )}
          {(showRegisterProjectOwnerForm || showProjectRegisterForm) && (
            <Route
              path="project-owner/:id"
              element={<RegisterProjectOwnerPage />}
            />
          )}
          {showUserProfile && (
            <>
              <Route path="change-phone" element={<ChangePhoneNumberPage />} />
            </>
          )}
          <Route path="notifications" element={<NotificationsPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <AccountModalsCenter />
      </UserAppEnvProvider>
    </UserModalsProvider>
  );
};
