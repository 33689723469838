import React, {
  memo,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  InputBaseProps,
  Tooltip,
} from '@mui/material';
import { UseFormRegister } from 'react-hook-form';

export type FormInputField<FormValuesType> = {
  name: keyof FormValuesType;
  label: string;
  type?: 'text' | 'password' | 'number';
  placeholder?: string;
  required?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: {
    element: ReactNode;
    isValidCheck?: boolean;
  };
  helperText?: string;
  textFieldProps?: TextFieldProps;
  inputProps?: InputBaseProps['inputProps'];
  tooltipLabel?: string;
  multiline?: boolean;
};

export interface FormInputProps<FormValues> {
  field: FormInputField<FormValues>;
  register: UseFormRegister<FormValues>;
  errorExists?: boolean;
  errorText?: string;
  isDirty?: boolean;
  disabled?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
}

export type TFormInput = <FormValuesType>(
  props: PropsWithChildren<FormInputProps<FormValuesType>>
) => ReactElement;

export const FormInput: TFormInput = memo(
  ({
    field,
    register,
    errorExists = false,
    errorText = '',
    isDirty = true,
    variant = 'standard',
    disabled = false,
  }) => {
    const [isTooltipOpen, setTooltipOpen] = useState(false);
    const { ref, name, onBlur, onChange } = register(field.name as any);
    const showEndAdornment = field.endAdornment?.isValidCheck
      ? !errorExists && isDirty
      : true;

    const showLabel = field.placeholder
      ? { placeholder: field.placeholder }
      : { label: field.label };

    return (
      <Tooltip
        title={field.tooltipLabel ? field.tooltipLabel : ''}
        placement="top-start"
        open={isTooltipOpen}
        onMouseEnter={(_) => setTooltipOpen(true)}
        onMouseLeave={(_) => setTooltipOpen(false)}
      >
        <TextField
          fullWidth
          key={name}
          margin="normal"
          name={name ?? ''}
          disabled={disabled}
          onClick={(_) => setTooltipOpen(false)}
          {...showLabel}
          sx={
            field.multiline && {
              '& textarea': {
                minHeight: 65,
                maxHeight: 100,
                overflow: 'auto !important',
              },
            }
          }
          type={field.type ?? 'text'}
          inputRef={ref}
          error={errorExists}
          helperText={field.helperText ? field.helperText : errorText}
          required={field.required}
          variant={variant}
          multiline={field.multiline}
          inputProps={{
            ...field.inputProps,
          }}
          InputProps={{
            startAdornment: field.startAdornment && (
              <InputAdornment position="start">
                {field.startAdornment}
              </InputAdornment>
            ),
            endAdornment: field.endAdornment?.element && showEndAdornment && (
              <InputAdornment position="end">
                {field.endAdornment?.element}
              </InputAdornment>
            ),
          }}
          onChange={onChange}
          onBlur={onBlur}
          {...field.textFieldProps}
        />
      </Tooltip>
    );
  }
);
