import { LightenColor } from '@energyweb/origin-ui-theme';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
  },
  button: {
    borderRadius: 0,
    justifyContent: 'flex-start',
    fontWeight: 600,
    letterSpacing: '0.1rem',
    padding: '10px 20px 10px 8px',
    width: '100%',
    color: theme.palette?.text.primary,
    '&:hover': {
      backgroundColor: LightenColor(
        theme.palette?.background.paper,
        5,
        theme.palette?.mode
      ),
    },
  },
  icon: {
    width: 'auto',
    height: 'auto',
    marginRight: 15,
    '& img': {
      width: 24,
      height: 24,
      verticalAlign: 'middle',
    },
  },
}));
