import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import {
  ProjectDTO,
  ProjectWell,
} from '@energyweb/origin-backend-react-query-client';
import { useApiAllExchangeCertificates } from '@energyweb/origin-ui-exchange-data';

import {
  IconGeneratedCredit,
  IconPendingCredit,
  IconActiveCredit,
  IconRetiredCredit,
} from '@energyweb/origin-ui-assets';
import React from 'react';
import { t } from 'i18next';
import { useApiClaimedCertificates } from '@energyweb/origin-ui-user-data';

export interface ProjectOwnerAccountProps {
  pendingProjects: ProjectDTO[];
  verifiedProjects: ProjectDTO[];
}

export type TProjectOwnerAccount = (
  props: PropsWithChildren<ProjectOwnerAccountProps>
) => {
  summaryData: SummaryFormData[];
};

export type SummaryFormData = {
  status: string;
  count: number;
  icon: ReactNode;
};

export const useProjectOwnerAccountEffects: TProjectOwnerAccount = ({
  pendingProjects,
  verifiedProjects,
}) => {
  const [generatedCreditsCount, setGeneratedCreditsCount] = useState<number>(0);
  const [pendingCreditsCount, setPendingCreditsCount] = useState<number>(0);
  const { exchangeCertificates: activeCarbonCredits } =
    useApiAllExchangeCertificates();
  const { claimedCertificates: retiredCarbonCredits } =
    useApiClaimedCertificates();

  const calculateCarbonCredits = (
    wells: ProjectWell[],
    creditsCount: number
  ) => {
    wells?.map((well: ProjectWell) => {
      if (Number(well.totalCarbonOffset) > 0) {
        creditsCount += 1;
      }
    });
    return creditsCount;
  };

  useEffect(() => {
    let pendingCreditsCount = 0;

    if (pendingProjects?.length > 0) {
      pendingProjects.map((project: ProjectDTO) => {
        const wells = project.reservesSubmission?.wells || [];
        pendingCreditsCount = calculateCarbonCredits(
          wells,
          pendingCreditsCount
        );
      });
      setPendingCreditsCount(pendingCreditsCount);
    }
  }, [pendingProjects]);

  useEffect(() => {
    let generatedCreditsCount = 0;

    if (verifiedProjects?.length > 0) {
      verifiedProjects.map((project: ProjectDTO) => {
        const wells = project.reservesSubmission?.wells || [];
        generatedCreditsCount = calculateCarbonCredits(
          wells,
          generatedCreditsCount
        );
      });
      setGeneratedCreditsCount(generatedCreditsCount);
    }
  }, [verifiedProjects]);

  const summaryData: SummaryFormData[] = [
    {
      status: t('user.userProfile.generatedCredits'),
      count: generatedCreditsCount,
      icon: <IconGeneratedCredit />,
    },
    {
      status: t('user.userProfile.pendingCredits'),
      count: pendingCreditsCount,
      icon: <IconPendingCredit />,
    },
    {
      status: t('user.userProfile.activeCredits'),
      count: activeCarbonCredits?.length || 0,
      icon: <IconActiveCredit />,
    },
    {
      status: t('user.userProfile.retiredCredits'),
      count: retiredCarbonCredits?.length || 0,
      icon: <IconRetiredCredit />,
    },
  ];

  return {
    summaryData,
  };
};
