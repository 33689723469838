import { ProjectVerifierStatus } from '@energyweb/origin-backend-react-query-client';
import { ProjectVerifier } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
import { FormSelectOption } from '@energyweb/origin-ui-core';

export const prepareCompanyMembersOptions = (
  verifierCompanies: any,
  companyId: number
): FormSelectOption[] => {
  if (!companyId) {
    return [];
  }

  const company = verifierCompanies?.find(
    (company: any) => company.id === companyId
  );
  const VERIFIER_COMPANY_MEMBERS_OPTIONS: FormSelectOption[] = company?.members
    ?.filter((member: any) => member.status === ProjectVerifierStatus.Accepted)
    .map((verifier: ProjectVerifier) => ({
      value: verifier.id,
      label: verifier.user?.firstName + ' ' + verifier.user?.lastName,
    }));

  return VERIFIER_COMPANY_MEMBERS_OPTIONS;
};

export const prepareCompanyMemberEmail = (
  verifierCompanies: any,
  verifierId: number,
  companyId: number
): string => {
  if (!verifierId || !companyId) {
    return '';
  }

  const company = verifierCompanies?.find(
    (company: any) => company.id === companyId
  );
  const verifier = company?.members?.find(
    (member: any) =>
      member.id === verifierId &&
      member.status === ProjectVerifierStatus.Accepted
  );

  return verifier?.email;
};
