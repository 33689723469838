import React, { FC } from 'react';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { Paper } from '@mui/material';
import { TableComponent } from '@energyweb/origin-ui-core';
import { useUploadedFilesEffects } from './UploadedFiles.effects';
import { useStyles } from './UploadedFiles.styles';

export interface UploadedFilesPageProps {
  project?: ProjectDTO;
  navigateToStep?: (step: number) => void;
}

export const UploadedFilesPage: FC<UploadedFilesPageProps> = ({
  project,
  navigateToStep,
}) => {
  const { files, header } = useUploadedFilesEffects(project, navigateToStep);
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <TableComponent
        header={header}
        data={
          files
            ? files?.map((file: any, index: number) => ({
                id: index + 1,
                name: file?.name,
                actions: file?.actions,
              }))
            : []
        }
      />
    </Paper>
  );
};

export default UploadedFilesPage;
