import { TableComponent } from '@energyweb/origin-ui-core';
import { Skeleton } from '@mui/material';
import React, { FC } from 'react';
import { useProjectVerifiersPageEffects } from './ProjectVerifiersPage.effects';

export const ProjectVerifiersPage: FC = () => {
  const { tableData, pageLoading } = useProjectVerifiersPageEffects();

  if (pageLoading) {
    return <Skeleton height={200} width="100%" />;
  }
  return <TableComponent {...tableData} />;
};

export default ProjectVerifiersPage;
