import React, { FC } from 'react';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { LoadingButton } from '@mui/lab';
import { useProjectAdditionalDocumentsEffects } from './ProjectAdditionalDocuments.effects';
import { FileUpload, useCommonStyles } from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from '@mui/icons-material';

export interface ProjectAdditionalDocumentsProps {
  project?: ProjectDTO;
  submitButtonEnabled?: boolean;
}

export const ProjectAdditionalDocuments: FC<
  ProjectAdditionalDocumentsProps
> = ({ project, submitButtonEnabled }) => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();

  const {
    uploadFunction,
    onChange,
    onSubmit,
    showSection,
    getUploadedFiles,
    documents,
  } = useProjectAdditionalDocumentsEffects(project);

  const fileOptions = [
    {
      dataCy: 'P&A-plan',
      mimeType: 'application/zip',
    },
    {
      dataCy: 'P&A-operationsReport',
      mimeType: 'application/zip',
    },
    {
      dataCy: 'reclamationPlan',
    },
    {
      dataCy: 'operationsReport',
    },
    {
      dataCy: 'verifierReport',
    },
  ];

  return (
    <div>
      {fileOptions.map((option) => (
        <FileUpload
          dropzoneText={t('file.upload.dropOrClick')}
          heading={t(`projectStatus.additionalDocuments.${option.dataCy}`)}
          apiUploadFunction={uploadFunction}
          onChange={onChange}
          wrapperProps={{ ['data-cy']: option.dataCy }}
          uploadedFiles={getUploadedFiles(option.dataCy)}
          required={!documents[option.dataCy]}
          showSection={showSection(option.dataCy)}
          mimeType={option.mimeType}
        />
      ))}
      {submitButtonEnabled && (
        <span>
          <LoadingButton
            variant="contained"
            size="large"
            className={`${commonClasses.button} ${commonClasses.secondaryButton}`}
            onClick={onSubmit}
            sx={{ marginTop: '10px' }}
            startIcon={<CheckCircle />}
            loadingPosition="start"
            disabled={Object.keys(documents).length !== 5}
          >
            {t('general.buttons.submit')}
          </LoadingButton>
        </span>
      )}
    </div>
  );
};

export default ProjectAdditionalDocuments;
