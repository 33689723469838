import React from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import {
  ProjectDTO,
  UploadDto,
} from '@energyweb/origin-backend-react-query-client';
import _ from 'lodash';

export const useUploadedFilesEffects = (
  project: ProjectDTO,
  navigateToStep?: (step: number) => void
) => {
  const { t } = useTranslation();

  const UPLOAD_OBJECTS_IN_PROJECT = [
    'carbonContent.uploads',
    'reservesSubmission.uploads',
    'reservesValidation.uploads',
    'ownership.uploads',
    'supplementalData.uploads',
  ];

  const uploads = UPLOAD_OBJECTS_IN_PROJECT.map((e) => _.get(project, e));

  const getFilesFormData = () => {
    return uploads.flatMap((files: UploadDto[], index: number) => {
      return files?.map((file) => ({
        id: index + 1,
        name: file?.name,
        actions: actions(index + 1),
      }));
    });
  };

  const actions = (step: number) => ({
    icon: <Edit data-cy="edit-icon" />,
    name: t('general.buttons.edit'),
    onClick: () => {
      navigateToStep(step);
    },
  });

  const header = {
    id: 'Id',
    name: t('project.register.overviewDetails.fileName'),
    actions: t('project.register.overviewDetails.action'),
  };

  return {
    files: getFilesFormData(),
    header,
    actions,
  };
};
