import React, { useCallback } from 'react';
import { useProjectData } from '@energyweb/origin-ui-organization-data';
import { useNavigate } from 'react-router';

export const useProjectStatusEffect = (id: number) => {
  const navigate = useNavigate();
  const { isLoading, project } = useProjectData(id);

  const onEdit = useCallback(() => {
    navigate(`/project/${id}/edit`);
  }, []);

  const onFileEdit = useCallback((file: any) => {
    console.log(file);
    navigate(`/project/${id}/edit?step=${file?.step}`);
  }, []);

  return {
    isLoading,
    project,
    onEdit,
    onFileEdit,
  };
};
