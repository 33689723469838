import React, { FC } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { FileUpload } from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { useStyles } from './AutomatedWellRegistration.styles';
import { useAutomatedWellRegistrationEffects } from './AutomatedWellRegistration.effects';

export interface AutomatedWellRegistrationProps {
  project?: ProjectDTO;
}

export const AutomatedWellRegistration: FC<AutomatedWellRegistrationProps> = ({
  project,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    documents,
    handleSubmit,
    uploadFunction,
    onChange,
    getUploadedFiles,
    downloadXLSX,
  } = useAutomatedWellRegistrationEffects(project);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} className={classes.grid}>
        <Typography className={classes.subtitle}>
          {t('project.register.reservesSubmission.choosingBetweenLoading')}
        </Typography>
        <Typography className={classes.text}>
          {t('project.register.reservesSubmission.automatedTitle')}
        </Typography>
        <Typography className={classes.text}>
          {t('project.register.reservesSubmission.automatedDescriptions')}
        </Typography>
        <Button
          className={classes.formBtn}
          color="primary"
          size="large"
          variant="text"
          onClick={downloadXLSX}
        >
          <FileDownloadOutlinedIcon />
          {t('project.register.reservesSubmission.downloadTemplate')}
        </Button>
      </Grid>
      <Grid item xs={6} className={classes.grid}>
        <FileUpload
          heading={t('project.register.reservesSubmission.uploadWells')}
          dropzoneText={t('file.upload.dropOrClick')}
          apiUploadFunction={uploadFunction}
          onChange={onChange}
          wrapperProps={{ ['data-cy']: 'automatedRegistration' }}
          uploadedFiles={getUploadedFiles()}
          dropzoneClassName={classes.dropzone}
        />
        <Button
          className={classes.formBtn}
          color="primary"
          size="large"
          variant="text"
          disabled={getUploadedFiles().length === 0}
          onClick={() => handleSubmit()}
        >
          {t('project.register.reservesSubmission.propagate')}
        </Button>
      </Grid>
    </Grid>
  );
};
