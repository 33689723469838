import { useProjectControllerGetOwnerLocation } from '@energyweb/origin-backend-react-query-client';

export const useOwnerLocationData = () => {
  const { data, isLoading, isSuccess } = useProjectControllerGetOwnerLocation();

  return {
    location: data,
    isLoading,
    isProjectCreated: isSuccess,
  };
};
