import {
  useUserControllerUpdateOwnUserSettings,
  UpdateOwnUserSettingsDTO,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';

export const useApiUpdateUserSettings = () => {
  const { mutate } = useUserControllerUpdateOwnUserSettings();

  const { t } = useTranslation();

  const submitHandler = (
    values: Pick<UpdateOwnUserSettingsDTO, 'notifications'>
  ) => {
    return mutate(
      { data: { ...values } },
      {
        onSuccess: () => {
          showNotification(
            t('user.profile.notifications.userSettingsUpdateSuccess'),
            NotificationTypeEnum.Success
          );
        },
        onError: (error: any) => {
          showNotification(
            `${t('user.profile.notifications.userSettingsUpdateError')}:
              ${error?.response?.data?.message || ''}
              `,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return {
    submitHandler,
  };
};
