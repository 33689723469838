import React, { PropsWithChildren, ReactElement } from 'react';
import {
  ProjectVerifierAccountProps,
  useProjectVerifierAccountEffects,
} from './ProjectVerifierAccount.effects';
import { ProjectsSummary } from '../../containers/user-account/ProjectsSummary';
import { t } from 'i18next';

type TProjectVerifierAccount = (
  props: PropsWithChildren<ProjectVerifierAccountProps>
) => ReactElement;

export const ProjectVerifierAccount: TProjectVerifierAccount = ({
  pendingProjects,
  verifiedProjects,
  rejectedProjects,
  changeRequestedProjects,
}) => {
  const { summaryData } = useProjectVerifierAccountEffects({
    pendingProjects,
    verifiedProjects,
    rejectedProjects,
    changeRequestedProjects,
  });

  return (
    <ProjectsSummary
      summaryData={summaryData}
      title={t('user.userProfile.summary')}
    />
  );
};
