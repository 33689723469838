import React, { FC } from 'react';
import { useParams, useNavigate } from 'react-router';
import { CircularProgress } from '@mui/material';
import { Role } from '@energyweb/origin-backend-core';
import { useUser } from '@energyweb/origin-ui-user-data';
import { useProjectStatusEffect } from './ProjectStatusPage.effects';
import { ProjectStatusLayout } from '../../containers/layout';

export const ProjectStatusPage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { isLoading, project, onEdit, onFileEdit } = useProjectStatusEffect(
    id && +id
  );
  const { user, userLoading } = useUser();

  if (isLoading || userLoading) return <CircularProgress />;

  if (project?.createdBy?.id !== user?.id) {
    navigate(`/project/public/${id}`, { replace: true });
    return <></>;
  }

  return (
    <ProjectStatusLayout
      project={project}
      role={Role.ProjectOwner}
      onEdit={onEdit}
      onFileEdit={onFileEdit}
    />
  );
};
