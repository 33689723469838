import React from 'react';
import { Grid, Box, Typography, Pagination } from '@mui/material';
import dayjs from 'dayjs';
import { NotificationStatus } from '@energyweb/origin-backend-core';
import { useTranslation } from 'react-i18next';
import { useNotificationsPageEffects } from './NotificationsPage.effects';
import { useStyles } from './NotificationsPage.styles';

export const NotificationsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { notifications, setPage, pageCount } = useNotificationsPageEffects();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        {t('notifications.all.title')}
      </Typography>
      <Grid item classes={{ root: classes.paper }}>
        <Box className={classes.box + (notifications?.length ? '' : ' empty')}>
          <div>
            {notifications?.length ? (
              notifications.map((notification: any) => (
                <Box
                  key={notification.id}
                  className={
                    classes.notification +
                    (notification.status === NotificationStatus.NotViewed
                      ? ' not-viewed'
                      : '')
                  }
                >
                  <div className={classes.firstLine}>
                    <Typography className="title">
                      {notification.title}
                    </Typography>
                    <Typography className={classes.date}>
                      {dayjs(notification?.createdAt).format(
                        'M/DD/YYYY hh:mm A'
                      )}
                    </Typography>
                  </div>
                  <Typography className={classes.description}>
                    {notification.text}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography className={classes.emptyList}>
                {t('notifications.all.empty')}
              </Typography>
            )}
          </div>
          {notifications?.length > 0 && (
            <>
              <Typography className={classes.paginationDescription}>
                {t('notifications.all.show', {
                  length: notifications?.length,
                  count: pageCount,
                })}
              </Typography>
              <Pagination
                className={classes.pagination}
                size="small"
                shape="rounded"
                defaultPage={1}
                count={pageCount > 0 ? Math.ceil(pageCount / 10) : 0}
                onChange={(e, index) => setPage(index)}
              />
            </>
          )}
        </Box>
      </Grid>
    </div>
  );
};

8793198;
