import React, { FC } from 'react';
import { FormInput, FormSelect } from '@energyweb/origin-ui-core';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useStyles } from './ProjectWellRegistrationPage.styles';
import { useProjectWellEffects } from './ProjectWellRegistrationPage.effects';
import { isEmpty } from 'lodash';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

export interface RegisterWellProps {
  project?: ProjectDTO;
}
export const ProjectWellForm: FC<RegisterWellProps> = ({ project }) => {
  const classes = useStyles();

  const {
    errors,
    control,
    dirtyFields,
    firstColumn,
    secondColumn,
    fields,
    formInputsProps,
    reset,
    handleSubmit,
    register,
    submitHandler,
  } = useProjectWellEffects(project);

  const onSubmit = (value: any) => {
    submitHandler(value);
    reset();
  };
  return (
    <Box className={classes.form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item sm={12} lg={12}>
            <Typography variant="subtitle1">{fields[0].label}</Typography>
            <FormInput
              key={fields[0].label}
              field={fields[0]}
              disabled={false}
              register={register}
              errorExists={!isEmpty((errors as any)[fields[0].name])}
              errorText={(errors as any)[fields[0].name]?.message ?? ''}
              isDirty={!!(dirtyFields as any)[fields[0].name]}
              variant={'outlined'}
              {...formInputsProps}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={12} lg={6}>
              {firstColumn.map((field, index) => (
                <Box key={index}>
                  <Typography variant="subtitle1">{field.label}</Typography>
                  <FormInput
                    field={field}
                    disabled={false}
                    register={register}
                    errorExists={!isEmpty((errors as any)[field.name])}
                    errorText={(errors as any)[field.name]?.message ?? ''}
                    isDirty={!!(dirtyFields as any)[field.name]}
                    variant={'outlined'}
                    {...formInputsProps}
                  />
                </Box>
              ))}
            </Grid>
            <Grid item sm={12} lg={6}>
              {secondColumn.map((field, index) => (
                <Box key={index}>
                  <Typography variant="subtitle1">{field.label}</Typography>
                  {(field.select && (
                    <FormSelect
                      field={field}
                      control={control}
                      errorExists={!isEmpty((errors as any)[field.name])}
                      errorText={(errors as any)[field.name]?.message ?? ''}
                      variant={'outlined'}
                      disabled={false}
                      register={register}
                    />
                  )) || (
                    <FormInput
                      key={index}
                      field={field}
                      disabled={false}
                      register={register}
                      errorExists={!isEmpty((errors as any)[field.name])}
                      errorText={(errors as any)[field.name]?.message ?? ''}
                      isDirty={!!(dirtyFields as any)[field.name]}
                      variant={'outlined'}
                      {...formInputsProps}
                    />
                  )}
                </Box>
              ))}
            </Grid>
          </Grid>
          <Grid item sm={12} lg={12}>
            <Button
              color="primary"
              name="submit"
              size="large"
              variant="contained"
              type="submit"
            >
              Add Well
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
